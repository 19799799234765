@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.donationTab {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background-color: $white;
  padding: 15px;

  .tabsForDonation {
    .react-tabs__tab-list {
      display: flex;
      justify-content: start !important;
      background-color: white !important;
      padding: 0 !important;
      margin-bottom: 20px !important;
      margin-left: 10px;
    }
    .react-tabs__tab {
      font-size: 16px !important;
      background-color: white !important;

      &:first-of-type {
        margin-right: 40px;
      }
    }
    .react-tabs__tab--selected {
      background-color: white !important;
      font-weight: 700 !important;
    }
  }
}

// responsive
@include responsive(xl) {
  .donationTab {
    padding: 15px;

    .tabsForDonation {
      .react-tabs__tab-list {
        margin-bottom: 18px !important;
        margin-left: 9px;
      }
      .react-tabs__tab {
        font-size: 15px !important;

        &:first-of-type {
          margin-right: 35px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .donationTab {
    padding: 15px;

    .tabsForDonation {
      .react-tabs__tab-list {
        margin-bottom: 16px !important;
        margin-left: 9px;
      }
      .react-tabs__tab {
        font-size: 14px !important;

        &:first-of-type {
          margin-right: 30px;
        }
      }
    }
  }
}

@include responsive(md) {
  .donationTab {
    padding: 15px;

    .tabsForDonation {
      .react-tabs__tab-list {
        margin-bottom: 15px !important;
        margin-left: 9px;
      }
      .react-tabs__tab {
        font-size: 14px !important;

        &:first-of-type {
          margin-right: 25px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .donationTab {
    padding: 15px;

    .tabsForDonation {
      .react-tabs__tab-list {
        margin-bottom: 15px !important;
        margin-left: 9px;
      }
      .react-tabs__tab {
        font-size: 13px !important;

        &:first-of-type {
          margin-right: 22px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .donationTab {
    padding: 15px;

    .tabsForDonation {
      .react-tabs__tab-list {
        margin-bottom: 14px !important;
        margin-left: 0px;
      }
      .react-tabs__tab {
        font-size: 13px !important;

        &:first-of-type {
          margin-right: 20px !important;
        }
      }
    }
  }
}
