@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.causeCard {
  width: 265px;
  background-color: $white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  .causeContent {
    h4 {
      width: 270px;
      height: 46px;
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      vertical-align: middle;
    }
  }
  .causeButtons {
    .causeHeart {
      width: 60px;
      padding: 5px 17px;
      border-radius: 20px;
      background-color: rgba($color: $theme-color, $alpha: 0.5);
      cursor: pointer;
      svg {
        width: 25px;
        height: 23px;
        transform: scale(1);
        path {
          fill: white;
          stroke: white;
        }
      }
    }
    .causeHeartActive {
      width: 60px;
      padding: 5px 17px;
      border-radius: 20px;
      background-color: transparent;
      border: 1px solid $green-shadow;
      cursor: pointer;
      svg {
        width: 25px;
        height: 23px;
        transform: scale(1);
        animation: bubble 0.3s;
        path {
          fill: $red;
          stroke: $red;
        }
      }
      @keyframes bubble {
        0% {
          transform: scale(0.7);
        }
        100% {
          transform: scale(1.3);
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .causeCard {
    width: 253px;
    .causeContent {
      h4 {
        font-size: 15px;
      }
    }
    .causeButtons {
      .causeHeart {
        width: 60px;
        padding: 5px 16px;
        svg {
          width: 25px;
          height: 23px;
        }
      }
      .causeHeartActive {
        width: 60px;
        padding: 5px 16px;
        svg {
          width: 25px;
          height: 23px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .causeCard {
    width: 223px;
    .causeContent {
      h4 {
        font-size: 14px;
      }
    }
    .causeButtons {
      .causeHeart {
        width: 58px;
        padding: 5px 15px;
        svg {
          width: 25px;
          height: 23px;
        }
      }
      .causeHeartActive {
        width: 58px;
        padding: 5px 15px;
        svg {
          width: 25px;
          height: 23px;
        }
      }
    }
  }
}

@include responsive(md) {
  .causeCard {
    width: 285px;
    .causeContent {
      h4 {
        font-size: 13px;
      }
    }
    .causeButtons {
      .causeHeart {
        width: 58px;
        padding: 5px 15px;
        svg {
          width: 25px;
          height: 23px;
        }
      }
      .causeHeartActive {
        width: 58px;
        padding: 5px 15px;
        svg {
          width: 25px;
          height: 23px;
        }
      }
    }
  }
}
