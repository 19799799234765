@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.notification {
  width: 100%;
  margin-top: 85px;
  padding: 13px 0 71px;
}

.breadcrumbsNotification {
  margin-bottom: 45px;
}

.notification-bold {
  font-weight: 700 !important;
}

.notificationIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $green-shadow;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 20px;
}

.notificationCard {
  width: 100%;
  display: flex;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  padding: 30px;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .notificationData {
    width: calc(100% - 212px);
    margin-right: 125px;
    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 15px;
      color: $black_1;
      line-height: 17px;
      letter-spacing: 0.04em;
      margin-bottom: 5px;
    }
    h5 {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 10px;
      color: #666668;
      margin-bottom: 10px;
    }
    strong {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 14px;
      color: $black_1;
      letter-spacing: 0.04em;
    }
    .readLess {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .read-btn {
      display: flex;
      justify-content: end;

      button {
        font-family: $font_jost;
        font-weight: 700;
        color: $green-shadow;
        background: none;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          color: $theme-color;
        }
      }

      .hideBtn {
        display: none;
      }
    }
  }

  .cancleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $red;
    padding: 6px;
    cursor: pointer;
    flex-shrink: 0;
    svg path {
      stroke: $white;
      fill: $white;
    }
  }
}

// responsive
@include responsive(xl) {
  .notification {
    margin-top: 80px;
    padding: 12px 0 68px;
  }

  .breadcrumbsNotification {
    margin-bottom: 42px;
  }

  .notificationIcon {
    width: 45px;
    height: 45px;
    margin-right: 18px;
  }

  .notificationCard {
    padding: 28px;
    margin-bottom: 28px;

    .notificationData {
      width: calc(100% - 205px);
      margin-right: 120px;
      p {
        font-size: 14px;
        line-height: 17px;
      }
      h5 {
        font-size: 10px;
      }
      strong {
        font-size: 13px;
      }
      .readLess {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .read-btn {
        display: flex;
        justify-content: end;

        button {
          font-family: $font_jost;
          font-weight: 700;
          color: $green-shadow;
          background: none;
          cursor: pointer;
          @include prefix(transition, all 0.3s ease-in-out);

          &:hover {
            color: $theme-color;
          }
        }

        .hideBtn {
          display: none;
        }
      }
    }

    .cancleButton {
      width: 19px;
      height: 19px;
      padding: 6px;
    }
  }
}

@include responsive(lg) {
  .notification {
    padding: 12px 0 64px;
  }

  .breadcrumbsNotification {
    margin-bottom: 40px;
  }

  .notificationIcon {
    width: 43px;
    height: 43px;
    margin-right: 17px;
  }

  .notificationCard {
    padding: 26px;
    margin-bottom: 26px;

    .notificationData {
      width: calc(100% - 190px);
      margin-right: 110px;
      p {
        line-height: 16px;
      }
      h5 {
        font-size: 10px;
      }
    }

    .cancleButton {
      width: 18px;
      height: 18px;
      padding: 6px;
    }
  }
}

@include responsive(md) {
  .notification {
    padding: 10px 0 60px;
  }

  .breadcrumbsNotification {
    margin-bottom: 35px;
  }

  .notificationIcon {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }

  .notificationCard {
    padding: 22px;
    margin-bottom: 22px;

    .notificationData {
      width: calc(100% - 165px);
      margin-right: 90px;
      p {
        font-size: 13px;
        line-height: 16px;
      }
      h5 {
        font-size: 10px;
      }
      strong {
        font-size: 12px;
      }
      .readLess {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .read-btn {
        display: flex;
        justify-content: end;

        button {
          font-family: $font_jost;
          font-weight: 700;
          color: $green-shadow;
          background: none;
          cursor: pointer;
          @include prefix(transition, all 0.3s ease-in-out);

          &:hover {
            color: $theme-color;
          }
        }

        .hideBtn {
          display: none;
        }
      }
    }

    .cancleButton {
      width: 18px;
      height: 18px;
      padding: 6px;
    }
  }
}

@include responsive(sm) {
  .notification {
    padding: 10px 0 35px;
  }

  .breadcrumbsNotification {
    margin-bottom: 25px;
  }

  .notificationIcon {
    width: 38px;
    height: 38px;
    margin-right: 15px;
  }

  .notificationCard {
    padding: 18px;
    margin-bottom: 18px;

    .notificationData {
      width: calc(100% - 100px);
      margin-right: 30px;
      p {
        line-height: 15px;
      }
      h5 {
        font-size: 10px;
      }
    }

    .cancleButton {
      width: 17px;
      height: 17px;
      padding: 5px;
    }
  }
}

@include responsive(xs) {
  .notification {
    padding: 10px 0 15px;
  }

  .breadcrumbsNotification {
    margin-bottom: 15px;
  }

  .notificationIcon {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    padding: 7px;
  }

  .notificationCard {
    padding: 15px;
    margin-bottom: 15px;

    .notificationData {
      width: calc(100% - 68px);
      margin-right: 10px;
      p {
        line-height: 14px;
        margin-bottom: 5px;
      }
      h5 {
        font-size: 10px;
      }
    }

    .cancleButton {
      width: 15px;
      height: 15px;
      padding: 4px;
    }
  }
}
