@import "./variable";
@import "./mixin";

// Fonts Size
// h1 {
//     font-size: 45px;
// }
h2 {
  font-size: 48px;

  .big {
    font-size: 32px;
  }
}

h3 {
  font-size: 32px;
}

// h4 {
//     font-size: 18px;
// }
// h5 {
//     font-size: 16px;
// }
p {
  font-size: 16px;

  &.small {
    font-size: 14px;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-success {
  color: $success;
}

.text-danger {
  color: $danger;
  margin-top: 5px;
}

.text-warning {
  color: $warning;
}

.text-info {
  color: $info;
}

.text-minute {
  color: $color2;
}

.position-relative {
  position: relative;
}
.swiper-button-prev.swiper-button-lock.swiper-button-disabled,
.swiper-button-next.swiper-button-lock.swiper-button-disabled {
  display: none;
}

.learnMoreWrap {
  .learnTextLink {
    text-decoration: underline;
    font-size: 16px;
    color: $theme-color;
    &:hover {
      opacity: 0.8;
    }
  }
}

// Font Family

// Width css
.w-100 {
  width: 100%;
}

// Height SCSS
.min-h100 {
  min-height: 100vh;
}

.h-100 {
  height: 100%;
}
.p-0 {
  padding: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.d-inlineBlock {
  display: inline-block;
}

.container {
  width: 1440px;
  max-width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 auto;
}

.container-main {
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;
}

.notFound {
  font-family: $font_jost;
  font-size: 16px;
  font-weight: 400;
  color: $black_1;
  text-transform: capitalize;
}

.communityFound{
  margin-top: 20px;
}

option {
  font-family: $font_jost !important;
  font-weight: 400;
  font-size: 14px !important;
  color: $light-gray;
}

.imageBorder {
  border: 0.2px solid rgba(238, 234, 234, 0.75);
}

.tooltip-information-wrap{
  cursor: pointer;
}

.react-tooltip{
  width: 500px !important;
  z-index: 9999;
}

@include responsive(xl) {
  .container {
    width: 1170px;
    padding: 0 15px;
  }

  .container-main {
    width: 1120px;
    padding: 0 15px;
  }

  .notFound {
    font-size: 15px;
  }
}

@include responsive(lg) {
  .notFound {
    font-size: 14px;
  }
}

@include responsive(md) {
  .notFound {
    font-size: 13px;
  }
}

@include responsive(sm) {
  .notFound {
    font-size: 12px;
  }
}

// @include responsive(xs) {
//   .container {
//     width: 290px;
//   }

//   .container-main {
//     width: 290px !important;
//   }
// }
