@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.guestSignin {
  .login-forgot {
    margin-bottom: 100px;
  }

  .sign-btn {
    margin-top: 40px;
  }
}

// responsive
@include responsive(lg) {
  .guestSignin {
    .login-forgot {
      margin-bottom: 80px;
    }
    .sign-btn {
      margin-top: 36px;
    }
  }
}

@include responsive(md) {
  .guestSignin {
    .login-forgot {
      margin-bottom: 70px;
    }
    .sign-btn {
      margin-top: 32px;
    }
  }
}

@include responsive(sm) {
  .guestSignin {
    .login-forgot {
      margin-bottom: 60px;
    }
    .sign-btn {
      margin-top: 30px;
    }
  }
}

@include responsive(xs) {
  .guestSignin {
    .login-forgot {
      margin-bottom: 50px;
    }
    .sign-btn {
      margin-top: 25px;
    }
  }
}
