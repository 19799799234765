@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.causeList {
  width: 100%;
  margin-top: 85px;
  padding: 13px 0 71px;
}

.breadcrumbsCause {
  margin-bottom: 45px;
}

.causelistWrap {
  background-color: $white-shadow;
}

.cause-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 10px;

  h3 {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 30px;
    color: $black_1;
  }
}

.causeList-search {
  width: 50%;
  margin: 40px auto 0px;

  .cause-search-formGrp {
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 10px 16px;
    background-color: $white;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);

    &:focus-within {
      border: 1px solid $theme-color;
    }

    input {
      background-color: transparent;
      width: calc(100% - 50px);
      margin: 0 10px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
    }
    ::-webkit-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
    }

    :-ms-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
    }

    ::placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
    }
    .cancle-cause {
      cursor: pointer;
    }
    svg {
      width: 14px;
      height: 14px;

      fill: gray;
    }
  }
}

.cause-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .causeCard {
    width: 312px;
    margin-right: 30px;
    margin-top: 30px;
    position: relative;

    &:nth-of-type(4n + 0) {
      margin-right: 0px;
    }

    .causeImage {
      width: 100%;
      height: 220px;

      figure {
        display: inline-block;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          color: transparent;
        }
      }
    }
    .causeContent {
      padding: 14px 21px 27px 20px;
      text-align: center;
    }
    .causeButtons {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}

// responsive
@include responsive(xl) {
  .causeList {
    margin-top: 80px;
    padding: 12px 0 68px;
  }

  .breadcrumbsCause {
    margin-bottom: 42px;
  }

  .cause-header {
    margin-bottom: 7px;

    h3 {
      font-size: 28px;
    }
  }

  .cause-wrap {
    width: 900px;
    margin: 10px auto 0;
    .causeCard {
      width: 280px;
      margin-right: 28px;
      margin-top: 28px;

      &:nth-of-type(3n + 0) {
        margin-right: 0;
      }

      &:nth-of-type(4n + 0) {
        margin-right: 26px;
      }

      &:nth-of-type(12) {
        margin-right: 0;
      }

      &:nth-of-type(24) {
        margin-right: 0;
      }

      .causeImage {
        height: 210px;
      }
      .causeContent {
        padding: 12px 18px 25px 18px;

        h4 {
          width: 100%;
          font-size: 16px;
          height: 40px;
        }
      }
      .causeButtons {
        .causeHeart {
          width: 62px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .causeList {
    padding: 12px 0 65px;
  }

  .breadcrumbsCause {
    margin-bottom: 40px;
  }

  .cause-header {
    margin-bottom: 7px;

    h3 {
      font-size: 26px;
    }
  }

  .cause-wrap {
    width: 856px;
    .causeCard {
      width: 268px;
      margin-right: 26px;
      margin-top: 26px;

      &:nth-of-type(3n + 0) {
        margin-right: 0;
      }

      .causeImage {
        height: 200px;
      }
      .causeContent {
        padding: 12px 18px 25px 18px;

        h4 {
          width: 100%;
          font-size: 15px;
          height: 40px;
        }
      }
      .causeButtons {
        .causeHeart {
          width: 62px;
        }
      }
    }
  }
}

@include responsive(md) {
  .causeList {
    padding: 10px 0 55px;
  }

  .breadcrumbsCause {
    margin-bottom: 30px;
  }

  .cause-header {
    margin-bottom: 6px;

    h3 {
      font-size: 22px;
    }
  }

  .causeList-search {
    width: 80%;
  }

  .cause-wrap {
    width: 616px;
    .causeCard {
      width: 298px;
      margin-right: 20px;
      margin-top: 20px;

      &:nth-of-type(4n + 0) {
        margin-right: 20px;
      }

      &:nth-of-type(3n + 0) {
        margin-right: 20px;
      }

      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }

      .causeImage {
        height: 200px;
      }
      .causeContent {
        padding: 10px 16px 20px 16px;

        h4 {
          width: 100%;
          font-size: 14px;
          height: 38px;
        }
      }
      .causeButtons {
        .causeHeart {
          width: 55px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .causeList {
    padding: 10px 0 40px;
  }

  .breadcrumbsCause {
    margin-bottom: 20px;
  }

  .cause-header {
    margin-bottom: 5px;

    h3 {
      font-size: 18px;
    }
  }

  .cause-wrap {
    width: 440px;
    .causeCard {
      width: 212px;
      margin-right: 15px;
      margin-top: 15px;

      &:nth-of-type(4n + 0) {
        margin-right: 15px;
      }

      &:nth-of-type(3n + 0) {
        margin-right: 15px;
      }

      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }

      .causeImage {
        height: 150px;
      }
      .causeContent {
        padding: 8px 12px 16px 12px;

        h4 {
          width: 100%;
          font-size: 13px;
          height: 34px;
        }
      }
      .causeButtons {
        .causeHeart {
          width: 45px;
          padding: 5px 12px;
        }
        .causeHeartActive {
          width: 45px;
          padding: 5px 12px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .causeList {
    padding: 10px 0 15px;
  }

  .breadcrumbsCause {
    margin-bottom: 15px;
  }

  .cause-header {
    margin-bottom: 4px;

    h3 {
      font-size: 14px;
    }
  }

  .causeList-search {
    width: 100%;
  }

  .cause-wrap {
    width: 100%;
    justify-content: center;
    .causeCard {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 0px;
      margin-top: 12px;
      padding: 10px;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: $light-green;
      }

      &:nth-of-type(4n + 0) {
        margin-right: 0px;
      }

      &:nth-of-type(3n + 0) {
        margin-right: 0px;
      }

      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }

      .causemainContent {
        display: flex;
        align-items: center;
      }

      .causeImage {
        width: 50px;
        height: 50px;
        margin-right: 8px;
      }
      .causeContent {
        padding: 0;
        margin-right: 8px;

        h4 {
          width: 158px;
          font-size: 12px;
          height: auto;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .causeButtons {
        position: static;
        .causeHeart {
          width: 45px;
        }
      }
    }
  }
}
