@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.mainAboutContent {
  .contentImage {
    width: 466px;

    .mainImage {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        color: transparent;
      }
    }
  }

  .contentText {
    width: auto;
    padding-top: 30px;

    // h3 {
    //   font-family: $font_jost;
    //   font-weight: 700;
    //   font-size: 16px;
    //   color: $green-shadow;
    //   margin-bottom: 15px;
    // }

    h3 {
      font-family: $font_lora;
      font-size: 21px;
      line-height: 26px;
      font-weight: 700;
      color: $black_1;
      margin-bottom: 15px;
    }

    h2 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 45px;
      line-height: 65px;
      color: $black_1;
      margin-bottom: 10px;
    }

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: $light-gray;
    }
  }
}

// responsive
@include responsive(lg) {
  .mainAboutContent {
    .contentImage {
      width: 400px;
    }
    .contentText {
      padding-top: 25px;

      // h3 {
      //   font-size: 18px;
      //   margin-bottom: 20px;
      // }
      h2 {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 8px;
      }
      p {
        font-size: 15px;
        line-height: 25px;
      }
    }
  }
} 

@include responsive(md) {
  .mainAboutContent {
    .contentImage {
      width: 370px;
    }
    .contentText {
      padding-top: 20px;

      // h3 {
      //   font-size: 16px;
      //   line-height: 18px;
      // }
      h2 {
        font-size: 26px;
        line-height: 35px;
        margin-bottom: 6px;
      }
      p {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
}

@include responsive(sm) {
  .mainAboutContent {
    .contentImage {
      width: 300px;
    }
    .contentText {
      padding-top: 16px;

      // h3 {
      //   font-size: 14px;
      //   line-height: 16px;
      // }
      h2 {
        font-size: 22px;
        line-height: 25px;
        margin-bottom: 2px;
      }
      p {
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
}

@include responsive(xs) {
  .mainAboutContent {
    .contentImage {
      width: 300px;
    }
    .contentText {
      padding-top: 10px;

      // h3 {
      //   font-size: 12px;
      // }
      h2 {
        font-size: 18px;
        line-height: 22px;
      }
      p {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
  