@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.communityDetail {
  width: 100%;
  margin: 114px 0 60px;

  .communityDetailsWrap {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
  }
  .newline {
    white-space: pre-line;
  }
  .communitySidebar {
    width: 556px;
  }

  .detailsCard {
    background: $white;
    margin-bottom: 30px;
    padding: 30px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

    .detailsFigure {
      max-width: 494px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        color: transparent;
      }
    }

    .communityName {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 14px;

      h1 {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        color: $black_1;
        margin-right: 8px;
      }

      .communityStarWrap {
        display: flex;
        align-items: center;
      }
      .communityStar {
        margin-top: -6px;
      }

      span {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 13px;
        color: $light-gray;
        text-transform: capitalize;
        margin-left: 15px;
      }
    }

    .cardUser {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .telegramButtons {
        flex-shrink: 0;
        width: 60px;
        padding: 11px 20px 8px 21px;
        border-radius: 50px;
        cursor: pointer;
        background-color: $green-shadow;
        svg {
          width: 30px;
          path {
            fill: $white;
            height: 20px;
          }
        }
      }

      ul {
        display: flex;
        flex-direction: column;
      }

      li {
        display: flex;
        // align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;

        svg {
          flex-shrink: 0;
        }

        h5 {
          font-family: $font_jost;
          font-weight: 400;
          color: $light-gray;
          font-size: 14px;
          margin-left: 5px;
        }

        &:last-of-type {
          margin-bottom: 0;
          margin-right: 0px;
        }
      }
    }

    .notAssociatedDetailsButton {
      button {
        width: 162px;
        background-color: $green-shadow;
        border-radius: 20px;
        text-align: center;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        padding: 8px 15px 7px 15px;
        margin-right: 15px;
        color: $white;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $theme-color;
        }
      }
    }

    .associatedDetailsButtons {
      button {
        text-align: center;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        border-radius: 20px;
        cursor: pointer;
      }

      .leaveButton {
        width: 177px;
        background-color: $white_1;
        padding: 8px 13px 7px;
        color: $black_1;
        margin-right: 14px;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $white-dark;
        }
      }

      .detailsBasket {
        width: 120px;
        background-color: $green-shadow;
        color: $white;
        padding: 8px 15px 7px;
        margin-right: 15px;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $theme-color;
        }
      }

      .rateButton {
        width: 162px;
        background-color: $green-shadow;
        color: $white;
        padding: 8px 15px 7px;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }

  .research {
    background-color: $white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    padding: 30px 28px 30px 30px;
    margin-bottom: 30px;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      margin-bottom: 14px;
    }

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      margin-bottom: 15px;
      line-height: 25px;
    }

    .readLess {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .member {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .member-list {
        display: flex;
        align-items: center;

        .member-images {
          display: flex;
          margin-right: 15px;

          figure {
            display: flex;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid $white;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
              color: transparent;
            }

            span {
              display: inline-block;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-size: cover;
            }

            &:nth-child(2) {
              margin-left: -5px;
            }

            &:nth-child(3) {
              margin-left: -5px;
            }

            &:nth-child(4) {
              margin-left: -5px;
            }
          }
        }

        .member-count {
          font-family: $font_jost;
          font-weight: 400;
          color: $green-shadow;
          font-size: 12px;
        }
      }

      button {
        font-family: $font_jost;
        font-weight: 700;
        color: $green-shadow;
        background: none;
        cursor: pointer;
      }

      .hideBtn {
        display: none;
      }
    }
  }

  .share {
    width: 100%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    background-color: $white;
    padding: 25px 30px 30px;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      margin-bottom: 8px;
      text-transform: capitalize;
    }

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      margin-bottom: 15px;
      text-transform: capitalize;
      line-height: 25px;
    }

    .copy-text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $light-green;
      border-radius: 50px;
      padding: 5px 4px 5px 22px;

      input {
        width: 365px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $light-gray;
        background: none;
        text-overflow: ellipsis;
      }

      button {
        border-radius: 50px;
        background-color: $green-shadow;
        width: 84px;
        height: 28px;
        text-align: center;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        cursor: pointer;
      }
    }
  }
}

.communityContent {
  width: 754px;

  .communityTab {
    .react-tabs__tab-list {
      border-bottom: none;
      width: 538px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $light-green;
      border-radius: 50px;
      margin-bottom: 25px;
      padding: 22px 32px;
    }

    .react-tabs__tab {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
      border: none;
      padding: 0px;
      background-color: $light-green;
    }

    .react-tabs__tab--selected {
      font-weight: 700;
      font-size: 14px;
      color: $green-shadow;
      background-color: $light-green;
    }
  }
}

.communityModal {
  width: 650px;
  height: 372px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 44px 52px 55px 44px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
    }

    button {
      background-color: $white;
      cursor: pointer;
    }
  }

  .modal-body {
    margin-bottom: 55px;

    h3 {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      text-transform: capitalize;
      margin-bottom: 35px;
    }

    .communityRating {
      display: flex;
      justify-content: center;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;

    .submit {
      width: 178px;
      background-color: $green-shadow;
      border-radius: 50px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 20px;
      color: $white;
      text-align: center;
      padding: 10px 47px 9px 48px;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: $theme-color;
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .communityDetail {
    margin: 108px 0 58px;

    .communityDetailsWrap {
      width: 1138px;
      margin-top: 45px;
    }

    .communitySidebar {
      width: 500px;
    }

    .detailsCard {
      padding: 28px;
      margin-bottom: 28px;

      .detailsFigure {
        max-width: 100%;
        height: 220px;
        margin-bottom: 23px;
      }

      .communityName {
        margin-bottom: 13px;

        h1 {
          font-size: 15px;
          line-height: 23px;
          margin-right: 7px;
        }

        .communityStar {
          margin-top: -5px;
        }

        span {
          font-size: 13px;
          margin-left: 13px;
        }
      }

      .cardUser {
        margin-bottom: 18px;

        .telegramButtons {
          width: 55px;
          padding: 10px 20px 7px 18px;
        }

        li {
          margin-right: 9px;
          margin-bottom: 9px;

          &:last-of-type {
            margin-bottom: 0;
          }

          h5 {
            font-size: 13px;
            margin-left: 4px;
          }
        }
      }

      .notAssociatedDetailsButton {
        button {
          width: 155px;
          font-size: 13px;
          padding: 8px 14px 7px 14px;
        }
      }

      .associatedDetailsButtons {
        button {
          font-size: 13px;
        }

        .leaveButton {
          width: 145px;
          padding: 8px 12px 7px;
        }

        .detailsBasket {
          width: 110px;
          padding: 8px 13px 7px;
          margin-right: 12px;
        }

        .rateButton {
          width: 155px;
          padding: 7px 14px;
        }
      }
    }

    .research {
      padding: 28px 26px 28px 28px;
      margin-bottom: 28px;

      h3 {
        font-size: 15px;
        margin-bottom: 12px;
      }
      p {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 14px;
      }
    }

    .share {
      padding: 22px 28px 28px;

      h3 {
        font-size: 15px;
        margin-bottom: 7px;
      }
      p {
        font-size: 13px;
        margin-bottom: 13px;
        line-height: 23px;
      }
      .copy-text {
        padding: 5px 4px 5px 15px;

        input {
          width: 335px;
          font-size: 13px;
          letter-spacing: 0.001em;
          white-space: nowrap;
          overflow: hidden;
        }
        button {
          width: 75px;
          height: 26px;
          font-size: 13px;
        }
      }
    }
  }

  .communityContent {
    width: 600px;

    .communityTab {
      .react-tabs__tab-list {
        width: 530px;
        margin-bottom: 24px;
        padding: 21px 30px;
      }
      .react-tabs__tab {
        font-size: 14px;
      }
      .react-tabs__tab--selected {
        font-size: 14px;
      }
    }
  }

  .communityModal {
    width: 630px;
    height: 350px;
    padding: 42px 50px 52px 42px;

    .modal-header {
      margin-bottom: 16px;

      h3 {
        font-size: 28px;
      }
    }

    .modal-body {
      margin-bottom: 52px;

      h3 {
        font-size: 13px;
        margin-bottom: 32px;
      }
    }

    .modal-footer {
      .submit {
        width: 170px;
        font-size: 19px;
        padding: 9px 45px 8px 46px;
      }
    }
  }
}

@include responsive(lg) {
  .communityDetail {
    margin: 104px 0 45px;

    .communityDetailsWrap {
      margin-top: 40px;
      width: 960px;
    }

    .communitySidebar {
      width: 420px;
    }

    .detailsCard {
      margin-bottom: 26px;
      padding: 26px;

      .detailsFigure {
        height: 200px;
        margin-bottom: 21px;
      }

      .communityName {
        margin-bottom: 12px;

        h1 {
          font-size: 14px;
          line-height: 22px;
          margin-right: 7px;
        }

        .communityStar {
          margin-top: -5px;
        }

        span {
          font-size: 13px;
          margin-left: 11px;
        }
      }

      .cardUser {
        margin-bottom: 16px;

        .telegramButtons {
          width: 55px;
          padding: 10px 20px 7px 18px;
        }

        li {
          margin-right: 9px;

          h5 {
            font-size: 13px;
            margin-left: 4px;
          }
        }
      }

      .notAssociatedDetailsButton {
        button {
          width: 150px;
          font-size: 13px;
          padding: 7px 12px;
        }
      }

      .associatedDetailsButtons {
        button {
          font-size: 13px;
        }

        .leaveButton {
          width: 125px;
          padding: 7px 12px;
          margin-right: 10px;
        }

        .detailsBasket {
          width: 105px;
          padding: 8px 11px 7px;
          margin-right: 10px;
        }

        .rateButton {
          width: 118px;
          padding: 7px 12px;
        }
      }
    }

    .research {
      padding: 26px 24px 26px 26px;
      margin-bottom: 26px;

      h3 {
        font-size: 14px;
        margin-bottom: 10px;
      }
      p {
        font-size: 13px;
        margin-bottom: 11px;
        line-height: 20px;
      }
    }

    .share {
      padding: 20px 26px;

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }
      p {
        font-size: 13px;
        margin-bottom: 12px;
        line-height: 20px;
      }
      .copy-text {
        padding: 5px 4px 5px 15px;

        input {
          width: 268px;
          font-size: 12px;
        }
        button {
          width: 72px;
          height: 25px;
          font-size: 12px;
        }
      }
    }
  }

  .communityContent {
    width: 510px;

    .communityTab {
      .react-tabs__tab-list {
        width: 410px;
        margin-bottom: 25px;
        padding: 18px 26px;
      }
      .react-tabs__tab {
        font-size: 14px;
      }
      .react-tabs__tab--selected {
        font-size: 14px;
      }
    }
  }

  .communityModal {
    width: 620px;
    height: 326px;
    padding: 38px 46px 48px 38px;

    .modal-header {
      margin-bottom: 14px;

      h3 {
        font-size: 26px;
      }
    }

    .modal-body {
      margin-bottom: 48px;

      h3 {
        font-size: 13px;
        margin-bottom: 28px;
      }
    }

    .modal-footer {
      .submit {
        width: 160px;
        font-size: 18px;
        padding: 8px 40px;
      }
    }
  }
}

@include responsive(md) {
  .communityDetail {
    margin: 95px 0 35px;

    .communityDetailsWrap {
      margin-top: 30px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }

    .communitySidebar {
      width: 100%;
      margin-bottom: 25px;
    }

    .detailsCard {
      margin-bottom: 22px;
      padding: 22px;

      .detailsFigure {
        height: 200px;
        margin-bottom: 15px;
      }

      .communityName {
        margin-bottom: 10px;

        h1 {
          font-size: 13px;
          line-height: 20px;
          margin-right: 7px;
        }

        .communityStar {
          margin-top: -5px;
        }

        span {
          font-size: 13px;
          margin-left: 10px;
        }
      }

      .cardUser {
        margin-bottom: 14px;
        .telegramButtons {
          width: 40px;
          padding: 4px 13px;
        }

        li {
          margin-right: 8px;
          margin-bottom: 8px;

          svg {
            width: 15px;
            height: 15px;
          }

          h5 {
            font-size: 13px;
            margin-left: 4px;
          }
        }
      }

      .notAssociatedDetailsButton {
        button {
          width: 145px;
          font-size: 13px;
          padding: 7px 0px;
        }
      }

      .associatedDetailsButtons {
        button {
          font-size: 13px;
        }

        .leaveButton {
          width: 123px;
          padding: 7px 0px;
          margin-right: 10px;
        }

        .detailsBasket {
          width: 105px;
          padding: 8px 11px 7px;
          margin-right: 10px;
        }

        .rateButton {
          width: 118px;
          padding: 7px 0px;
        }
      }
    }

    .research {
      padding: 24px;
      margin-bottom: 22px;

      h3 {
        font-size: 14px;
        margin-bottom: 8px;
      }
      p {
        font-size: 13px;
        margin-bottom: 8px;
        line-height: 18px;
      }
    }

    .share {
      padding: 20px 24px;

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }
      p {
        font-size: 13px;
        margin-bottom: 12px;
        line-height: 20px;
      }
      .copy-text {
        padding: 5px 4px 5px 15px;

        input {
          width: 580px;
          font-size: 12px;
        }
        button {
          width: 72px;
          height: 25px;
          font-size: 12px;
        }
      }
    }
  }

  .communityContent {
    width: 100%;

    .communityTab {
      .react-tabs__tab-list {
        width: 450px;
        margin-bottom: 22px;
        padding: 14px 45px 15px 30px;
      }
      .react-tabs__tab {
        font-size: 15px;
      }
      .react-tabs__tab--selected {
        font-size: 15px;
      }
    }
  }

  .communityModal {
    width: 600px;
    height: 304px;
    padding: 34px 42px 42px 34px;

    .modal-header {
      margin-bottom: 12px;

      h3 {
        font-size: 24px;
      }
    }

    .modal-body {
      margin-bottom: 44px;

      h3 {
        font-size: 13px;
        margin-bottom: 24px;
      }
    }

    .modal-footer {
      .submit {
        width: 158px;
        font-size: 17px;
        padding: 8px 36px;
      }
    }
  }
}

@include responsive(sm) {
  .communityDetail {
    margin: 90px 0 25px;

    .communityDetailsWrap {
      margin-top: 20px;
    }

    .communitySidebar {
      margin-bottom: 20px;
    }

    .detailsCard {
      margin-bottom: 20px;
      padding: 20px;

      .detailsFigure {
        height: 180px;
        margin-bottom: 12px;
      }

      .communityName {
        margin-bottom: 9px;

        h1 {
          font-size: 12px;
          line-height: 18px;
          margin-right: 7px;
        }

        .communityStar {
          margin-top: -5px;
        }

        span {
          font-size: 12px;
          margin-left: 9px;
        }
      }

      .cardUser {
        margin-bottom: 12px;

        .telegramButtons {
          width: 40px;
          padding: 4px 13px;
        }

        li {
          margin-right: 8px;

          svg {
            width: 14px;
            height: 14px;
          }

          h5 {
            font-size: 12px;
            margin-left: 4px;
          }
        }
      }

      .notAssociatedDetailsButton {
        button {
          width: 140px;
          font-size: 12px;
          padding: 6px 0px;
        }
      }

      .associatedDetailsButtons {
        button {
          font-size: 12px;
        }

        .leaveButton {
          width: 122px;
          padding: 6px 0px;
          margin-right: 10px;
        }

        .detailsBasket {
          width: 100px;
          padding: 8px 11px 7px;
          margin-right: 10px;
        }

        .rateButton {
          width: 118px;
          padding: 7px 0px;
        }
      }
    }

    .research {
      padding: 22px;
      margin-bottom: 20px;

      h3 {
        font-size: 13px;
        margin-bottom: 6px;
      }
      p {
        font-size: 12px;
        margin-bottom: 6px;
        line-height: 18px;
      }
    }

    .share {
      padding: 18px 22px;

      h3 {
        font-size: 13px;
        margin-bottom: 4px;
      }
      p {
        font-size: 12px;
        margin-bottom: 8px;
        line-height: 18px;
      }
      .copy-text {
        padding: 5px 4px 5px 15px;

        input {
          width: 395px;
          font-size: 12px;
        }
        button {
          width: 72px;
          height: 25px;
          font-size: 12px;
        }
      }
    }
  }

  .communityContent {
    .communityTab {
      .react-tabs__tab-list {
        width: 410px;
        margin-bottom: 20px;
        padding: 14px 40px 14px 28px;
      }
      .react-tabs__tab {
        font-size: 14px;
      }
      .react-tabs__tab--selected {
        font-size: 14px;
      }
    }
  }

  .communityModal {
    width: 500px;
    height: 300px;
    padding: 30px 38px 38px 30px;

    .modal-header {
      margin-bottom: 10px;

      h3 {
        font-size: 22px;
      }
    }

    .modal-body {
      margin-bottom: 40px;

      h3 {
        font-size: 12px;
        margin-bottom: 22px;
      }
    }

    .modal-footer {
      .submit {
        width: 150px;
        font-size: 16px;
        padding: 8px 34px;
      }
    }
  }
}

@include responsive(xs) {
  .communityDetail {
    margin: 68px 0 15px;

    .communityDetailsWrap {
      margin-top: 15px;
      width: 100%;
    }

    .communitySidebar {
      margin-bottom: 15px;
    }

    .detailsCard {
      margin-bottom: 15px;
      padding: 15px;

      .detailsFigure {
        height: 100px;
        margin-bottom: 10px;
      }

      .communityName {
        margin-bottom: 8px;

        h1 {
          line-height: 14px;
          margin-right: 5px;
        }

        .communityStar {
          margin-top: -2px;
        }

        span {
          margin-left: 8px;
        }
      }

      .cardUser {
        margin-bottom: 10px;

        .telegramButtons {
          width: 42px;
          padding: 0px 13px 0px 11px;
        }

        li {
          margin-right: 6px;

          svg {
            width: 13px;
            height: 13px;
          }

          h5 {
            font-size: 11px;
            margin-left: 4px;
          }
        }
      }

      .notAssociatedDetailsButton {
        button {
          width: 120px;
          font-size: 12px;
          padding: 5px 0px;
          margin-right: 10px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .associatedDetailsButtons {
        button {
          font-size: 11px;
          padding: 5px 0;
        }

        .leaveButton {
          width: 120px;
          padding: 5px 0px;
          margin-bottom: 5px;
        }

        .rateButton {
          width: 120px;
          padding: 5px 0px;
        }

        .detailsBasket {
          padding: 5px 0;
        }
      }
    }

    .research {
      padding: 15px;
      margin-bottom: 15px;

      h3 {
        font-size: 12px;
        margin-bottom: 4px;
      }
      p {
        font-size: 11px;
        margin-bottom: 5px;
        line-height: 15px;
      }
    }

    .share {
      padding: 15px;

      h3 {
        font-size: 12px;
        margin-bottom: 4px;
      }
      p {
        font-size: 11px;
        margin-bottom: 7px;
        line-height: 16px;
      }
      .copy-text {
        padding: 4px 4px 4px 12px;

        input {
          width: 170px;
          font-size: 11px;
        }
        button {
          width: 60px;
          height: 22px;
          font-size: 12px;
        }
      }
    }
  }

  .communityContent {
    .communityTab {
      .react-tabs__tab-list {
        width: 250px;
        margin-bottom: 15px;
        padding: 15px;
      }
      .react-tabs__tab {
        font-size: 12px;
      }
      .react-tabs__tab--selected {
        font-size: 12px;
      }
    }
  }

  .communityModal {
    width: 290px;
    height: auto;
    padding: 15px;

    .modal-header {
      margin-bottom: 8px;

      h3 {
        font-size: 18px;
      }
    }

    .modal-body {
      margin-bottom: 30px;

      h3 {
        font-size: 12px;
        margin-bottom: 20px;
      }

      .star-container {
        padding: 0 !important;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }
      svg {
        width: 35px !important;
        height: 35px !important;
      }
    }

    .modal-footer {
      .submit {
        width: 130px;
        font-size: 14px;
        padding: 7px 30px;
      }
    }
  }
}
