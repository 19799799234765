@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.tabCommunity {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  padding-bottom: 30px;

  .tabCommunityHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    padding: 25px 30px 0 40px;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 24px;
      color: $black_1;
      text-transform: capitalize;
    }
    button {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $green_shadow;
      text-transform: capitalize;
      background: none;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        color: $theme-color;
      }
    }
  }
  .tabCommunityBodyWrap {
    .content {
      max-height: 858px;
      padding: 0 30px 0 40px;
    }
  }
  .tabCommunityBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
    padding: 10px;
    @include prefix(transition, all 0.3s ease-in-out);
    &:hover {
      background-color: $light-green;
      padding: 10px;
      border-radius: 7px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .tabCommunityLeft {
      display: flex;
      align-items: center;
      figure {
        width: 65px;
        height: 65px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      h3 {
        width: 320px;
        font-family: $font_jost;
        font-weight: 500;
        font-size: 14px;
        color: $black_1;
      }
    }
    .tabCommunityRight {
      button {
        width: 116px;
        height: 31px;
        border-radius: 21px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        background-color: $white_1;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $theme-color;
        }
      }

      .communityBasket {
        background-color: $green_shadow;
        margin-right: 15px;
      }

      .join {
        background-color: $green_shadow;
      }

      .leave {
        background-color: $white-dark;
        color: $gray_1;

        &:hover {
          background-color: $white-dark;
          color: $black_1;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .tabCommunity {
    padding-bottom: 28px;

    .tabCommunityHeader {
      margin-bottom: 13px;
      padding: 24px 28px 0 38px;

      h3 {
        font-size: 22px;
      }
      button {
        font-size: 13px;
      }
    }
    .tabCommunityBodyWrap {
      .content {
        max-height: 539px;
        padding: 0px 28px 0 36px;
      }
    }
    .tabCommunityBody {
      margin-bottom: 18px;
      padding: 9px;
      &:hover {
        padding: 9px;
      }

      .tabCommunityLeft {
        figure {
          width: 62px;
          height: 62px;
          margin-right: 18px;
        }
        h3 {
          font-size: 13px;
          width: 185px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .tabCommunityRight {
        button {
          width: 110px;
          height: 28px;
          font-size: 13px;
        }

        .communityBasket {
          margin-right: 13px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .tabCommunity {
    padding-bottom: 26px;

    .tabCommunityHeader {
      margin-bottom: 12px;
      padding: 22px 26px 0 30px;

      h3 {
        font-size: 20px;
      }
      button {
        font-size: 13px;
      }
    }
    .tabCommunityBodyWrap {
      .content {
        padding: 0 26px 0 32px;
        max-height: 482px;
      }
    }
    .tabCommunityBody {
      margin-bottom: 16px;
      padding: 8px;
      &:hover {
        padding: 8px;
      }

      .tabCommunityLeft {
        figure {
          width: 55px;
          height: 55px;
          margin-right: 12px;
        }
        h3 {
          font-size: 12px;
          width: 150px;
        }
      }
      .tabCommunityRight {
        button {
          width: 99px;
          height: 28px;
          font-size: 13px;
        }

        .communityBasket {
          margin-right: 10px;
        }
      }
    }
  }
}

@include responsive(md) {
  .tabCommunity {
    padding-bottom: 24px;

    .tabCommunityHeader {
      margin-bottom: 12px;
      padding: 22px 26px 0 26px;

      h3 {
        font-size: 18px;
      }
      button {
        font-size: 13px;
      }
    }
    .tabCommunityBodyWrap {
      .content {
        padding: 0 22px 0 26px;
        max-height: 100%;
      }
    }
    .tabCommunityBody {
      margin-bottom: 15px;
      padding: 8px;
      &:hover {
        padding: 8px;
      }

      .tabCommunityLeft {
        figure {
          width: 60px;
          height: 60px;
          margin-right: 15px;
        }
        h3 {
          font-size: 13px;
          width: 300px;
        }
      }
      .tabCommunityRight {
        button {
          width: 120px;
          height: 30px;
          font-size: 13px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .tabCommunity {
    padding-bottom: 22px;

    .tabCommunityHeader {
      margin-bottom: 12px;
      padding: 20px 24px 0 24px;

      h3 {
        font-size: 16px;
      }
      button {
        font-size: 12px;
      }
    }
    .tabCommunityBodyWrap {
      .content {
        padding: 0 20px 0 22px;
      }
    }
    .tabCommunityBody {
      margin-bottom: 13px;
      padding: 8px;
      &:hover {
        padding: 8px;
      }

      .tabCommunityLeft {
        figure {
          width: 55px;
          height: 55px;
          margin-right: 13px;
        }
        h3 {
          width: 185px;
          font-size: 12px;
        }
      }
      .tabCommunityRight {
        button {
          width: 100px;
          height: 28px;
          font-size: 12px;
        }

        .communityBasket {
          margin-right: 10px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .tabCommunity {
    padding-bottom: 15px;

    .tabCommunityHeader {
      margin-bottom: 10px;
      padding: 15px 15px 0 15px;

      h3 {
        font-size: 14px;
      }
      button {
        font-size: 12px;
      }
    }
    .tabCommunityBodyWrap {
      .content {
        padding: 0 15px;
      }
    }
    .tabCommunityBody {
      margin-bottom: 10px;
      padding: 6px 0 6px 6px;
      flex-direction: column;
      align-items: flex-start;
      &:hover {
        padding: 6px 0 6px 6px;
      }

      .tabCommunityLeft {
        margin-bottom: 10px;
        figure {
          width: 45px;
          height: 45px;
          margin-right: 10px;
          flex-shrink: 0;
        }
        h3 {
          max-width: 200px;
          font-size: 12px;
        }
      }
      .tabCommunityRight {
        button {
          width: 88px;
          height: 28px;
          font-size: 12px;
        }

        .communityBasket {
          margin-right: 6px;
        }
      }
    }
  }
}
