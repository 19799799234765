@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.form {
  .form-group {
    margin: 21px 0 0px;
  }

  // .forgot-pass{
  //     margin-bottom: 40px;
  // }

  .submit {
    margin-bottom: 12px;
    a {
      background-color: $green-shadow;
      border-radius: 50px;
      display: inline-block;
      width: 350px;
      padding: 11px 0;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $white;
    }
  }

  .login-forgot {
    margin-bottom: 175px;

    a {
      font-family: $font_jost;
      font-weight: 500;
      font-size: 12px;
      color: $black_1;
      text-decoration: underline;
    }
  }
}

// responsive
@include responsive(lg) {
  .login-forgot {
    margin-bottom: 110px;

    a {
      font-size: 12px;
    }
  }
}

@include responsive(md) {
  .login-forgot {
    margin-bottom: 80px;

    a {
      font-size: 11px;
    }
  }
}

@include responsive(sm) {
  .login-forgot {
    margin-bottom: 50px;

    a {
      font-size: 11px;
    }
  }
}

@include responsive(xs) {
    .login-forgot {
        margin-bottom: 60px !important;
    
        a {
          font-size: 10px;
        }
      }
}
