@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.detailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  h2 {
    font-family: $font_jost;
    font-weight: 700;
    font-size: 24px;
    color: $black_1;
    text-transform: capitalize;
  }

  .detailsSwitch {
    span {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      margin-right: 16px;
    }

    button {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 14px;
      color: $green-shadow;
      background-color: transparent;
      cursor: pointer;
    }
  }
}

.scrollBar {
  width: 100%;
  // height: 712px;
  // height: 488px;

  .content {
    padding: 6px;
    background: transparent;
    width: 100%;
  }
}

.dropdownAvtar {
  position: relative;

  figure {
    cursor: pointer;
    width: 25px;
    height: 25px;
    text-align: center;
  }

  .profileDropdown {
    width: 160px;
    position: absolute;
    right: 10px;
    top: 28px;
    z-index: 8888;
    box-shadow: 0 0px 8px rgba($color: $black, $alpha: 0.2);

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      right: 10px;
      width: 20px;
      height: 20px;
      background: $white-shadow;
      transform: rotate(45deg);
      box-shadow: 0 0px 8px rgba($color: $black, $alpha: 0.2);
    }

    li {
      width: 100%;
      padding: 7px 24px 7px 21px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      z-index: 555;
      position: relative;
      color: $light-gray;
      background-color: $white;
      @include prefix(transition, all 0.3s ease-in-out);
      cursor: pointer;

      &:hover {
        background-color: $light-green;
      }
    }
  }
}

.postDeleteModal {
  width: 535px;
  height: 262px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  //   padding: 15px 18px 50px 18px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 18px 12px 0;

    button {
      background-color: $white;
      cursor: pointer;
    }
  }

  .modal-body {
    text-align: center;
    padding: 0 38px;

    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 28px;
      color: $black_1;
      // text-transform: capitalize;
      margin-bottom: 17px;
    }

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $gray;
      line-height: 25px;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      margin-bottom: 22px;
    }

    button {
      width: 200px;
      border-radius: 50px;
      padding: 13px 75px;
      cursor: pointer;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      color: $white;
      @include prefix(transition, all 0.3s ease-in-out);

      &:first-of-type {
        margin-right: 30px;
        background-color: $black_1;
      }

      &:last-of-type {
        background-color: $green-shadow;

        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }

  .modal-footer {
    display: none;
  }
}

.postEditModal {
  width: 630px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 46px 50px 90px 50px;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
      text-transform: capitalize;
    }

    button {
      background-color: $white;
      cursor: pointer;
    }
  }

  .modal-body {
    text-align: center;

    .textarea {
      // margin-bottom: 25px;
      textarea {
        width: 100%;
        height: 120px;
        border: 1px solid $light-gray;
        border-radius: 7px;
        resize: none;
        padding: 8px 15px;
        color: $light-gray;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;

        &::placeholder {
          color: $light-gray;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          text-transform: capitalize;
        }
      }
    }

    .postVideoUpload {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .editUpload {
      display: flex;
      // justify-content: center;
      border: 2px dashed $green-shadow;
      border-radius: 50px;
      padding: 9px 13px 8px 11px;
      cursor: pointer;
      margin: 30px 0px 0px;

      .uploading {
        width: 508px;
        display: flex;
        justify-content: space-between;
        background-color: $light-green;
        padding: 9px 12px 10px 20px;
        border-radius: 30px;

        figure {
          margin-right: 20px;
        }

        .fileContent {
          text-align: left;
          margin-right: 64px;

          h4 {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 12px;
            color: $black;
          }

          p {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 12px;
            color: rgba($color: #000000, $alpha: 0.4);
          }
        }

        button {
          width: 105px;
          background-color: $green-shadow;
          border-radius: 50px;
          font-family: $font_jost;
          font-weight: 700;
          color: $white;
          cursor: pointer;
          @include prefix(transition, all 0.3s ease-in-out);

          &:hover {
            background-color: $theme-color;
          }
        }
      }
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      li {
        margin-right: 24px;
      }
    }

    .forgot {
      display: flex;
      justify-content: space-between;

      .remember {
        display: flex;
        align-items: center;

        .check {
          width: 16px;
          height: 16px;
          background-color: white;
          border-radius: 50%;
          vertical-align: middle;
          border: 1px solid $theme-color;
          appearance: none;
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
        }

        .check:checked {
          border: none;
        }

        label {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          color: $light-gray;
          letter-spacing: 0.04em;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      .pass {
        a {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          color: $light-gray;
          letter-spacing: 0.04em;
        }
      }
    }

    .editButton {
      margin-top: 30px;

      button {
        width: 200px;
        border-radius: 50px;
        padding: 13px 68px;
        cursor: pointer;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        color: $white;
        @include prefix(transition, all 0.3s ease-in-out);

        &:first-of-type {
          margin-right: 30px;
          background-color: $black_1;

          &:hover {
            background-color: $theme-color;
          }
        }

        &:last-of-type {
          background-color: $green-shadow;
        }
      }
    }

    .image-item {
      border: none;
      width: 78px !important;
      height: 70px;
      border-radius: 7px !important;

      img {
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }

    .video-post-box {
      position: relative;
      @include prefix(transition, all 0.3s ease-in-out);
      &:hover .video-item__btn-wrapper {
        display: flex;
      }
    }

    .image-item__btn-wrapper::before {
      background-color: rgba(240, 247, 239, 0.8);
    }

    .video-item__btn-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: none;
      align-items: center;
      justify-content: center;
      z-index: 55555;
      background-color: rgba(240, 247, 239, 0.8);
      button {
        width: 22px;
        height: 22px;
        background-color: transparent;
        cursor: pointer;

        svg {
          width: 100%;
          height: 100%;
          path,
          circle {
            width: 100% !important;
            height: 100% !important;
            fill: transparent !important;
            stroke: $green-shadow !important;
          }
        }
      }
      .video-dlt {
        display: inline-block !important;
        margin-right: 5px;

        svg path {
          fill: $green-shadow !important;
        }
      }

      // &::before {
      //   content: "";
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   background-color: rgba(240, 247, 239, 0.8);
      // }
    }

    .img-btn:first-of-type {
      display: none;
    }
  }

  .modal-footer {
    display: none;
  }
}

// responsive
@include responsive(xl) {
  .detailsHeader {
    margin-bottom: 30px;

    h2 {
      font-size: 22px;
    }

    .detailsSwitch {
      span {
        font-size: 13px;
        margin-right: 15px;
      }

      button {
        font-size: 13px;
      }
    }
  }

  .dropdownAvtar {
    .profileDropdown {
      width: 150px;
      right: 8px;
      top: 25px;

      &::before {
        top: -10px;
        right: 10px;
        width: 20px;
        height: 20px;
      }

      li {
        padding: 6px 22px 6px 20px;
        font-size: 13px;
      }
    }
  }

  .postDeleteModal {
    width: 500px;
    height: auto;

    .modal-header {
      margin: 15px 16px 12px 0;
    }

    .modal-body {
      padding: 0 36px 25px;

      h3 {
        font-size: 26px;
        margin-bottom: 16px;
      }

      p {
        font-size: 13px;
        margin-bottom: 20px;
      }

      button {
        width: 190px;
        padding: 12px 70px;
        font-size: 15px;

        &:first-of-type {
          margin-right: 26px;
        }
      }
    }
  }

  .postEditModal {
    width: 600px;
    padding: 44px 48px 85px;

    .modal-header {
      margin-bottom: 18px;

      h3 {
        font-size: 28px;
      }
    }

    .modal-body {
      .textarea {
        textarea {
          height: 110px;
          padding: 8px 14px;
          font-size: 13px;

          &::placeholder {
            font-size: 13px;
          }
        }
      }

      .editUpload {
        padding: 9px 12px 8px 10px;
        margin: 28px 0px 0px;

        .uploading {
          width: 100%;
          padding: 15px;

          figure {
            margin-right: 18px;
          }

          .fileContent {
            margin-right: 44px;

            h4 {
              font-size: 12px;
            }

            p {
              font-size: 12px;
            }
          }

          button {
            width: 100px;
          }
        }
      }

      ul {
        margin-top: 28px;

        li {
          margin-right: 22px;
        }
      }

      .forgot {
        .remember {
          .check {
            width: 15px;
            height: 15px;
          }

          label {
            font-size: 12px;
            margin-left: 5px;
          }
        }

        .pass {
          a {
            font-size: 12px;
          }
        }
      }

      .editButton {
        margin-top: 28px;

        button {
          width: 190px;
          padding: 12px 64px;
          font-size: 15px;

          &:first-of-type {
            margin-right: 28px;
          }
        }
      }

      .image-item {
        width: 76px !important;
        height: 70px;
        margin-bottom: 0 !important;
      }
    }
  }
}

@include responsive(lg) {
  .detailsHeader {
    margin-bottom: 26px;

    h2 {
      font-size: 20px;
    }

    .detailsSwitch {
      span {
        font-size: 12px;
        margin-right: 14px;
      }

      button {
        font-size: 12px;
      }
    }
  }

  .dropdownAvtar {
    .profileDropdown {
      width: 140px;
      right: 8px;
      top: 25px;

      &::before {
        top: -10px;
        right: 10px;
        width: 20px;
        height: 20px;
      }

      li {
        padding: 6px 20px;
        font-size: 12px;
      }
    }
  }

  .postDeleteModal {
    width: 480px;

    .modal-header {
      margin: 15px 15px 12px 0;
    }

    .modal-body {
      padding: 0 32px 25px;

      h3 {
        font-size: 24px;
        margin-bottom: 15px;
      }

      p {
        font-size: 12px;
        margin-bottom: 18px;
      }

      button {
        width: 170px;
        padding: 10px 0px;
        font-size: 15px;

        &:first-of-type {
          margin-right: 24px;
        }
      }
    }
  }

  .postEditModal {
    width: 580px;
    padding: 40px 42px 75px;

    .modal-header {
      margin-bottom: 16px;

      h3 {
        font-size: 26px;
      }
    }

    .modal-body {
      .textarea {
        textarea {
          height: 100px;
          padding: 8px 12px;
          font-size: 13px;

          &::placeholder {
            font-size: 13px;
          }
        }
      }

      .editUpload {
        padding: 8px 11px 8px 10px;
        margin: 25px 0px 0px;

        .uploading {
          padding: 14px;

          figure {
            margin-right: 15px;
          }

          .fileContent {
            margin-right: 47px;

            h4 {
              font-size: 12px;
            }

            p {
              font-size: 12px;
            }
          }

          button {
            width: 95px;
          }
        }
      }

      ul {
        margin-top: 25px;

        li {
          margin-right: 20px;
        }
      }

      .forgot {
        .remember {
          label {
            font-size: 12px;
            margin-left: 5px;
          }
        }

        .pass {
          a {
            font-size: 12px;
          }
        }
      }

      .editButton {
        margin-top: 25px;

        button {
          width: 170px;
          padding: 10px 0px;
          font-size: 14px;

          &:first-of-type {
            margin-right: 25px;
          }
        }
      }

      .image-item {
        width: 74px !important;
        height: 70px;
        margin-bottom: 0 !important;
      }
    }
  }
}

@include responsive(md) {
  .detailsHeader {
    margin-bottom: 22px;

    h2 {
      font-size: 19px;
    }

    .detailsSwitch {
      span {
        font-size: 12px;
        margin-right: 13px;
      }

      button {
        font-size: 12px;
      }
    }
  }

  .dropdownAvtar {
    .profileDropdown {
      width: 120px;
      right: 8px;
      top: 25px;

      &::before {
        top: -8px;
        right: 10px;
        width: 18px;
        height: 18px;
      }

      li {
        padding: 5px 18px;
        font-size: 12px;
      }
    }
  }

  .postDeleteModal {
    width: 450px;

    .modal-header {
      margin: 12px 12px 12px 0;
    }

    .modal-body {
      padding: 0 30px 22px;

      h3 {
        font-size: 22px;
        margin-bottom: 14px;
      }

      p {
        font-size: 12px;
        margin-bottom: 16px;
        line-height: 20px;
      }

      button {
        width: 150px;
        padding: 9px 0px;
        font-size: 14px;

        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }

  .postEditModal {
    width: 550px;
    padding: 36px 38px 50px;

    .modal-header {
      margin-bottom: 15px;

      h3 {
        font-size: 24px;
      }
    }

    .modal-body {
      .textarea {
        textarea {
          height: 90px;
          padding: 8px 11px;
          font-size: 12px;

          &::placeholder {
            font-size: 12px;
          }
        }
      }

      .editUpload {
        padding: 8px 10px;
        margin: 22px 0px 0px;

        .uploading {
          padding: 13px;

          figure {
            margin-right: 13px;
          }

          .fileContent {
            margin-right: 35px;

            h4 {
              font-size: 12px;
            }

            p {
              font-size: 12px;
            }
          }

          button {
            width: 90px;
            font-size: 13px;
          }
        }
      }

      ul {
        margin-top: 22px;

        li {
          margin-right: 18px;
        }
      }

      .forgot {
        .remember {
          label {
            font-size: 12px;
            margin-left: 4px;
          }
        }

        .pass {
          a {
            font-size: 12px;
          }
        }
      }

      .editButton {
        margin-top: 22px;

        button {
          width: 150px;
          padding: 9px 0px;
          font-size: 13px;

          &:first-of-type {
            margin-right: 22px;
          }
        }
      }

      .image-item {
        width: 65px !important;
        height: 65px;
        margin-bottom: 0 !important;
      }
    }
  }
}

@include responsive(sm) {
  .detailsHeader {
    margin-bottom: 18px;

    h2 {
      font-size: 18px;
    }

    .detailsSwitch {
      span {
        font-size: 12px;
        margin-right: 11px;
      }

      button {
        font-size: 12px;
      }
    }
  }

  .dropdownAvtar {
    .profileDropdown {
      width: 110px;
      right: 8px;
      top: 25px;

      &::before {
        top: -8px;
        right: 12px;
        width: 16px;
        height: 16px;
      }

      li {
        padding: 5px 16px;
        font-size: 11px;
      }
    }
  }

  .postDeleteModal {
    width: 430px;

    .modal-header {
      margin: 12px 15px 12px 0;
    }

    .modal-body {
      padding: 0 28px 20px;

      h3 {
        font-size: 20px;
        margin-bottom: 12px;
      }

      p {
        font-size: 12px;
        margin-bottom: 14px;
        line-height: 18px;
      }

      button {
        width: 120px;
        padding: 8px 0px;
        font-size: 13px;

        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }

  .postEditModal {
    width: 500px;
    padding: 30px 25px;

    .modal-header {
      margin-bottom: 14px;

      h3 {
        font-size: 22px;
      }
    }

    .modal-body {
      .textarea {
        textarea {
          height: 80px;
          padding: 8px 10px;
          font-size: 12px;

          &::placeholder {
            font-size: 12px;
          }
        }
      }

      .editUpload {
        padding: 8px;
        margin: 20px 0px 0px;

        .uploading {
          padding: 12px;

          figure {
            margin-right: 10px;
          }

          .fileContent {
            margin-right: 42px;

            h4 {
              font-size: 11px;
            }

            p {
              font-size: 11px;
            }
          }

          button {
            width: 88px;
            font-size: 12px;
          }
        }
      }

      ul {
        margin-top: 18px;

        li {
          margin-right: 16px;
        }
      }

      .forgot {
        .remember {
          label {
            font-size: 12px;
            margin-left: 4px;
          }
        }

        .pass {
          a {
            font-size: 12px;
          }
        }
      }

      .editButton {
        margin-top: 18px;

        button {
          width: 130px;
          padding: 8px 0px;
          font-size: 12px;

          &:first-of-type {
            margin-right: 18px;
          }
        }
      }

      .image-item {
        width: 60px !important;
        height: 60px;
        margin-bottom: 0 !important;
      }
    }
  }
}

@include responsive(xs) {
  .detailsHeader {
    margin-bottom: 15px;

    h2 {
      font-size: 12px;
    }

    .detailsSwitch {
      span {
        font-size: 11px;
        margin-right: 8px;
      }

      button {
        font-size: 11px;
      }
    }
  }

  .dropdownAvtar {
    .profileDropdown {
      width: 100px;
      right: 8px;
      top: 23px;

      &::before {
        top: -6px;
        right: 8px;
        width: 14px;
        height: 14px;
      }

      li {
        padding: 4px 10px;
        font-size: 11px;
      }
    }
  }

  .postDeleteModal {
    width: calc(100% - 15px);

    .modal-header {
      margin: 12px 15px 12px 0;
    }

    .modal-body {
      padding: 0 15px 15px;

      h3 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      p {
        font-size: 11px;
        margin-bottom: 12px;
        line-height: 16px;
      }

      button {
        width: 100px;
        padding: 7px 0px;
        font-size: 12px;

        &:first-of-type {
          margin-right: 14px;
        }
      }
    }
  }

  .postEditModal {
    width: calc(100% - 15px);
    padding: 15px;

    .modal-header {
      margin-bottom: 12px;

      h3 {
        font-size: 20px;
      }
    }

    .modal-body {
      .textarea {
        textarea {
          height: 70px;
          padding: 8px;
          font-size: 11px;

          &::placeholder {
            font-size: 11px;
          }
        }
      }

      .editUpload {
        padding: 6px;
        margin: 15px 0px 0px;

        .uploading {
          padding: 10px;
          flex-wrap: wrap;

          figure {
            display: none;
          }

          .fileContent {
            margin-right: 0px;

            h4 {
              font-size: 10px;
            }

            p {
              font-size: 10px;
            }
          }

          button {
            display: none;
          }
        }
      }

      ul {
        margin-top: 15px;

        li {
          margin-right: 14px;
        }
      }

      .forgot {
        .remember {
          label {
            font-size: 11px;
          }
        }

        .pass {
          a {
            font-size: 11px;
          }
        }
      }

      .editButton {
        margin-top: 15px;

        button {
          width: 100px;
          padding: 8px 0px;
          font-size: 12px;

          &:first-of-type {
            margin-right: 15px;
          }
        }
      }

      .image-item {
        width: 50px !important;
        height: 50px;
        margin-bottom: 0 !important;
      }

      .img-map-box {
        flex-direction: column;
        margin: 0 auto;
      }
    }
  }
}
