@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.cardDetails {
  h1 {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 30px;
    color: $black_1;
    text-transform: capitalize;
    margin-bottom: 28px;
  }
  .cardDetailsWrap {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }
  .cardDetailsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 0.75px solid $black_1;
    span {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      text-transform: capitalize;
    }
    .addCard {
      width: 156px;
      background-color: $green-shadow;
      border-radius: 20px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $white;
      cursor: pointer;
      padding: 9px 29px;
      @include prefix(transition, all 0.3s ease-in-out);
      &:hover {
        background-color: $theme-color;
      }
    }
  }
  .cardDetailsBody {
    .content {
      max-height: 570px;
    }
    .paymentCard {
      display: flex;
      align-items: center;
      padding: 14px 5px;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: $light-green;
      }

      span {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        color: $gray_3;
      }
      h5 {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
        margin-top: 8px;
      }
      .CardName {
        width: 134px;
        margin-right: 62px;
      }
      .cardNumber {
        width: 190px;
        margin-right: 44px;
      }
      .expiryDate {
        width: 80px;
        margin-right: 80px;
      }
      .deleteButton {
        button {
          width: 105px;
          background-color: $gray_4;
          border-radius: 20px;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          padding: 6px 27px;
          cursor: pointer;
          color: $black_1;
          @include prefix(transition, all 0.3s ease-in-out);
          &:hover {
            background-color: $white-dark;
          }
        }
      }
    }
  }
}

.cardModal {
  width: 580px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 38px 60px 46px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
      text-transform: capitalize;
    }
    button {
      background-color: $white;
      cursor: pointer;
    }
  }
  .modal-body {
    li {
      margin-bottom: 16px;
      &:nth-of-type(3) {
        margin-bottom: 32px;
      }
      &:nth-of-type(4) {
        margin-bottom: 0px;
        margin-left: 30px;
      }
      &:last-of-type {
        margin-bottom: 0;
        text-align: center;
      }
    }
    .formGroup {
      input {
        width: 426px;
        padding: 9px 0px 8px 14px;
      }
    }
    .form-grp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      border: 1px solid $light-gray;
      border-radius: 7px;
      padding-right: 14px;

      input {
        width: 100%;
        padding: 9px 14px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
        background-color: transparent;
      }
      ::-webkit-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      :-ms-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      ::placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
      &:focus-within {
        border: 1px solid $green-shadow;
        svg path,
        circle,
        .react {
          stroke: $green-shadow;
          fill: none;
        }
      }
    }
    .modalForms {
      display: flex;
      .forms {
        margin-right: 20px;
      }
      .form-grp {
        width: 220px;
      }
    }
    .rememberReg {
      justify-content: flex-start;
    }
    .cardTerms {
      h5 {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        text-transform: capitalize;
        a {
          color: $green-shadow;
        }
      }
    }
    .cardDonateButton {
      width: 280px;
      background-color: $green-shadow;
      cursor: pointer;
      border-radius: 50px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      padding: 16px 67px 19px 66px;
      color: $white;
      margin-top: 30px;
      @include prefix(transition, all 0.3s ease-in-out);
      &:hover {
        background-color: $theme-color;
      }
    }
  }
  .modal-footer {
    display: none;
  }
}

// responsive
@include responsive(xl) {
  .cardDetails {
    h1 {
      font-size: 28px;
      margin-bottom: 26px;
    }
    .cardDetailsHeader {
      padding: 18px 28px;
      span {
        font-size: 15px;
      }
      .addCard {
        width: 150px;
        font-size: 13px;
        padding: 9px 24px;
      }
    }
    .cardDetailsBody {
      .content {
        max-height: 500px;
      }
      .paymentCard {
        padding: 12px 4px;

        span {
          font-size: 12px;
        }
        h5 {
          font-size: 13px;
          margin-top: 8px;
        }
        .CardName {
          width: 130px;
          margin-right: 50px;
        }
        .cardNumber {
          width: 95px;
          margin-right: 30px;
        }
        .expiryDate {
          width: 70px;
          margin-right: 70px;
        }
        .deleteButton {
          button {
            width: 100px;
            font-size: 13px;
            padding: 4px 22px;
          }
        }
      }
    }
  }
  .cardModal {
    width: 520px;
    padding: 32px 56px 42px;

    .modal-header {
      margin-bottom: 42px;

      h3 {
        font-size: 28px;
      }
    }
    .modal-body {
      li {
        margin-bottom: 14px;
        &:nth-of-type(3) {
          margin-bottom: 30px;
        }
        &:nth-of-type(4) {
          margin-left: 26px;
        }
      }
      .formGroup {
        input {
          width: 400px;
          padding: 8px 0 8px 12px;
        }
      }
      .form-grp {
        padding-right: 12px;

        input {
          padding: 8px 12px;
        }
        ::-webkit-input-placeholder {
          font-size: 13px;
        }

        :-ms-input-placeholder {
          font-size: 13px;
        }

        ::placeholder {
          font-size: 13px;
        }
      }
      .modalForms {
        .forms {
          margin-right: 18px;
        }
        .form-grp {
          width: 195px;
        }
      }
      .cardTerms {
        h5 {
          font-size: 12px;
        }
      }
      .cardDonateButton {
        width: 250px;
        font-size: 15px;
        padding: 15px 60px 18px;
        margin-top: 28px;
      }
    }
  }
}

@include responsive(lg) {
  .cardDetails {
    h1 {
      font-size: 26px;
      margin-bottom: 24px;
    }
    .cardDetailsHeader {
      padding: 16px 24px;
      span {
        font-size: 14px;
      }
      .addCard {
        width: 140px;
        font-size: 13px;
        padding: 9px 22px;
      }
    }
    .cardDetailsBody {
      .content {
        max-height: 470px;
      }
      .paymentCard {
        padding: 10px 4px;

        span {
          font-size: 12px;
        }
        h5 {
          font-size: 13px;
          margin-top: 8px;
        }
        .CardName {
          width: 100px;
          margin-right: 30px;
        }
        .cardNumber {
          width: 95px;
          margin-right: 30px;
        }
        .expiryDate {
          width: 70px;
          margin-right: 50px;
        }
        .deleteButton {
          button {
            width: 90px;
            font-size: 13px;
            padding: 4px 20px;
          }
        }
      }
    }
  }
  .cardModal {
    width: 500px;
    padding: 30px 50px 35px;

    .modal-header {
      margin-bottom: 35px;

      h3 {
        font-size: 26px;
      }
    }
    .modal-body {
      li {
        margin-bottom: 14px;
        &:nth-of-type(3) {
          margin-bottom: 28px;
        }
        &:nth-of-type(4) {
          margin-left: 22px;
        }
      }
      .formGroup {
        input {
          width: 388px;
          padding: 8px 0 8px 10px;
        }
      }
      .form-grp {
        padding-right: 10px;

        input {
          padding: 8px 12px;
        }
        ::-webkit-input-placeholder {
          font-size: 13px;
        }

        :-ms-input-placeholder {
          font-size: 13px;
        }

        ::placeholder {
          font-size: 13px;
        }
      }
      .modalForms {
        .forms {
          margin-right: 15px;
        }
        .form-grp {
          width: 192px;
        }
      }
      .cardTerms {
        h5 {
          font-size: 12px;
        }
      }
      .cardDonateButton {
        width: 220px;
        font-size: 14px;
        padding: 15px 50px 16px;
        margin-top: 28px;
      }
    }
  }
}

@include responsive(md) {
  .cardDetails {
    h1 {
      font-size: 24px;
      margin-bottom: 22px;
    }
    .cardDetailsHeader {
      padding: 15px 22px;
      span {
        font-size: 14px;
      }
      .addCard {
        width: 130px;
        font-size: 13px;
        padding: 9px 20px;
      }
    }
    .cardDetailsBody {
      .content {
        max-height: 460px;
      }
      .paymentCard {
        justify-content: space-between;
        padding: 10px 4px;

        span {
          font-size: 12px;
        }
        h5 {
          font-size: 13px;
          margin-top: 8px;
        }
        .CardName {
          width: 100px;
          margin-right: 30px;
        }
        .cardNumber {
          width: 95px;
          margin-right: 30px;
        }
        .expiryDate {
          width: 70px;
          margin-right: 50px;
        }
        .deleteButton {
          button {
            width: 90px;
            font-size: 13px;
            padding: 4px 20px;
          }
        }
      }
    }
  }
  .cardModal {
    width: 480px;
    padding: 25px 40px 30px;

    .modal-header {
      margin-bottom: 25px;

      h3 {
        font-size: 22px;
      }
    }
    .modal-body {
      li {
        margin-bottom: 13px;
        &:nth-of-type(3) {
          margin-bottom: 25px;
        }
        &:nth-of-type(4) {
          margin-left: 20px;
        }
      }
      .formGroup {
        input {
          width: 388px;
          padding: 8px 0 8px 9px;
        }
      }
      .form-grp {
        padding-right: 9px;

        input {
          padding: 8px 11px;
        }
        ::-webkit-input-placeholder {
          font-size: 12px;
        }

        :-ms-input-placeholder {
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }
      }
      .modalForms {
        .forms {
          margin-right: 14px;
        }
        .form-grp {
          width: 193px;
        }
      }
      .cardTerms {
        h5 {
          font-size: 12px;
        }
      }
      .cardDonateButton {
        width: 200px;
        font-size: 13px;
        padding: 15px 35px;
        margin-top: 25px;
      }
    }
  }
}

@include responsive(sm) {
  .cardDetails {
    h1 {
      font-size: 18px;
      margin-bottom: 15px;
    }
    .cardDetailsHeader {
      padding: 12px 15px;
      span {
        font-size: 13px;
      }
      .addCard {
        width: 100px;
        font-size: 12px;
        padding: 10px;
      }
    }
    .cardDetailsBody {
      .paymentCard {
        padding: 10px 4px;

        span {
          font-size: 12px;
        }
        h5 {
          font-size: 12px;
          margin-top: 5px;
        }
        .CardName {
          width: 75px;
          flex-shrink: 0;
          margin-right: 15px;
        }
        .cardNumber {
          width: 70px;
          flex-shrink: 0;
          margin-right: 15px;
        }
        .expiryDate {
          width: 65px;
          flex-shrink: 0;
          margin-right: 15px;
        }
        .deleteButton {
          flex-shrink: 0;
          button {
            width: 70px;
            font-size: 12px;
            padding: 4px 12px;
          }
        }
      }
    }
  }
  .cardModal {
    width: 460px;
    padding: 20px 30px 25px;

    .modal-header {
      margin-bottom: 20px;

      h3 {
        font-size: 20px;
      }
    }
    .modal-body {
      li {
        margin-bottom: 12px;
        &:nth-of-type(3) {
          margin-bottom: 20px;
        }
        &:nth-of-type(4) {
          margin-left: 20px;
        }
      }
      .formGroup {
        input {
          width: 388px;
          padding: 8px 0 8px;
        }
      }
      .form-grp {
        padding-right: 8px;

        input {
          padding: 8px 10px;
        }
        ::-webkit-input-placeholder {
          font-size: 12px;
        }

        :-ms-input-placeholder {
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }
      }
      .modalForms {
        .forms {
          margin-right: 12px;
          &:last-of-type {
            margin-right: 0;
          }
        }
        .form-grp {
          width: 100%;
        }
      }
      .cardTerms {
        h5 {
          font-size: 12px;
        }
      }
      .cardDonateButton {
        width: 180px;
        font-size: 13px;
        padding: 15px 25px;
        margin-top: 20px;
      }
    }
  }
}

@include responsive(xs) {
  .cardDetails {
    h1 {
      font-size: 18px;
      margin-bottom: 15px;
    }
    .cardDetailsHeader {
      padding: 12px 15px;
      span {
        font-size: 13px;
      }
      .addCard {
        width: 100px;
        font-size: 12px;
        padding: 10px;
      }
    }
    .cardDetailsBody {
      .paymentCard {
        width: 485px;
        padding: 10px 4px;

        span {
          font-size: 12px;
        }
        h5 {
          font-size: 12px;
          margin-top: 5px;
        }
        .CardName {
          width: 75px;
          flex-shrink: 0;
          margin-right: 15px;
        }
        .cardNumber {
          width: 70px;
          flex-shrink: 0;
          margin-right: 15px;
        }
        .expiryDate {
          width: 65px;
          flex-shrink: 0;
          margin-right: 15px;
        }
        .deleteButton {
          flex-shrink: 0;
          button {
            width: 70px;
            font-size: 12px;
            padding: 4px 12px;
          }
        }
      }
    }
  }
  .cardModal {
    width: 290px;
    padding: 15px;

    .modal-header {
      margin-bottom: 15px;

      h3 {
        font-size: 18px;
      }
    }
    .modal-body {
      li {
        margin-bottom: 12px;
        &:nth-of-type(3) {
          margin-bottom: 15px;
        }
        &:nth-of-type(4) {
          margin-left: 0px;
        }
      }
      .formGroup {
        input {
          width: 250px;
          padding: 8px 0 8px;
        }
      }
      .form-grp {
        padding-right: 8px;

        input {
          padding: 8px 10px;
        }
        ::-webkit-input-placeholder {
          font-size: 12px;
        }

        :-ms-input-placeholder {
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }
      }
      .modalForms {
        .forms {
          margin-right: 12px;
          &:last-of-type {
            margin-right: 0;
          }
        }
        .form-grp {
          width: 100%;
        }
      }
      .cardTerms {
        h5 {
          font-size: 12px;
        }
      }
      .cardDonateButton {
        width: 150px;
        font-size: 13px;
        padding: 15px;
        margin-top: 15px;
      }
    }
  }
}
