// Color Variable
$theme-color: #6daf5f;
$white: #ffffff;
$white-shadow: #fcfffc;
$white_1: #e1e1e1;
$white-dark: #c4c4c4;
$black: #000000;
$black_1: #1e1e1e;
$black_2: #2c2c2e;
$brown: #931c1c;

// Gray
$color1: #444a4d;
$color2: #778288;
$color3: #a4b4bd;
$color4: #d5e2ea;
$color5: #e2f1f9;
$gray: #8a8a8a;
$gray_1: #6a6a6a;
$gray_2: #666666;
$gray_3: #999999;
$gray_4: #e1e1e1;
$gray_5: #666668;
$light-gray: #4f4f4f;

// red
$red: #d33520;

// green
$green-shadow: #22841a;
$light-green: #f0f7ef;

$success: #089b55;
$danger: orange;
$warning: #e5b64b;
$info: #448fb8;

// media quary
$xl: 1439px;
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 575px;

// font
$font_lora: Lora;
$font_jost: Jost;
