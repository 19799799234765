@import "../../../scss/style.scss";

.image-item {
  display: flex;
  margin: 10px 10px 10px 0;
  height: 104px;
  width: 104px;
  border-radius: 5px;
  border: 2px dotted #42a44c;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  :nth-child(2n + 1) {
    margin-right: 0;
  }
  &:hover .image-item__btn-wrapper {
    display: flex;
  }
}
.image-item__btn-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include prefix(transition, all 0.4s ease-in-out);
  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .img-btn {
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    margin-right: 11px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    position: relative;
    z-index: 11;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 1;
    svg {
      height: 20px;
      width: 20px;
      color: #42a44c;
    }
    // &.remove{
    //     background-color: red;
    // }
    &:last-child {
      margin-right: 0;
    }
  }
}
.img-map-box {
  display: flex;
  flex-wrap: wrap;
}
.upload__image-wrapper {
  display: flex;
}
.upload-btn {
  margin: 10px 10px 10px 0;
  background-color: transparent;
  border: 2px dotted #42a44c;
  color: #42a44c;
  border-radius: 5px;
  overflow: hidden;
  font-size: 35px;
  display: inline-block;
  font-weight: 400;
  height: 104px;
  width: 104px;
  padding: 0 10px;
  cursor: pointer;
}

// responsive
@include responsive(xl) {
  .image-item {
    margin: 9px 9px 9px 0;
    width: 100px;
    height: 100px;
    img {
      padding: 9px;
    }
  }
  .image-item__btn-wrapper {
    .img-btn {
      font-size: 11px;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      svg {
        width: 19px;
        height: 19px;
      }
    }
  }
  .upload-btn {
    margin: 9px 9px 9px 0;
    font-size: 32px;
    width: 100px;
    height: 100px;
    padding: 0 9px;
  }
}

@include responsive(lg) {
  .image-item {
    margin: 8px 8px 8px 0;
    width: 95px;
    height: 95px;
    img {
      padding: 8px;
    }
  }
  .image-item__btn-wrapper {
    .img-btn {
      font-size: 10px;
      margin-right: 9px;
      width: 22px;
      height: 22px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  .upload-btn {
    margin: 8px 8px 8px 0;
    font-size: 28px;
    width: 90px;
    height: 90px;
    padding: 0 8px;
  }
}

@include responsive(md) {
  .image-item {
    margin: 7px 7px 7px 0;
    width: 90px;
    height: 90px;
    img {
      padding: 7px;
    }
  }
  .image-item__btn-wrapper {
    .img-btn {
      font-size: 10px;
      margin-right: 8px;
      width: 20px;
      height: 20px;
      svg {
        width: 17px;
        height: 17px;
      }
    }
  }
  .upload-btn {
    margin: 7px 7px 7px 0;
    font-size: 26px;
    width: 85px;
    height: 85px;
    padding: 0 7px;
  }
}

@include responsive(sm) {
  .image-item {
    margin: 6px 6px 6px 0;
    width: 85px;
    height: 85px;
    img {
      padding: 6px;
    }
  }
  .image-item__btn-wrapper {
    .img-btn {
      font-size: 10px;
      margin-right: 7px;
      width: 18px;
      height: 18px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .upload-btn {
    margin: 6px 6px 6px 0;
    font-size: 24px;
    width: 80px;
    height: 80px;
    padding: 0 6px;
  }
}

@include responsive(xs) {
  .image-item {
    margin: 6px 6px 6px 0;
    width: 80px;
    height: 80px;
    img {
      padding: 5px;
    }
  }
  .image-item__btn-wrapper {
    .img-btn {
      font-size: 10px;
      margin-right: 6px;
      width: 17px;
      height: 17px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  .upload-btn {
    margin: 6px 6px 6px 0;
    font-size: 22px;
    width: 75px;
    height: 75px;
    padding: 0 5px;
  }
}
