@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.favoriteCommunity {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 3px;

  .communityCard {
    width: 220px;
    margin-bottom: 30px;
    margin-top: 0;
    margin-right: 24px;
    &:nth-of-type(3n + 0) {
      margin-right: 0;
    }
    figure {
      width: 100%;
      height: 130px;
    }
    .communityDetails {
      display: flex;
      align-items: center;
      justify-content: center;

      h5 {
        width: 110px;
        flex-shrink: 0;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-right: 5px;
      }
      ul {
        width: 90px !important;
        display: flex;
        justify-content: start !important;
        align-items: center;
        li {
          margin-left: 6px;
          span {
            display: block;
            width: 72px;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            text-transform: capitalize;
            color: $black_1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:first-of-type {
            margin-left: 0px;
            flex-shrink: 0;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .member {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      .member-list {
        display: flex;
        align-items: center;
        .member-images {
          display: flex;
          margin-right: 15px;
          figure {
            display: flex;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid $white;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
              color: transparent;
            }
            span {
              display: inline-block;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-size: cover;
            }

            &:nth-child(2) {
              margin-left: -5px;
            }

            &:nth-child(3) {
              margin-left: -5px;
            }

            &:nth-child(4) {
              margin-left: -5px;
            }
          }
        }
        .member-count {
          font-family: $font_jost;
          font-weight: 400;
          color: $green-shadow;
          font-size: 12px;
        }
      }
    }
    .communityButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 35px;
      .communityBasket {
        width: 30%;
        border-radius: 20px;
        padding: 10px 18px;
        color: $white;
        background: $green-shadow;
        @include prefix(transition, all 0.3s ease-in-out);
        cursor: pointer;

        &:hover {
          background: $theme-color;
        }
        img {
          width: 15px;
        }
        svg {
          path {
            fill: $white;
          }
        }
      }
      .associateCommunityBasket {
        background-color: rgba($color: $green-shadow, $alpha: 0.3);

        &:hover {
          background-color: $light-green;
        }
        svg {
          path {
            fill: $green-shadow;
          }
        }
      }
      .joinButtons {
        width: 30%;
        padding: 10px 16px;
        border-radius: 50px;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);
        img {
          width: 30px;
        }
      }
      .telegramButton {
        width: 30%;
        padding: 10px 18px;
        border-radius: 50px;
        cursor: pointer;
      }
    }
    .associatedButton {
      .joinButtons {
        background-color: $white_1;
        color: $gray_1;
        &:hover {
          background-color: $white-dark;
          color: $black_1;
        }
      }
      .telegramButton {
        background-color: $white_1;
        svg path {
          fill: $color2;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .favoriteCommunity {
    padding: 9px 3px;

    .communityCard {
      width: 210px;
      margin-bottom: 28px;
      margin-right: 22px;
      &:nth-of-type(3n + 0) {
        margin-right: 22px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
      .communityImage {
        margin-bottom: 13px;
        .mainImage {
          height: 125px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 23px;
          font-size: 15px;
        }
        .communityDetails {
          h5 {
            width: 90px;
            font-size: 13px;
            margin-right: 4px;
          }
          ul {
            width: auto !important;
            li {
              margin-left: 5px;
              span {
                width: auto;
                font-size: 13px;
              }
              img {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        .member {
          margin-top: 8px;
          .member-list {
            .member-images {
              figure {
                width: 23px;
                height: 23px;
              }
            }
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          margin-top: 34px;
          .communityBasket {
            width: 30%;
            padding: 10px 16px;

            img {
              width: 14px;
            }
          }
          .joinButtons {
            width: 30%;
            padding: 9px 16px;
            img {
              width: 25px;
            }
          }
          .telegramButton {
            width: 30%;
            padding: 9px 16px;
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .favoriteCommunity {
    padding: 9px 3px;

    .communityCard {
      width: 195px;
      margin-bottom: 26px;
      margin-right: 20px;
      &:nth-of-type(3n + 0) {
        margin-right: 20px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
      .communityImage {
        margin-bottom: 12px;
        .mainImage {
          height: 100px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 22px;
          font-size: 15px;
        }
        .communityDetails {
          flex-wrap: wrap;
          h5 {
            width: 155px;
            text-align: center;
            font-size: 13px;
            margin-right: 0px;
          }
          ul {
            width: auto !important;
            li {
              margin-left: 5px;
              span {
                width: auto;
                font-size: 13px;
              }
              img {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        .member {
          margin-top: 8px;
          .member-list {
            .member-images {
              figure {
                width: 23px;
                height: 23px;
              }
            }
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          margin-top: 30px;
          .communityBasket {
            width: 30%;
            padding: 10px 16px;

            img {
              width: 14px;
            }
            svg {
              width: 14px;
            }
          }
          .joinButtons {
            width: 30%;
            padding: 9px 16px;
            img {
              width: 20px;
            }
          }
          .telegramButton {
            width: 30%;
            padding: 9px 16px;
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .favoriteCommunity {
    padding: 7px 3px;

    .communityCard {
      width: 194px;
      margin-bottom: 22px;
      margin-right: 18px;
      &:nth-of-type(3n + 0) {
        margin-right: 18px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 18px;
      }
      .communityImage {
        margin-bottom: 10px;
        .mainImage {
          height: 110px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 20px;
          font-size: 14px;
        }
        .communityDetails {
          h5 {
            width: 155px;
            font-size: 14px;
          }
          ul {
            li {
              margin-left: 4px;
              span {
                font-size: 12px;
              }
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
        .member {
          margin-top: 7px;
          .member-list {
            .member-images {
              figure {
                width: 21px;
                height: 21px;
              }
            }
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          margin-top: 22px;
          .communityBasket {
            width: 30%;
            padding: 10px 14px;

            img {
              width: 14px;
            }
            svg {
              width: 13px;
            }
          }
          .joinButtons {
            width: 30%;
            padding: 9px 15px;
            img {
              width: 20px;
            }
          }
          .telegramButton {
            width: 30%;
            padding: 9px 15px;
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .favoriteCommunity {
    padding: 7px 3px;

    .communityCard {
      width: 200px;
      margin-bottom: 20px;
      margin-right: 16px;
      &:nth-of-type(3n + 0) {
        margin-right: 16px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 16px;
      }
      .communityImage {
        margin-bottom: 8px;
        .mainImage {
          height: 90px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 18px;
          font-size: 13px;
        }
        .communityDetails {
          h5 {
            width: 160px;
            font-size: 13px;
          }
          ul {
            li {
              margin-left: 3px;
              span {
                font-size: 12px;
              }
              img {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
        .member {
          margin-top: 5px;
          .member-list {
            .member-images {
              figure {
                width: 20px;
                height: 20px;
              }
            }
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          margin-top: 18px;
          .communityBasket {
            width: 30%;
            padding: 10px 14px;

            img {
              width: 13px;
            }
            svg {
              width: 12px;
            }
          }
          .joinButtons {
            width: 30%;
            padding: 9px 14px;
            img {
              width: 20px;
            }
          }
          .telegramButton {
            width: 30%;
            padding: 9px 14px;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .favoriteCommunity {
    padding: 7px 3px;

    .communityCard {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0px;
      &:nth-of-type(3n + 0) {
        margin-right: 0px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
      .communityImage {
        margin-bottom: 6px;
        .mainImage {
          height: 120px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 17px;
          font-size: 13px;
        }
        .communityDetails {
          h5 {
            width: 80px;
          }
          ul {
            li {
              margin-left: 2px;
            }
          }
        }
        .member {
          margin-top: 4px;
          .member-list {
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          margin-top: 15px;
          .communityBasket {
            width: 30%;
            padding: 10px 14px;

            img {
              width: 12px;
            }
            svg {
              width: 12px;
            }
          }
          .joinButtons {
            width: 30%;
            padding: 10px 14px;
            img {
              width: 20px;
            }
          }
          .telegramButton {
            width: 30%;
            padding: 10px 14px;
          }
        }
      }
    }
  }
}
