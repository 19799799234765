@import "../../scss/style.scss";

.header-section {
  width: 100%;
  padding: 17px 24px !important;
}

.register-nav {
  display: flex;
  align-items: center;
  justify-content: end;
}

.steps-num-div {
  margin-bottom: 100px;
  position: relative;

  .steps-num {
    display: flex;
    align-items: center;
    position: relative;
    span {
      background-color: #52c41a;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-weight: 400;
      font-size: 16px;
    }
    p {
      padding-left: 10px;
      display: inline-block;
      position: relative;
    }
    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: 100%;
      display: block;
      min-width: 100px;
      height: 1px;
      background: rgba(0, 0, 0, 0.06);
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
  }
}

.section-step {
  padding: 18px 30px;
  .step-text-box {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    h2 {
      text-align: center;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      color: rgba(0, 0, 0, 0.37);
      font-weight: 400;
      font-size: 23px;
      width: 60%;
      margin: auto;
    }
  }
}

.progressbar-box {
  width: 100px;
  height: 100px;
  margin: 0 auto 30px;
}

.button-box {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
  .link-btn {
    font-family: $font_jost;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    width: auto;
    min-width: 100px;
    // background-color: $green-shadow;
  }
}

.normalText {
  text-transform: none;
}

.bottom-text {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: $font_jost;
  font-weight: 400;
  font-size: 14px;
  color: $green-shadow;

  &:last-of-type {
    margin-top: 10px;
  }

  a {
    display: inline-block;
    min-width: 200px;
    padding: 10px;
    color: $white;
    @include prefix(box-shadow, 0px 2px 0px rgba(0, 0, 0, 0.043));
    @include prefix(transition, all 0.4s ease-in-out);
    border: 1px solid #42a44c;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    background-color: $theme-color;
    &:hover {
      background-color: transparent;
      color: #42a44c;
    }
  }
}

.dropdownGrp {
  font-family: $font_jost;
  font-weight: 400;
  font-size: 14px;
  color: $light-gray;
  text-align: left;
}
.css-1pahdxg-control {
  border-color: transparent;
  box-shadow: none;
  border-width: 0;
}
.css-1s2u09g-control {
  background-color: transparent;
  border-width: 0px;
}
.form-grp {
  // border: 1px solid $black_1;
  border-radius: 7px;
}

.steps-main-div {
  padding: 0 150px;
  .step-btn.completed {
    background-color: $green-shadow !important;
  }
  .completed,
  .active {
    border-color: #52c41a !important;
  }

  .active.step-btn {
    background-color: rgb(224, 224, 224) !important;
  }
  #RFS-Label {
    font-family: $font_jost;
    font-size: 16px !important;
    margin-top: 10px;
  }
}

// responsive
@include responsive(xl) {
  .section-step {
    padding: 17px 28px;
  }

  .button-box {
    margin-bottom: 18px;
    .link-btn {
      font-size: 15px;
      min-width: 95px;
      flex-basis: 0;
    }
  }

  .bottom-text {
    font-size: 14px;
  }

  .progressbar-box {
    width: 95px;
    height: 95px;
    margin: 0 auto 28px;
  }
}

@include responsive(lg) {
  .section-step {
    padding: 16px 26px;
  }

  .button-box {
    margin-bottom: 16px;
    .link-btn {
      font-size: 14px;
      min-width: 90px;
    }
  }

  .bottom-text {
    font-size: 14px;
  }

  .progressbar-box {
    width: 90px;
    height: 90px;
    margin: 0 auto 26px;
  }
}

@include responsive(md) {
  .section-step {
    padding: 14px 24px;
  }

  .button-box {
    margin-bottom: 15px;
    .link-btn {
      font-size: 13px;
      min-width: 85px;
    }
  }

  .bottom-text {
    font-size: 13px;
  }

  .progressbar-box {
    width: 85px;
    height: 85px;
    margin: 0 auto 24px;
  }
}

@include responsive(sm) {
  .section-step {
    padding: 12px 20px;
  }

  .button-box {
    margin-bottom: 15px;
    .link-btn {
      font-size: 12px;
      min-width: 80px;
    }
  }

  .bottom-text {
    font-size: 12px;
  }

  .progressbar-box {
    width: 80px;
    height: 80px;
    margin: 0 auto 22px;
  }
}

@include responsive(xs) {
  .section-step {
    padding: 12px 18px;
  }

  .steps-main-div #RFS-Label {
    font-size: 13px !important;
  }

  .button-box {
    margin-bottom: 15px;
    .link-btn {
      font-size: 12px;
      min-width: 75px;
    }
  }

  .bottom-text {
    font-size: 12px;

    a{
      font-size: 13px;
    }
  }

  .progressbar-box {
    width: 75px;
    height: 75px;
    margin: 0 auto 20px;
  }
}
