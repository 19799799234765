@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.postCreationCard {
  width: 100%;
  margin-bottom: 40px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

  .postInfo {
    display: flex;
    padding: 30px;
    .postImage {
      margin-right: 30px;
      figure {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        // background-image: url("../../../../../public/images/placeholder.png");

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
    .postFormGroup {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 16px;

      textarea {
        width: 100%;
        border-radius: 16px;
        padding: 14px 30px 13px 30px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $gray_2;
        resize: none;
      }
    }
  }
  .post-radio {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
  .forgot {
    display: flex;
    justify-content: space-between;

    &:first-of-type {
      margin-right: 15px;
    }

    .remember {
      display: flex;
      align-items: center;

      .check {
        width: 16px;
        height: 16px;
        background-color: white;
        border-radius: 50%;
        vertical-align: middle;
        border: 1px solid $theme-color;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
      }

      .check:checked {
        border: none;
      }

      label {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        color: $light-gray;
        letter-spacing: 0.04em;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .pass {
      a {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        color: $light-gray;
        letter-spacing: 0.04em;
      }
    }
  }
  .postFooter {
    display: flex;
    justify-content: space-between;
    background-color: $light-green;
    ul {
      display: flex;
      align-items: center;
      padding: 14px 0px 13px 104px;
      li {
        display: flex;
        align-items: center;
        margin-right: 18px;
        cursor: pointer;
        span {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          margin-left: 8px;
        }
      }
    }
    .messageSend {
      button {
        padding: 15px 25px 14px;
        background-color: $theme-color;
        cursor: pointer;
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .postCreationCard {
    margin-bottom: 36px;

    .postInfo {
      padding: 28px;
      .postImage {
        margin-right: 28px;
        figure {
          width: 42px;
          height: 42px;
        }
      }
      .postFormGroup {
        textarea {
          padding: 12px 28px;
          font-size: 14px;
        }
      }
    }
    .post-radio {
      padding-bottom: 28px;
    }
    .forgot {
      &:first-of-type {
        margin-right: 14px;
      }

      .remember {
        .check {
          width: 15px;
          height: 15px;
        }

        label {
          font-size: 12px;
          margin-left: 5px;
        }
      }

      .pass {
        a {
          font-size: 12px;
        }
      }
    }
    .postFooter {
      ul {
        padding: 13px 0px 13px 95px;
        li {
          margin-right: 17px;
          span {
            font-size: 12px;
            margin-left: 7px;
          }
        }
      }
      .messageSend {
        button {
          padding: 14px 22px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .postCreationCard {
    margin-bottom: 32px;

    .postInfo {
      padding: 26px;
      .postImage {
        margin-right: 24px;
        figure {
          width: 42px;
          height: 42px;
        }
      }
      .postFormGroup {
        textarea {
          padding: 11px 24px;
          font-size: 13px;
        }
      }
    }
    .post-radio {
      padding-bottom: 22px;
    }
    .forgot {
      &:first-of-type {
        margin-right: 13px;
      }

      .remember {
        .check {
          width: 15px;
          height: 15px;
        }

        label {
          font-size: 12px;
          margin-left: 5px;
        }
      }

      .pass {
        a {
          font-size: 12px;
        }
      }
    }
    .postFooter {
      ul {
        padding: 12px 0px 12px 80px;
        li {
          margin-right: 15px;
          span {
            font-size: 12px;
            margin-left: 6px;
          }
        }
      }
      .messageSend {
        button {
          padding: 13px 20px;
        }
      }
    }
  }
}

@include responsive(md) {
  .postCreationCard {
    margin-bottom: 28px;

    .postInfo {
      padding: 22px;
      .postImage {
        margin-right: 22px;
        figure {
          width: 45px;
          height: 45px;
        }
      }
      .postFormGroup {
        textarea {
          padding: 11px 24px;
          font-size: 13px;
        }
      }
    }
    .post-radio {
      padding-bottom: 20px;
    }
    .forgot {
      &:first-of-type {
        margin-right: 12px;
      }

      .remember {
        .check {
          width: 15px;
          height: 15px;
        }

        label {
          font-size: 12px;
          margin-left: 5px;
        }
      }

      .pass {
        a {
          font-size: 12px;
        }
      }
    }
    .postFooter {
      ul {
        padding: 12px 0px 12px 80px;
        li {
          margin-right: 15px;
          span {
            font-size: 12px;
            margin-left: 6px;
          }
        }
      }
      .messageSend {
        button {
          padding: 15px 20px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .postCreationCard {
    margin-bottom: 22px;

    .postInfo {
      padding: 20px;
      .postImage {
        margin-right: 20px;
        figure {
          width: 43px;
          height: 43px;
        }
      }
      .postFormGroup {
        textarea {
          padding: 11px 20px;
          font-size: 13px;
        }
      }
    }
    .post-radio {
      padding-bottom: 18px;
    }
    .forgot {
      &:first-of-type {
        margin-right: 10px;
      }

      .remember {
        .check {
          width: 15px;
          height: 15px;
        }

        label {
          font-size: 12px;
          margin-left: 4px;
        }
      }

      .pass {
        a {
          font-size: 12px;
        }
      }
    }
    .postFooter {
      ul {
        padding: 12px 0px 12px 70px;
        li {
          margin-right: 15px;
          span {
            font-size: 12px;
            margin-left: 6px;
          }
        }
      }
      .messageSend {
        button {
          padding: 15px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .postCreationCard {
    margin-bottom: 15px;

    .postInfo {
      padding: 15px;
      .postImage {
        margin-right: 10px;
        figure {
          width: 40px;
          height: 40px;
        }
      }
      .postFormGroup {
        textarea {
          padding: 10px 15px;
          font-size: 12px;
        }
      }
    }
    .post-radio {
      padding-bottom: 15px;
    }
    .forgot {
      &:first-of-type {
        margin-right: 8px;
      }
    }
    .postFooter {
      ul {
        padding: 12px 0px 12px 12px;
        li {
          margin-right: 10px;
          span {
            font-size: 12px;
            margin-left: 5px;
          }
        }
      }
      .messageSend {
        button {
          padding: 13px;
        }
      }
    }
  }
}
