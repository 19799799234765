@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.charityList {
  width: 100%;
  margin-top: 85px;
  padding: 14px 0 30px;
}

.breadcrumbs {
  margin-bottom: 65px;
}

.charitylistWrap {
  .charity-header {
    margin-bottom: 30px;
    &.sticky {
      position: sticky;
      top: 85px;
      background-color: #ffffff;
      padding: 30px 0;
      z-index: 9;
    }
    .non-profit-header {
      display: flex;
      justify-content: space-between;
    }

    .register {
      display: inline-block;
      width: 170px;
      height: 35px;
      text-align: center;
      padding: 7px 0px 8px;
      background-color: $green-shadow;
      border-radius: 20px;
      color: $white;
      font-size: 14px;
      font-family: $font_jost;
      @include prefix(transition, all 0.3s ease-in-out);
      &:hover {
        background-color: $theme-color;
      }
    }

    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px ;
      color: $black_1;
    }
    .charity-header-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      .charityModal {
        strong {
          font-family: $font_jost;
          color: $black_1;
          font-size: 16px;
        }
        .filterButton {
          position: relative;
          background: none;
          cursor: pointer;

          .filterbadge {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 13px;
            height: 13px;
            position: absolute;
            left: 20px;
            top: 2px;
            border-radius: 50%;
            background-color: $red;
            font-family: $font_jost;
            font-weight: 700;
            font-size: 9px;
            color: $white;
            padding: 0 3px;
          }
        }
      }
      .charityList-search {
        width: 50%;
        margin-right: 40px;
        .charity-search-formGrp {
          display: flex;
          align-items: center;
          border-radius: 50px;
          padding: 10px 16px;
          background-color: $white;
          border: 1px solid rgba(0, 0, 0, 0.2);

          &:focus-within {
            border: 1px solid $theme-color;
          }

          input {
            background-color: transparent;
            width: calc(100% - 50px);
            margin: 0 10px;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $black_1;
          }
          ::-webkit-input-placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $black_1;
          }

          :-ms-input-placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $black_1;
          }

          ::placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $black_1;
          }
          .cancle-cause {
            cursor: pointer;
          }
          svg {
            width: 14px;
            height: 14px;

            fill: gray;
          }
        }
      }
    }

    .giving_partner_button {
      a {
        display: inline-block;
        width: 320px;
        text-align: center;
        background-color: $green-shadow;
        border-radius: 50px;
        padding: 12px 20px;
        font-family: $font_jost;
        font-weight: 400;
        color: $white;
        font-size: 18px;
        @include prefix(transition, all 0.3s ease-in-out);
        &:hover {
          background-color: $theme-color;
        }
      }
    }
    .giving_button {
      text-align: center;
    }
  }

  .charity-wrap {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-left: 4px;

    .charityCard {
      text-align: center;
      width: 311px;
      margin-right: 30px;
      padding: 22px 16px 35px 17px;

      &:nth-of-type(4n + 0) {
        margin-right: 0px;
      }
    }
  }
}

.filterModal {
  width: 631px;
  padding: 45px 30px 33px 48px;
  position: absolute;
  background-color: $white;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  z-index: 9999;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

  .check {
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid $theme-color;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }

  .check:checked {
    background: url("../../../public/images/remember_icon.svg");
    border: none;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
      display: flex;
      align-items: center;
      .note {
        margin-left: 10px;
      }
    }

    button {
      background: none;
      cursor: pointer;
    }
  }

  .modal-body {
    .modal-contribution {
      .contribution-title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        span {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 16px;
          color: $black_1;
          margin-left: 10px;
        }
      }

      .contribution-checkbox {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;

        .contribution-input {
          display: flex;
          align-items: center;
          margin-right: 7px;
          margin-bottom: 20px;

          span {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }

    .modal-charity {
      margin-bottom: 46px;

      .charity-title {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        span {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 16px;
          color: $black_1;
          margin-left: 10px;
        }
      }

      .charity-checkbox {
        display: flex;

        .charity-input {
          display: flex;
          align-items: center;
          margin-right: 10px;

          span {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;

    .submit {
      background-color: $green-shadow;
      border-radius: 50px;
      color: $white;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 20px;
      width: 177px;
      height: 45px;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: $theme-color;
      }
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgba(30, 30, 30, 0.8) !important;
  z-index: 9998;
}

// responsive
@include responsive(xl) {
  .charityList {
    margin-top: 80px;
    padding: 12px 0 26px;
  }

  .charitylistWrap {
    .charity-header {
      &.sticky {
        top: 80px;
      }
    }
  }

  .breadcrumbs {
    margin-bottom: 60px;
  }
}

.charitylistWrap {
  .charity-header {
    margin-bottom: 38px;
    .register {
      width: 160px;
      height: 35px;
      padding: 7px 0;
      font-size: 13px;
    }

  }
  .giving_partner_button {
    a {
      width: 300px;
      padding: 12px 18px;
    }

    .charity-wrap {
      width: 900px;
      margin: 0 auto;
      padding-top: 9px;
      padding-left: 3px;

      .charityCard {
        width: 280px;
        margin-right: 28px;
        padding: 20px 14px 32px 15px;
        margin-bottom: 24px;
        &:nth-of-type(3n + 0) {
          margin-right: 0px;
        }
        &:nth-of-type(4n + 0) {
          margin-right: 24px;
        }
        &:nth-of-type(12n) {
          margin-right: 0px;
        }

        .charityImage {
          height: 125px;
          margin-top: 15px;
          margin-bottom: 7px;
        }

        .charitynWIcon {
          h4 {
            height: 48px;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 13px;
          }
          .charityRight {
            width: 19px;
            height: 19px;
            margin-left: 9px;
            margin-top: 6px;
          }
        }

        p {
          height: 66px;
          font-size: 13px;
          line-height: 23px;
          -webkit-line-clamp: 3;
          margin-bottom: 16px;
        }
        .charityButtons {
          .basket {
            min-width: 190px;
            padding: 8px 30px;
            font-size: 15px;
            margin-right: 10px;
          }
          .charityHeart {
            min-width: 58px;
            padding: 4px 13px 4px;

            svg {
              width: 24px;
              height: 22px;
            }
          }
          .charityHeartActive {
            min-width: 58px;
            padding: 4px 13px 4px;

            svg {
              width: 24px;
              height: 22px;
            }
          }
        }
      }
    }
  }

  .filterModal {
    width: 610px;
    padding: 42px 28px 30px 46px;

    .modal-header {
      margin-bottom: 22px;

      h3 {
        font-size: 28px;
      }
    }
    .modal-body {
      .modal-contribution {
        .contribution-title {
          margin-bottom: 15px;
          span {
            font-size: 15px;
            margin-left: 9px;
          }
        }
        .contribution-checkbox {
          margin-bottom: 13px;

          .contribution-input {
            margin-right: 6px;
            margin-bottom: 18px;

            span {
              font-size: 13px;
              margin-left: 9px;
            }
          }
        }
      }

      .modal-charity {
        margin-bottom: 44px;

        .charity-title {
          margin-bottom: 22px;

          span {
            font-size: 14px;
          }
        }
        .charity-checkbox {
          .charity-input {
            margin-right: 9px;

            span {
              font-size: 13px;
              margin-left: 9px;
            }
          }
        }
      }
    }
    .modal-footer {
      .submit {
        font-size: 18px;
        width: 160px;
        height: 42px;
        &:first-of-type {
          margin-right: 9px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .charityList {
    padding: 12px 0 24px;
  }

  .breadcrumbs {
    margin-bottom: 55px;
  }

  .filterModal {
    width: 600px;
    padding: 40px 26px 30px 42px;

    .modal-header {
      margin-bottom: 20px;
      .register {
        width: 160px;
        height: 32px;
        padding: 7px 0;
        font-size: 13px;
      }
      h3 {
        font-size: 26px;
      }
      .giving_partner_button {
        a {
          width: 280px;
          padding: 12px 16px;
          font-size: 16px;
        }
      }
    }
    .modal-body {
      .modal-contribution {
        .contribution-title {
          margin-bottom: 14px;
          span {
            font-size: 15px;
            margin-left: 8px;
          }
        }
        .contribution-checkbox {
          margin-bottom: 12px;

          .contribution-input {
            margin-right: 6px;
            margin-bottom: 16px;

            span {
              font-size: 13px;
              margin-left: 8px;
            }
          }
        }
      }

      .modal-charity {
        margin-bottom: 42px;

        .charity-title {
          margin-bottom: 20px;

          span {
            font-size: 14px;
          }
        }
        .charity-checkbox {
          .charity-input {
            margin-right: 8px;

            span {
              font-size: 13px;
              margin-left: 8px;
            }
          }
        }
      }
    }
    .modal-footer {
      .submit {
        font-size: 18px;
        width: 150px;
        height: 40px;
        &:first-of-type {
          margin-right: 8px;
        }
      }
    }
  }

  .charitylistWrap {
    .charity-header {
      margin-bottom: 34px;
      h3 {
        font-size: 26px;
      }
    }

    .charity-wrap {
      width: 858px;
      padding-top: 8px;
      padding-left: 3px;

      .charityCard {
        width: 269px;
        margin-right: 24px;
        padding: 18px 14px 30px 15px;
        margin-bottom: 24px;
        &:nth-of-type(3n + 0) {
          margin-right: 0px;
        }
        &:nth-of-type(4n + 0) {
          margin-right: 24px;
        }
        &:nth-of-type(12n + 0) {
          margin-right: 0px;
        }

        .charityImage {
          height: 120px;
          margin-top: 14px;
          margin-bottom: 7px;
        }

        .charitynWIcon {
          h4 {
            height: 46px;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 13px;
          }
          .charityRight {
            width: 18px;
            height: 18px;
            margin-left: 8px;
            margin-top: 6px;
          }
        }
        p {
          height: 46px;
          font-size: 13px;
          line-height: 23px;
          -webkit-line-clamp: 2;
          margin-bottom: 16px;
        }
        .charityButtons {
          .basket {
            min-width: 180px;
            padding: 8px 28px;
            font-size: 15px;
            margin-right: 10px;
          }
          .charityHeart {
            min-width: 56px;
            padding: 4px 13px 4px;

            svg {
              width: 24px;
              height: 22px;
            }
          }
          .charityHeartActive {
            min-width: 56px;
            padding: 4px 13px 4px;

            svg {
              width: 24px;
              height: 22px;
            }
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .charityList {
    padding: 10px 0 20px;
  }

  .breadcrumbs {
    margin-bottom: 45px;
  }
}

.filterModal {
  width: 580px;
  padding: 36px 22px 26px 38px;

  .modal-header {
    margin-bottom: 16px;
    .register {
      width: 160px;
      height: 32px;
      padding: 7px 0;
      font-size: 13px;
    }
    h3 {
      font-size: 22px;
    }
    .giving_partner_button {
      a {
        width: 250px;
        padding: 12px 15px;
        font-size: 15px;
      }
    }
    .modal-body {
      .modal-contribution {
        .contribution-title {
          margin-bottom: 12px;
          span {
            font-size: 14px;
            margin-left: 7px;
          }
        }
        .contribution-checkbox {
          margin-bottom: 11px;

          .contribution-input {
            margin-right: 5px;
            margin-bottom: 14px;

            span {
              font-size: 12px;
              margin-left: 7px;
            }
          }
        }
      }

      .modal-charity {
        margin-bottom: 38px;

        .charity-title {
          margin-bottom: 18px;

          span {
            font-size: 13px;
          }
        }
        .charity-checkbox {
          .charity-input {
            margin-right: 7px;

            span {
              font-size: 12px;
              margin-left: 7px;
            }
          }
        }
      }
    }
    .modal-footer {
      .submit {
        font-size: 16px;
        width: 120px;
        height: 36px;
        &:first-of-type {
          margin-right: 7px;
        }
      }
    }
  }

  .charitylistWrap {
    .charity-header {
      margin-bottom: 28px;
      h3 {
        font-size: 22px;
      }
      .charityModal {
        .filterButton {
          width: 30px;
          height: 30px;

          .filterbadge {
            left: 18px;
          }
        }
      }
    }

    .charity-wrap {
      width: 545px;
      padding-top: 7px;
      padding-left: 3px;

      .charityCard {
        width: 260px;
        margin-right: 22px;
        padding: 16px 13px 28px 14px;
        margin-bottom: 20px;
        &:nth-of-type(3n + 0) {
          margin-right: 22px;
        }
        &:nth-of-type(4n + 0) {
          margin-right: 22px;
        }
        &:nth-of-type(2n + 0) {
          margin-right: 0px;
        }

        .charityImage {
          height: 115px;
          margin-top: 12px;
          margin-bottom: 6px;
        }

        .charitynWIcon {
          h4 {
            height: 42px;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 11px;
          }
          .charityRight {
            width: 16px;
            height: 16px;
            margin-left: 6px;
          }
        }
        p {
          height: 40px;
          font-size: 12px;
          line-height: 21px;
          margin-bottom: 15px;
        }
        .charityButtons {
          .basket {
            min-width: 170px;
            padding: 8px 28px;
            font-size: 14px;
            margin-right: 8px;
          }
          .charityHeart {
            min-width: 52px;
            padding: 3px 12px 3px;

            svg {
              width: 22px;
              height: 20px;
            }
          }
          .charityHeartActive {
            min-width: 52px;
            padding: 3px 12px 3px;

            svg {
              width: 22px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .charityList {
    padding: 10px 0 15px;
  }

  .breadcrumbs {
    margin-bottom: 35px;
  }

  .filterModal {
    width: 480px;
    padding: 32px 18px 22px 32px;

    .modal-header {
      margin-bottom: 12px;
      .register {
        width: 150px;
        height: 30px;
        padding: 5px 0;
        font-size: 13px;
      }
      h3 {
        font-size: 20px;
      }
      .giving_partner_button {
        margin: 15px auto 0;
        a {
          padding: 12px 14px;
          font-size: 14px;
        }
      }
    }
    .modal-body {
      .modal-contribution {
        .contribution-title {
          margin-bottom: 10px;
          span {
            font-size: 13px;
            margin-left: 6px;
          }
        }
        .contribution-checkbox {
          margin-bottom: 8px;

          .contribution-input {
            margin-right: 4px;
            margin-bottom: 12px;

            span {
              font-size: 11px;
              margin-left: 6px;
            }
          }
        }
      }

      .modal-charity {
        margin-bottom: 30px;

        .charity-title {
          margin-bottom: 15px;

          span {
            font-size: 12px;
          }
        }
        .charity-checkbox {
          .charity-input {
            margin-right: 6px;

            span {
              font-size: 11px;
              margin-left: 6px;
            }
          }
        }
      }
    }
    .modal-footer {
      .submit {
        font-size: 14px;
        width: 110px;
        height: 30px;
        &:first-of-type {
          margin-right: 6px;
        }
      }
    }
  }

  .charitylistWrap {
    .charity-header {
      margin-bottom: 24px;
      h3 {
        font-size: 20px;
      }
      .charityModal {
        .filterButton {
          width: 28px;
          height: 28px;

          .filterbadge {
            left: 18px;
            top: 0;
          }
        }
      }
    }

    .charity-wrap {
      width: 440px;
      padding-top: 6px;
      padding-left: 2px;

      .charityCard {
        width: 206px;
        margin-right: 18px;
        padding: 14px 12px 26px 12px;
        margin-bottom: 20px;
        &:nth-of-type(3n + 0) {
          margin-right: 18px;
        }
        &:nth-of-type(4n + 0) {
          margin-right: 18px;
        }
        &:nth-of-type(2n + 0) {
          margin-right: 0px;
        }

        .charityImage {
          height: 110px;
          margin-top: 10px;
          margin-bottom: 5px;
        }

        .charitynWIcon {
          h4 {
            height: 38px;
            font-size: 13px;
            line-height: 20px;
            margin-bottom: 10px;
          }
          .charityRight {
            width: 15px;
            height: 15px;
            margin-left: 5px;
          }
        }
        p {
          height: 38px;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 12px;
        }
        .charityButtons {
          .basket {
            min-width: 130px;
            padding: 6px 15px;
            font-size: 13px;
            margin-right: 4px;
          }
          .charityHeart {
            min-width: 48px;
            padding: 2px 10px 2px;

            svg {
              width: 20px;
              height: 18px;
            }
          }
          .charityHeartActive {
            min-width: 48px;
            padding: 2px 10px 2px;

            svg {
              width: 20px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .breadcrumbs {
    margin-bottom: 25px;
  }

  .filterModal {
    max-width: 300px;
    padding: 15px;

    .modal-header {
      margin-bottom: 12px;

      h3 {
        font-size: 18px;
      }
    }
    .modal-body {
      .modal-contribution {
        .contribution-title {
          margin-bottom: 10px;
          span {
            font-size: 12px;
            margin-left: 6px;
          }
        }
        .contribution-checkbox {
          margin-bottom: 8px;

          .contribution-input {
            margin-right: 4px;
            margin-bottom: 12px;

            span {
              font-size: 11px;
              margin-left: 6px;
            }
          }
        }
      }

      .modal-charity {
        margin-bottom: 15px;

        .charity-title {
          margin-bottom: 15px;

          span {
            font-size: 12px;
          }
        }
        .charity-checkbox {
          .charity-input {
            margin-right: 6px;

            span {
              font-size: 11px;
              margin-left: 6px;
            }
          }
        }
      }
    }
    .modal-footer {
      .submit {
        font-size: 12px;
        width: 100px;
        height: 25px;
        &:first-of-type {
          margin-right: 6px;
        }
      }
    }
  }

  .charitylistWrap {
    .charity-header {
      margin-bottom: 15px;
      h3 {
        font-size: 16px;
      }
      .charityModal {
        .filterButton {
          width: 25px;
          height: 25px;

          .filterbadge {
            left: 18px;
            top: 0;
          }
        }
      }
    }

    .charity-wrap {
      width: 100%;
      padding: 3px;

      .charityCard {
        width: 100%;
        margin-right: 0px;
        padding: 12px 10px 24px 10px;
        margin-bottom: 15px;
        &:nth-of-type(3n + 0) {
          margin-right: 0px;
        }
        &:nth-of-type(4n + 0) {
          margin-right: 0px;
        }
        &:nth-of-type(2n + 0) {
          margin-right: 0px;
        }

        .charityImage {
          height: 120px;
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .charitynWIcon {
          h4 {
            height: 36px;
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 8px;
          }
        }
        p {
          height: 34px;
          font-size: 11px;
          line-height: 18px;
          margin-bottom: 10px;
        }
        .charityButtons {
          justify-content: center;
          .basket {
            min-width: 130px;
            padding: 6px 24px;
            font-size: 12px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
