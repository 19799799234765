@import "../../scss/style.scss";

.react-tabs__tab--selected {
  background-color: #ebf5ec;
  &:focus:after {
    display: none;
  }
}
.checkbox-list {
  display: flex;
  .check-label {
    margin-right: 60px;
  }
}

.round {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  @include prefix(box-shadow, 0px 2px 0px rgba(0, 0, 0, 0.043));
}
.range-div {
  span {
    display: block;
    margin-top: 10px;
  }
}

.panel-box {
  .form-box {
    padding: 30px 0;
  }
}
.select-box {
  border-radius: 5px !important;
}
.div-checkbox {
  .inner-checkbox {
    display: inline-block;
    width: 49%;
    margin-right: 18px;
    vertical-align: top;
    margin-bottom: 15px;
    &:nth-child(2n + 0) {
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    // &:nth-last-child(2){
    //     margin-bottom: 0;
    // }
  }
}

.tab-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  p {
    font-family: $font_jost;
    font-size: 18px;
    margin-right: 30px;
    color: $black_1;
  }
  .tab-div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .check-label {
      font-size: 16px;
      display: flex;
      align-items: center;
      .checkmark {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        top: 3px;
        &::after {
          left: 4px;
          top: 1px;
          width: 4px;
          height: 8px;
        }
      }
    }
  }
}

.star-section {
  .form-box {
    padding-top: 0;
  }
  .rating-inner-box {
    text-align: center;
    margin-bottom: 30px;
    span {
      color: #000000;
      font-size: 14px;
      margin-top: 10px;
      font-weight: 600;
    }
    label {
      font-weight: 600;
      font-size: 14px;
    }
    .overAll-rating-wrap {
      display: flex;
      justify-content: center;

      .overAll-rating {
        display: flex;
        margin-right: 100px;
        span {
          margin-top: 0;
          margin-right: 40px;
        }
      }
      .overall-score {
        display: flex;
        span {
          margin-top: 0;
          margin-right: 40px;
        }
        .scoring-wrap {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .score-addon-rating {
            width: 100%;
            display: flex;
            align-items: center;
            border-radius: 7px;
            border: 1px solid rgba(0, 0, 0, 0.15);

            &:focus-within {
              border: 1px solid $theme-color;
            }

            strong {
              width: 20%;
              margin-top: 0;
              border-left: 1px solid rgba(0, 0, 0, 0.15);
              padding: 5px 10px;
              background-color: $theme-color;
              border-radius: 0 7px 7px 0;
              color: $white;
            }
          }
          .all-scoring-error {
            width: 100%;
            color: $red;
            font-weight: 400;
            text-align: right;
            margin-top: 10px;
            margin-right: 0;
          }

          .score-counting {
            width: calc(100% - 20px);
            font-family: $font_jost;
            padding: 5px 0px !important;
            text-align: center;
            color: $black_1;
            border: none;
            background-color: $white;
            border-radius: 7px;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
    }
    .percentage-counting {
      border: 1px solid $black_1;
      padding: 5px 20px;
      border-radius: 7px;
    }
  }
}

// @media (min-width: 768px) {
//   .div-checkbox {
//     .inner-checkbox {
//       &:nth-child(2n + 0) {
//         margin-right: 0;
//       }
//       &:nth-last-child(2) {
//         margin-bottom: 0;
//       }
//     }
//   }
// }

// @media (max-width: 991px) {
//   .div-checkbox {
//     .inner-checkbox {
//       margin-right: 14px;
//     }
//   }
// }

// @media (max-width: 767px) {
//   .div-checkbox {
//     .inner-checkbox {
//       width: 100%;
//       margin-right: 0;
//     }
//   }
//   .checkbox-list {
//     flex-wrap: wrap;
//     .check-label {
//       margin-right: 0;
//       margin-bottom: 20px;
//       width: 180px;
//       &:nth-child(n + 3) {
//         margin-bottom: 0;
//       }
//     }
//   }
//   .rating-inner-box {
//     .checkbox-list {
//       .check-label {
//         width: 170px;
//         margin-bottom: 10px;
//       }
//     }
//   }
// }
// @media (max-width: 575px) {
//   .tab-box {
//     .tab-div {
//       margin-right: 15px;
//     }
//   }
//   .checkbox-list {
//     .check-label {
//       width: 140px;
//     }
//   }

//   .rating-inner-box {
//     .checkbox-list {
//       .check-label {
//         width: 125px;
//         margin-bottom: 10px;
//       }
//     }
//   }
// }

// responsive
@include responsive(xl) {
  .tab-box {
    margin-bottom: 28px;
    .tab-div {
      margin-right: 46px;
      .check-label {
        font-size: 15px;
        .checkmark {
          width: 17px;
          height: 17px;
          &::after {
            width: 3px;
            height: 7px;
            top: 1px;
            left: 4px;
          }
        }
      }
    }
  }

  .panel-box {
    .form-box {
      padding: 28px 0;
    }
  }

  .div-checkbox {
    .inner-checkbox {
      margin-right: 17px;
      margin-bottom: 14px;
      width: 48%;
    }
  }

  .range-div {
    span {
      margin-top: 9px;
    }
  }

  .round {
    width: 18px !important;
    height: 18px !important;
  }

  .checkbox-list {
    .check-label {
      margin-right: 58px;
      margin-bottom: 8px;
    }
  }

  .star-section {
    .rating-inner-box {
      margin-bottom: 28px;
      span {
        font-size: 13px;
        margin-top: 9px;
      }
      label {
        font-size: 13px;
      }
      .overAll-rating-wrap {
        .overAll-rating {
          margin-right: 90px;
          span {
            margin-right: 35px;
          }
        }
        .overall-score {
          span {
            margin-right: 35px;
          }
          .scoring-wrap {
            .score-addon-rating {
              strong {
                padding: 5px 10px;
              }
            }
            .all-scoring-error {
              text-align: right;
              margin-top: 10px;
            }

            .score-counting {
              padding: 5px 0px !important;
            }
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .tab-box {
    margin-bottom: 26px;
    .tab-div {
      margin-right: 42px;
      .check-label {
        font-size: 14px;
        .checkmark {
          width: 16px;
          height: 16px;
          &::after {
            width: 2px;
            height: 6px;
            top: 2px;
            left: 4px;
          }
        }
      }
    }
  }

  .panel-box {
    .form-box {
      padding: 26px 0;
    }
  }

  .div-checkbox {
    .inner-checkbox {
      margin-right: 15px;
      margin-bottom: 13px;
      width: 48%;
    }
  }

  .range-div {
    span {
      margin-top: 8px;
    }
  }

  .round {
    width: 16px !important;
    height: 16px !important;
  }

  .checkbox-list {
    .check-label {
      margin-right: 54px;
      margin-bottom: 7px;
    }
  }

  .star-section {
    .rating-inner-box {
      margin-bottom: 26px;
      span {
        font-size: 12px;
        margin-top: 8px;
      }
      label {
        font-size: 12px;
      }
      .overAll-rating-wrap {
        .overAll-rating {
          margin-right: 65px;
          span {
            margin-right: 32px;
          }
        }
        .overall-score {
          span {
            margin-right: 32px;
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .tab-box {
    margin-bottom: 24px;
    .tab-div {
      margin-right: 35px;
      .check-label {
        font-size: 13px;
        .checkmark {
          width: 15px;
          height: 15px;
          &::after {
            width: 2px;
            height: 5px;
            top: 2px;
            left: 4px;
          }
        }
      }
    }
  }

  .panel-box {
    .form-box {
      padding: 24px 0;
    }
  }

  .div-checkbox {
    .inner-checkbox {
      margin-right: 12px;
      margin-bottom: 11px;
      width: 48%;
    }
  }

  .range-div {
    span {
      margin-top: 7px;
    }
  }

  .round {
    width: 15px !important;
    height: 15px !important;
  }

  .checkbox-list {
    .check-label {
      margin-right: 50px;
      margin-bottom: 6px;
    }
  }

  .star-section {
    .rating-inner-box {
      margin-bottom: 24px;
      span {
        font-size: 12px;
        margin-top: 7px;
      }
      label {
        font-size: 12px;
      }
      .overAll-rating-wrap {
        .overAll-rating {
          margin-right: 60px;
          span {
            margin-right: 30px;
          }
        }
        .overall-score {
          span {
            margin-right: 30px;
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .tab-box {
    margin-bottom: 22px;
    .tab-div {
      margin-right: 32px;
      .check-label {
        font-size: 13px;
        .checkmark {
          width: 14px;
          height: 14px;
          &::after {
            width: 2px;
            height: 5px;
            top: 2px;
            left: 4px;
          }
        }
      }
    }
  }

  .panel-box {
    .form-box {
      padding: 22px 0;
    }
  }

  .div-checkbox {
    .inner-checkbox {
      margin-right: 11px;
      margin-bottom: 10px;
      width: 48%;
    }
  }

  .range-div {
    span {
      margin-top: 7px;
    }
  }

  .round {
    width: 14px !important;
    height: 14px !important;
  }

  .checkbox-list {
    .check-label {
      margin-right: 48px;
      margin-bottom: 6px;
    }
  }

  .star-section {
    .rating-inner-box {
      margin-bottom: 24px;
      span {
        font-size: 12px;
        margin-top: 7px;
      }
      label {
        font-size: 12px;
      }
      .overAll-rating-wrap {
        flex-wrap: wrap;
        justify-content: flex-start;

        .overAll-rating {
          margin-bottom: 15px;
        }
        .overall-score {
          .scoring-wrap {
            .score-addon-rating {
              strong {
                padding: 6px 10px;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .tab-box {
    margin-bottom: 20px;
    justify-content: space-between;
    .tab-div {
      margin-right: 0px;
      .check-label {
        font-size: 12px;
        .checkmark {
          width: 13px;
          height: 13px;
          &::after {
            width: 2px;
            height: 4px;
            top: 1px;
            left: 3px;
          }
        }
      }
    }
  }

  .panel-box {
    .form-box {
      padding: 10px 0;
    }
  }

  .div-checkbox {
    .inner-checkbox {
      width: 100%;
      margin-right: 10px;
      margin-bottom: 8px;
    }
  }

  .range-div {
    span {
      margin-top: 6px;
    }
  }

  .round {
    width: 13px !important;
    height: 13px !important;
  }

  .checkbox-list {
    flex-wrap: wrap;
    .check-label {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 5px;
    }
  }

  .star-section {
    .rating-inner-box {
      margin-bottom: 22px;
      span {
        font-size: 12px;
        margin-top: 6px;
      }
      label {
        text-align: center;
        font-size: 12px;
      }
      .overAll-rating-wrap {
        .overAll-rating {
          flex-direction: column;
          span {
            margin-bottom: 10px;
          }
        }
        .overall-score {
          flex-direction: column;
          span {
            margin-bottom: 10px;
          }
          .scoring-wrap{
            .score-addon-rating{
              strong{
                padding: 7px 10px;
              }
            }
          }
        }
      }
    }
  }
}
