@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.giving_partner {
  width: 100%;
  margin-top: 85px;
  padding: 13px 0 71px;
}

.breadcrumb_giving_partner {
  margin-bottom: 45px;
}

.giving-partner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 27px;
  .pageTitle {
    margin-bottom: 0;
  }
}
.giving_partner_button {
  a {
    display: inline-block;
    width: 320px;
    text-align: center;
    background-color: $green-shadow;
    border-radius: 50px;
    padding: 12px 20px;
    font-family: $font_jost;
    font-weight: 400;
    color: $white;
    font-size: 18px;
    @include prefix(transition, all 0.3s ease-in-out);
    &:hover {
      background-color: $theme-color;
    }
  }
}
.giving_button {
  text-align: center;
}

.giving_partner_content {
  width: 100%;
  padding: 20px 0 50px;

  .giving_content {
    float: left;

    .contentText {
      padding-top: 0;

      // h3,
      // h2 {
      //   display: none;
      // }

      p {
        h3,
        h2 {
          display: block;
        }
      }
    }

    .contentImage {
      float: left;
      margin: 0 50px 12px 0px;
      .mainImage {
        width: 100%;
        height: 345px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .giving_partner {
    margin-top: 80px;
    padding: 12px 0 68px;
  }

  .breadcrumb_giving_partner {
    margin-bottom: 42px;
  }

  .giving-partner-header {
    margin-bottom: 25px;
    .pageTitle {
      margin-bottom: 0px;
    }
  }
  .giving_partner_button {
    a {
      width: 300px;
      padding: 12px 18px;
    }
  }

  .giving_partner_content {
    padding: 20px 0 40px;

    .giving_content {
      .contentImage {
        margin: 0 45px 18px 0;
        .mainImage {
          height: 320px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .giving_partner {
    padding: 12px 0 64px;
  }

  .breadcrumb_giving_partner {
    margin-bottom: 40px;
  }

  .giving-partner-header {
    margin-bottom: 23px;
    .pageTitle {
      margin-bottom: 0px;
    }
  }
  .giving_partner_button {
    a {
      width: 280px;
      padding: 12px 16px;
      font-size: 16px;
    }
  }

  .giving_partner_content {
    padding: 20px 0 36px;

    .giving_content {
      .contentImage {
        margin: 0 40px 18px 0;
        .mainImage {
          height: 300px;
        }
      }
    }
  }
}

@include responsive(md) {
  .giving_partner {
    padding: 10px 0 40px;
  }

  .breadcrumb_giving_partner {
    margin-bottom: 30px;
  }

  .giving-partner-header {
    margin-bottom: 20px;
    .pageTitle {
      margin-bottom: 0px;
    }
  }
  .giving_partner_button {
    a {
      width: 250px;
      padding: 12px 15px;
      font-size: 15px;
    }
  }

  .giving_partner_content {
    padding: 20px 0 34px;

    .giving_content {
      .contentImage {
        margin: 0 30px 16px 0;
        .mainImage {
          height: 270px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .giving_partner {
    padding: 10px 0 30px;
  }

  .breadcrumb_giving_partner {
    margin-bottom: 20px;
  }

  .giving-partner-header {
    margin-bottom: 15px;
    .pageTitle {
      margin-bottom: 0px;
    }
  }

  .giving_partner_content {
    padding: 20px 0 30px;

    .giving_content {
      width: 100%;
      float: none;
      display: flex;
      flex-direction: column;

      .contentText {
        padding-top: 16px;
      }

      .contentImage {
        float: none;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;

        .mainImage {
          width: 300px;
          height: 250px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .giving_partner {
    padding: 10px 0 15px;
  }

  .breadcrumb_giving_partner {
    margin-bottom: 15px;
  }

  .giving-partner-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .giving_partner_button {
    margin: 15px auto 0;
    a {
      padding: 12px 14px;
      font-size: 14px;
    }
  }

  .giving_partner_content {
    padding: 15px 0;

    .giving_content {
      .contentText {
        padding-top: 15px;
      }

      .contentImage {
        .mainImage {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}
