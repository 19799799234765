@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

// about-banner
.about-banner {
  width: 100%;
  background-image: url("../../../public/images/aboutBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 124px 376px 204px 370px;
  margin-top: 85px;
  text-align: center;

  p {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 45px;
    color: $white;
  }
}

// about rosi
.aboutRosi {
  width: 100%;
  padding: 100px 0;

  .aboutSec {
    margin-bottom: 30px;
    float: left;

    .contentImage {
      float: left;
      margin: 0 50px 20px 0px;
      .mainImage {
        width: 100%;
        height: 345px;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }

  .aboutRosiDesc {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: $light-gray;
    margin-bottom: 30px;
  }

  .rosiTogether {
    float: left;

    h3 {
      font-family: $font_lora;
      font-size: 21px;
      line-height: 26px;
      font-weight: 700;
      color: $black_1;
      margin-bottom: 15px;
    }

    .togetherText {
      p {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        color: $light-gray;
        line-height: 30px;
        margin-bottom: 47px;

        &:first-of-type {
          margin-bottom: 30px;
        }
      }

      h3 {
        font-family: $font_lora;
        font-weight: 700;
        font-size: 21px;
        color: $black_1;
        line-height: 26px;
      }
    }

    .togetherImage {
      width: 466px;
      float: right;
      margin: 0 0 20px 50px;

      .mainImage {
        width: 100%;
        height: 345px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

// rosi name
.rosiName {
  background-color: rgba($color: $theme-color, $alpha: 0.1);
  padding: 101px 0 117px;

  span {
    color: $green-shadow;
  }
}

// section content
.aboutContent {
  width: 100%;
  padding: 88px 0 100px;

  .aboutDesc {
    margin-bottom: 50px;
    float: left;

    .contentImage {
      float: left;
      margin: 0 50px 20px 0px;
      .mainImage {
        width: 100%;
        height: 345px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .aboutDescs {
    float: left;

    .contentImage {
      float: right;
      margin: 0 0 20px 50px;
      .mainImage {
        width: 100%;
        height: 400px;
      }
    }
  }
}

// leadership
.leadership {
  width: 100%;
  padding: 100px 0 30px;
  background-color: rgba($color: $theme-color, $alpha: 0.1);
  text-align: center;

  .leadership-wrap {
    width: 100%;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      color: $green-shadow;
      font-size: 16px;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    h2 {
      width: 80%;
      margin: 0 auto;
      font-family: $font_lora;
      font-weight: 700;
      color: $black_1;
      font-size: 45px;
      margin-bottom: 50px;
    }
  }
}

// donate now
.aboutDonate {
  padding: 76px 0;
}

.donateAbout {
  display: inline-block;
  width: 280px;
  padding: 6px 66px;
  border-radius: 20px;
  background-color: $green-shadow;
  color: $white;
  font-family: $font_jost;
  font-weight: 400;
  font-size: 16px;
  @include prefix(transition, all 0.3s ease-in-out);

  &:hover {
    background-color: $theme-color;
  }
}

// responsive
@include responsive(xl) {
  //  about banner
  .about-banner {
    padding: 108px 340px 180px;
    margin-top: 80px;

    p {
      font-size: 42px;
    }
  }
  // about rosi
  .aboutRosi {
    padding: 90px 0;

    .aboutSec {
      margin-bottom: 28px;

      .contentImage {
        margin: 0 45px 18px 0;
        .mainImage {
          height: 320px;
        }
      }
    }
    .rosiTogether {
      .togetherText {
        p {
          font-size: 15px;
          line-height: 28px;
          &:first-of-type {
            margin-bottom: 25px;
          }
        }
        h3 {
          font-size: 19px;
          line-height: 23px;
        }
      }
      .togetherImage {
        width: 430px;
        margin: 0 0 18px 45px;

        .mainImage {
          height: 320px;
        }
      }
    }
  }

  // rosi name
  .rosiName {
    padding: 100px 0 110px;

    .section-left {
      width: 54%;
      h4 {
        font-size: 15px;
        margin-bottom: 26px;
      }
      h1 {
        max-width: 485px;
        font-size: 42px;
      }
    }
    .section-right {
      width: 46%;
      h5 {
        max-width: 485px;
        font-size: 15px;
        margin-bottom: 28px;
      }
      p {
        max-width: 485px;
        font-size: 15px;
        line-height: 28px;
      }
    }
  }

  // section content
  .aboutContent {
    padding: 84px 0 95px;

    .aboutDesc {
      margin-bottom: 45px;

      .contentImage {
        margin: 0 45px 18px 0;
        .mainImage {
          height: 320px;
        }
      }
    }
    .aboutDescs {
      .contentImage {
        margin: 0 0 18px 45px;
        .mainImage {
          height: 380px;
        }
      }
    }
  }

  // leadership
  .leadership {
    padding: 90px 0 30px;

    .leadership-wrap {
      h3 {
        font-size: 15px;
        margin-bottom: 18px;
      }
      h2 {
        width: 74%;
        font-size: 42px;
        margin-bottom: 40px;
      }
    }
  }

  // donate now
  .aboutDonate {
    padding: 68px 0;
  }

  .donateAbout {
    width: 270px;
    font-size: 15px;
    padding: 5px 62px;
  }
}

@include responsive(lg) {
  //  about banner
  .about-banner {
    padding: 100px 300px 150px 300px;

    p {
      font-size: 40px;
    }
  }
  // about rosi
  .aboutRosi {
    padding: 80px 0;

    .aboutSec {
      margin-bottom: 25px;

      .contentImage {
        margin: 0 40px 18px 0;
        .mainImage {
          height: 300px;
        }
      }
    }
    .rosiTogether {
      .togetherText {
        p {
          font-size: 15px;
          line-height: 25px;
          &:first-of-type {
            margin-bottom: 20px;
          }
        }
        h3 {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .togetherImage {
        width: 400px;
        margin: 0 0 18px 40px;

        .mainImage {
          height: 300px;
        }
      }
    }
  }

  // rosi name
  .rosiName {
    padding: 100px 0;

    .section-left {
      width: 50%;
      h4 {
        font-size: 14px;
        margin-bottom: 24px;
      }
      h1 {
        max-width: 475px;
        font-size: 40px;
      }
    }
    .section-right {
      width: 50%;
      h5 {
        max-width: 475px;
        font-size: 14px;
        margin-bottom: 25px;
      }
      p {
        max-width: 475px;
        font-size: 14px;
        line-height: 25px;
      }
    }
  }

  // section content
  .aboutContent {
    padding: 80px 0 90px;

    .aboutDesc {
      margin-bottom: 40px;

      .contentImage {
        margin: 0 40px 18px 0;
        .mainImage {
          height: 300px;
        }
      }
    }
    .aboutDescs {
      .contentImage {
        margin: 0 0 18px 45px;
        .mainImage {
          height: 350px;
        }
      }
    }
  }

  // leadership
  .leadership {
    padding: 80px 0 30px;

    .leadership-wrap {
      h3 {
        font-size: 15px;
        margin-bottom: 18px;
      }
      h2 {
        width: 84%;
        font-size: 40px;
        margin-bottom: 40px;
      }
    }
  }

  // donate now
  .aboutDonate {
    padding: 60px 0;
  }

  .donateAbout {
    width: 270px;
    font-size: 15px;
    padding: 5px 60px;
  }
}

@include responsive(md) {
  //  about banner
  .about-banner {
    padding: 80px 280px 120px 280px;

    p {
      font-size: 34px;
    }
  }
  // about rosi
  .aboutRosi {
    padding: 60px 0;

    .aboutSec {
      margin-bottom: 20px;

      .contentImage {
        margin: 0 30px 16px 0;
        .mainImage {
          height: 270px;
        }
      }
    }
    .rosiTogether {
      .togetherText {
        p {
          font-size: 14px;
          line-height: 22px;
          &:first-of-type {
            margin-bottom: 16px;
          }
        }
        h3 {
          font-size: 16px;
          line-height: 18px;
        }
      }
      .togetherImage {
        width: 350px;
        margin: 0 0 16px 30px;

        .mainImage {
          height: 270px;
        }
      }
    }
  }

  // rosi name
  .rosiName {
    padding: 70px 0;

    .section-left {
      h4 {
        font-size: 13px;
        margin-bottom: 20px;
      }
      h1 {
        max-width: 450px;
        font-size: 30px;
      }
    }
    .section-right {
      h5 {
        max-width: 450px;
        font-size: 13px;
        margin-bottom: 20px;
      }
      p {
        max-width: 450px;
        font-size: 13px;
        line-height: 22px;
      }
    }
  }

  // section content
  .aboutContent {
    padding: 60px 0;

    .aboutDesc {
      margin-bottom: 30px;

      .contentImage {
        margin: 0 30px 16px 0;
        .mainImage {
          height: 270px;
        }
      }
    }
    .aboutDescs {
      .contentImage {
        margin: 0 0 16px 40px;
        .mainImage {
          height: 320px;
        }
      }
    }
  }

  // leadership
  .leadership {
    padding: 60px 0 20px;

    .leadership-wrap {
      h3 {
        font-size: 14px;
        margin-bottom: 16px;
      }
      h2 {
        font-size: 30px;
        margin-bottom: 35px;
      }
    }
  }

  // donate now
  .aboutDonate {
    padding: 50px 0;
  }

  .donateAbout {
    width: 250px;
    font-size: 15px;
    padding: 5px 55px;
  }
}

@include responsive(sm) {
  //  about banner
  .about-banner {
    padding: 60px 220px 80px 220px;

    p {
      font-size: 28px;
    }
  }
  // about rosi
  .aboutRosi {
    padding: 40px 0;

    .aboutSec {
      float: none;
      margin-bottom: 15px;

      .contentImage {
        width: 100%;
        float: none;
        margin: 0;
        display: flex;
        justify-content: center;

        .mainImage {
          width: 300px;
          height: 250px;
        }
      }
    }
    .rosiTogether {
      float: none;
      .togetherText {
        margin-top: 15px;
        p {
          font-size: 13px;
          line-height: 22px;
          &:first-of-type {
            margin-bottom: 10px;
          }
        }
        h3 {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .togetherImage {
        float: none;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;

        .mainImage {
          width: 250px;
          height: 250px;
        }
      }
    }
  }

  // rosi name
  .rosiName {
    padding: 40px 0;

    .section-left {
      h4 {
        font-size: 12px;
        margin-bottom: 10px;
      }
      h1 {
        max-width: 400px;
        font-size: 24px;
      }
    }
    .section-right {
      h5 {
        max-width: 400px;
        font-size: 12px;
        margin-bottom: 10px;
      }
      p {
        max-width: 400px;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  // section content
  .aboutContent {
    padding: 40px 0;

    .aboutDesc {
      width: 100%;
      float: none;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .contentImage {
        float: none;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;

        .mainImage {
          width: 300px;
          height: 250px;
        }
      }
    }
    .aboutDescs {
      float: none;
      .contentImage {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        float: none;

        .mainImage {
          width: 300px;
          height: 250px;
        }
      }
    }
  }
  // leadership
  .leadership {
    padding: 40px 0 15px;

    .leadership-wrap {
      h3 {
        font-size: 13px;
        margin-bottom: 8px;
      }
      h2 {
        width: 88%;
        font-size: 24px;
        margin-bottom: 25px;
      }
    }
  }
  // donate now
  .aboutDonate {
    padding: 40px 0;
  }

  .donateAbout {
    width: 200px;
    font-size: 14px;
    padding: 5px 50px;
  }
}

@include responsive(xs) {
  //  about banner
  .about-banner {
    padding: 40px 0;

    p {
      font-size: 22px;
    }
  }
  // about rosi
  .aboutRosi {
    padding: 15px 0;

    .aboutSec {
      margin-bottom: 15px;

      .contentImage {
        .mainImage {
          width: 200px;
          height: 200px;
        }
      }
    }
    .rosiTogether {
      .togetherText {
        p {
          font-size: 12px;
          line-height: 20px;
          &:first-of-type {
            margin-bottom: 8px;
          }
        }
        h3 {
          font-size: 12px;
        }
      }
      .togetherImage {
        .mainImage {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  // rosi name
  .rosiName {
    padding: 15px 0;

    .main-about {
      flex-direction: column;
      .section-left {
        width: 100%;
        margin-bottom: 18px;
        h4 {
          font-size: 12px;
          margin-bottom: 4px;
        }
        h1 {
          max-width: 100%;
          font-size: 22px;
        }
      }
      .section-right {
        width: 100%;
        h5 {
          max-width: 100%;
          font-size: 12px;
          margin-bottom: 4px;
        }
        p {
          max-width: 100%;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
  // section content
  .aboutContent {
    padding: 15px 0;

    .aboutDesc {
      margin-bottom: 15px;

      .contentImage {
        .mainImage {
          width: 200px;
          height: 200px;
        }
      }
    }
    .aboutDescs {
      .contentImage {
        .mainImage {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
  // leadership
  .leadership {
    padding: 15px 0;

    .leadership-wrap {
      h3 {
        font-size: 12px;
        margin-bottom: 4px;
      }
      h2 {
        width: 100%;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
  // donate now
  .aboutDonate {
    padding: 20px 0;
  }

  .donateAbout {
    width: 150px;
    font-size: 13px;
    padding: 4px 40px;
  }
}
