@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.home-registration {
  width: 31%;
  text-align: center;

  h1 {
    font-family: $font_jost;
    font-weight: 700;
    font-size: 22px;
    color: $black_1;
    margin-bottom: 20px;
  }

  .registration-content {
    padding: 20px;
    margin-bottom: 20px;
    height: 320px;
    overflow-y: auto;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #4f4f4f;
      text-align: left;
    }

    ul {
      list-style-type: disc; 
      display: block;
      text-align: start;
      list-style-position: inside;
      margin: 10px 0 10px 10px
    }
    ol {
      display: block;
      text-align: start;
      list-style-type: decimal;
      list-style-position: inside;
      margin: 10px 0 10px 10px
    }
  }
  a {
    width: 80%;
    margin: 0 auto;
    display: inline-block;
    border-radius: 50px;
    font-family: $font_jost;
    font-weight: 400;
    text-align: center;
    color: $white;
    font-size: 16px;
    padding: 12px 0px;
    background-color: $green-shadow;
    @include prefix(transition, all 0.3s ease-in-out);

    &:hover {
      background-color: $theme-color;
    }
  }
}

// responsive
@include responsive(xl) {
  .home-registration {
    h1 {
      font-size: 22px;
      margin-bottom: 20px;
    }

    .registration-content {
      padding: 20px;
      margin-bottom: 20px;

      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
    a {
      font-size: 16px;
      padding: 20px 0px;
    }
  }
}

@include responsive(md) {
  .home-registration {
    width: 100%;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
    a {
      width: 50%;
    }
  }
}

@include responsive(xs) {
    .home-registration{
        a{
            width: 100%;
        }
    }
}
