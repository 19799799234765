@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.chat {
  width: 100%;
  margin-top: 85px;
  padding: 13px 0 71px;
}

.emptyChatList {
  padding: 15px;
  font-family: $font_jost;
  color: $red;
  font-weight: 400;
  min-height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadcrumbsMessage {
  margin-bottom: 52px;
}

.chat-wrap {
  width: 100%;
  border: 1px solid $black;
}

.chat-header {
  margin-bottom: 43px;
  h3 {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 30px;
    color: $black_1;
  }
}

.messageHeader {
  background-color: $theme-color;
  padding: 23px;

  .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 370px;
    background-color: $white;
    border-radius: 50px;
    padding-right: 20px;

    input {
      width: 325px;
      background-color: transparent;
      padding: 11px 0px 11px 23px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
    }
    ::-webkit-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
    }

    :-ms-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
    }

    ::placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
    }
  }
}

.messageContent {
  display: flex;
}

.chat-list-wrap {
  width: 415px;
  max-width: 100%;
  border-right: 1px solid $black;
}

.chat-listing {
  width: 100%;
  // height: 720px;
  height: 670px;

  li {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    &:last-child {
      margin-bottom: 0;
    }

    .chat-user {
      display: flex;
      cursor: pointer;
      margin: 15px 12px 15px 15px;
      padding: 7px 17px 13px 10px;
      border-radius: 7px;

      .rosiChatWrap {
        display: flex;
      }

      &:hover {
        background-color: $light-green;
      }

      &.active-chat {
        background-color: $light-green;

        .chat-nm-desc {
          strong {
            color: $black;
          }

          p {
            color: $black_1;
          }
        }
      }

      figure {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;

        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }

      .chat-nm-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 230px;
        max-width: 100%;
        margin-right: 12px;
        strong {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 16px;
          color: $black_1;
          width: 100%;
          line-height: 23px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        p {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          color: #525252;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 17px;
        }
      }

      .rosi-time {
        display: flex;
        align-items: flex-end;
      }

      .chat-time {
        span {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 10px;
          text-transform: uppercase;
          padding-bottom: 4px;
        }
      }
    }
  }
  .notReadList .chat-user .rosiChatWrap {
    .chat-nm-desc {
      strong {
        font-weight: 700;
        color: $black_1;
      }

      p {
        font-weight: 700;
      }
    }
  }
}

.scroll-wrapper {
  max-height: calc(100vh - 103px);
  overflow-y: auto;
}

.chat-placeholder {
  width: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-body-wrap {
  width: calc(100% - 415px);
  max-width: 100%;
  background-color: transparent;
  .input-box {
    width: 100%;
    font-family: $font_jost;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    padding: 10px 0px 10px 20px;
    border-radius: 50px;
  }
  ::-webkit-input-placeholder {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    color: $black_1;
  }

  :-ms-input-placeholder {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    color: $black_1;
  }

  ::placeholder {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    color: $black_1;
  }
}

.dropdownAvtar {
  .profileDropdown {
    width: 150px;
    right: 8px;
    top: 25px;

    &::before {
      top: -10px;
      right: 10px;
      width: 20px;
      height: 20px;
    }

    li {
      padding: 6px 22px 6px 20px;
      font-size: 13px;
    }
  }
}
.chat-footer {
  .chat-action {
    button {
      border: 0;
      cursor: pointer;
      margin-right: 10px;
      font-size: 24px;
      line-height: 0;
      width: 32px;
      padding: 0;
      background-color: transparent;
      svg {
        fill: rgba(66, 164, 76, 0.85);
        width: 20px;
        height: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .footerInputGrp {
    display: flex;
    align-items: center;
    border: 1px solid rgba($color: $black, $alpha: 0.2);
    border-radius: 50px;
    margin: 0 23px 40px;
    padding-right: 34px;

    &:focus-within {
      border: 1px solid $theme-color;

      .send-btn {
        svg path {
          fill: $theme-color;
        }
      }
    }
  }
}

.chat-body-header {
  padding: 20px 24px 22px;
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
  .active-chat-user {
    display: flex;
    align-items: center;

    figure {
      height: 50px;
      width: 50px;
      margin-right: 12px;

      img {
        height: 100%;
        width: 100%;
      }
    }
    .dropdownAvtar {
      figure {
        height: 50px;
        width: 50px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 50%;
          width: 50%;
        }
      }
      .profileDropdown {
        width: 150px;
        right: 45px;
        top: 25px;

        &::before {
          top: -10px;
          right: 10px;
          width: 20px;
          height: 20px;
        }

        li {
          padding: 6px 22px 6px 20px;
          font-size: 14px;
          font-family: Jost, sans-serif;
        }
      }
    }
    .chat-nm-desc {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      strong {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
        text-transform: capitalize;
      }
    }
  }
}

.chat-body-main {
  width: 100%;

  .chat-body {
    padding: 22px;

    .chat-msg {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;

      figure {
        height: 35px;
        width: 35px;
        margin-right: 15px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .chat-receiver-content {
        display: flex;
        flex-direction: column;
        max-width: calc(50% - 60px);
        background-color: $light-green;
        border-radius: 0 7px 7px 0;
        padding: 4px 11px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: -12px;
          top: 0;
          width: 0;
          height: 0;
          border-top: 12px solid $light-green;
          border-left: 12px solid transparent;
        }

        .chat-message {
          h3 {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $black_2;
            text-transform: capitalize;
            margin-bottom: 4px;
          }
          span {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $black_2;

            a {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $black_1;
              margin-bottom: 3px;
              word-break: break-word;
              border-bottom: 1px solid $black_1;
            }
          }
        }

        small {
          font-family: $font_jost;
          font-size: 10px;
          color: $gray_5;
          text-align: end;
          text-transform: uppercase;
        }
      }

      &.sender {
        flex-direction: row-reverse;

        .chat-sender-content {
          display: flex;
          flex-direction: column;
          max-width: calc(50% - 60px);
          background-color: $theme-color;
          padding: 5px 10px 8px 10px;
          border-radius: 7px 0 0 7px;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            right: -9px;
            top: 0;
            width: 0;
            height: 0;
            border-top: 10px solid $theme-color;
            border-right: 10px solid transparent;
          }

          span {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $white;
            margin-bottom: 3px;

            a {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $white;
              margin-bottom: 3px;
              word-break: break-word;
              border-bottom: 1px solid $white;
            }
          }

          small {
            font-family: $font_jost;
            font-weight: 300;
            font-size: 12px;
            color: $white;
            text-align: end;
            text-transform: uppercase;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

//   /* Track */
::-webkit-scrollbar-track {
  background: white;
}

//   /* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888;
}

//   /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(58, 57, 57);
}
.reverse-scroll-wrapper {
  max-height: calc(100vh - 288px);
  min-height: calc(100vh - 288px);
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
.border-50 {
  border-radius: 50%;
}
.imgClass {
  object-fit: contain;
}

.custom-file-upload {
  cursor: pointer;

  label {
    cursor: pointer;
  }

  input[type="file"] {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
}

.chat-back {
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  line-height: 0;
  display: none;
}

// responsive
@include responsive(xl) {
  .chat {
    margin-top: 80px;
    padding: 12px 0 65px;
  }

  .breadcrumbsMessage {
    margin-bottom: 48px;
  }

  .chat-header {
    margin-bottom: 38px;
    h3 {
      font-size: 28px;
    }
  }

  .messageHeader {
    padding: 21px;

    .form-group {
      width: 365px;
      padding-right: 18px;

      input {
        width: 320px;
        padding: 10px 0 10px 20px;
        font-size: 14px;
      }
      ::-webkit-input-placeholder {
        font-size: 14px;
      }

      :-ms-input-placeholder {
        font-size: 14px;
      }

      ::placeholder {
        font-size: 14px;
      }
    }
  }

  .chat-list-wrap {
    width: 410px;
  }

  .chat-listing {
    height: 920px;

    li {
      .chat-user {
        margin: 13px 12px 13px 13px;
        padding: 7px 15px 12px 10px;

        figure {
          width: 48px;
          height: 48px;
          margin-right: 10px;
        }

        .chat-nm-desc {
          width: 225px;
          margin-right: 14px;
          strong {
            font-size: 15px;
            line-height: 22px;
          }
          p {
            font-size: 12px;
            line-height: 17px;
          }
        }

        .chat-time {
          span {
            font-size: 10px;
            padding-bottom: 4px;
          }
        }
      }
    }
  }

  .scroll-wrapper {
    max-height: calc(100vh - 100px);
  }

  .chat-placeholder {
    width: 63%;
  }

  .chat-body-wrap {
    width: calc(100% - 410px);
    .input-box {
      font-size: 14px;
      line-height: 32px;
      padding: 10px 0 10px 18px;
    }
    ::-webkit-input-placeholder {
      font-size: 14px;
    }

    :-ms-input-placeholder {
      font-size: 14px;
    }

    ::placeholder {
      font-size: 14px;
    }
  }

  .chat-footer {
    .footerInputGrp {
      padding-right: 28px;
      margin: 0 20px 45px;
    }
  }

  .chat-body-header {
    padding: 18px 22px 20px;
    .active-chat-user {
      figure {
        height: 48px;
        width: 48px;
        margin-right: 11px;
      }

      .chat-nm-desc {
        strong {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }

  .chat-body-main {
    .chat-body {
      padding: 20px;

      .chat-msg {
        margin-bottom: 14px;

        figure {
          height: 32px;
          width: 32px;
          margin-right: 13px;
        }

        .chat-receiver-content {
          max-width: calc(50% - 30px);
          padding: 4px 10px;

          .chat-message {
            h3 {
              font-size: 14px;
              margin-bottom: 4px;
            }
            span {
              font-size: 13px;
              line-height: 18px;
            }
          }

          small {
            font-size: 10px;
          }
        }

        &.sender {
          .chat-sender-content {
            max-width: calc(50% - 65px);
            padding: 5px 10px 8px 10px;

            span {
              font-size: 14px;
              margin-bottom: 3px;
            }

            small {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .reverse-scroll-wrapper {
    max-height: calc(100vh - 290px);
    min-height: calc(100vh - 290px);
  }
}

@include responsive(lg) {
  .chat {
    padding: 12px 0 55px;
  }

  .breadcrumbsMessage {
    margin-bottom: 40px;
  }

  .chat-header {
    margin-bottom: 34px;
    h3 {
      font-size: 24px;
    }
  }

  .messageHeader {
    padding: 19px;

    .form-group {
      width: 360px;
      padding-right: 18px;

      input {
        width: 318px;
        padding: 8px 0 8px 18px;
        font-size: 13px;
      }
      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }
    }
  }

  .chat-list-wrap {
    width: 380px;
  }

  .chat-listing {
    height: 917px;

    li {
      .chat-user {
        margin: 11px;
        padding: 7px 12px 12px 10px;

        figure {
          width: 45px;
          height: 45px;
          margin-right: 9px;
        }

        .chat-nm-desc {
          width: 225px;
          margin-right: 12px;
          strong {
            font-size: 14px;
            line-height: 20px;
          }
          p {
            font-size: 12px;
            line-height: 17px;
          }
        }

        .chat-time {
          span {
            font-size: 10px;
            padding-bottom: 3px;
          }
        }
      }
    }
  }

  .scroll-wrapper {
    max-height: calc(100vh - 90px);
  }

  .chat-placeholder {
    width: 60%;
  }

  .chat-body-wrap {
    width: calc(100% - 380px);
    .input-box {
      font-size: 13px;
      line-height: 28px;
      padding: 10px 0 10px 16px;
    }
    ::-webkit-input-placeholder {
      font-size: 13px;
    }

    :-ms-input-placeholder {
      font-size: 13px;
    }

    ::placeholder {
      font-size: 13px;
    }
  }

  .chat-footer {
    .footerInputGrp {
      padding-right: 24px;
      margin: 0 18px 42px;
    }
  }

  .chat-body-header {
    padding: 16px 20px 18px;
    .active-chat-user {
      figure {
        height: 46px;
        width: 46px;
        margin-right: 10px;
      }

      .chat-nm-desc {
        strong {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .chat-body-main {
    .chat-body {
      padding: 18px;

      .chat-msg {
        margin-bottom: 12px;

        figure {
          height: 30px;
          width: 30px;
          margin-right: 12px;
        }

        .chat-receiver-content {
          max-width: calc(50% - 15px);
          padding: 4px 8px;

          .chat-message {
            h3 {
              font-size: 13px;
              margin-bottom: 4px;
            }
            span {
              font-size: 12px;
              line-height: 16px;
            }
          }

          small {
            font-size: 10px;
          }
        }

        &.sender {
          .chat-sender-content {
            max-width: calc(50% - 50px);
            padding: 5px 10px 8px 10px;

            span {
              font-size: 13px;
              margin-bottom: 3px;
            }

            small {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .reverse-scroll-wrapper {
    max-height: calc(100vh - 280px);
    min-height: calc(100vh - 280px);
  }
}

@include responsive(md) {
  .chat {
    padding: 10px 0 40px;
  }

  .breadcrumbsMessage {
    margin-bottom: 30px;
  }

  .chat-header {
    margin-bottom: 30px;
    h3 {
      font-size: 22px;
    }
  }

  .messageHeader {
    padding: 18px;

    .form-group {
      width: 360px;
      padding-right: 20px;

      input {
        width: 315px;
        padding: 8px 0 8px 16px;
        font-size: 13px;
      }
      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }
    }
  }

  .chat-list-wrap {
    width: 100%;
    border: none;
    &.inactive {
      display: none;
    }
  }

  .chat-listing {
    height: 525px;

    li {
      .chat-user {
        justify-content: space-between;
        align-items: center;
        margin: 10px;
        padding: 7px 10px 12px;

        figure {
          width: 45px;
          height: 45px;
          margin-right: 10px;
        }

        .chat-nm-desc {
          width: 600px;
          margin-right: 12px;
          strong {
            font-size: 14px;
            line-height: 20px;
          }
          p {
            font-size: 12px;
            line-height: 17px;
          }
        }

        .chat-time {
          span {
            font-size: 10px;
            padding-bottom: 3px;
          }
        }
      }
    }
  }

  .scroll-wrapper {
    max-height: calc(100vh - 0px);
  }

  .chat-placeholder {
    width: 100%;
    display: none;
  }

  .chat-body-wrap {
    width: 100%;
    display: none;

    &.active {
      display: block;
    }

    .input-box {
      font-size: 13px;
      line-height: 28px;
      padding: 10px 0 10px 16px;
    }
    ::-webkit-input-placeholder {
      font-size: 13px;
    }

    :-ms-input-placeholder {
      font-size: 13px;
    }

    ::placeholder {
      font-size: 13px;
    }
  }

  .chat-footer {
    .footerInputGrp {
      padding-right: 24px;
      margin: 0 18px 42px;
    }
  }

  .chat-body-header {
    padding: 16px 20px 18px;
    .active-chat-user {
      figure {
        height: 46px;
        width: 46px;
        margin-right: 10px;
      }

      .chat-nm-desc {
        strong {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .chat-body-main {
    .chat-body {
      padding: 18px;

      .chat-msg {
        margin-bottom: 12px;

        figure {
          height: 30px;
          width: 30px;
          margin-right: 12px;
        }

        .chat-receiver-content {
          max-width: calc(50% - 15px);
          padding: 4px 8px;

          .chat-message {
            h3 {
              font-size: 13px;
              margin-bottom: 4px;
            }
            span {
              font-size: 12px;
              line-height: 16px;
            }
          }

          small {
            font-size: 10px;
          }
        }

        &.sender {
          .chat-sender-content {
            max-width: calc(50% - 50px);
            padding: 5px 10px 8px 10px;

            span {
              font-size: 13px;
              margin-bottom: 3px;
            }

            small {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .reverse-scroll-wrapper {
    max-height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
  }

  .chat-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $light-green;

    svg {
      transform: rotate(90deg);
    }
  }

  .chat-wrap {
    .chat-body-wrap {
      width: 100%;
      display: none;

      &.active {
        display: block;
      }

      .chat-msg {
        .chat-content {
          width: calc(100% - 57px);
        }
      }
    }
  }
}

@include responsive(sm) {
  .chat {
    padding: 10px 0 25px;
  }

  .breadcrumbsMessage {
    margin-bottom: 20px;
  }

  .chat-header {
    margin-bottom: 20px;
    h3 {
      font-size: 20px;
    }
  }

  .messageHeader {
    padding: 16px;

    .form-group {
      width: 320px;
      padding-right: 20px;

      input {
        width: 278px;
        padding: 8px 0 8px 16px;
        font-size: 13px;
      }
      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }
    }
  }

  .chat-listing {
    height: 525px;

    li {
      .chat-user {
        margin: 10px;
        padding: 7px 10px 12px;

        figure {
          width: 45px;
          height: 45px;
          margin-right: 10px;
        }

        .chat-nm-desc {
          width: 390px;
          margin-right: 12px;
          strong {
            font-size: 14px;
            line-height: 20px;
          }
          p {
            font-size: 12px;
            line-height: 17px;
          }
        }

        .chat-time {
          span {
            font-size: 10px;
            padding-bottom: 3px;
          }
        }
      }
    }
  }

  .scroll-wrapper {
    max-height: calc(100vh - 0px);
  }

  .chat-placeholder {
    width: 100%;
    display: none;
  }

  .chat-body-wrap {
    width: 100%;
    display: none;

    &.active {
      display: block;
    }

    .input-box {
      font-size: 13px;
      line-height: 28px;
      padding: 10px 0 10px 16px;
    }
    ::-webkit-input-placeholder {
      font-size: 13px;
    }

    :-ms-input-placeholder {
      font-size: 13px;
    }

    ::placeholder {
      font-size: 13px;
    }
  }

  .chat-footer {
    .footerInputGrp {
      padding-right: 24px;
      margin: 0 18px 42px;
    }
  }

  .chat-body-header {
    padding: 15px 16px 16px;
    .active-chat-user {
      figure {
        height: 46px;
        width: 46px;
        margin-right: 10px;
      }

      .chat-nm-desc {
        strong {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .chat-body-main {
    .chat-body {
      padding: 16px;

      .chat-msg {
        margin-bottom: 12px;

        figure {
          height: 30px;
          width: 30px;
          margin-right: 12px;
        }

        .chat-receiver-content {
          max-width: calc(50% - 15px);
          padding: 4px 8px;

          .chat-message {
            h3 {
              font-size: 13px;
              margin-bottom: 4px;
            }
            span {
              font-size: 12px;
              line-height: 16px;
            }
          }

          small {
            font-size: 10px;
          }
        }

        &.sender {
          .chat-sender-content {
            max-width: calc(50% - 5px);
            padding: 5px 10px 8px 10px;

            span {
              font-size: 13px;
              margin-bottom: 3px;
            }

            small {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .reverse-scroll-wrapper {
    max-height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
  }

  .chat-wrap {
    .chat-body-wrap {
      width: 100%;
      display: none;

      &.active {
        display: block;
      }

      .chat-msg {
        .chat-content {
          width: calc(100% - 57px);
        }
      }
    }
  }
}

@include responsive(xs) {
  .chat {
    padding: 10px 0 15px;
  }

  .breadcrumbsMessage {
    margin-bottom: 15px;
  }

  .chat-header {
    margin-bottom: 15px;
    h3 {
      font-size: 18px;
    }
  }

  .messageHeader {
    padding: 15px;

    .form-group {
      width: 258px;
      padding-right: 18px;

      input {
        width: 216px;
        padding: 8px 0 8px 15px;
        font-size: 12px;
      }
      ::-webkit-input-placeholder {
        font-size: 12px;
      }

      :-ms-input-placeholder {
        font-size: 12px;
      }

      ::placeholder {
        font-size: 12px;
      }
    }
  }

  .chat-listing {
    height: 450px;

    li {
      .chat-user {
        margin: 8px;
        padding: 7px 8px 10px;

        figure {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }

        .chat-nm-desc {
          width: 160px;
          margin-right: 10px;
          strong {
            font-size: 13px;
            line-height: 18px;
          }
          p {
            font-size: 12px;
            line-height: 17px;
          }
        }

        .chat-time {
          span {
            font-size: 10px;
            padding-bottom: 3px;
          }
        }
      }
    }
  }

  .scroll-wrapper {
    max-height: calc(100vh - 0px);
  }

  .chat-placeholder {
    width: 100%;
    display: none;
  }

  .chat-body-wrap {
    .input-box {
      font-size: 13px;
      line-height: 28px;
      padding: 10px 0 10px 14px;
    }
    ::-webkit-input-placeholder {
      font-size: 12px;
    }

    :-ms-input-placeholder {
      font-size: 12px;
    }

    ::placeholder {
      font-size: 12px;
    }
  }

  .chat-footer {
    .footerInputGrp {
      padding-right: 10px;
      margin: 0 15px 20px;
    }
  }

  .chat-body-header {
    padding: 15px;
    .active-chat-user {
      figure {
        height: 40px;
        width: 40px;
        margin-right: 10px;
      }

      .chat-nm-desc {
        strong {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }

  .chat-body-main {
    .chat-body {
      padding: 15px;

      .chat-msg {
        margin-bottom: 12px;

        figure {
          height: 30px;
          width: 30px;
          margin-right: 12px;
        }

        .chat-receiver-content {
          max-width: calc(60% - 0px);
          padding: 4px 8px;

          .chat-message {
            h3 {
              font-size: 13px;
              margin-bottom: 4px;
            }
            span {
              font-size: 12px;
              line-height: 16px;
            }
          }

          small {
            font-size: 10px;
          }
        }

        &.sender {
          .chat-sender-content {
            max-width: calc(60% - 0px);
            padding: 5px 10px 8px 10px;

            span {
              font-size: 13px;
              margin-bottom: 3px;
            }

            small {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .reverse-scroll-wrapper {
    max-height: calc(100vh - 350px);
    min-height: calc(100vh - 350px);
  }

  .chat-wrap {
    .chat-body-wrap {
      width: 100%;
      display: none;

      &.active {
        display: block;
      }

      .chat-msg {
        .chat-content {
          width: calc(100% - 57px);
        }
      }
    }
  }
}
