@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";
@import "../../../../../scss/common";
@import "../../../../../scss/reset";
@import "../../../../../scss/flex";

.videoMedia {
  .rowVideo {
    display: flex;
    flex-wrap: wrap;

    .VideoColumn {
      width: 280px;
      height: 280px;
      position: relative;
      margin-right: 70px;
      cursor: pointer;
      overflow: hidden;
      @include prefix(transition, all 0.3s ease-in-out);
      &:nth-of-type(even) {
        margin-right: 0;
      }

      .videoThumbnail {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 4;
        overflow: hidden;
        @include prefix(transition, all 0.3s ease-in-out);
        img {
          width: 100%;
          height: 100%;
        }
      }
      &:hover .videoBgColor {
        @include prefix(transition, all 0.3s ease-in-out);
        top: 0;
        z-index: 9;
      }
      .play {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: none;
        cursor: pointer;
        z-index: 10;
      }
    }
  }
}

.videoModal {
  width: 630px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: $white;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .modal-body {
    width: 100%;
    height: 364px !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

    .video-react-video {
      width: 100%;
      // height: 364px !important;
    }
    .video-react.video-react-fluid {
      padding-top: 0px !important;
      height: 364px !important;
    }
  }

  .modal-footer {
    display: none !important;
  }
}

.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

// responsive
@include responsive(sm) {
  .videoModal {
    width: 550px;
  }
}

@include responsive(xs) {
  .videoModal {
    width: 290px;

    .modal-header{
      button{
        padding: 5px;
      }
    }
  }
}
