@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.faq {
  width: 100%;
  margin-top: 85px;
  padding: 13px 0 71px;
}

.breadcrumbsFaq {
  margin-bottom: 45px;
}
.newline {
  white-space: pre-line !important;
}
.pageTitle {
  margin-bottom: 27px;
}

.accordion {
  border: none !important;
  .accordionItem {
    margin-bottom: 30px;
    background-color: transparent !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .accordionWrapItem {
    margin-bottom: 30px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .accordionButton {
    background-color: transparent;
    padding: 20px 30px;
    position: relative;
    @include prefix(transition, all 0.3s ease-in-out);
    &:hover {
      background-color: rgba(109, 175, 95, 0.4);
    }
    &::before {
      position: absolute;
      right: 30px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      transform: rotate(225deg);
    }
  }
  .openAccordionButton {
    background-color: transparent;
    padding: 20px 30px;
    position: relative;
    @include prefix(transition, all 0.3s ease-in-out);
    &:hover {
      background-color: rgba(109, 175, 95, 0.4);
    }
    &::before {
      position: absolute;
      right: 30px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      transform: rotate(45deg) !important;
    }
  }
  .accordionPanel {
    background-color: $light-green;
    padding: 0 80px 30px 30px;
  }
  .accordionQuestion {
    background-color: $light-green;
    position: relative;
    @include prefix(transition, all 0.3s ease-in-out);
    &:hover {
      background-color: rgba(109, 175, 95, 0.4);
    }
    &::before {
      position: absolute;
      right: 30px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }
  span {
    font-family: $font_jost;
    font-weight: 700;
    font-size: 16px;
    color: $black_1;
  }
  p {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 16px;
    color: $light-gray;
    line-height: 30px;
    letter-spacing: 0.04em;
  }
}

// responsive
@include responsive(xl) {
  .faq {
    margin-top: 80px;
    padding: 12px 0 68px;
  }

  .breadcrumbsFaq {
    margin-bottom: 42px;
  }

  .pageTitle {
    margin-bottom: 25px;
  }

  .accordion {
    .accordionItem {
      margin-bottom: 28px;
    }
    .accordionWrapItem {
      margin-bottom: 28px;
    }
    .accordionButton {
      padding: 18px 28px;
      &::before {
        right: 28px;
      }
    }
    .openAccordionButton {
      padding: 18px 28px;
      &::before {
        right: 28px;
      }
    }
    .accordionPanel {
      padding: 0 76px 28px 28px;
    }
    .accordionQuestion {
      &::before {
        right: 28px;
      }
    }
    span {
      font-size: 15px;
    }
    p {
      font-size: 15px;
      line-height: 28px;
    }
  }
}

@include responsive(lg) {
  .faq {
    padding: 12px 0 64px;
  }

  .breadcrumbsFaq {
    margin-bottom: 40px;
  }

  .pageTitle {
    margin-bottom: 23px;
  }

  .accordion {
    .accordionItem {
      margin-bottom: 26px;
    }
    .accordionWrapItem {
      margin-bottom: 26px;
    }
    .accordionButton {
      padding: 16px 26px;
      &::before {
        right: 26px;
      }
    }
    .openAccordionButton {
      padding: 16px 26px;
      &::before {
        right: 26px;
      }
    }
    .accordionPanel {
      padding: 0 74px 26px 26px;
    }
    .accordionQuestion {
      &::before {
        right: 26px;
      }
    }
    span {
      font-size: 14px;
    }
    p {
      font-size: 14px;
      line-height: 26px;
    }
  }
}

@include responsive(md) {
  .faq {
    padding: 10px 0 40px;
  }

  .breadcrumbsFaq {
    margin-bottom: 30px;
  }

  .pageTitle {
    margin-bottom: 20px;
  }

  .accordion {
    .accordionItem {
      margin-bottom: 24px;
    }
    .accordionWrapItem {
      margin-bottom: 24px;
    }
    .accordionButton {
      padding: 15px 20px;
      &::before {
        right: 20px;
      }
    }
    .openAccordionButton {
      padding: 15px 20px;
      &::before {
        right: 20px;
      }
    }
    .accordionPanel {
      padding: 0 50px 24px 24px;
    }
    .accordionQuestion {
      &::before {
        right: 24px;
      }
    }
    span {
      font-size: 13px;
    }
    p {
      font-size: 13px;
      line-height: 20px;
    }
    .accordion__button::before {
      width: 9px;
      height: 9px;
    }
  }
}

@include responsive(sm) {
  .faq {
    padding: 10px 0 30px;
  }

  .breadcrumbsFaq {
    margin-bottom: 20px;
  }

  .pageTitle {
    margin-bottom: 15px;
  }

  .accordion {
    .accordionItem {
      margin-bottom: 20px;
    }
    .accordionWrapItem {
      margin-bottom: 20px;
    }
    .accordionButton {
      padding: 15px 18px;
      &::before {
        right: 18px;
      }
    }
    .openAccordionButton {
      padding: 15px 18px;
      &::before {
        right: 18px;
      }
    }
    .accordionPanel {
      padding: 0 40px 20px 20px;
    }
    .accordionQuestion {
      &::before {
        right: 20px;
      }
    }
    span {
      font-size: 13px;
    }
    p {
      font-size: 13px;
      line-height: 18px;
    }
    .accordion__button::before {
      width: 8px;
      height: 8px;
    }
  }
}

@include responsive(xs) {
  .faq {
    padding: 10px 0 15px;
  }

  .breadcrumbsFaq {
    margin-bottom: 15px;
  }

  .pageTitle {
    margin-bottom: 15px;
  }

  .accordion {
    .accordionItem {
      margin-bottom: 15px;
    }
    .accordionWrapItem {
      margin-bottom: 15px;
    }
    .accordionButton {
      padding: 15px;
      &::before {
        right: 15px;
      }
    }
    .openAccordionButton {
      padding: 15px;
      &::before {
        right: 15px;
      }
    }
    .accordionPanel {
      padding: 0 15px 15px 15px;
    }
    .accordionQuestion {
      &::before {
        right: 15px;
      }
    }
    span {
      display: inline-block;
      width: 145px;
      font-size: 12px;
    }
    p {
      font-size: 12px;
      line-height: 17px;
    }
    .accordion__button::before {
      width: 7px;
      height: 7px;
    }
  }
}
