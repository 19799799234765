@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.userAccountCount {
  width: 330px;
  height: 75px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  margin-left: 52px;
  background-color: $light-green;

  .attendingPostCount {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 175px;

    &:nth-of-type(2) {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 1px;
        height: 56px;
        background-color: rgba($color: $green-shadow, $alpha: 0.5);
      }
    }
  }
  span {
    font-family: $font_jost;
    font-weight: 700;
    font-size: 20px;
    color: $green-shadow;
    margin-bottom: 4px;
  }
  h4 {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 12px;
    color: $gray;
  }
}

// responsive
@include responsive(xl) {
  .userAccountCount {
    width: 320px;
    height: 75px;
    margin-left: 30px;

    .attendingPostCount {
      width: 160px;

      &:nth-of-type(2) {
        &::before {
          height: 50px;
        }
      }
    }
    span {
      font-size: 18px;
      margin-bottom: 4px;
    }
    h4 {
      font-size: 12px;
    }
  }
}

@include responsive(lg) {
  .userAccountCount {
    width: 250px;
    height: 70px;
    margin-left: 26px;

    .attendingPostCount {
      width: 125px;

      &:nth-of-type(2) {
        &::before {
          height: 45px;
        }
      }
    }
    span {
      font-size: 16px;
      margin-bottom: 4px;
    }
    h4 {
      font-size: 11px;
    }
  }
}

@include responsive(md) {
  .userAccountCount {
    width: 450px;
    height: 75px;

    .attendingPostCount {
      width: 225px;

      &:nth-of-type(2) {
        &::before {
          height: 45px;
        }
      }
    }
    span {
      font-size: 16px;
      margin-bottom: 4px;
    }
    h4 {
      font-size: 12px;
    }
  }
}

@include responsive(sm) {
  .userAccountCount {
    width: 300px;
    height: 70px;

    .attendingPostCount {
      width: 150px;

      &:nth-of-type(2) {
        &::before {
          height: 42px;
        }
      }
    }
    span {
      font-size: 15px;
      margin-bottom: 3px;
    }
    h4 {
      font-size: 12px;
    }
  }
}

@include responsive(xs) {
  .userAccountCount {
    width: 100%;
    height: 60px;
    margin-left: 0;

    .attendingPostCount {
      width: 50%;

      &:nth-of-type(2) {
        &::before {
          height: 40px;
        }
      }
    }
    span {
      font-size: 14px;
      margin-bottom: 0px;
    }
    h4 {
      font-size: 11px;
    }
  }
}
