@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.rosiBasket {
  width: 100%;
  margin-top: 85px;
  padding: 13px 0 71px;
}

.breadcrumbsBasket {
  margin-bottom: 45px;
}

.rosiBasketWrap {
  display: flex;
  justify-content: space-between;

  .basketTitle {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 30px;
    color: $black_1;
    margin-bottom: 42px;
  }

  .summary-button {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    a {
      display: inline-block;
      width: 320px;
      text-align: center;
      background-color: $green-shadow;
      border-radius: 50px;
      padding: 12px 20px;
      font-family: Jost, sans-serif;
      font-weight: 400;
      color: $white;
      font-size: 18px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $theme-color;
      }
    }
  }

  .basketSidebarWrap {
    width: 514px;
    text-align: center;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    padding: 40px 30px 30px 30px;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      text-align: center;
      text-transform: capitalize;
    }

    .basket-radio {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
      margin-top: 30px;

      li:first-of-type {
        margin-right: 30px;
      }
    }

    .forgot {
      display: flex;
      justify-content: space-between;

      .remember {
        display: flex;
        align-items: center;

        .check {
          width: 16px;
          height: 16px;
          background-color: white;
          border-radius: 50%;
          vertical-align: middle;
          border: 1px solid $theme-color;
          appearance: none;
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
        }

        .check:checked {
          border: none;
        }

        label {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          color: $light-gray;
          letter-spacing: 0.04em;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      .pass {
        a {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          color: $light-gray;
          letter-spacing: 0.04em;
        }
      }
    }

    .paymentProcess {
      .rosiBasketCash {
        .cashButtonGroup {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0 30px;

          button {
            width: 73px;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 16px;
            padding: 9px 13px;
            color: $black_1;
            border-radius: 3px;
            cursor: pointer;
            background-color: $white_1;
            @include prefix(transition, all 0.3s ease-in-out);
          }

          .selected {
            background-color: $green-shadow;
            color: $white;

            &:hover {
              background-color: $theme-color;
            }
          }

          .cashButton:hover {
            background-color: $white-dark;
          }
        }
      }

      .rosiInputOne {
        .manualAmount {
          margin-bottom: 10px;

          label {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 16px;
            color: $black_1;
            // margin-bottom: 10px;
          }

          .forms {
            margin: 10px 0;
          }

          .form-group {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: start;
            border: 1px solid $light-gray;
            border-radius: 7px;
            padding-left: 14px;

            h5 {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $light-gray;
              margin-right: 10px;
            }

            input {
              padding: 9px 14px 8px 0;
              border-radius: 7px;
              width: 97%;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input[type="number"] {
              -moz-appearance: textfield;
            }
          }

          .inputNote {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 12px;
            color: $black_1;
            text-transform: capitalize;
          }
        }
      }

      .paymentMethod {
        text-align: center;
        margin-top: 54px;
      }

      .paymentMethodwrap {
        display: flex;
        width: 84%;
        margin: 5px 25px 14px;
        flex-wrap: wrap;

        .visaPayment {
          width: 180px;
          display: flex;
          align-items: flex-start;
          background-color: $light-green;
          border-radius: 3px;
          margin-top: 15px;
          padding: 10px;
          cursor: pointer;
          margin-right: 20px;
          @include prefix(transition, all 0.3s ease-in-out);

          &:hover {
            background-color: rgba($color: $theme-color, $alpha: 0.4);
          }

          &:nth-of-type(2n + 0) {
            margin-right: 0px;
          }

          figure {
            width: 25px;
            height: 25px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .paymentActive {
          background-color: rgba($color: $theme-color, $alpha: 0.7);
        }

        ul {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-left: 10px;

          li {
            display: flex;
            flex-wrap: wrap;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 10px;
            color: $black_1;
            letter-spacing: 0.04em;
          }

          .credit {
            font-size: 8px;
            letter-spacing: 0.04em;
            color: $green-shadow;
          }
        }
      }

      .add {
        width: 73px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        color: $white;
        background-color: $green-shadow;
        border-radius: 3px;
        padding: 9px 19px 9px 20px;
        margin-bottom: 20px;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $theme-color;
        }
      }
    }

    .rosiInputStepTwo {
      .manualAmount {
        text-align: left;
        margin-bottom: 32px;

        label {
          display: inline-block;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 10px;
        }

        .form-group {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          border: 1px solid $light-gray;
          border-radius: 7px;
          margin-bottom: 10px;

          input {
            width: 426px;
            padding: 9px 0 8px 0;
            background-color: transparent;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            line-height: 25px;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }

          span {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            line-height: 25px;
            color: $light-gray;
            margin-right: 10px;
          }
        }

        .inputNote {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          line-height: 25px;
          color: $black_1;
          text-transform: capitalize;
        }
      }

      .secondMethod {
        h3 {
          margin-bottom: 28px;
        }

        ul {
          li {
            width: 100%;
            margin-bottom: 16px;

            &:last-of-type {
              margin-bottom: 30px;
            }
          }

          .listInput {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }

          .basketFields {
            width: 49%;
            flex-shrink: 0;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }

            input {
              width: 96%;
              padding: 9px 0 8px 14px;
              background-color: transparent;
              font-size: 14px;
              font-weight: 400;
              font-family: $font_jost;
              color: $black;
              line-height: 25px;
            }

            ::-webkit-input-placeholder {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $black;
            }

            :-ms-input-placeholder {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $black;
            }

            ::placeholder {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $black;
            }

            .dropdownGrp {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $light-gray;
              text-align: left;
            }
            .css-1pahdxg-control {
              border-color: transparent;
              box-shadow: none;
              border-width: 0;
            }
            .css-1s2u09g-control {
              background-color: transparent;
              border-width: 0px;
            }
            .form-grp {
              border: 1px solid $black_1;
              border-radius: 7px;
            }
          }

          .toUpperCase {
            input {
              text-transform: uppercase;
            }

            ::-webkit-input-placeholder {
              text-transform: none;
            }
            :-moz-placeholder {
              text-transform: none;
            }
            ::-moz-placeholder {
              text-transform: none;
            }
            :-ms-input-placeholder {
              text-transform: none;
            }
            ::placeholder {
              text-transform: none;
            }
          }

          .basketGrp {
            width: 100%;
            border: 1px solid $light-gray;
            border-radius: 7px;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            // padding-right: 12px;

            svg {
              margin-right: 12px;
            }

            &:focus-within {
              border: 1px solid $theme-color;

              svg path,
              circle {
                stroke: $theme-color;
              }
            }

            input {
              width: 96%;
              padding: 9px 0 8px 14px;
              background-color: transparent;
              font-size: 14px;
              font-weight: 400;
              font-family: $font_jost;
              color: $black;
              line-height: 25px;
            }

            ::-webkit-input-placeholder {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $black;
            }

            :-ms-input-placeholder {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $black;
            }

            ::placeholder {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $black;
            }

            .css-b62m3t-container {
              width: 100%;
              text-align: left;
            }

            .css-1s2u09g-control {
              border: none;
              border-radius: 7px;
              box-shadow: transparent;
              font-family: $font_jost;
              font-weight: 400;
              color: $light-gray;
              font-size: 14px;
            }

            .css-1pahdxg-control {
              border-color: transparent;
              box-shadow: none;
              border-width: 0;
              background-color: transparent;
              font-family: $font_jost;
              font-weight: 400;
              color: $light-gray;
              font-size: 14px;
            }

            .select__menu {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $light-gray;
            }

            .select--is-disabled.css-3iigni-container {
              width: 100%;
              text-align: left;
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $light-gray;
            }

            .css-1insrsq-control {
              border-radius: 7px;
              border: none;
            }
          }

          .forms {
            .form-group {
              border: 1px solid $light-gray;
              border-radius: 7px;
              display: flex;
              align-items: center;
              padding-right: 12px;
            }

            .form-group:focus-within {
              border: 1px solid $theme-color;

              svg path,
              circle {
                stroke: $theme-color;
              }
            }
          }
        }

        .paymentGrp {
          input {
            width: 96%;
            padding: 9px 0 8px 14px;
            background-color: transparent;
            font-size: 14px;
            font-weight: 400;
            font-family: $font_jost;
            color: $black;
            line-height: 25px;
          }

          ::-webkit-input-placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $black;
          }

          :-ms-input-placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $black;
          }

          ::placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $black;
          }
        }
      }
    }

    .donateAmount {
      min-width: 280px;
      background-color: $green-shadow;
      border-radius: 50px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      color: $white;
      margin: 0 auto;
      padding: 16px 67px 19px 66px;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: $theme-color;
      }
    }
  }

  .address-terms {
    margin-bottom: 30px;
    // margin-left: 25px;

    .rememberReg {
      justify-content: start;
      // text-transform: capitalize;

      a {
        color: $green-shadow;
      }

      h5 {
        text-align: left;
      }
    }
  }

  .basketContentWrap {
    width: 760px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

    .basketContentHeader {
      display: flex;
      justify-content: space-between;
      background-color: $white;
      padding: 20px 24px;
      border-bottom: 1px solid #b3b3b3;

      h3 {
        width: 50%;
        font-family: $font_jost;
        font-weight: 700;
        font-size: 16px;
        color: $black_1;
      }
    }

    .descPadding {
      padding: 15px 22px;
    }

    .basketContentBody {
      .basketScroll {
        max-height: 740px;
      }

      .basketWrapCard {
        display: flex;
        align-items: center;
        padding: 20px 24px;

        .basketSummary {
          width: 50%;
          display: flex;
          align-items: flex-start;
          margin-right: 44px;

          figure {
            width: 138px;
            height: 65px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
              color: transparent;
              object-fit: cover;
            }
          }

          .naming {
            h5 {
              font-family: $font_jost;
              font-weight: 700;
              font-size: 12px;
              color: $green-shadow;
            }

            .basketCharityName {
              width: 194px;
              font-family: $font_jost;
              font-weight: 700;
              font-size: 14px;
              line-height: 25px;
              color: $black_1;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }

        .basketPercent {
          width: 50%;
          display: flex;
          justify-content: start;
          align-items: center;

          .basketPercentWrap {
            width: 160px;
            margin-right: 44px;

            .isLock {
              display: flex;
              align-items: center;
              margin-top: 12px;
              cursor: pointer;

              h6 {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 12px;
                color: $black_1;
                margin-left: 5px;
                text-transform: capitalize;
              }
            }
          }

          .percentButton {
            width: 53px;
            padding: 4px 10px;
            background-color: $green-shadow;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 16px;
            color: $white;
            border-radius: 3px;
            margin-right: 44px;
            cursor: pointer;
            @include prefix(transition, all 0.3s ease-in-out);

            &:hover {
              background-color: $theme-color;
            }
          }

          .percentCancel {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $red;
            cursor: pointer;
            padding: 6px;

            svg path {
              stroke: $white;
            }

            @include prefix(transition, all 0.3s ease-in-out);

            &:hover {
              background-color: #ce5139;
            }
          }
        }
      }

      .basketLockCard {
        background-color: $light-green;
      }
    }

    .basketContentFooter {
      padding: 20px 24px;

      .summaryCheckBox {
        .rememberReg {
          justify-content: flex-start;
          align-items: center;
        }

        h4 {
          font-weight: 700;
          font-size: 14px;
        }
      }

      p {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        color: $black_1;
        // text-transform: capitalize;
        letter-spacing: 0.04em;
        margin: 10px 0 0 26px;
      }
    }
  }
  .summary-error-box {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    padding: 20px 24px;
    text-align: center;
    h4 {
      margin-bottom: 15px;
      text-transform: none;
      &:last-of-type {
        margin-bottom: 0;
      }
      a {
        color: $green-shadow;
        @include prefix(transition, all 0.3s ease-in-out);
        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  .basketHelp {
    width: 760px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    background-color: $light-green;
    padding: 20px 24px;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 12px;
      color: $green-shadow;
      margin-bottom: 5px;
      text-transform: capitalize;
      letter-spacing: 0.04em;
    }

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 12px;
      color: $black_1;
      text-transform: capitalize;
      letter-spacing: 0.04em;
    }

    .baketHelpButton {
      min-width: 177px;
      background-color: $black_1;
      border-radius: 20px;
      padding: 7px 20px 8px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);

      a {
        color: $white;
      }

      &:hover {
        background-color: $black;
      }
    }
  }
}

.donationModal {
  width: 510px;
  height: 390px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 30px 44px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    button {
      background-color: $white;
      cursor: pointer;
    }
  }

  .modal-body {
    text-align: center;

    .donationRight {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 134px;
      height: 134px;
      margin: 0 auto;
      border-radius: 50%;
      background-color: $green-shadow;
    }

    h2 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 24px;
      color: $black_1;
      text-transform: capitalize;
      margin-top: 30px;
    }

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      text-transform: capitalize;
      margin-bottom: 22px;
      line-height: 25px;
      letter-spacing: 0.04em;
    }

    .donationButtonGrpModal {
      display: flex;
      align-items: center;
      justify-content: center;

      .againButton {
        width: 175px;
        background-color: $green-shadow;
        border-radius: 20px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        padding: 7px 23px 8px 22px;
        margin-right: 16px;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);
      }

      .doneButton {
        width: 100px;
        background-color: $white_1;
        border-radius: 20px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
        padding: 8px 26px 7px 26px;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $white-dark;
        }
      }
    }
  }

  .modal-footer {
    display: none;
  }
}

// responsive
@include responsive(xl) {
  .rosiBasket {
    margin-top: 80px;
    padding: 12px 0 60px;
  }

  .breadcrumbsBasket {
    margin-bottom: 40px;
  }

  .rosiBasketWrap {
    .basketTitle {
      font-size: 28px;
      margin-bottom: 38px;
    }

    .summary-button {
      margin-bottom: 28px;
      a {
        padding: 12px 18px;
      }
    }

    .basketSidebarWrap {
      width: 500px;
      padding: 38px 28px 28px 28px;

      h3 {
        font-size: 15px;
      }

      .basket-radio {
        padding-bottom: 28px;
        margin-top: 28px;

        li:first-of-type {
          margin-right: 28px;
        }
      }

      .forgot {
        .remember {
          .check {
            width: 15px;
            height: 15px;
          }
        }
      }

      .paymentProcess {
        .rosiBasketCash {
          .cashButtonGroup {
            margin: 8px 0 28px;

            button {
              width: 68px;
              font-size: 15px;
              padding: 8px 12px;
            }
          }
        }

        .paymentMethod {
          margin-top: 45px;
        }

        .paymentMethodwrap {
          width: 83%;
          margin: 4px 22px 13px;

          .visaPayment {
            width: 175px;
            margin-top: 14px;
            padding: 9px;
            margin-right: 18px;

            figure {
              width: 24px;
              height: 24px;
            }
          }
        }

        .add {
          width: 70px;
          font-size: 15px;
          padding: 9px 16px 9px 18px;
          margin-bottom: 18px;
        }
      }

      .rosiInputStepTwo {
        .manualAmount {
          margin-bottom: 28px;

          label {
            font-size: 15px;
            line-height: 23px;
            margin-bottom: 9px;
          }

          .form-group {
            margin-bottom: 9px;

            input {
              width: 410px;
              padding: 8px 0;
              font-size: 14px;
              line-height: 22px;
            }

            span {
              font-size: 14px;
              line-height: 22px;
              margin-right: 9px;
            }
          }

          .inputNote {
            line-height: 22px;
          }
        }

        .secondMethod {
          h3 {
            margin-bottom: 26px;
          }

          ul {
            li {
              margin-bottom: 15px;

              &:last-of-type {
                margin-bottom: 28px;
              }
            }

            .basketFields {
              width: 48%;

              input {
                width: 94%;
                padding: 8px 0 8px 12px;
                font-size: 14px;
                line-height: 22px;
              }

              ::-webkit-input-placeholder {
                font-size: 14px;
              }

              :-ms-input-placeholder {
                font-size: 14px;
              }

              ::placeholder {
                font-size: 14px;
              }
            }

            .basketGrp {
              svg {
                margin-right: 10px;
              }

              input {
                width: 94%;
                padding: 8px 0 8px 12px;
                font-size: 14px;
                line-height: 22px;
              }

              ::-webkit-input-placeholder {
                font-size: 14px;
              }

              :-ms-input-placeholder {
                font-size: 14px;
              }

              ::placeholder {
                font-size: 14px;
              }

              .css-1s2u09g-control {
                font-size: 14px;
              }

              .css-1pahdxg-control {
                font-size: 14px;
              }

              .select__menu {
                font-size: 14px;
              }

              .select--is-disabled.css-3iigni-container {
                font-size: 14px;
              }
            }

            .forms {
              .form-group {
                padding-right: 10px;
              }
            }
          }

          .paymentGrp {
            input {
              width: 96%;
              padding: 8px 0 8px 12px;
              font-size: 14px;
              line-height: 22px;
            }

            ::-webkit-input-placeholder {
              font-size: 14px;
            }

            :-ms-input-placeholder {
              font-size: 14px;
            }

            ::placeholder {
              font-size: 14px;
            }
          }
        }
      }

      .donateAmount {
        min-width: 240px;
        font-size: 15px;
        padding: 16px 50px;
      }
    }

    .address-terms {
      margin-bottom: 28px;
      margin-left: 22px;
    }

    .basketContentWrap {
      width: 600px;

      .basketContentHeader {
        padding: 18px 22px;

        h3 {
          font-size: 15px;
        }
      }

      .descPadding {
        padding: 14px 22px;
      }

      .basketContentBody {
        .basketScroll {
          max-height: 700px;
        }

        .basketWrapCard {
          padding: 18px 22px;

          .basketSummary {
            width: 50%;
            margin-right: 0px;

            figure {
              width: 120px;
              height: 60px;
              margin-right: 9px;
            }

            .naming {
              h5 {
                font-size: 12px;
              }

              .basketCharityName {
                width: 135px;
                font-size: 13px;
                line-height: 22px;
              }
            }
          }

          .basketPercent {
            width: 50%;

            .basketPercentWrap {
              width: 148px;
              margin-right: 30px;

              .isLock {
                margin-top: 12px;

                h6 {
                  font-size: 12px;
                  margin-left: 5px;
                }
              }
            }

            .percentButton {
              width: 50px;
              padding: 4px 8px;
              font-size: 14px;
              margin-right: 30px;
            }

            .percentCancel {
              width: 19px;
              height: 19px;
              padding: 5px;
            }
          }
        }
      }

      .basketContentFooter {
        padding: 18px 22px;

        .summaryCheckBox {
          h4 {
            font-size: 13px;
          }
        }

        p {
          font-size: 12px;
          margin: 9px 0 0 24px;
          line-height: 20px;
        }
      }
    }

    .summary-error-box {
      padding: 20px;
      h4 {
        margin-bottom: 12px;
      }
    }

    .basketHelp {
      width: 600px;
      margin-top: 20px;
      padding: 18px 22px;

      h3 {
        font-size: 12px;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
      }

      .baketHelpButton {
        min-width: 160px;
        padding: 7px 18px;
        font-size: 14px;
      }
    }
  }

  .donationModal {
    width: 480px;
    height: auto;
    padding: 28px 40px;

    .modal-header {
      margin-bottom: 6px;
    }

    .modal-body {
      .donationRight {
        width: 125px;
        height: 125px;
      }

      h2 {
        margin-bottom: 10px;
        font-size: 22px;
        margin-top: 28px;
      }
      p {
        font-size: 14px;
        margin-bottom: 20px;
        line-height: 22px;
      }

      .donationButtonGrpModal {
        .againButton {
          width: 170px;
          font-size: 14px;
          padding: 6px 22px 8px 22px;
          margin-right: 15px;
        }

        .doneButton {
          width: 95px;
          font-size: 14px;
          padding: 7px 24px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .rosiBasket {
    padding: 12px 0 50px;
  }

  .breadcrumbsBasket {
    margin-bottom: 30px;
  }

  .rosiBasketWrap {
    width: 960px;
    .basketTitle {
      font-size: 24px;
      margin-bottom: 30px;
    }

    .summary-button {
      margin-bottom: 26px;
      a {
        width: 300px;
        padding: 12px 17px;
        font-size: 16px;
      }
    }

    .basketSidebarWrap {
      width: 410px;
      padding: 34px 24px 24px 24px;

      h3 {
        font-size: 14px;
      }

      .basket-radio {
        padding-bottom: 24px;
        margin-top: 24px;

        li:first-of-type {
          margin-right: 24px;
        }
      }

      .forgot {
        .remember {
          .check {
            width: 15px;
            height: 15px;
          }
        }
      }

      .paymentProcess {
        .rosiBasketCash {
          .cashButtonGroup {
            margin: 8px 0 24px;

            button {
              width: 64px;
              font-size: 14px;
              padding: 8px 10px;
            }
          }
        }

        .paymentMethod {
          margin-top: 40px;
        }

        .paymentMethodwrap {
          width: 100%;
          margin: 3px auto 12px;

          .visaPayment {
            width: 173px;
            margin-top: 13px;
            padding: 8px;
            margin-right: 16px;

            figure {
              width: 22px;
              height: 22px;
            }
          }
        }

        .add {
          width: 68px;
          font-size: 14px;
          padding: 9px 15px 9px 16px;
          margin-bottom: 16px;
        }
      }

      .rosiInputStepTwo {
        .manualAmount {
          margin-bottom: 24px;

          label {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 9px;
          }

          .form-group {
            margin-bottom: 9px;

            input {
              width: 330px;
              padding: 8px 0;
              font-size: 14px;
              line-height: 20px;
            }

            span {
              font-size: 14px;
              line-height: 20px;
              margin-right: 8px;
            }
          }

          .inputNote {
            line-height: 20px;
          }
        }

        .secondMethod {
          h3 {
            margin-bottom: 24px;
          }

          ul {
            li {
              margin-bottom: 14px;

              &:last-of-type {
                margin-bottom: 24px;
              }
            }

            .basketFields {
              width: 48%;

              input {
                width: 94%;
                padding: 8px 0 8px 10px;
                font-size: 14px;
                line-height: 20px;
              }

              ::-webkit-input-placeholder {
                font-size: 14px;
              }

              :-ms-input-placeholder {
                font-size: 14px;
              }

              ::placeholder {
                font-size: 14px;
              }
            }

            .basketGrp {
              svg {
                margin-right: 9px;
              }

              input {
                width: 94%;
                padding: 8px 0 8px 10px;
                font-size: 14px;
                line-height: 20px;
              }

              ::-webkit-input-placeholder {
                font-size: 14px;
              }

              :-ms-input-placeholder {
                font-size: 14px;
              }

              ::placeholder {
                font-size: 14px;
              }

              .css-1s2u09g-control {
                font-size: 14px;
              }

              .css-1pahdxg-control {
                font-size: 14px;
              }

              .select__menu {
                font-size: 14px;
              }

              .select--is-disabled.css-3iigni-container {
                font-size: 14px;
              }
            }

            .forms {
              .form-group {
                padding-right: 9px;
              }
            }
          }

          .paymentGrp {
            input {
              width: 96%;
              padding: 8px 0 8px 10px;
              font-size: 14px;
              line-height: 20px;
            }

            ::-webkit-input-placeholder {
              font-size: 14px;
            }

            :-ms-input-placeholder {
              font-size: 14px;
            }

            ::placeholder {
              font-size: 14px;
            }
          }
        }
      }

      .donateAmount {
        min-width: 230px;
        font-size: 14px;
        padding: 15px 40px;
      }
    }

    .address-terms {
      margin-bottom: 24px;
      margin-left: 10px;
    }

    .basketContentWrap {
      width: 520px;

      .basketContentHeader {
        padding: 16px 20px;

        h3 {
          font-size: 14px;
        }
      }

      .descPadding {
        padding: 14px 20px;
      }

      .basketContentBody {
        .basketScroll {
          max-height: 680px;
        }

        .basketWrapCard {
          padding: 16px 20px;

          .basketSummary {
            width: 50%;

            figure {
              width: 100px;
              height: 50px;
              margin-right: 8px;
            }

            .naming {
              h5 {
                font-size: 12px;
              }

              .basketCharityName {
                width: 110px;
                font-size: 12px;
                line-height: 20px;
              }
            }
          }

          .basketPercent {
            width: 50%;

            .basketPercentWrap {
              width: 130px;
              margin-right: 20px;

              .isLock {
                margin-top: 10px;

                h6 {
                  font-size: 11px;
                  margin-left: 5px;
                }
              }
            }

            .percentButton {
              width: 48px;
              padding: 4px 8px;
              font-size: 13px;
              margin-right: 20px;
            }

            .percentCancel {
              width: 18px;
              height: 18px;
              padding: 6px;
            }
          }
        }
      }

      .basketContentFooter {
        padding: 16px 20px;

        .summaryCheckBox {
          h4 {
            font-size: 12px;
          }
        }

        p {
          font-size: 11px;
          margin: 9px 0 0 20px;
        }
      }
    }

    .basketHelp {
      width: 510px;
      margin-top: 18px;
      padding: 16px 20px;

      h3 {
        font-size: 12px;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
      }

      .baketHelpButton {
        min-width: 140px;
        padding: 6px 16px;
        font-size: 13px;
      }
    }
  }

  .donationModal {
    width: 450px;
    padding: 26px 38px;

    .modal-header {
      margin-bottom: 6px;
    }

    .modal-body {
      .donationRight {
        width: 120px;
        height: 120px;
      }

      h2 {
        font-size: 20px;
        margin-top: 26px;
      }
      p {
        font-size: 13px;
        margin-bottom: 18px;
        line-height: 20px;
      }

      .donationButtonGrpModal {
        .againButton {
          width: 160px;
          font-size: 13px;
          padding: 6px 20px;
          margin-right: 14px;
        }

        .doneButton {
          width: 90px;
          font-size: 13px;
          padding: 6px 20px;
        }
      }
    }
  }
}

@include responsive(md) {
  .rosiBasket {
    padding: 10px 0 40px;
  }

  .breadcrumbsBasket {
    margin-bottom: 25px;
  }

  .rosiBasketWrap {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column-reverse;

    .basketTitle {
      font-size: 22px;
      margin-bottom: 26px;
    }

    .summary-button {
      margin-bottom: 24px;
      a {
        width: 280px;
        padding: 12px 16px;
        font-size: 15px;
      }
    }

    .basketSidebar {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 0;
    }

    .basketSidebarWrap {
      width: 100%;
      padding: 30px 22px 22px 22px;

      h3 {
        font-size: 13px;
      }

      .basket-radio {
        padding-bottom: 20px;
        margin-top: 20px;

        li:first-of-type {
          margin-right: 20px;
        }
      }

      .forgot {
        .remember {
          .check {
            width: 15px;
            height: 15px;
          }
        }
      }

      .paymentProcess {
        .rosiBasketCash {
          .cashButtonGroup {
            width: 70%;
            margin: 8px auto 24px;

            button {
              width: 64px;
              font-size: 14px;
              padding: 8px 10px;
            }
          }
        }

        .paymentMethod {
          margin-top: 30px;
        }

        .paymentMethodwrap {
          align-items: center;
          margin: 2px auto 12px;

          .visaPayment {
            width: 173px;
            margin-top: 13px;
            padding: 8px;
            margin-right: 16px;
            min-height: 58px;

            figure {
              width: 22px;
              height: 22px;
            }
            &:nth-of-type(2n + 0) {
              margin-right: 15px;
            }
            &:nth-of-type(3n + 0) {
              margin-right: 0;
            }
          }
        }

        .add {
          width: 68px;
          font-size: 13px;
          padding: 9px 14px;
          margin-bottom: 15px;
        }
      }

      .rosiInputStepTwo {
        .manualAmount {
          margin-bottom: 20px;

          label {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 8px;
          }

          .form-group {
            margin-bottom: 8px;

            input {
              width: calc(100% - 30px);
              padding: 8px 0;
              font-size: 14px;
              line-height: 20px;
            }

            span {
              font-size: 14px;
              line-height: 20px;
              margin-right: 8px;
            }
          }

          .inputNote {
            line-height: 18px;
          }
        }

        .secondMethod {
          h3 {
            margin-bottom: 20px;
          }

          ul {
            li {
              margin-bottom: 13px;

              &:last-of-type {
                margin-bottom: 20px;
              }
            }

            .basketFields {
              width: 48%;

              input {
                width: 97%;
                padding: 8px 0 8px 10px;
                font-size: 13px;
                line-height: 18px;
              }

              ::-webkit-input-placeholder {
                font-size: 13px;
              }

              :-ms-input-placeholder {
                font-size: 13px;
              }

              ::placeholder {
                font-size: 13px;
              }
            }

            .basketGrp {
              svg {
                margin-right: 9px;
              }

              input {
                width: 97%;
                padding: 8px 0 8px 10px;
                font-size: 13px;
                line-height: 18px;
              }

              ::-webkit-input-placeholder {
                font-size: 13px;
              }

              :-ms-input-placeholder {
                font-size: 13px;
              }

              ::placeholder {
                font-size: 13px;
              }

              .css-1s2u09g-control {
                font-size: 13px;
              }

              .css-1pahdxg-control {
                font-size: 13px;
              }

              .select__menu {
                font-size: 13px;
              }

              .select--is-disabled.css-3iigni-container {
                font-size: 13px;
              }
            }

            .forms {
              .form-group {
                padding-right: 9px;
              }
            }
          }

          .paymentGrp {
            input {
              width: 96%;
              padding: 8px 0 8px 10px;
              font-size: 3px;
              line-height: 18px;
            }

            ::-webkit-input-placeholder {
              font-size: 13px;
            }

            :-ms-input-placeholder {
              font-size: 13px;
            }

            ::placeholder {
              font-size: 13px;
            }
          }
        }
      }

      .donateAmount {
        min-width: 200px;
        font-size: 13px;
        padding: 14px 30px;
      }
    }

    .address-terms {
      margin-bottom: 20px;
      margin-left: 9px;
    }

    .basketContent {
      width: 100%;
    }

    .basketContentWrap {
      width: 100%;

      .basketContentHeader {
        padding: 15px 18px;

        h3 {
          font-size: 13px;
        }
      }

      .descPadding {
        padding: 14px 18px;
      }

      .basketContentBody {
        .basketScroll {
          max-height: 600px;
        }

        .basketWrapCard {
          padding: 14px 18px;

          .basketSummary {
            width: 50%;

            figure {
              width: 150px;
              height: 60px;
              margin-right: 10px;
            }

            .naming {
              h5 {
                font-size: 12px;
              }

              .basketCharityName {
                width: 130px;
                font-size: 12px;
                line-height: 18px;
              }
            }
          }

          .basketPercent {
            width: 50%;

            .basketPercentWrap {
              width: 150px;
              margin-right: 35px;

              .isLock {
                margin-top: 10px;

                h6 {
                  font-size: 12px;
                  margin-left: 6px;
                }
              }
            }

            .percentButton {
              width: 50px;
              padding: 4px 10px;
              font-size: 13px;
              margin-right: 35px;
            }

            .percentCancel {
              width: 20px;
              height: 20px;
              padding: 6px;
            }
          }
        }
      }

      .basketContentFooter {
        padding: 16px 20px;

        .summaryCheckBox {
          h4 {
            font-size: 13px;
          }
        }

        p {
          font-size: 12px;
          margin: 9px 0 0 22px;
        }
      }
    }

    .basketHelp {
      width: 100%;
      margin-top: 16px;
      padding: 15px 18px;

      h3 {
        font-size: 12px;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
      }

      .baketHelpButton {
        min-width: 150px;
        padding: 8px 16px;
        font-size: 13px;
      }
    }
  }

  .donationModal {
    width: 430px;
    padding: 24px 34px;

    .modal-header {
      margin-bottom: 6px;
    }

    .modal-body {
      .donationRight {
        width: 110px;
        height: 110px;
      }

      h2 {
        font-size: 18px;
        margin-top: 22px;
      }
      p {
        font-size: 13px;
        margin-bottom: 17px;
        line-height: 18px;
      }

      .donationButtonGrpModal {
        .againButton {
          width: 150px;
          font-size: 13px;
          padding: 6px 18px;
          margin-right: 13px;
        }

        .doneButton {
          width: 85px;
          font-size: 13px;
          padding: 6px 18px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .rosiBasket {
    padding: 10px 0 30px;
  }

  .breadcrumbsBasket {
    margin-bottom: 20px;
  }

  .rosiBasketWrap {
    .basketTitle {
      font-size: 20px;
      margin-bottom: 22px;
    }

    .summary-button {
      margin-bottom: 20px;
      a {
        width: 260px;
        padding: 12px 15px;
        font-size: 14px;
      }
    }

    .basketSidebar {
      margin-top: 26px;
    }

    .basketSidebarWrap {
      width: 400px;
      padding: 28px 20px 20px 20px;

      h3 {
        font-size: 12px;
      }

      .basket-radio {
        padding-bottom: 18px;
        margin-top: 18px;

        li:first-of-type {
          margin-right: 18px;
        }
      }

      .paymentProcess {
        .rosiBasketCash {
          .cashButtonGroup {
            margin: 8px 0 20px;

            button {
              width: 60px;
              font-size: 12px;
              padding: 8px 10px;
            }
          }
        }

        .paymentMethod {
          margin-top: 20px;
        }

        .paymentMethodwrap {
          margin: 2px auto 10px;

          .visaPayment {
            width: 165px;
            margin-top: 12px;
            padding: 7px;
            margin-right: 15px;

            figure {
              width: 20px;
              height: 20px;
            }
          }
        }

        .add {
          width: 62px;
          font-size: 12px;
          padding: 9px 12px;
          margin-bottom: 14px;
        }
      }

      .rosiInputStepTwo {
        .manualAmount {
          margin-bottom: 18px;

          label {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 8px;
          }

          .form-group {
            margin-bottom: 8px;

            input {
              width: 330px;
              padding: 8px 0;
              font-size: 12px;
              line-height: 20px;
            }

            span {
              font-size: 12px;
              line-height: 20px;
              margin-right: 8px;
            }
          }

          .inputNote {
            line-height: 16px;
          }
        }

        .secondMethod {
          h3 {
            margin-bottom: 18px;
          }

          ul {
            li {
              margin-bottom: 12px;

              &:last-of-type {
                margin-bottom: 18px;
              }
            }

            .basketFields {
              width: 48%;

              input {
                width: 94%;
                padding: 8px 0 8px 10px;
                font-size: 12px;
                line-height: 18px;
              }

              ::-webkit-input-placeholder {
                font-size: 12px;
              }

              :-ms-input-placeholder {
                font-size: 12px;
              }

              ::placeholder {
                font-size: 12px;
              }
            }

            .basketGrp {
              svg {
                margin-right: 8px;
              }

              input {
                width: 94%;
                padding: 8px 0 8px 10px;
                font-size: 12px;
                line-height: 18px;
              }

              ::-webkit-input-placeholder {
                font-size: 12px;
              }

              :-ms-input-placeholder {
                font-size: 12px;
              }

              ::placeholder {
                font-size: 12px;
              }

              .css-1s2u09g-control {
                font-size: 12px;
              }

              .css-1pahdxg-control {
                font-size: 12px;
              }

              .select__menu {
                font-size: 12px;
              }

              .select--is-disabled.css-3iigni-container {
                font-size: 12px;
              }
            }

            .forms {
              .form-group {
                padding-right: 8px;
              }
            }
          }

          .paymentGrp {
            input {
              width: 96%;
              padding: 8px 0 8px 10px;
              font-size: 12px;
              line-height: 16px;
            }

            ::-webkit-input-placeholder {
              font-size: 12px;
            }

            :-ms-input-placeholder {
              font-size: 12px;
            }

            ::placeholder {
              font-size: 12px;
            }
          }
        }
      }

      .donateAmount {
        min-width: 180px;
        font-size: 12px;
        padding: 12px 20px;
      }
    }

    .address-terms {
      margin-bottom: 18px;
      margin-left: 9px;
    }

    .basketContent {
      width: 100%;
    }

    .basketContentWrap {
      width: 100%;

      .basketContentHeader {
        padding: 14px 16px;

        h3 {
          font-size: 12px;
        }
      }

      .descPadding {
        padding: 14px 16px;
      }

      .basketContentBody {
        .basketScroll {
          max-height: 600px;
        }

        .basketWrapCard {
          padding: 12px 16px;

          .basketSummary {
            figure {
              width: 130px;
              height: 50px;
              margin-right: 9px;
            }

            .naming {
              h5 {
                font-size: 11px;
              }

              .basketCharityName {
                width: 100px;
                font-size: 11px;
                line-height: 16px;
              }
            }
          }

          .basketPercent {
            .basketPercentWrap {
              width: 120px;
              margin-right: 20px;

              .isLock {
                margin-top: 9px;

                h6 {
                  font-size: 11px;
                }
              }
            }

            .percentButton {
              width: 45px;
              padding: 4px 8px;
              font-size: 12px;
              margin-right: 20px;
            }

            .percentCancel {
              width: 18px;
              height: 18px;
              padding: 6px;
            }
          }
        }
      }

      .basketContentFooter {
        padding: 15px 18px;

        .summaryCheckBox {
          h4 {
            font-size: 12px;
          }
        }

        p {
          font-size: 12px;
          margin: 5px 0 0 16px;
        }
      }
    }

    .basketHelp {
      width: 100%;
      margin-top: 15px;
      padding: 15px 16px;

      h3 {
        font-size: 12px;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
      }

      .baketHelpButton {
        min-width: 140px;
        padding: 8px 15px;
        font-size: 12px;
      }
    }
  }

  .donationModal {
    width: 400px;
    padding: 22px 30px;

    .modal-header {
      margin-bottom: 6px;
    }

    .modal-body {
      .donationRight {
        width: 100px;
        height: 100px;
      }

      h2 {
        font-size: 16px;
        margin-top: 20px;
      }
      p {
        font-size: 13px;
        margin-bottom: 16px;
        line-height: 18px;
      }

      .donationButtonGrpModal {
        .againButton {
          width: 140px;
          font-size: 13px;
          padding: 6px 16px;
          margin-right: 12px;
        }

        .doneButton {
          width: 80px;
          font-size: 13px;
          padding: 6px 16px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .rosiBasket {
    padding: 10px 0 15px;
  }

  .breadcrumbsBasket {
    margin-bottom: 15px;
  }

  .rosiBasketWrap {
    width: 100%;

    .basketTitle {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .summary-button {
      margin-bottom: 15px;
      a {
        width: 240px;
        font-size: 13px;
      }
    }

    .basketSidebar {
      width: 100%;
      margin-top: 15px;
    }

    .basketSidebarWrap {
      width: 100%;
      padding: 15px;

      h3 {
        font-size: 12px;
      }

      .basket-radio {
        padding-bottom: 15px;
        margin-top: 15px;

        li:first-of-type {
          margin-right: 9px;
        }
      }

      .paymentProcess {
        .rosiBasketCash {
          .cashButtonGroup {
            width: 260px;
            margin: 8px auto 15px;

            button {
              width: 45px;
              font-size: 11px;
              padding: 6px;
            }
          }
        }

        .paymentMethod {
          margin-top: 15px;
        }

        .paymentMethodwrap {
          margin: 0 auto 10px;

          .visaPayment {
            width: 290px;
            margin: 15px auto 0;
            padding: 10px;

            &:nth-of-type(2n + 0) {
              margin: 15px auto 0;
            }

            &:nth-of-type(3n + 0) {
              margin: 15px auto 0;
            }

            figure {
              width: 25px;
              height: 25px;
            }

            .paymentName {
              margin-left: 15px;
            }
          }
        }

        .add {
          width: 65px;
          font-size: 12px;
          padding: 9px 15px;
          margin-bottom: 15px;
        }
      }

      .rosiInputStepTwo {
        .manualAmount {
          margin-bottom: 15px;

          label {
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 8px;
          }

          .form-group {
            margin-bottom: 8px;

            input {
              width: calc(100% - 25px);
              padding: 8px 0;
              font-size: 12px;
              line-height: 15px;
            }

            span {
              font-size: 12px;
              line-height: 15px;
              margin-right: 8px;
            }
          }

          .inputNote {
            line-height: 15px;
          }
        }

        .secondMethod {
          h3 {
            margin-bottom: 15px;
          }

          ul {
            li {
              margin-bottom: 12px;

              &:last-of-type {
                margin-bottom: 15px;
              }
            }

            .listInput {
              flex-wrap: wrap;
            }

            .basketFields {
              width: 100%;
              flex-wrap: wrap;
              margin-bottom: 12px;

              &:last-of-type {
                margin-bottom: 0;
              }

              input {
                width: 94%;
                padding: 8px 0 8px 10px;
                font-size: 12px;
                line-height: 15px;
              }

              ::-webkit-input-placeholder {
                font-size: 12px;
              }

              :-ms-input-placeholder {
                font-size: 12px;
              }

              ::placeholder {
                font-size: 12px;
              }
            }

            .basketGrp {
              svg {
                margin-right: 8px;
              }

              input {
                width: 94%;
                padding: 8px 0 8px 10px;
                font-size: 12px;
                line-height: 15px;
              }

              ::-webkit-input-placeholder {
                font-size: 12px;
              }

              :-ms-input-placeholder {
                font-size: 12px;
              }

              ::placeholder {
                font-size: 12px;
              }

              .css-1s2u09g-control {
                font-size: 12px;
              }

              .css-1pahdxg-control {
                font-size: 12px;
              }

              .select__menu {
                font-size: 12px;
              }

              .select--is-disabled.css-3iigni-container {
                font-size: 12px;
              }
            }

            .forms {
              .form-group {
                padding-right: 8px;
              }
            }
          }

          .paymentGrp {
            input {
              width: 96%;
              padding: 8px 0 8px 10px;
              font-size: 12px;
              line-height: 15px;
            }

            ::-webkit-input-placeholder {
              font-size: 12px;
            }

            :-ms-input-placeholder {
              font-size: 12px;
            }

            ::placeholder {
              font-size: 12px;
            }
          }
        }
      }

      .donateAmount {
        min-width: 160px;
        font-size: 12px;
        padding: 12px 15px;
      }
    }

    .address-terms {
      margin-bottom: 18px;
      margin-left: 9px;
    }

    .basketContentWrap {
      .basketContentHeader {
        padding: 15px;

        h3 {
          font-size: 12px;
        }
      }

      .descPadding {
        padding: 14px 15px;
      }

      .basketContentBody {
        .basketScroll {
          max-height: 500px;
        }

        .basketWrapCard {
          padding: 10px 15px;

          .basketSummary {
            width: 30%;
            flex-direction: column;
            figure {
              width: 50px;
              height: 40px;
              margin-right: 0;
              margin-bottom: 5px;
            }

            .naming {
              h5 {
                font-size: 11px;
              }

              .basketCharityName {
                width: 70px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
          }

          .basketPercent {
            width: 70%;
          }
        }
      }
    }

    .basketHelp {
      margin-top: 15px;
      padding: 15px;
      flex-wrap: wrap;

      h3 {
        font-size: 12px;
        margin-bottom: 3px;
      }

      p {
        font-size: 11px;
        margin-bottom: 10px;
      }

      .baketHelpButton {
        min-width: 130px;
        padding: 8px 10px;
        font-size: 12px;
      }
    }
  }

  .donationModal {
    width: 290px;
    padding: 15px;

    .modal-body {
      .donationRight {
        width: 100px;
        height: 100px;
      }

      h2 {
        font-size: 15px;
        margin-top: 15px;
        margin-bottom: 5px;
      }
      p {
        font-size: 12px;
        margin-bottom: 15px;
        line-height: 18px;
      }

      .donationButtonGrpModal {
        .againButton {
          width: 130px;
          font-size: 13px;
          padding: 5px 15px;
          margin-right: 12px;
        }

        .doneButton {
          width: 80px;
          font-size: 13px;
          padding: 5px 15px;
        }
      }
    }
  }
}
