@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.post {
  width: 100%;
  margin-top: 85px;
  padding: 13px 0 71px;
}

.breadcrumbsPost {
  margin-bottom: 45px;
}

.postWrap {
  display: flex;
  justify-content: space-between;
  .postSidebar {
    width: 556px;
    // account
    .accountPost {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px 30px 50px 36px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      margin-bottom: 40px;
      .postAccountDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        figure {
          width: 107px;
          height: 107px;
          border-radius: 50%;
          margin-bottom: 15px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            color: transparent;
          }
        }
        h3 {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 14px;
          color: $gray_2;
          text-transform: capitalize;
        }
      }
      .postAccountCount {
        width: 330px;
        height: 75px;
        display: flex;
        align-items: center;
        background-color: $light-green;
        border-radius: 18px;
        margin-left: 52px;

        .attendingPostCount {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 110px;

          &:nth-of-type(2) {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              width: 1px;
              height: 56px;
              background-color: rgba($color: $green-shadow, $alpha: 0.5);
            }
            &::after {
              content: "";
              position: absolute;
              right: 0;
              width: 1px;
              height: 56px;
              background-color: rgba($color: $green-shadow, $alpha: 0.5);
            }
          }
        }
        span {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 20px;
          color: $green-shadow;
          margin-bottom: 4px;
        }
        h4 {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          color: $gray;
        }
      }
    }
    // postTabbing
    .postTabbing {
      margin-bottom: 30px;

      .communityTab {
        .react-tabs__tab-list {
          border-bottom: none;
          width: 443px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $light-green;
          border-radius: 50px;
          margin-bottom: 30px;
          padding: 12px 52px 12px 30px;
        }

        .react-tabs__tab {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $black_1;
          border: none;
          padding: 0px;
          background-color: $light-green;
          // @include prefix(transition, all 0.3s ease-in-out);
          &:hover {
            color: $theme-color;
          }
        }

        .react-tabs__tab--selected {
          font-weight: 700;
          font-size: 14px;
          color: $green-shadow;
          background-color: $light-green;
        }
      }
    }
    .badge {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      padding: 30px;
      margin-bottom: 30px;
      .badgeWrap {
        margin-right: 38px;
        h3 {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 16px;
          color: $black_1;
          margin-bottom: 18px;
        }
        figure {
          width: 150px;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $theme-color;
          overflow: hidden;
        }
      }
      .badgecontent {
        width: 306px;
        h2 {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 20px;
          color: $black_1;
          text-transform: capitalize;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 18px;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          line-height: 25px;
          letter-spacing: 0.004em;
          text-transform: capitalize;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        a {
          display: flex;
          justify-content: end;
          font-family: $font_jost;
          font-weight: 700;
          font-size: 14px;
          color: $green-shadow;
          margin-top: 18px;
          @include prefix(transition, all 0.3s ease-in-out);
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
    // share
    .share {
      width: 100%;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      background-color: $white;
      padding: 25px 30px 30px;

      h3 {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 16px;
        color: $black_1;
        margin-bottom: 8px;
        text-transform: capitalize;
      }

      p {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $light-gray;
        margin-bottom: 15px;
        text-transform: capitalize;
        line-height: 25px;
      }

      .copy-text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $light-green;
        border-radius: 50px;
        padding: 5px 4px 5px 22px;

        input {
          width: 365px;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
          background: none;
          text-overflow: ellipsis;
        }

        button {
          border-radius: 50px;
          background-color: $green-shadow;
          width: 84px;
          height: 28px;
          text-align: center;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $white;
        }
      }
    }
  }
  .postContent {
    width: 752px;

    .tabsForpost {
      .react-tabs__tab-list {
        border-bottom: none;
      }
      .react-tabs__tab {
        background-color: $white !important;
        font-size: 18px !important;
        color: $black_1;
      }
      .react-tabs__tab--selected {
        background-color: white !important;
        font-weight: 700 !important;
        color: $green-shadow;
        border: none;
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .post {
    margin-top: 80px;
    padding: 12px 0 68px;
  }

  .breadcrumbsPost {
    margin-bottom: 43px;
  }

  .postWrap {
    .postSidebar {
      width: 500px;
      .accountPost {
        padding: 25px;
        margin-bottom: 38px;
        .postAccountDetails {
          figure {
            width: 100px;
            height: 100px;
            margin-bottom: 13px;
          }
          h3 {
            font-size: 13px;
          }
        }
        .postAccountCount {
          width: 320px;
          height: 75px;
          margin-left: 45px;
          .attendingPostCount {
            width: 106px;

            &:nth-of-type(2) {
              &::before {
                height: 50px;
              }
              &::after {
                height: 50px;
              }
            }
          }
          span {
            font-size: 18px;
            margin-bottom: 4px;
          }
          h4 {
            font-size: 12px;
          }
        }
      }
      .postTabbing {
        margin-bottom: 26px;

        .communityTab {
          .react-tabs__tab-list {
            width: 430px;
            margin-bottom: 26px;
            padding: 12px 45px 12px 28px;
          }

          .react-tabs__tab {
            font-size: 13px;
          }

          .react-tabs__tab--selected {
            font-size: 13px;
          }
        }
      }
      .badge {
        padding: 26px;
        margin-bottom: 26px;
        .badgeWrap {
          margin-right: 40px;
          h3 {
            font-size: 15px;
            margin-bottom: 16px;
          }
          figure {
            width: 135px;
            height: 135px;
          }
        }
        .badgecontent {
          width: 270px;
          h2 {
            font-size: 18px;
            margin-bottom: 9px;
          }
          p {
            margin-bottom: 16px;
            font-size: 12px;
            line-height: 22px;
          }

          a {
            font-size: 13px;
            margin-top: 16px;
          }
        }
      }
      .share {
        padding: 22px 28px 28px;

        h3 {
          font-size: 15px;
          margin-bottom: 7px;
        }

        p {
          font-size: 13px;
          margin-bottom: 13px;
          line-height: 23px;
        }

        .copy-text {
          padding: 5px 4px 5px 15px;

          input {
            width: 335px;
            font-size: 13px;
            letter-spacing: 0.001em;
            white-space: nowrap;
            overflow: hidden;
          }

          button {
            width: 75px;
            height: 26px;
            font-size: 13px;
          }
        }
      }
    }
    .postContent {
      width: 600px;

      .tabsForpost {
        .react-tabs__tab {
          font-size: 16px !important;
        }
      }
    }
  }
}

@include responsive(lg) {
  .post {
    padding: 12px 0 55px;
  }

  .breadcrumbsPost {
    margin-bottom: 38px;
  }

  .postWrap {
    .postSidebar {
      width: 410px;
      .accountPost {
        padding: 22px;
        margin-bottom: 30px;
        .postAccountDetails {
          figure {
            width: 90px;
            height: 90px;
            margin-bottom: 12px;
          }
          h3 {
            font-size: 12px;
          }
        }
        .postAccountCount {
          width: 250px;
          height: 70px;
          margin-left: 35px;
          .attendingPostCount {
            width: 83px;

            &:nth-of-type(2) {
              &::before {
                height: 45px;
              }
              &::after {
                height: 45px;
              }
            }
          }
          span {
            font-size: 16px;
            margin-bottom: 4px;
          }
          h4 {
            font-size: 11px;
          }
        }
      }
      .postTabbing {
        margin-bottom: 24px;

        .communityTab {
          .react-tabs__tab-list {
            width: 350px;
            margin-bottom: 24px;
            padding: 11px 40px 11px 26px;
          }

          .react-tabs__tab {
            font-size: 13px;
          }

          .react-tabs__tab--selected {
            font-size: 13px;
          }
        }
      }
      .badge {
        padding: 24px;
        margin-bottom: 24px;
        .badgeWrap {
          margin-right: 34px;
          h3 {
            font-size: 14px;
            margin-bottom: 15px;
          }
          figure {
            width: 120px;
            height: 120px;
          }
        }
        .badgecontent {
          width: 199px;
          h2 {
            font-size: 16px;
            margin-bottom: 8px;
          }
          p {
            margin-bottom: 14px;
            font-size: 12px;
            line-height: 20px;
          }

          a {
            font-size: 13px;
            margin-top: 16px;
          }
        }
      }
      .share {
        padding: 20px 26px;

        h3 {
          font-size: 14px;
          margin-bottom: 5px;
        }

        p {
          font-size: 13px;
          margin-bottom: 12px;
          line-height: 20px;
        }

        .copy-text {
          padding: 5px 4px 5px 15px;

          input {
            width: 268px;
            font-size: 12px;
          }

          button {
            width: 72px;
            height: 25px;
            font-size: 12px;
          }
        }
      }
    }
    .postContent {
      width: 510px;

      .tabsForpost {
        .react-tabs__tab {
          font-size: 15px !important;
        }
      }
    }
  }
}

@include responsive(md) {
  .post {
    padding: 10px 0 45px;
  }

  .breadcrumbsPost {
    margin-bottom: 30px;
  }

  .postWrap {
    width: 100%;
    flex-direction: column;
    align-items: center;
    .postSidebar {
      width: 100%;
      margin-bottom: 25px;
      .accountPost {
        padding: 22px;
        margin-bottom: 26px;
        .postAccountDetails {
          flex-direction: row;
          figure {
            width: 100px;
            height: 100px;
            margin-bottom: 0px;
          }
          h3 {
            margin-left: 20px;
            font-size: 13px;
          }
        }
        .postAccountCount {
          width: 420px;
          height: 75px;
          margin-left: 40px;
          .attendingPostCount {
            width: 140px;

            &:nth-of-type(2) {
              &::before {
                height: 45px;
              }
              &::after {
                height: 45px;
              }
            }
          }
          span {
            font-size: 16px;
            margin-bottom: 4px;
          }
          h4 {
            font-size: 12px;
          }
        }
      }
      .postTabbing {
        margin-bottom: 22px;

        .communityTab {
          .react-tabs__tab-list {
            width: 350px;
            margin-bottom: 22px;
            padding: 11px 36px 11px 24px;
          }

          .react-tabs__tab {
            font-size: 13px;
          }

          .react-tabs__tab--selected {
            font-size: 13px;
          }
        }
      }
      .badge {
        padding: 22px;
        margin-bottom: 22px;
        .badgeWrap {
          width: 130px;
          margin-right: 30px;
          h3 {
            font-size: 14px;
            margin-bottom: 13px;
          }
          figure {
            width: 110px;
            height: 110px;
          }
        }
        .badgecontent {
          width: calc(100% - 150px);
          h2 {
            font-size: 15px;
            margin-bottom: 7px;
          }
          p {
            margin-bottom: 12px;
            font-size: 12px;
            line-height: 18px;
          }

          a {
            font-size: 12px;
            margin-top: 14px;
          }
        }
      }
      .share {
        padding: 20px 24px;

        h3 {
          font-size: 14px;
          margin-bottom: 5px;
        }

        p {
          font-size: 13px;
          margin-bottom: 12px;
          line-height: 20px;
        }

        .copy-text {
          padding: 5px 4px 5px 15px;

          input {
            width: 580px;
            font-size: 12px;
          }

          button {
            width: 72px;
            height: 25px;
            font-size: 12px;
          }
        }
      }
    }
    .postContent {
      width: 100%;

      .tabsForpost {
        .react-tabs__tab {
          font-size: 14px !important;
        }
      }
    }
  }
}

@include responsive(sm) {
  .post {
    padding: 10px 0 30px;
  }

  .breadcrumbsPost {
    margin-bottom: 22px;
  }

  .postWrap {
    .postSidebar {
      margin-bottom: 20px;
      .accountPost {
        padding: 20px;
        margin-bottom: 20px;
        .postAccountDetails {
          figure {
            width: 80px;
            height: 80px;
          }
          h3 {
            margin-left: 15px;
            font-size: 12px;
          }
        }
        .postAccountCount {
          width: 300px;
          height: 70px;
          margin-left: 30px;
          .attendingPostCount {
            width: 100px;

            &:nth-of-type(2) {
              &::before {
                height: 42px;
              }
              &::after {
                height: 42px;
              }
            }
          }
          span {
            font-size: 15px;
            margin-bottom: 3px;
          }
          h4 {
            font-size: 12px;
          }
        }
      }
      .postTabbing {
        margin-bottom: 20px;

        .communityTab {
          .react-tabs__tab-list {
            width: 330px;
            margin-bottom: 20px;
            padding: 10px 30px 10px 20px;
          }

          .react-tabs__tab {
            font-size: 12px;
          }

          .react-tabs__tab--selected {
            font-size: 12px;
          }
        }
      }
      .badge {
        padding: 20px;
        margin-bottom: 20px;
        .badgeWrap {
          width: 120px;
          margin-right: 22px;
          h3 {
            font-size: 13px;
            margin-bottom: 10px;
          }
          figure {
            width: 100px;
            height: 100px;
          }
        }
        .badgecontent {
          width: calc(100% - 150px);
          h2 {
            font-size: 14px;
            margin-bottom: 6px;
          }
          p {
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 17px;
          }

          a {
            font-size: 12px;
            margin-top: 12px;
          }
        }
      }
      .share {
        padding: 18px 22px;

        h3 {
          font-size: 13px;
          margin-bottom: 4px;
        }
        p {
          font-size: 12px;
          margin-bottom: 8px;
          line-height: 18px;
        }
        .copy-text {
          padding: 5px 4px 5px 15px;

          input {
            width: 395px;
            font-size: 12px;
          }
          button {
            width: 72px;
            height: 25px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .post {
    padding: 10px 0 15px;
  }

  .breadcrumbsPost {
    margin-bottom: 15px;
  }

  .postWrap {
    .postSidebar {
      margin-bottom: 15px;
      .accountPost {
        padding: 15px;
        margin-bottom: 15px;
        flex-wrap: wrap;
        justify-content: center;
        .postAccountDetails {
          margin-bottom: 10px;
          flex-direction: column;
          figure {
            width: 60px;
            height: 60px;
            margin-bottom: 8px;
          }
          h3 {
            margin-left: 0;
            font-size: 12px;
          }
        }
        .postAccountCount {
          width: 100%;
          height: 60px;
          margin-left: 0px;
          .attendingPostCount {
            width: 33%;

            &:nth-of-type(2) {
              &::before {
                height: 40px;
              }
              &::after {
                height: 40px;
              }
            }
          }
          span {
            font-size: 14px;
            margin-bottom: 0px;
          }
          h4 {
            font-size: 11px;
          }
        }
      }
      .postTabbing {
        margin-bottom: 15px;

        .communityTab {
          .react-tabs__tab-list {
            width: 260px;
            margin-bottom: 15px;
            padding: 12px;
          }

          .react-tabs__tab {
            font-size: 11px;
          }

          .react-tabs__tab--selected {
            font-size: 11px;
          }
        }
      }
      .badge {
        padding: 15px;
        margin-bottom: 15px;
        flex-wrap: wrap;
        justify-content: center;
        .badgeWrap {
          margin-right: 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 10px;
          h3 {
            font-size: 12px;
            margin-bottom: 8px;
          }
          figure {
            width: 60px;
            height: 60px;
          }
        }
        .badgecontent {
          width: 100%;
          h2 {
            font-size: 13px;
            margin-bottom: 5px;
          }
          p {
            margin-bottom: 5px;
            font-size: 12px;
            line-height: 16px;
          }

          a {
            font-size: 12px;
            margin-top: 10px;
          }
        }
      }
      .share {
        padding: 15px;

        h3 {
          font-size: 12px;
          margin-bottom: 4px;
        }
        p {
          font-size: 11px;
          margin-bottom: 7px;
          line-height: 16px;
        }
        .copy-text {
          padding: 4px 4px 4px 12px;

          input {
            width: 170px;
            font-size: 11px;
          }
          button {
            width: 60px;
            height: 22px;
            font-size: 12px;
          }
        }
      }
    }
    .postContent {
      .tabsForpost {
        .react-tabs__tab {
          font-size: 12px !important;
          padding: 5px 10px;
        }
      }
    }
  }
}
