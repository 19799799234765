@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.charityCard {
  width: 263px;
  display: flex;
  // justify-content: end;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  padding: 0px 14px 23px;
  text-align: baseLine;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;

  .charityImage {
    display: inline-block;
    height: 130px;
    margin-top: 16px;
    vertical-align: middle;
    margin-bottom: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      color: transparent;
    }
  }
  .newline {
    white-space: pre-line;
  }
  .charitynWIcon {
    display: flex;
    justify-content: center;
    h4 {
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      line-height: 25px;
      margin-bottom: 15px;
    }
    .charityRight {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-left: 10px;
      margin-top: 5px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  p {
    height: 121px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $font_jost;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-size: 14px;
    color: $light-gray;
    line-height: 25px;
    margin-bottom: 18px;
  }

  .charityButtons {
    display: flex;
    justify-content: center;
    .charityBasket {
      width: 28%;
      border-radius: 50px;
      padding: 10px 18px;
      color: $white;
      margin-right: 10px;
      background: $green-shadow;
      @include prefix(transition, all 0.3s ease-in-out);
      cursor: pointer;

      &:hover {
        background: $theme-color;
      }
      img {
        width: 15px;
      }
      svg {
        path {
          fill: $white;
        }
      }
    }
    .associateCharityBasket {
      background-color: rgba($color: $green-shadow, $alpha: 0.3);

      &:hover {
        background-color: $light-green;
      }
      svg {
        path {
          fill: $green-shadow;
        }
      }
    }
    .telegramButton {
      width: 28%;
      padding: 10px 18px;
      border-radius: 50px;
      cursor: pointer;
      background: $green-shadow;
    }

    // .basket {
    //   min-width: 200px;
    //   background-color: $green-shadow;
    //   font-family: $font_jost;
    //   font-weight: 400;
    //   padding: 9px 33px;
    //   font-size: 16px;
    //   color: $white;
    //   border-radius: 20px;
    //   margin-right: 12px;
    //   @include prefix(transition, all 0.3s ease-in-out);

    //   &:hover {
    //     background-color: $theme-color;
    //   }
    // }

    .charityHeart {
      // min-width: 60px;
      width: 28%;
      padding: 6px 15px 5px;
      background-color: rgba($color: $theme-color, $alpha: 0.5);
      border-radius: 50px;
      margin-right: 10px;
      cursor: pointer;

      svg {
        width: 25px;
        height: 23px;

        path {
          fill: white;
          stroke: white;
        }
      }
    }

    .charityHeartActive {
      // min-width: 60px;
      width: 28%;
      padding: 6px 15px 5px;
      border-radius: 50px;
      background-color: transparent;
      border: 1px solid $green-shadow;
      margin-right: 10px;
      cursor: pointer;

      svg {
        width: 25px;
        height: 23px;
        transform: scale(1);
        animation: bubble 0.2s;

        path {
          fill: $red;
          stroke: $red;
        }
      }
    }
    @keyframes bubble {
      0% {
        transform: scale(0.8);
      }
      100% {
        transform: scale(1.2);
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .charityCard {
    width: 280px;
    padding: 0px 12px 20px;
    margin-bottom: 28px;

    .charityImage {
      height: 125px;
      margin-top: 15px;
      margin-bottom: 7px;
    }
    .charitynWIcon {
      h4 {
        height: 48px;
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 13px;
      }
      .charityRight {
        width: 19px;
        height: 19px;
        margin-left: 9px;
        margin-top: 6px;
      }
    }
    p {
      height: 66px;
      font-size: 13px;
      line-height: 23px;
      -webkit-line-clamp: 3;
      margin-bottom: 16px;
    }
  }
}

@include responsive(lg) {
  .charityCard {
    width: 269px;
    padding: 0px 12px 20px;
    margin-bottom: 26px;

    .charityImage {
      height: 120px;
      margin-top: 14px;
      margin-bottom: 7px;
    }
    .charitynWIcon {
      h4 {
        height: 48px;
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 13px;
      }
      .charityRight {
        width: 18px;
        height: 18px;
        margin-left: 8px;
        margin-top: 6px;
      }
    }
    p {
      height: 46px;
      font-size: 13px;
      line-height: 23px;
      -webkit-line-clamp: 2;
      margin-bottom: 16px;
    }
  }
}

@include responsive(md) {
  .charityCard {
    width: 260px;
    padding: 0px 12px 20px;
    margin-bottom: 22px;

    .charityImage {
      height: 115px;
      margin-top: 12px;
      margin-bottom: 6px;
    }
    .charitynWIcon {
      h4 {
        height: 42px;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 11px;
      }
      .charityRight {
        width: 16px;
        height: 16px;
        margin-left: 6px;
      }
    }
    p {
      height: 40px;
      font-size: 12px;
      line-height: 21px;
      margin-bottom: 15px;
    }
  }
}

@include responsive(sm) {
  .charityCard {
    width: 206px;
    padding: 0px 12px 20px;
    margin-bottom: 18px;

    .charityImage {
      height: 110px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .charitynWIcon {
      h4 {
        height: 38px;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .charityRight {
        width: 15px;
        height: 15px;
        margin-left: 5px;
      }
    }
    p {
      height: 38px;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 12px;
    }
  }
}

@include responsive(xs) {
  .charityCard {
    width: 100%;
    padding: 0px 10px 20px;
    margin-bottom: 15px;

    .charityImage {
      height: 120px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .charitynWIcon {
      h4 {
        height: 36px;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 8px;
      }
    }
    p {
      height: 34px;
      font-size: 11px;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }
}
