@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.eventDetail {
  width: 100%;
  margin: 114px 0 60px;
}
.newline{
  white-space: pre-line
}
.eventDetailsWrap {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;

  // sidebar
  .eventSidebar {
    width: 556px;
  }

  // event creator
  .eventCreator {
    width: 100%;
    background: $white;
    margin-bottom: 30px;
    padding: 30px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    text-align: center;

    h1 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 24px;
      color: $black_1;
      margin-bottom: 14px;
    }
  }

  .creatorImage {
    width: 64px;
    height: 64px;
    margin: 0 auto;

    figure {
      width: 100%;
      height: 100%;
      border-radius: 50%;

      img {
        color: transparent;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .organize {
    margin-top: 10px;

    span {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $gray_2;
    }

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      color: $black;
      font-size: 16px;
    }
  }

  // attending
  .attending {
    width: 100%;
    // text-align: center;
    padding: 40px 80px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

    h1 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: $black_1;
      text-align: center;
      margin-bottom: 34px;
    }

    .attendingCountWrap {
      display: flex;
      align-items: center;
    }

    .attendingCount {
      width: 88px;
      min-height: 74px;
      border-radius: 18px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: rgba($color: $theme-color, $alpha: 0.4);
      }

      span {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: $green-shadow;
        margin-bottom: 4px;
      }

      h4 {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        color: $gray;
        text-transform: capitalize;
      }

      &:nth-of-type(1) {
        margin-right: 60px;

        &::before {
          content: "";
          height: 52px;
          width: 1px;
          background-color: rgba($color: $green-shadow, $alpha: 0.5);
          position: absolute;
          right: -40px;
        }
      }

      &:last-of-type {
        margin-left: 60px;

        &::before {
          content: "";
          height: 52px;
          width: 1px;
          background-color: rgba($color: $green-shadow, $alpha: 0.5);
          position: absolute;
          left: -40px;
        }
      }
    }
    .attendingCountActive {
      background-color: $light-green;
      border-radius: 18px;
      @include prefix(transition, all 0.3s ease-in-out);
    }
  }

  // share
  .share {
    width: 100%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    background-color: $white;
    padding: 25px 30px 30px;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 24px;
      color: $black_1;
      margin-bottom: 8px;
      text-transform: capitalize;
    }

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      margin-bottom: 15px;
      text-transform: capitalize;
      line-height: 25px;
    }

    .copy-text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $light-green;
      border-radius: 50px;
      padding: 5px 4px 5px 22px;

      input {
        width: 370px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $light-gray;
        background: none;
        text-overflow: ellipsis;
      }

      button {
        border-radius: 50px;
        background-color: $green-shadow;
        width: 84px;
        height: 28px;
        text-align: center;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        cursor: pointer;
      }
    }
  }

  // content
  .eventContent {
    width: 756px;

    .ps__thumb-y {
      visibility: hidden;
    }
    .eventmainContent {
      padding: 30px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

      .content {
        max-height: 645px;
      }
    }

    .mainEventImage {
      width: 100%;
      height: 365px;
      margin-bottom: 16px;

      figure {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        color: transparent;
      }
    }

    .mainEventContent {
      h2 {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 20px;
        color: $black_1;
        margin-bottom: 16px;
      }

      ul li {
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        .eventContact,
        .eventDestination,
        .addressEvent,
        .dateEvent,
        .website {
          display: flex;
          align-items: center;
        }

        .timeEvent {
          display: flex;
          align-items: center;
          font-weight: 400;
          span {
             &:first-of-type{
              margin-right: 10px;
             }
          }
          svg path {
            stroke: $light-gray;
          }
        }

        p,
        span,
        address,
        h4 {
          font-family: $font_jost;
          font-weight: 400;
          color: $light-gray;
          letter-spacing: 0.04em;
          font-size: 14px;
        }

        p {
          line-height: 25px;
        }

        span,
        address {
          margin-left: 8px;
          font-style: normal;
        }

        .dateEvent {
          margin-right: 12px;
        }

        .emailEvent a {
          display: flex;
          align-items: center;

          span {
            max-width: 100%;
            margin-right: 20px;
          }
        }

        .emailEvent,
        .telEvent {
          span {
            border-bottom: 1px solid $light-gray;
            @include prefix(transition, all 0.3s ease-in-out);
            &:hover {
              color: $gray;
            }
          }
        }

        .website {
          svg {
            width: 18px;
            height: 15px;
          }

          h4 {
            width: 600px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-style: normal;
            line-height: 25px;
            margin-left: 7px;
            a {
              color: $light-gray;
              border-bottom: 1px solid $light-gray;
              @include prefix(transition, all 0.3s ease-in-out);
              &:hover {
                color: $gray;
              }
            }
          }
        }

        img {
          height: 18px;
          width: 17px;
        } 
      }
    }

    .eventComplete {
      width: 180px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      padding: 11px 16px 15px 15px;
      background-color: $theme-color;
      margin-bottom: 10px;

      span {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 14px;
        color: $white;
        letter-spacing: 0.04em;
        margin-left: 10px;
      }

      svg path {
        fill: $white;
      }
    }
    .eventButtons {
      margin-bottom: 18px;

      button {
        cursor: pointer;
        text-transform: capitalize;
        @include prefix(transition, all 0.3s ease-in-out);
        border-radius: 20px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;

        &:first-of-type {
          width: 162px;
          color: $black_1;
          // background-color: $green-shadow;
          padding: 8px 15px 7px;

          &:hover {
            background-color: $white-dark;
          }
        }

        &:nth-of-type(2) {
          width: 121px;
          padding: 8px 36px 7px 35px;
          color: $black_1;
          margin: 0 21px 0 16px;

          &:hover {
            background-color: $white-dark;
          }
        }

        &:last-of-type {
          width: 162px;
          padding: 8px 41px 7px 40px;
          color: $black_1;

          &:hover {
            background-color: $white-dark;
          }
        }
      }

      .selectedBtn {
        background-color: $green-shadow;
        color: $white !important;

        &:hover {
          background-color: $theme-color !important;
        }
      }
    }
  }
}

.eventModal {
  width: 510px;
  height: 390px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 30px 44px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    button {
      background-color: $white;
      cursor: pointer;
    }
  }

  .modal-body {
    text-align: center;

    .eventJoined {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 134px;
      height: 134px;
      margin: 0 auto;
      border-radius: 50%;
      background-color: $green-shadow;
    }

    h2 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 24px;
      color: $black_1;
      text-transform: capitalize;
      margin-top: 30px;
    }

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      text-transform: capitalize;
      margin-bottom: 22px;
      line-height: 25px;
      letter-spacing: 0.04em;
      &:first-of-type {
        margin-bottom: 0;
      }
    }

    button {
      width: 175px;
      background-color: $green-shadow;
      border-radius: 20px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $white;
      padding: 7px 23px 8px 22px;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: $theme-color;
      }
    }
  }

  .modal-footer {
    display: none;
  }
}

// responsive
@include responsive(xl) {
  .eventDetail {
    margin: 108px 0 50px;
  }

  .eventDetailsWrap {
    width: 1138px;
    margin-top: 44px;

    // sidebar
    .eventSidebar {
      width: 500px;
    }

    // event creator
    .eventCreator {
      margin-bottom: 28px;
      padding: 28px;

      h1 {
        font-size: 22px;
        margin-bottom: 13px;
      }
    }

    .creatorImage {
      width: 62px;
      height: 62px;
    }

    .organize {
      margin-top: 8px;

      span {
        font-size: 13px;
      }

      h3 {
        font-size: 15px;
      }
    }

    // attending
    .attending {
      padding: 36px 78px;
      margin-bottom: 28px;

      h1 {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 32px;
      }

      .attendingCount {
        width: 85px;
        min-height: 70px;

        span {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 4px;
        }

        h4 {
          font-size: 12px;
        }

        &:nth-of-type(1) {
          margin-right: 56px;

          &::before {
            height: 50px;
            width: 1px;
            right: -36px;
          }
        }

        &:last-of-type {
          margin-left: 56px;

          &::before {
            height: 50px;
            width: 1px;
            left: -36px;
          }
        }
      }
    }

    // share
    .share {
      padding: 22px 28px 28px;

      h3 {
        font-size: 22px;
        margin-bottom: 7px;
      }

      p {
        font-size: 13px;
        margin-bottom: 13px;
        line-height: 23px;
      }

      .copy-text {
        padding: 5px 4px 5px 15px;

        input {
          width: 335px;
          font-size: 13px;
          letter-spacing: 0.001em;
          white-space: nowrap;
          overflow: hidden;
        }

        button {
          width: 75px;
          height: 26px;
          font-size: 13px;
        }
      }
    }

    // content
    .eventContent {
      width: 600px;

      .eventmainContent {
        padding: 28px;

        .content {
          max-height: 600px;
          padding: 0;
        }
      }

      .mainEventImage {
        height: 300px;
        margin-bottom: 14px;
      }

      .mainEventContent {
        h2 {
          font-size: 18px;
          margin-bottom: 15px;
        }

        ul li {
          margin-bottom: 11px;

          p,
          span,
          address,
          h4 {
            font-size: 14px;
          }

          p {
            line-height: 22px;
          }

          span,
          address {
            margin-left: 8px;
          }

          .dateEvent {
            margin-right: 12px;
          }

          .emailEvent a {
            span {
              margin-right: 18px;
            }
          }

          .addressEvent {
            address {
              margin-right: 18px;
            }
          }

          .website {
            h4 {
              width: 100%;
              line-height: 23px;
              margin-left: 10px;
            }
          }
        }
      }

      .eventComplete {
        width: 175px;
        padding: 10px 14px 14px 14px;
        margin-bottom: 8px;

        span {
          font-size: 13px;
          margin-left: 8px;
        }
      }
      .eventButtons {
        margin-bottom: 16px;

        button {
          font-size: 13px;

          &:first-of-type {
            width: 155px;
            padding: 8px 14px 7px;
          }

          &:nth-of-type(2) {
            width: 110px;
            padding: 8px 30px;
            margin: 0 18px 0 15px;
          }

          &:last-of-type {
            width: 155px;
            padding: 8px 14px 7px;
          }
        }
      }
    }
  }

  .eventModal {
    width: 490px;
    height: auto;
    padding: 28px 40px;

    .modal-header {
      margin-bottom: 5px;
    }

    .modal-body {
      .eventJoined {
        width: 130px;
        height: 130px;
      }

      h2 {
        font-size: 22px;
        margin-top: 28px;
      }

      p {
        font-size: 13px;
        margin-bottom: 20px;
        line-height: 22px;
      }

      button {
        width: 170px;
        font-size: 14px;
        padding: 8px 20px;
      }
    }
  }
}

@include responsive(lg) {
  .eventDetail {
    margin: 104px 0 45px;
  }

  .eventDetailsWrap {
    width: 960px;
    margin-top: 36px;

    // sidebar
    .eventSidebar {
      width: 410px;
    }

    // event creator
    .eventCreator {
      margin-bottom: 26px;
      padding: 26px;

      h1 {
        font-size: 20px;
        margin-bottom: 13px;
      }
    }

    .creatorImage {
      width: 60px;
      height: 60px;
    }

    .organize {
      margin-top: 8px;

      span {
        font-size: 13px;
      }

      h3 {
        font-size: 14px;
      }
    }

    // attending
    .attending {
      padding: 32px 50px;
      margin-bottom: 26px;

      h1 {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 28px;
      }

      .attendingCount {
        width: 70px;
        min-height: 70px;

        span {
          font-size: 17px;
          line-height: 26px;
          margin-bottom: 4px;
        }

        h4 {
          font-size: 12px;
        }

        &:nth-of-type(1) {
          margin-right: 50px;

          &::before {
            height: 50px;
            width: 1px;
            right: -30px;
          }
        }

        &:last-of-type {
          margin-left: 50px;

          &::before {
            height: 50px;
            width: 1px;
            left: -30px;
          }
        }
      }
    }

    // share
    .share {
      padding: 20px 26px;

      h3 {
        font-size: 20px;
        margin-bottom: 5px;
      }

      p {
        font-size: 13px;
        margin-bottom: 12px;
        line-height: 20px;
      }

      .copy-text {
        padding: 5px 4px 5px 15px;

        input {
          width: 265px;
          font-size: 12px;
        }

        button {
          width: 72px;
          height: 25px;
          font-size: 12px;
        }
      }
    }

    // content
    .eventContent {
      width: 510px;

      .eventmainContent {
        padding: 25px;

        .content {
          max-height: 600px;
          padding: 0;
        }
      }

      .mainEventImage {
        height: 270px;
        margin-bottom: 18px;
      }

      .mainEventContent {
        h2 {
          font-size: 16px;
          margin-bottom: 15px;
        }

        ul li {
          margin-bottom: 10px;

          p,
          span,
          address,
          h4 {
            font-size: 13px;
          }

          p {
            line-height: 20px;
          }

          span,
          address {
            margin-left: 8px;
          }

          .dateEvent {
            margin-right: 12px;
          }

          .emailEvent a {
            span {
              margin-right: 16px;
            }
          }

          .addressEvent {
            address {
              margin-right: 16px;
            }
          }

          .website {
            h4 {
              line-height: 21px;
              margin-left: 10px;
            }
          }
        }
      }

      .eventComplete {
        width: 170px;
        padding: 10px 14px 14px;
        margin-bottom: 8px;

        span {
          font-size: 13px;
          margin-left: 8px;
        }
      }
      .eventButtons {
        margin-bottom: 15px;

        button {
          font-size: 13px;

          &:first-of-type {
            width: 145px;
            padding: 8px 12px 7px;
          }

          &:nth-of-type(2) {
            width: 110px;
            padding: 8px 26px;
            margin: 0 16px 0 15px;
          }

          &:last-of-type {
            width: 145px;
            padding: 8px 12px 7px;
          }
        }
      }
    }
  }

  .eventModal {
    width: 450px;
    padding: 26px 38px;

    .modal-header {
      margin-bottom: 5px;
    }

    .modal-body {
      .eventJoined {
        width: 120px;
        height: 120px;
      }

      h2 {
        font-size: 20px;
        margin-top: 24px;
      }

      p {
        font-size: 13px;
        margin-bottom: 18px;
        line-height: 20px;
      }

      button {
        width: 160px;
        font-size: 13px;
        padding: 8px 18px;
      }
    }
  }
}

@include responsive(md) {
  .eventDetail {
    margin: 95px 0 35px;
  }

  .eventDetailsWrap {
    margin-top: 28px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    // sidebar
    .eventSidebar {
      width: 100%;
      margin-bottom: 25px;
    }

    // event creator
    .eventCreator {
      margin-bottom: 22px;
      padding: 22px;

      h1 {
        font-size: 18px;
        margin-bottom: 12px;
      }
    }

    .creatorImage {
      width: 50px;
      height: 50px;
    }

    .organize {
      margin-top: 8px;

      span {
        font-size: 12px;
      }

      h3 {
        font-size: 13px;
      }
    }

    // attending
    .attending {
      padding: 28px 45px;
      margin-bottom: 24px;

      h1 {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 25px;
      }

      .attendingCountWrap {
        justify-content: center;
      }
      .attendingCount {
        width: 150px;
        min-height: 70px;

        span {
          font-size: 17px;
          line-height: 26px;
          margin-bottom: 4px;
        }

        h4 {
          font-size: 13px;
        }

        &:nth-of-type(1) {
          margin-right: 50px;

          &::before {
            height: 50px;
            width: 1px;
            right: -30px;
          }
        }

        &:last-of-type {
          margin-left: 50px;

          &::before {
            height: 50px;
            width: 1px;
            left: -30px;
          }
        }
      }
    }

    // share
    .share {
      padding: 20px 24px;

      h3 {
        font-size: 18px;
        margin-bottom: 5px;
      }

      p {
        font-size: 13px;
        margin-bottom: 12px;
        line-height: 20px;
      }

      .copy-text {
        padding: 5px 4px 5px 15px;

        input {
          width: 580px;
          font-size: 12px;
        }

        button {
          width: 72px;
          height: 25px;
          font-size: 12px;
        }
      }
    }

    // content
    .eventContent {
      width: 100%;

      .eventmainContent {
        padding: 22px;

        .content {
          max-height: 100%;
        }
      }

      .mainEventImage {
        height: 260px;
        margin-bottom: 18px;
      }

      .mainEventContent {
        h2 {
          font-size: 16px;
          margin-bottom: 15px;
        }

        ul li {
          margin-bottom: 10px;

          p,
          span,
          address,
          h4 {
            font-size: 13px;
          }

          p {
            line-height: 20px;
          }

          span,
          address {
            margin-left: 8px;
          }

          .dateEvent {
            margin-right: 12px;
          }

          .emailEvent a {
            span {
              margin-right: 16px;
            }
          }

          .addressEvent {
            address {
              margin-right: 16px;
            }
          }

          .website {
            h4 {
              line-height: 21px;
              margin-left: 10px;
            }
          }
        }
      }

      .eventComplete {
        width: 160px;
        padding: 10px 14px 14px;
        margin-bottom: 8px;

        span {
          font-size: 13px;
          margin-left: 8px;
        }
      }
      .eventButtons {
        margin-bottom: 15px;

        button {
          font-size: 13px;

          &:first-of-type {
            width: 140px;
            padding: 8px 12px 7px;
          }

          &:nth-of-type(2) {
            width: 105px;
            padding: 8px 26px;
            margin: 0 15px;
          }

          &:last-of-type {
            width: 140px;
            padding: 8px 12px 7px;
          }
        }
      }
    }
  }

  .eventModal {
    width: 430px;
    padding: 22px 36px;

    .modal-header {
      margin-bottom: 5px;
    }

    .modal-body {
      .eventJoined {
        width: 110px;
        height: 110px;
      }

      h2 {
        font-size: 18px;
        margin-top: 22px;
      }

      p {
        font-size: 13px;
        margin-bottom: 16px;
        line-height: 18px;
      }

      button {
        width: 150px;
        font-size: 13px;
        padding: 8px 16px;
      }
    }
  }
}

@include responsive(sm) {
  .eventDetail {
    margin: 90px 0 25px;
  }

  .eventDetailsWrap {
    margin-top: 20px;

    // sidebar
    .eventSidebar {
      margin-bottom: 20px;
    }

    // event creator
    .eventCreator {
      margin-bottom: 20px;
      padding: 20px;

      h1 {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    .creatorImage {
      width: 50px;
      height: 50px;
    }

    .organize {
      margin-top: 8px;

      span {
        font-size: 12px;
      }

      h3 {
        font-size: 13px;
      }
    }

    // attending
    .attending {
      padding: 20px 40px;
      margin-bottom: 20px;

      h1 {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .attendingCount {
        width: 120px;
        min-height: 60px;

        span {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 4px;
        }

        h4 {
          font-size: 13px;
        }

        &:nth-of-type(1) {
          margin-right: 50px;

          &::before {
            height: 50px;
            width: 1px;
            right: -30px;
          }
        }

        &:last-of-type {
          margin-left: 50px;

          &::before {
            height: 50px;
            width: 1px;
            left: -30px;
          }
        }
      }
    }

    // share
    .share {
      padding: 22px;

      h3 {
        font-size: 13px;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        margin-bottom: 6px;
        line-height: 18px;
      }

      .copy-text {
        padding: 5px 4px 5px 15px;

        input {
          width: 395px;
          font-size: 12px;
        }

        button {
          width: 72px;
          height: 25px;
          font-size: 12px;
        }
      }
    }

    // content
    .eventContent {
      .eventmainContent {
        padding: 20px;
      }

      .mainEventImage {
        height: 200px;
        margin-bottom: 18px;
      }

      .mainEventContent {
        h2 {
          font-size: 15px;
          margin-bottom: 15px;
        }

        ul li {
          margin-bottom: 10px;

          p,
          span,
          address,
          h4 {
            font-size: 13px;
          }

          p {
            line-height: 20px;
          }

          span,
          address {
            margin-left: 8px;
          }

          .dateEvent {
            margin-right: 12px;
          }

          .emailEvent a {
            span {
              margin-right: 15px;
            }
          }

          .addressEvent {
            address {
              margin-right: 15px;
            }
          }

          .website {
            h4 {
              line-height: 20px;
              margin-left: 10px;
            }
          }
        }
      }

      .eventComplete {
        width: 160px;
        padding: 10px 14px 14px;
        margin-bottom: 8px;

        span {
          font-size: 13px;
          margin-left: 8px;
        }
      }
      .eventButtons {
        margin-bottom: 15px;

        button {
          font-size: 13px;

          &:first-of-type {
            width: 120px;
            padding: 8px 12px 7px;
          }

          &:nth-of-type(2) {
            width: 90px;
            padding: 8px 20px;
            margin: 0 15px;
          }

          &:last-of-type {
            width: 120px;
            padding: 8px 12px 7px;
          }
        }
      }
    }
  }

  .eventModal {
    width: 400px;
    padding: 20px 30px;

    .modal-header {
      margin-bottom: 5px;
    }

    .modal-body {
      .eventJoined {
        width: 100px;
        height: 100px;
      }

      h2 {
        font-size: 16px;
        margin-top: 20px;
      }

      p {
        font-size: 13px;
        margin-bottom: 15px;
        line-height: 18px;
      }

      button {
        width: 140px;
        font-size: 13px;
        padding: 8px 15px;
      }
    }
  }
}

@include responsive(xs) {
  .eventDetail {
    margin: 68px 0 15px;
  }

  .eventDetailsWrap {
    margin-top: 15px;

    // sidebar
    .eventSidebar {
      margin-bottom: 15px;
    }

    // event creator
    .eventCreator {
      margin-bottom: 15px;
      padding: 15px;

      h1 {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }

    .creatorImage {
      width: 50px;
      height: 50px;
    }

    .organize {
      margin-top: 8px;

      span {
        font-size: 12px;
      }

      h3 {
        font-size: 13px;
      }
    }

    // attending
    .attending {
      padding: 15px;
      margin-bottom: 15px;

      h1 {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 18px;
      }

      .attendingCount {
        width: 66px;
        min-height: 60px;

        span {
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 4px;
        }

        h4 {
          font-size: 12px;
        }

        &:nth-of-type(1) {
          margin-right: 30px;

          &::before {
            height: 50px;
            width: 1px;
            right: -10px;
          }
        }

        &:last-of-type {
          margin-left: 30px;

          &::before {
            height: 50px;
            width: 1px;
            left: -10px;
          }
        }
      }
    }

    // share
    .share {
      padding: 15px;

      h3 {
        font-size: 12px;
        margin-bottom: 4px;
      }

      p {
        font-size: 11px;
        margin-bottom: 7px;
        line-height: 16px;
      }

      .copy-text {
        padding: 4px 4px 4px 12px;

        input {
          width: 170px;
          font-size: 11px;
        }

        button {
          width: 60px;
          height: 22px;
          font-size: 12px;
        }
      }
    }

    // content
    .eventContent {
      .eventmainContent {
        padding: 15px;
      }

      .mainEventImage {
        height: 180px;
        margin-bottom: 15px;
      }

      .mainEventContent {
        h2 {
          font-size: 14px;
          margin-bottom: 15px;
        }

        ul li {
          margin-bottom: 10px;

          p,
          span,
          address,
          h4 {
            font-size: 13px;
          }

          p {
            line-height: 20px;
          }

          span,
          address {
            margin-left: 8px;
          }

          .dateEvent {
            margin-right: 12px;
          }

          .eventContact {
            flex-wrap: wrap;
          }

          .emailEvent,
          .telEvent {
            width: 100%;
          }

          .emailEvent {
            margin-bottom: 10px;
            a {
              span {
                margin-right: 15px;
              }
            }
          }

          .addressEvent {
            address {
              margin-right: 15px;
            }
          }

          .website {
            h4 {
              line-height: 20px;
              margin-left: 10px;
            }
          }
        }
      }

      .eventComplete {
        width: 160px;
        padding: 10px 14px 14px;
        margin-bottom: 8px;

        span {
          font-size: 13px;
          margin-left: 8px;
        }
      }
      .eventButtons {
        margin-bottom: 15px;

        button {
          font-size: 12px;

          &:first-of-type {
            width: 85px;
            padding: 8px 12px 7px;
          }

          &:nth-of-type(2) {
            width: 70px;
            padding: 8px 15px;
            margin: 0 8px;
          }

          &:last-of-type {
            width: 85px;
            padding: 8px 12px 7px;
          }
        }
      }
    }
  }

  .eventModal {
    width: 290px;
    padding: 15px;

    .modal-header {
      margin-bottom: 5px;
    }

    .modal-body {
      .eventJoined {
        width: 95px;
        height: 95px;
      }

      h2 {
        font-size: 14px;
        margin-top: 15px;
      }

      p {
        font-size: 13px;
        margin-bottom: 15px;
        line-height: 18px;
      }

      button {
        width: 130px;
        font-size: 13px;
        padding: 8px 15px;
      }
    }
  }
}
