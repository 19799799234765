@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.changeCheck {
  margin-bottom: 0 !important;
  margin-top: 20px;
}

.changeButton {
  button {
    display: inline-block;
    width: 350px;
    background-color: $green-shadow;
    padding: 11px 85px;
    border-radius: 50px;
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: $white;
    @include prefix(transition, all 0.3s ease-in-out);
    margin-top: 38px;
    margin-bottom: 110px;
    cursor: pointer;
    &:hover {
      background-color: $theme-color;
    }
  }
}

// responsive
@include responsive(lg) {
  .changeCheck {
    margin-top: 18px;
  }

  .changeButton {
    button {
      width: 335px;
      padding: 10px 70px;
      font-size: 13px;
      margin-top: 34px;
      margin-bottom: 90px;
    }
  }
}

@include responsive(md) {
  .changeCheck {
    margin-top: 16px;
  }

  .changeButton {
    button {
      width: 310px;
      padding: 10px 70px;
      font-size: 13px;
      margin-top: 30px;
      margin-bottom: 80px;
    }
  }
}

@include responsive(sm) {
  .changeCheck {
    margin-top: 14px;
  }

  .changeButton {
    button {
      width: 300px;
      padding: 8px 60px;
      font-size: 12px;
      margin-top: 25px;
      margin-bottom: 70px;
    }
  }
}

@include responsive(xs) {
  .changeCheck {
    margin-top: 12px;
  }

  .changeButton {
    button {
      width: 216px;
      padding: 8px 55px;
      font-size: 11px;
      margin-top: 20px;
      margin-bottom: 60px;
    }
  }
}
