@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.tabCause {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  padding-bottom: 28px;

  .tabCauseHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    padding: 25px 30px 0 40px;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 24px;
      color: $black_1;
      text-transform: capitalize;
    }
    button {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $green_shadow;
      text-transform: capitalize;
      background: none;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        color: $theme-color;
      }
    }
  }
  .tabCauseBodyWrap {
    .content {
      max-height: 856px;
      padding: 0 30px 0 40px;
    }
  }
  .tabCauseBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
    cursor: pointer;
    @include prefix(transition, all 0.3s ease-in-out);

    &:hover {
      background-color: $light-green;
      padding: 10px;
      border-radius: 7px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .tabCauseLeft {
      display: flex;
      align-items: center;
      figure {
        width: 65px;
        height: 65px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          color: transparent;
        }
      }
      h3 {
        font-family: $font_jost;
        font-weight: 500;
        font-size: 14px;
        color: $black_1;
      }
    }
    .tabCauseRight {
      button {
        width: 28px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: $green_shadow;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);

        svg {
          transform: rotate(-90deg);

          path {
            fill: $white !important;
          }
        }
        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .tabCause {
    padding-bottom: 24px;

    .tabCauseHeader {
      margin-bottom: 13px;
      padding: 22px 28px 0 36px;

      h3 {
        font-size: 22px;
      }
      button {
        font-size: 14px;
      }
    }
    .tabCauseBodyWrap {
      .content {
        padding: 0 28px 0 36px;
      }
    }
    .tabCauseBody {
      margin-bottom: 18px;
      padding: 9px;

      &:hover {
        padding: 9px;
      }

      .tabCauseLeft {
        figure {
          width: 62px;
          height: 62px;
          margin-right: 18px;
        }
        h3 {
          font-size: 13px;
        }
      }
      .tabCauseRight {
        button {
          width: 26px;
          height: 25px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .tabCause {
    padding-bottom: 22px;

    .tabCauseHeader {
      margin-bottom: 12px;
      padding: 20px 24px 0 30px;

      h3 {
        font-size: 20px;
      }
      button {
        font-size: 13px;
      }
    }
    .tabCauseBodyWrap {
      .content {
        padding: 0 24px 0 30px;
      }
    }
    .tabCauseBody {
      margin-bottom: 16px;
      padding: 8px;

      &:hover {
        padding: 8px;
      }

      .tabCauseLeft {
        figure {
          width: 60px;
          height: 60px;
          margin-right: 16px;
        }
        h3 {
          font-size: 12px;
        }
      }
      .tabCauseRight {
        button {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

@include responsive(md) {
  .tabCause {
    padding-bottom: 20px;

    .tabCauseHeader {
      margin-bottom: 10px;
      padding: 18px 22px 0 26px;

      h3 {
        font-size: 18px;
      }
      button {
        font-size: 13px;
      }
    }
    .tabCauseBodyWrap {
      .content {
        padding: 0 22px 0 26px;
      }
    }
    .tabCauseBody {
      margin-bottom: 15px;
      padding: 7px;

      &:hover {
        padding: 7px;
      }

      .tabCauseLeft {
        figure {
          width: 60px;
          height: 60px;
          margin-right: 15px;
        }
        h3 {
          font-size: 12px;
        }
      }
      .tabCauseRight {
        button {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .tabCause {
    padding-bottom: 18px;

    .tabCauseHeader {
      margin-bottom: 8px;
      padding: 16px 20px 0 22px;

      h3 {
        font-size: 16px;
      }
      button {
        font-size: 12px;
      }
    }
    .tabCauseBodyWrap {
      .content {
        padding: 0 20px 0 22px;
      }
    }
    .tabCauseBody {
      margin-bottom: 15px;
      padding: 6px;

      &:hover {
        padding: 6px;
      }

      .tabCauseLeft {
        figure {
          width: 58px;
          height: 58px;
          margin-right: 12px;
        }
        h3 {
          font-size: 12px;
        }
      }
      .tabCauseRight {
        button {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .tabCause {
    padding-bottom: 15px;

    .tabCauseHeader {
      margin-bottom: 6px;
      padding: 15px 15px 0 15px;

      h3 {
        font-size: 15px;
      }
      button {
        font-size: 11px;
      }
    }
    .tabCauseBodyWrap {
      .content {
        padding: 0 15px;
      }
    }
    .tabCauseBody {
      padding: 5px;

      &:hover {
        padding: 5px;
      }

      .tabCauseLeft {
        figure {
          width: 45px;
          height: 45px;
          margin-right: 7px;
          flex-shrink: 0;
        }
        h3 {
          font-size: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-right: 10px;
        }
      }
      .tabCauseRight {
        button {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
