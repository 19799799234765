@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.communityList {
  width: 100%;
  margin-top: 85px;
  padding: 15px 0 60px;

  .communityTab {
    width: 100%;

    .communityListTab ul {
      width: 100%;
      display: flex;
      justify-content: end;
    }
    .react-tabs__tab-list {
      border-bottom: none;
      align-items: center;
      border-radius: 50px;
    }

    .react-tabs__tab {
      padding: 22px 0;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
      border: none;
      background-color: $light-green;
      text-transform: capitalize;
      &:first-of-type {
        padding-left: 55px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        padding-right: 96px;
      }
      &:last-of-type {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        padding-right: 55px;
      }
    }
    .react-tabs__tab--selected {
      font-weight: 700;
      font-size: 16px;
      color: $green-shadow;
      background-color: $light-green;
    }
  }
  .communityDetails {
    display: flex;
    align-items: center;
    justify-content: center;

    h5 {
      width: 110px;
      flex-shrink: 0;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-right: 5px;
    }
    ul {
      width: 90px !important;
      display: flex;
      justify-content: start !important;
      align-items: center;
      li {
        margin-left: 6px;
        span {
          display: block;
          width: 72px;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          text-transform: capitalize;
          color: $black_1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:first-of-type {
          margin-left: 0px;
          flex-shrink: 0;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .member {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .member-list {
      display: flex;
      align-items: center;
      .member-images {
        display: flex;
        margin-right: 15px;
        figure {
          display: flex;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid $white;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            color: transparent;
          }
          span {
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
          }

          &:nth-child(2) {
            margin-left: -5px;
          }

          &:nth-child(3) {
            margin-left: -5px;
          }

          &:nth-child(4) {
            margin-left: -5px;
          }
        }
      }
      .member-count {
        font-family: $font_jost;
        font-weight: 400;
        color: $green-shadow;
        font-size: 12px;
      }
    }
  }

  .communityButtons {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    .communityBasket {
      width: 30%;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      border-radius: 50px;
      padding: 7px 0 10px;
      color: $white;
      background: $green-shadow;
      margin-right: 10px;
      @include prefix(transition, all 0.3s ease-in-out);
      cursor: pointer;

      &:hover {
        background: $theme-color;
      }
      img {
        width: 15px;
      }
      svg {
        path {
          fill: $white;
        }
      }
    }
    .associateCommunityBasket {
      background-color: rgba($color: $green-shadow, $alpha: 0.3);

      &:hover {
        background-color: $light-green;
      }
      svg {
        path {
          fill: $green-shadow;
        }
      }
    }
    .joinButtons {
      width: 30%;
      font-family: $font_jost;
      font-weight: 400;
      padding: 10px;
      font-size: 14px;
      border-radius: 50px;
      text-align: center;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);
      margin-right: 10px;
      img {
        width: 30px;
      }
    }
    .telegramButton {
      width: 30%;
      padding: 11px 23px 8px 21px;
      border-radius: 50px;
      cursor: pointer;
    }
  }
  .notAsociatedButton {
    .joinButtons {
      color: $white;
      background-color: $white-dark;
      &:hover {
        background-color: $theme-color;
      }
    }
    .telegramButton {
      background-color: rgba($color: $green-shadow, $alpha: 0.5);
    }
  }
  .associatedButton {
    .joinButtons {
      // background-color: $white_1;
      // color: $gray_1;
      background-color: $gray_4;
      color: $green-shadow;
      border: 1px solid $green-shadow;

      &:hover {
        background-color: $white-dark;
        color: $black_1;
      }
    }
    .telegramButton {
      background-color: $white_1;
      svg path {
        fill: $color2;
      }
    }
  }
}

.breadcrumbsCommunity {
  margin-bottom: 45px;
}

.associated,
.notAssociated {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}

.communityHeader {
  display: flex;
  justify-content: space-between;
  position: relative;
  .community-title{
    position: absolute;
    left: 0;
    top: 18px;
  }
  
  h3 {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 30px;
    color: $black_1;
  }
  .communityList-search {
    width: 50%;
    margin: 40px auto 0px;

    .community-search-formGrp {
      display: flex;
      align-items: center;
      border-radius: 50px;
      padding: 10px 16px;
      background-color: $white;
      border: 1px solid rgba($color: #000000, $alpha: 0.2);

      &:focus-within {
        border: 1px solid $theme-color;
      }

      input {
        background-color: transparent;
        width: calc(100% - 50px);
        margin: 0 10px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }
      ::-webkit-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      :-ms-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      ::placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }
      .cancle-cause {
        cursor: pointer;
      }
      svg {
        width: 14px;
        height: 14px;

        fill: gray;
      }
    }
  }
}

.communityheader {
  h3 {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 30px;
    color: $black_1;
  }
  .communityList-search {
    width: 50%;
    margin: 40px auto 0px;

    .community-search-formGrp {
      display: flex;
      align-items: center;
      border-radius: 50px;
      padding: 10px 16px;
      background-color: $white;
      box-shadow: 0 4px 8px rgba($color: #000000, $alpha: 0.2);

      input {
        background-color: transparent;
        width: calc(100% - 50px);
        margin: 0 10px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }
      ::-webkit-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      :-ms-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      ::placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }
      .cancle-cause {
        cursor: pointer;
      }
      svg {
        width: 14px;
        height: 14px;

        fill: gray;
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .breadcrumbsCommunity {
    margin-bottom: 40px;
  }

  .communityList {
    margin-top: 80px;
    padding: 12px 0 55px;

    .communityTab {
      .react-tabs__tab {
        padding: 20px 0;
        font-size: 13px;
        &:first-of-type {
          padding-left: 52px;
          padding-right: 94px;
        }
        &:last-of-type {
          padding-right: 52px;
        }
      }
      .react-tabs__tab--selected {
        font-size: 14px;
      }
    }
  }

  .communityHeader {
    h3 {
      font-size: 24px;
      top: 15px;
    }
  }

  .communityheader {
    h3 {
      font-size: 24px;
    }
  }

  .associated,
  .notAssociated {
    width: 900px;
    margin: 0 auto;
    padding: 7px;

    .communityCard {
      width: 280px;
      padding: 20px 16px;
      margin-right: 22px;
      margin-top: 28px;
      &:nth-of-type(4n + 0) {
        margin-right: 20px;
      }
      &:nth-of-type(3n + 0) {
        margin-right: 0px;
      }

      &:nth-of-type(12) {
        margin-right: 0;
      }

      .communityImage {
        margin-bottom: 13px;
        .mainImage {
          height: 125px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 23px;
          font-size: 15px;
        }
        .communityDetails {
          h5 {
            width: 105px;
            font-size: 13px;
            margin-right: 4px;
          }
          ul {
            width: auto !important;
            li {
              margin-left: 5px;
              span {
                width: auto;
                font-size: 13px;
              }
              img {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        .member {
          margin-top: 8px;
          .member-list {
            .member-images {
              figure {
                width: 23px;
                height: 23px;
              }
            }
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          margin-top: 34px;

          .communityBasket {
            width: 110px;
            font-size: 13px;
            padding: 9px;
            margin-right: 8px;
          }

          .joinButtons {
            width: 60px;
            padding: 9px;
            margin-right: 8px;
          }
          .telegramButton {
            width: 55px;
            padding: 10px 20px 7px 18px;
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .breadcrumbsCommunity {
    margin-bottom: 35px;
  }

  .communityList {
    padding: 12px 0 65px;

    .communityTab {
      .react-tabs__tab {
        padding: 18px 0;
        &:first-of-type {
          padding-left: 50px;
          padding-right: 90px;
        }
        &:last-of-type {
          padding-right: 50px;
        }
      }
      .react-tabs__tab--selected {
        font-size: 13px;
      }
    }
  }

  .associated,
  .notAssociated {
    width: 858px;
    padding: 7px;

    .communityCard {
      width: 268px;
      padding: 20px 16px;
      margin-right: 20px;
      margin-top: 25px;
      &:nth-of-type(4n + 0) {
        margin-right: 20px;
      }
      &:nth-of-type(3n + 0) {
        margin-right: 0px;
      }

      .communityImage {
        margin-bottom: 12px;
        .mainImage {
          height: 120px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 22px;
          font-size: 15px;
        }
        .communityDetails {
          h5 {
            width: 100px;
            font-size: 13px;
            margin-right: 4px;
          }
          ul {
            width: auto !important;
            li {
              margin-left: 5px;
              span {
                width: auto;
                font-size: 13px;
              }
              img {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        .member {
          margin-top: 8px;
          .member-list {
            .member-images {
              figure {
                width: 23px;
                height: 23px;
              }
            }
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          margin-top: 30px;
          .communityBasket {
            width: 105px;
            padding: 9px;
            margin-right: 8px;
          }
          .joinButtons {
            width: 60px;
            padding: 8px;
            font-size: 13px;
            margin-right: 8px;
          }
          .telegramButton {
            width: 55px;
            padding: 10px 20px 7px 18px;
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .breadcrumbsCommunity {
    margin-bottom: 28px;
  }

  .communityList {
    padding: 10px 0 55px;

    .communityTab {
      .react-tabs__tab {
        padding: 14px 0;
        font-size: 12px;
        &:first-of-type {
          padding-left: 40px;
          padding-right: 70px;
        }
        &:last-of-type {
          padding-right: 40px;
        }
      }
      .react-tabs__tab--selected {
        font-size: 12px;
      }
    }
  }

  .communityHeader {
    h3 {
      font-size: 20px;
      top: 10px;
    }
    .communityList-search {
      width: 80%;
    }
  }

  .communityheader {
    h3 {
      font-size: 24px;
    }

    .communityList-search {
      width: 80%;
    }
  }

  .associated,
  .notAssociated {
    width: 650px;
    padding: 3px;

    .communityCard {
      width: 204px;
      padding: 16px 12px;
      margin-right: 15px;
      margin-top: 16px;
      &:nth-of-type(4n + 0) {
        margin-right: 15px;
      }
      &:nth-of-type(12) {
        margin-right: 0;
      }
      &:nth-of-type(24) {
        margin-right: 0;
      }
      &:nth-of-type(36) {
        margin-right: 0;
      }

      .communityImage {
        margin-bottom: 10px;
        .mainImage {
          height: 110px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 20px;
          font-size: 14px;
        }
        .communityDetails {
          h5 {
            width: 90px;
            font-size: 12px;
            margin-right: 3px;
          }
          ul {
            li {
              margin-left: 4px;
              span {
                font-size: 12px;
              }
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
        .member {
          margin-top: 7px;
          .member-list {
            .member-images {
              figure {
                width: 21px;
                height: 21px;
              }
            }
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          margin-top: 22px;
          .communityBasket {
            width: 80px;
            padding: 5px 0;
            margin-right: 6px;
          }
          .joinButtons {
            width: 48px;
            padding: 5px 0;
            margin-right: 6px;
          }
          .telegramButton {
            width: 40px;
            padding: 4px 13px;
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .breadcrumbsCommunity {
    margin-bottom: 20px;
  }

  .communityList {
    padding: 10px 0 40px;

    .communityTab {
      .react-tabs__tab {
        padding: 10px 0;
        font-size: 11px;
        &:first-of-type {
          padding-left: 30px;
          padding-right: 50px;
        }
        &:last-of-type {
          padding-right: 30px;
        }
      }
      .react-tabs__tab--selected {
        font-size: 11px;
      }
    }
  }

  .communityHeader {
    h3 {
      font-size: 16px;
      top: 9px;
    }
  }

  .communityheader {
    h3 {
      font-size: 24px;
    }
  }

  .associated,
  .notAssociated {
    width: 440px;
    padding: 4px;

    .communityCard {
      width: 208px;
      padding: 12px 10px;
      margin-right: 14px;
      margin-top: 12px;
      &:nth-of-type(4n + 0) {
        margin-right: 14px;
      }
      &:nth-of-type(3n + 0) {
        margin-right: 14px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }

      .communityImage {
        margin-bottom: 8px;
        .mainImage {
          height: 90px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 18px;
          font-size: 13px;
        }
        .communityDetails {
          h5 {
            width: 85px;
            font-size: 11px;
            margin-right: 2px;
          }
          ul {
            li {
              margin-left: 3px;
              span {
                font-size: 11px;
              }
              img {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
        .member {
          margin-top: 5px;
          .member-list {
            .member-images {
              figure {
                width: 20px;
                height: 20px;
              }
            }
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          margin-top: 18px;
          .communityBasket {
            width: 90px;
            padding: 5px 0;
            margin-right: 6px;
          }
          .joinButtons {
            width: 46px;
            padding: 5px 0;
            margin-right: 6px;
          }
          .telegramButton {
            width: 40px;
            padding: 4px 13px;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .breadcrumbsCommunity {
    margin-bottom: 15px;
  }

  .communityList {
    padding: 10px 0 15px;

    .communityTab {
      .react-tabs__tab {
        padding: 8px 0;
        font-size: 11px;
        &:first-of-type {
          padding-left: 20px;
          padding-right: 40px;
        }
        &:last-of-type {
          padding-right: 20px;
        }
      }
      .react-tabs__tab--selected {
        font-size: 11px;
      }
    }
  }

  .communityHeader {
    flex-direction: column;
    h3 {
      font-size: 14px;
      top: 9px;
      margin-bottom: 15px;
      position: static;
    }
    .communityList-search {
      width: 98%;
    }
  }

  .communityheader {
    h3 {
      font-size: 20px;
    }
    .communityList-search {
      width: 98%;
    }
  }

  .associated,
  .notAssociated {
    width: 100%;
    padding: 2px;
    justify-content: center;

    .communityCard {
      width: 100%;
      padding: 12px 10px;
      margin-right: 0px;
      margin-top: 12px;
      flex-shrink: 1;
      &:nth-of-type(4n + 0) {
        margin-right: 0px;
      }
      &:nth-of-type(3n + 0) {
        margin-right: 0px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }

      .communityImage {
        margin-bottom: 6px;
        .mainImage {
          height: 120px;
        }
      }
      .communityListContent {
        h4 {
          min-height: 17px;
          font-size: 13px;
        }
        .communityDetails {
          h5 {
            width: 80px;
          }
          ul {
            li {
              margin-left: 2px;
            }
          }
        }
        .member {
          margin-top: 4px;
          .member-list {
            .member-count {
              font-size: 11px;
            }
          }
        }
        .communityButtons {
          display: flex;
          justify-content: center;
          margin-top: 15px;
          .communityBasket {
            width: 92px;
            padding: 5px 0;
            margin-right: 6px;
          }
          .joinButtons {
            width: 50px;
            padding: 6px 0;
            margin-right: 5px;
          }
          .telegramButton {
            width: 42px;
            padding: 0px 13px 0px 11px;
          }
        }
      }
    }
  }
}
