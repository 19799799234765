@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.input-error {
  display: flex;
  justify-content: end;
  text-align: right;

  span {
    font-family: $font_jost;
    font-size: 14px;
    color: $red;
  }
}

.requiredField {
  border: 1px solid red;
  display: flex;
  align-items: center;
  border-radius: 7px;
  padding-right: 14px;

  select {
    width: 100%;
    border-radius: 7px;
    padding: 9px 25px 8px 14px;
    background-color: $white;
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    color: $light-gray;
    // border: 1px solid red;
  }

  input {
    // width set for profile details birthday error
    width: calc(100% - 20px);
    padding: 9px 13px 8px 16px;
    background-color: $white;
    border-radius: 7px;
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    color: $light-gray;
  }
}

// responsive
@include responsive(lg) {
  .input-error {
    span {
      font-size: 13px;
    }
  }
}

@include responsive(md) {
  .input-error {
    span {
      font-size: 12px;
    }
  }
}

@include responsive(sm) {
  .input-error {
    span {
      font-size: 11px;
    }
  }
}
