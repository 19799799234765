@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.terms {
  width: 100%;
  margin-top: 85px;
  padding: 13px 0 71px;
}

.breadcrumbsTerms {
  margin-bottom: 45px;
}

.pageTitle {
  font-family: $font_lora;
  font-weight: 700;
  font-size: 30px;
  color: $black_1;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.termCondition {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  padding: 32px 56px 40px 28px;
}

.para {
  font-family: $font_jost;
  font-weight: 400;
  font-size: 16px;
  color: $light-gray;
  line-height: 30px;
  letter-spacing: 0.04em;
  margin-bottom: 20px;
  word-break: break-all;
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
}

.paraTitle {
  font-family: $font_jost;
  font-weight: 700;
  font-size: 16px;
  color: $light-gray;
  text-transform: capitalize;
  letter-spacing: 0.04em;
  margin-bottom: 10px;
}

.updateDate {
  font-family: $font_jost;
  font-weight: 700;
  font-size: 16px;
  color: $light-gray;
  text-transform: capitalize;
  margin-top: 10px;
}

// responsive
@include responsive(xl) {
  .terms {
    margin-top: 80px;
    padding: 12px 0 68px;
  }

  .breadcrumbsTerms {
    margin-bottom: 43px;
  }

  .pageTitle {
    font-size: 28px;
  }

  .termCondition {
    padding: 30px 54px 38px 26px;
  }

  .para {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 18px;
  }

  .paraTitle {
    font-size: 15px;
    margin-bottom: 9px;
  }

  .updateDate {
    font-size: 15px;
    margin-top: 9px;
  }
}

@include responsive(lg) {
  .terms {
    padding: 12px 0 64px;
  }

  .breadcrumbsTerms {
    margin-bottom: 40px;
  }

  .pageTitle {
    font-size: 24px;
  }

  .termCondition {
    padding: 28px 52px 36px 24px;
  }

  .para {
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 16px;
  }

  .paraTitle {
    font-size: 15px;
    margin-bottom: 8px;
  }

  .updateDate {
    font-size: 15px;
    margin-top: 8px;
  }
}

@include responsive(md) {
  .terms {
    padding: 10px 0 40px;
  }

  .breadcrumbsTerms {
    margin-bottom: 30px;
  }

  .pageTitle {
    font-size: 22px;
  }

  .termCondition {
    padding: 25px;
  }

  .para {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .paraTitle {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .updateDate {
    font-size: 14px;
    margin-top: 8px;
  }
}

@include responsive(sm) {
  .terms {
    padding: 10px 0 30px;
  }

  .breadcrumbsTerms {
    margin-bottom: 20px;
  }

  .pageTitle {
    font-size: 18px;
  }

  .termCondition {
    padding: 20px;
  }

  .para {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .paraTitle {
    font-size: 13px;
    margin-bottom: 8px;
  }

  .updateDate {
    font-size: 13px;
    margin-top: 8px;
  }
}

@include responsive(xs) {
  .terms {
    padding: 10px 0 15px;
  }

  .breadcrumbsTerms {
    margin-bottom: 15px;
  }

  .pageTitle {
    font-size: 16px;
  }

  .termCondition {
    padding: 15px;
  }

  .para {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .paraTitle {
    font-size: 12px;
    margin-bottom: 7px;
  }

  .updateDate {
    font-size: 12px;
    margin-bottom: 7px;
  }
}
