@import "../../scss/style.scss";

.section-step {
  min-height: 100vh;
  background-color: rgba(66, 164, 76, 0.1);
  position: relative;

  .steps-main-div {
    padding: 0 180px;
    min-height: 100vh;
  }

  button span {
    font-family: $font_jost;
  }

  .form-box {
    h2 {
      color: rgba(66, 164, 76, 0.85);
      margin-bottom: 45px;
      font-weight: 700;
      text-align: center;
    }

    &.step-form-box {
      padding: 0;
    }
  }

  &.header-section {
    padding: 30px 0;
    min-height: inherit;

    .col-section {
      display: block;
    }

    .section-div {
      padding-top: 50px;
      min-height: inherit;
    }

    .inner-div {
      text-align: center;
      margin-top: 50px;

      h2 {
        margin-bottom: 15px;
        font-family: $font_jost;
        font-weight: 500;
        color: $green-shadow;
        font-size: 33px;
      }

      p {
        font-family: $font_jost;
        font-size: 23px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.37);
        width: 50%;
        margin: 0 auto;
      }
    }
  }

  .col-section {
    display: none;
  }

  strong {
    width: 100%;
    display: block;
    font-family: $font_jost;
    font-size: 14px;
    text-align: center;
    color: $light-gray;
    font-weight: 400;
    margin-top: 50px;
  }
}

.charityHeader {
  h2 {
    font-family: $font_jost;
    font-weight: 700;
    font-size: 33px;
    color: $green-shadow;
    text-align: center;
  }
}

.headerRosi {
  margin-bottom: 40px;
  a {
    display: inline-block;
  }
}

.label-text {
  display: block;
  font-family: $font_jost;
  margin: 20px 0 10px;
  color: $black_1;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize;
  line-height: 20px;

  span {
    color: red;
    display: inline-block;
    margin-right: 5px;
  }
}

.navigator {
  font-family: $font_jost;
  font-weight: 600;
  font-size: 14px;
  color: $green-shadow;
}

.stepForm {
  li {
    margin: 0 auto;
    width: 50%;
    position: relative;

  .tooltip-information{
        position: absolute;
        top: 5px;
        right: -5%;
        cursor: pointer;
    }
  }
}

.input-list {
  flex-wrap: wrap;
  margin-bottom: 22px;

  .nonprofileAddress {
    .nonAddress {
      margin-top: 15px;
    }
  }

  li {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }

    .textarea {
      width: 100%;
      textarea {
        width: 100%;
        height: 70px;
        resize: none;
        padding: 10px;
        border-radius: 7px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .formGrp {
      flex-direction: row-reverse;
      padding-left: 0px !important;
    }
    .form-grp {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      vertical-align: top;
      background-color: $white;
      // padding-left: 14px;

      svg {
        margin-left: 14px;
      }

      .inputAddons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        border: 1px solid #d9d9d9;
        padding: 8px 6px;
        border-radius: 5px;
        background-color: $white;
        span {
          color: rgba($color: #000000, $alpha: 0.85);
          font-family: $font_jost;
          font-weight: 400;
        }
      }

      &.col-grp {
        width: 49%;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        margin-top: 10px;

        &:last-child {
          margin-right: 0;
        }

        &:nth-child(n + 2) {
          margin-right: 0;
        }
      }
    }

    .inner-form-list {
      display: flex;
      width: 100%;

      .gender-formGrp {
        width: 50%;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
        .form-grp {
          width: 225px;
          margin-right: 0 !important;
        }
      }
      .marginClass {
        margin-right: 8px !important;
      }
    }

    .input-box {
      height: 40px;
      width: 100%;
      font-family: "Jost", sans-serif;
      font-weight: 400;
      // display: inline-block;
      // line-height: 35px;
      // padding: 10px 20px;
      border-radius: 5px;
      // border: 1px solid #d9d9d9;
      text-align: center;
      font-size: 15px;
      color: $black_1;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 15px;
      color: $light-gray;
    }

    :-ms-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 15px;
      color: $light-gray;
    }

    ::placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 15px;
      color: $light-gray;
    }
  }
}

.link-btn {
  display: inline-block;
  width: 100%;
  height: 40px;
  text-transform: capitalize;
  line-height: 40px;
  text-align: center;
  background-color: $theme-color;
  color: $white;
  @include prefix(box-shadow, 0px 2px 0px rgba(0, 0, 0, 0.043));
  @include prefix(transition, all 0.4s ease-in-out);
  border: 1px solid #42a44c;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;

  &.back {
    width: 80px;
  }

  &:hover {
    background-color: transparent;
    color: #42a44c;
  }
}

.inner-div {
  text-align: center;
  margin-bottom: 25px;

  h2 {
    font-family: $font_jost;
    font-weight: 500;
    font-size: 33px;
    color: $green-shadow;
  }

  p {
    font-family: $font_jost;
    font-size: 23px;
    color: rgba($color: $light-gray, $alpha: 0.8);
  }
}

.figure-img {
  width: 425px;
  height: 450px;
  display: block;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.header-link-box {
  .link-text {
    font-size: 33px;
    display: inline-block;
    color: #42a44c;
    font-family: "Lora-Bold", sans-serif;
  }
}

.right-list-box {
  text-align: right;

  ul {
    li {
      margin-right: 35px;
      display: inline-block;

      a {
        font-family: $font_jost;
        color: $green-shadow;
        display: inline-block;
        font-weight: 400;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          color: $theme-color;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.rating-innerbox {
  margin-top: 10px;

  .inner-boxs {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    label {
      margin-bottom: 0;
      width: 20%;
      text-align: left;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .star-ratings {
      width: 60%;
    }
    .counting-wrap {
      width: 20%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .reg-addon-rating {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 7px;
        border: 1px solid rgba(0, 0, 0, 0.15);

        &:focus-within {
          border: 1px solid $theme-color;
        }

        strong {
          width: 20%;
          margin-top: 0;
          border-left: 1px solid rgba(0, 0, 0, 0.15);
          padding: 5px 10px;
          background-color: $theme-color;
          border-radius: 0 7px 7px 0;
          color: $white;
        }
      }
      .rating-error {
        width: 100%;
        color: $red;
        font-weight: 400;
        text-align: right;
      }

      .percentage-counting {
        width: calc(100% - 20px);
        font-family: $font_jost;
        padding: 5px 0px !important;
        text-align: center;
        color: $black_1;
        border: none;
        background-color: $white;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
  // .rating-score-name {
  //   display: inline-block;
  //   margin-bottom: 15px;
  // }
}

// check-box
.check-label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  font-family: $font_jost;
  color: $black_1;
  @include prefix(user-select, none);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark:after {
        display: block;
      }

      ~ .checkmark {
        background-color: $green-shadow;
      }
    }
  }

  .checkmark {
    &:after {
      left: 3px;
      top: 0px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      @include prefix(transform, rotate(45deg));
    }
  }
}

.rememberReg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ratings-reg {
  font-weight: 700;
  color: $black_1;
  font-family: $font_jost;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background-color: transparent;
  border: 1px solid $gray;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

.registrationErrorModal {
  max-width: 450px;
  position: absolute;
  z-index: 7777;
  top: 50px;
  background-color: $light-green;
  right: 50px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  .registrationErrorModalWrap {
    max-height: 120px;
    padding: 10px 10px 10px 25px;
    ul li {
      font-family: $font_jost;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      list-style: disc;
      color: $red;
    }
  }
}

.errorProfile {
  flex-direction: row;
  background-color: $white;
  // z-index: 555;
  // height: 400px;
  // overflow: auto;

  li h3 {
    margin-bottom: 0;
  }
}

// responsive
@include responsive(xl) {
  .headerRosi {
    margin-bottom: 38px;
    a {
      width: 128px;
      height: 73px;
    }
  }

  .charityHeader {
    h2 {
      font-size: 30px;
    }
  }

  .section-step {
    .steps-main-div {
      padding: 0 150px;
    }

    strong {
      font-size: 14px;
      margin-top: 46px;
    }
  }

  .input-list {
    margin-bottom: 21px;

    li {
      margin-bottom: 26px;

      .checkbox-list {
        .check-label {
          margin-right: 35px;
        }
      }

      .form-grp {
        svg {
          margin-left: 13px;
        }

        .input-box {
          height: 38px;
          font-size: 14px;
        }
        ::-webkit-input-placeholder {
          font-size: 13px;
        }

        :-ms-input-placeholder {
          font-size: 13px;
        }

        ::placeholder {
          font-size: 13px;
        }

        .inputAddons {
          width: 48px;
          padding: 8px 6px;
          border-top: none;
          border-bottom: none;
          border-left: none;
          span {
            font-size: 15px;
          }
        }
      }

      .textarea {
        textarea {
          height: 68px;
          padding: 9px;
          font-size: 14px;
        }
      }

      .inner-form-list {
        .form-grp {
          &.col-grp {
            width: 100%;
            margin-right: 0;
            margin-top: 9px;
          }
        }
        .marginClass {
          margin-right: 0px !important;
        }
      }
    }
  }

  .inner-div {
    margin-bottom: 23px;
  }

  .check-label {
    padding-left: 24px;
    font-size: 13px;

    .checkmark::after {
      width: 3px;
      height: 7px;
    }
  }

  .checkmark {
    width: 15px;
    height: 15px;
  }

  .label-text {
    margin-bottom: 15px;
    font-size: 14px !important;

    span {
      margin-right: 4px;
    }
  }

  .rating-innerbox {
    .inner-boxs {
      margin-bottom: 14px;

      label {
        width: 20%;
      }

      .star-ratings {
        width: 60%;
      }
      .counting-wrap {
        width: 20%;

        .reg-addon-rating {
          strong {
            width: 20%;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  .registrationErrorModal {
    max-width: 430px;
    top: 45px;
    right: 45px;
    .registrationErrorModalWrap {
      max-height: 115px;
      padding: 9px 9px 9px 22px;
      ul li {
        font-size: 15px;
        line-height: 23px;
      }
    }
  }

  .figure-img {
    width: 100%;
    height: 400px;
  }
}

@include responsive(lg) {
  .headerRosi {
    margin-bottom: 36px;
    a {
      width: 125px;
      height: 70px;
    }
  }

  .charityHeader {
    h2 {
      font-size: 28px;
    }
  }

  .section-step {
    .steps-main-div {
      padding: 0 120px;
    }

    strong {
      font-size: 14px;
      margin-top: 42px;
    }
  }

  .input-list {
    margin-bottom: 19px;

    li {
      margin-bottom: 24px;

      .checkbox-list {
        .check-label {
          margin-right: 20px;
          font-size: 13px;
        }
      }

      .form-grp {
        svg {
          margin-left: 12px;
        }

        .input-box {
          height: 36px;
          font-size: 14px;
        }
        ::-webkit-input-placeholder {
          font-size: 12px;
        }

        :-ms-input-placeholder {
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }

        .inputAddons {
          width: 47px;
          padding: 8px 6px;
          span {
            font-size: 14px;
          }
        }
      }

      .textarea {
        textarea {
          height: 66px;
          padding: 8px;
          font-size: 14px;
        }
      }

      .inner-form-list {
        flex-wrap: wrap;
        .gender-formGrp {
          width: 100%;
          margin-right: 0;
          .form-grp {
            width: 100%;
          }
        }
        .form-grp {
          &.col-grp {
            width: 100%;
            margin-right: 0;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .inner-div {
    margin-bottom: 22px;
  }

  .check-label {
    padding-left: 22px;
    font-size: 13px;

    .checkmark::after {
      width: 3px;
      height: 7px;
    }
  }

  .checkmark {
    width: 15px;
    height: 15px;
  }

  .label-text {
    margin-bottom: 8px;
    font-size: 14px !important;

    span {
      margin-right: 4px;
    }
  }

  .rating-innerbox {
    .inner-boxs {
      margin-bottom: 14px;

      label {
        width: 25%;
      }

      .star-ratings {
        width: 50%;
      }
      .counting-wrap {
        width: 25%;
      }
    }
  }

  .registrationErrorModal {
    max-width: 350px;
    top: 40px;
    right: 40px;
    .registrationErrorModalWrap {
      max-height: 115px;
      padding: 8px 8px 8px 20px;
      ul li {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .figure-img {
    width: 100%;
    height: 380px;
  }
}

@include responsive(md) {
  .headerRosi {
    margin-bottom: 32px;
    a {
      width: 120px;
      height: 65px;
    }
  }

  .charityHeader {
    h2 {
      font-size: 26px;
    }
  }

  .section-step {
    .steps-main-div {
      padding: 0px;
    }

    strong {
      font-size: 13px;
      margin-top: 38px;
    }
  }

  .input-list {
    margin-bottom: 18px;

    .checkbox-list {
      .check-label {
        margin-right: 20px;
        font-size: 13px;
      }
    }

    li {
      margin-bottom: 22px;

      .form-grp {
        svg {
          margin-left: 11px;
        }

        .input-box {
          height: 34px;
          font-size: 13px;
        }
        ::-webkit-input-placeholder {
          font-size: 12px;
        }

        :-ms-input-placeholder {
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }

        .inputAddons {
          width: 40px;
          padding: 7px 5px;
          span {
            font-size: 13px;
          }
        }
      }

      .textarea {
        textarea {
          height: 64px;
          padding: 7px;
          font-size: 13px;
        }
      }

      .inner-form-list {
        .form-grp {
          &.col-grp {
            margin-top: 7px;
          }
        }
      }
    }
  }

  .inner-div {
    margin-bottom: 20px;
  }

  .check-label {
    padding-left: 20px;
    font-size: 12px;

    .checkmark::after {
      width: 2px;
      height: 6px;
    }
  }

  .checkmark {
    width: 14px;
    height: 14px;
  }

  .label-text {
    margin-bottom: 7px;
    font-size: 13px !important;

    span {
      margin-right: 3px;
    }
  }

  .rating-innerbox {
    .inner-boxs {
      margin-bottom: 12px;

      label {
        width: 25%;
      }

      .star-ratings {
        width: 50%;
      }
      .counting-wrap {
        width: 25%;
      }
    }
  }

  .registrationErrorModal {
    max-width: 260px;
    top: 35px;
    right: 35px;
    .registrationErrorModalWrap {
      max-height: 100px;
      padding: 7px 7px 7px 18px;
      ul li {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .figure-img {
    height: 350px;
  }
}

@include responsive(sm) {
  .headerRosi {
    margin-bottom: 28px;
    a {
      width: 110px;
      height: 60px;
    }
  }

  .charityHeader {
    h2 {
      font-size: 24px;
    }
  }

  .section-step {
    strong {
      font-size: 12px;
      margin-top: 36px;
    }
  }

  .input-list {
    margin-bottom: 16px;

    .checkbox-list {
      flex-wrap: wrap;
    }

    li {
      margin-bottom: 20px;

      .form-grp {
        svg {
          margin-left: 10px;
        }

        .input-box {
          height: 32px;
          font-size: 12px;
        }
        ::-webkit-input-placeholder {
          font-size: 12px;
        }

        :-ms-input-placeholder {
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }

        .inputAddons {
          width: 38px;
          padding: 6px 5px;
          span {
            font-size: 12px;
          }
        }
      }

      .textarea {
        textarea {
          height: 62px;
          padding: 7px;
          font-size: 12px;
        }
      }

      .inner-form-list {
        .form-grp {
          &.col-grp {
            margin-top: 6px;
          }
        }
      }
    }
  }

  .inner-div {
    margin-bottom: 18px;
  }

  .check-label {
    padding-left: 18px;
    font-size: 12px;

    .checkmark::after {
      width: 2px;
      height: 6px;
    }
  }

  .checkmark {
    width: 13px;
    height: 13px;
  }

  .label-text {
    margin-bottom: 7px;
    font-size: 12px !important;

    span {
      margin-right: 3px;
    }
  }

  .rating-innerbox {
    .inner-boxs {
      margin-bottom: 10px;

      label {
        width: 30%;
      }

      .star-ratings {
        width: 40%;
      }
      .counting-wrap {
        width: 30%;
        .reg-addon-rating {
          strong {
            padding: 6px 10px;
          }
        }
      }
    }
  }

  .registrationErrorModal {
    max-width: 230px;
    top: 20px;
    right: 30px;
    .registrationErrorModalWrap {
      max-height: 86px;
      padding: 6px 6px 6px 16px;
      ul li {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .figure-img {
    height: 300px;
  }
}

@include responsive(xs) {
  .stepForm {
    li {
      width: 100%;
    }
  }

  .headerRosi {
    margin-bottom: 26px;
    a {
      width: 100px;
      height: 55px;
    }
  }

  .charityHeader {
    h2 {
      font-size: 22px;
    }
  }

  .section-step {
    strong {
      font-size: 10px;
      margin-top: 25px;
    }

    .steps-main-div > div {
      padding: 10px 0px !important;
    }
  }

  .input-list {
    margin-bottom: 15px;

    li {
      width: 100%;
      margin-bottom: 15px;

      .form-grp {
        svg {
          margin-left: 7px;
        }

        .input-box {
          height: 28px;
          font-size: 12px;
        }
        ::-webkit-input-placeholder {
          font-size: 12px;
        }

        :-ms-input-placeholder {
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }

        .inputAddons {
          width: 34px;
          padding: 5px;
          span {
            font-size: 12px;
          }
        }
      }

      .textarea {
        textarea {
          height: 58px;
          padding: 6px;
          font-size: 12px;
        }
      }

      .inner-form-list {
        .form-grp {
          &.col-grp {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .inner-div {
    margin-bottom: 15px;
  }

  .check-label {
    padding-left: 15px;
    font-size: 12px;

    .checkmark::after {
      width: 2px;
      height: 6px;
    }
  }

  .checkmark {
    width: 12px;
    height: 12px;
  }

  .label-text {
    margin-bottom: 6px;
    font-size: 12px !important;

    span {
      margin-right: 3px;
      margin-top: 4px;
    }
  }

  .rating-innerbox {
    .inner-boxs {
      &:first-of-type {
        display: none;
      }
      margin-bottom: 8px;
      flex-direction: column;
      label {
        width: 100%;
      }
      .star-ratings {
        width: 100%;
        margin-bottom: 8px;
      }
      .counting-wrap {
        width: 100%;
        .reg-addon-rating {
          strong {
            font-size: 12px;
          }
        }
      }
    }
  }

  .registrationErrorModal {
    max-width: 165px;
    top: 15px;
    right: 15px;
    .registrationErrorModalWrap {
      max-height: 80px;
      padding: 5px 5px 5px 15px;
      ul li {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }

  #RFS-StepButton {
    width: 25px;
    height: 25px;
  }

  #RFS-Label {
    font-size: 12px;
    margin-top: 10px;
  }

  .figure-img {
    height: 200px;
  }
}
