@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.transactionDetail {
  h3 {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 30px;
    color: $black_1;
  }
  .transactionDetailWrap {
    margin-top: 20px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    select{
      font-family: $font_jost;
    }
    .transactionDetailHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 27px 23px 19px;
      background-color: $theme-color;
    }
    .transactionFormGroup {
      width: 270px;
      display: flex;
      padding-right: 16px;
      justify-content: space-between;
      align-items: center;
      border-radius: 50px;
      background-color: $white;

      input {
        width: 235px;
        padding: 12px 10px 10px 20px;
        background-color: transparent;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }
      ::-webkit-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      :-ms-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      ::placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }
      svg path {
        stroke: $gray;
      }
    }
    .transactionButtons {
      display: flex;
      align-items: center;
    }
    .generateButton {
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background-color: $black_1;
      padding: 10px 18px 10px 20px;
      margin-left: 20px;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);
      &:hover {
        background-color: $black;
      }
      button {
        background-color: transparent;
        svg {
          fill: black;
        }
      }
      span {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 14px;
        color: $white;
        text-transform: capitalize;
        margin-left: 10px;
      }
    }
    .transcationRange {
      min-width: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border-radius: 20px;
      padding: 12px 20px 10px 20px;
      cursor: pointer;
      span {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
        margin-left: 11px;
      }
      svg {
        fill: $green-shadow;
        stroke: $green-shadow;
      }
    }
    .transactionDetailBody > div > div > div > div > div:hover {
      background-color: $light-green;
      cursor: pointer;
    }
    .transactionDetailBody {
      .paymentColor {
        color: $red;
      }
      span {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 15px;
        color: $black_1;
      }
      h3 {
        width: 85px;
        font-family: $font_jost;
        font-weight: 700;
        font-size: 12px;
        color: $black_1;
      }
      .tableCount {
        min-width: 45px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        color: $white;
        background-color: $green-shadow;
        border-radius: 3px;
        text-align: center;
        padding: 4px 12px;
      }
      .download {
        background-color: transparent;
        cursor: pointer;
        display: flex;
        svg {
          fill: transparent;
          path {
            stroke: $green-shadow;
          }
        }
        .commonRound {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
        .whiteButton {
          background-color: $green-shadow;
          padding: 7px;
          svg {
            path,
            circle {
              stroke: $white;
            }
          }
        }
        .cancelSub {
          background-color: $red;
          padding: 7px;
          svg {
            path {
              stroke: $white;
            }
          }
        }
        button {
          background-color: transparent;
          margin-left: 3px;
          cursor: pointer;
          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
}
div:first-child {
  white-space: normal !important;
}
.WQNxq {
  max-height: 500px !important;
  overflow: auto !important;
  font-family: $font_jost;
  font-weight: 400;
  font-size: 16px;
}

.rangeModal {
  width: 500px;
  height: auto;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 30px 40px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
    }
    button {
      cursor: pointer;
      background-color: transparent;
    }
  }
  .modal-body {
    text-align: center;

    input,select{
      font-family: $font_jost !important;
    }
    optgroup{
      font-family: $font_jost !important;
    }
    .customDatePickerWidth{
      width: 100%;
    }
    .dateRange {
      margin: 0 auto;
      font-family: $font_jost;
      font-weight: 400;
      line-height: 21px;
    }
    .rdrDefinedRangesWrapper {
      display: none;
    }
    .rdrCalendarWrapper {
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
    .donationButtonGroup {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .againButton {
        width: 100px;
        background-color: $green-shadow;
        border-radius: 20px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        padding: 7px 23px 8px 22px;
        margin-left: 16px;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);
      }

      .doneButton {
        width: 100px;
        background-color: $black_1;
        border-radius: 20px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        padding: 8px 26px 7px 26px;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $black_1;
        }
      }
    }
  }
  .modal-footer {
    display: none;
  }
}

.donationdetailsModal {
  width: 750px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding: 30px;
    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
      line-height: 38px;
    }
    button {
      background-color: transparent;
      cursor: pointer;
    }
  }
  .modal-body {
    padding-bottom: 20px;
    background-color: $white;

    .donationMainModalDetails {
      display: flex;
    }
    .body-leftDetails {
      width: 30%;
      background-color: $theme-color;
      ul {
        li {
          margin: 20px 20px 0 20px;
          padding-bottom: 25px;
          border-bottom: 1px solid $white;
          &:last-of-type {
            border-bottom: none;
          }
          h1 {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 15px;
            color: $white;
            text-transform: capitalize;
            margin-bottom: 5px;
          }
          h3 {
            font-family: $font_jost;
            font-weight: 700;
            font-size: 18px;
            color: $white;
            text-transform: capitalize;
          }
        }
      }
    }
    .scrollbar-container.ps {
      width: 70%;
    }
    .donationModalScroll {
      max-height: 460px;
    }
    .body-rightDetails {
      .modalCause {
        display: flex;
        align-items: center;
        background-color: $light-green;
        padding-bottom: 20px;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;

        &:nth-of-type(2n + 0) {
          background-color: $white;
        }

        figure {
          width: 120px;
          height: 60px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .modalCauseDetails {
          width: 200px;
          margin: 0 50px;
          h3 {
            font-family: $font_jost;
            font-weight: 700;
            font-size: 12px;
            line-height: 25px;
            color: $theme-color;
            text-transform: uppercase;
          }
          p {
            font-family: $font_jost;
            font-weight: 700;
            font-size: 14px;
            color: $black_1;
            line-height: 25px;
            text-transform: capitalize;
          }
        }

        .donationCountButton {
          span {
            display: inline-block;
            width: 73px;
            height: 33px;
            border-radius: 3px;
            background-color: $theme-color;
            text-align: center;
            color: $white;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            padding: 4px 0;
          }
        }
      }
    }
  }
  .updateDonationButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    a {
      background-color: $theme-color;
      width: 180px;
      text-align: center;
      border-radius: 50px;
      font-size: 18px;
      color: $white;
      font-family: $font_jost, sans-serif;
      padding: 15px 0;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);
      &:hover {
        background-color: $green-shadow;
      }
    }

    h5 {
      color: #d33520;
      font-weight: 700;
    }
  }
  .modal-footer {
    display: none;
  }
}

.subscriptionModal {
  width: 500px;
  height: auto;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 30px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
    button {
      background-color: transparent;
      cursor: pointer;
    }
  }
  .modal-body {
    .subBodyDetails {
      margin-bottom: 25px;
      p {
        font-family: $font_jost;
        font-weight: 500;
        font-size: 28px;
        // text-transform: capitalize;
        color: $black_1;
        text-align: center;
      }
    }
    .subButtonGrp {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button,
      a {
        width: 150px;
        border-radius: 20px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        text-transform: capitalize;
        color: $white;
        text-align: center;
        padding: 7px 23px 8px 22px;
        margin-right: 16px;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);
      }
      button {
        background-color: $green-shadow;
        &:hover {
          background-color: $theme-color;
        }
      }
      .subCancel {
        background-color: $black_1;
        &:hover {
          background-color: $black;
        }
      }
    }
  }
  .modal-footer {
    display: none;
  }
}

.updateSub {
  width: 500px;
  height: auto;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 30px 40px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
    }
    button {
      cursor: pointer;
      background-color: transparent;
    }
  }
  .modal-body {
    .updateForm {
      ul li {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 30px;
          .forms {
            .form-group {
              flex-direction: row-reverse;
              padding-left: 15px;
              padding-right: 0;
              justify-content: flex-start;
              span h5 {
                font-family: Jost, sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: #4f4f4f;
                margin-right: 5px;
              }
              input {
                padding: 9px 30px 8px 0px;
              }
            }
          }
        }
        label {
          display: inline-block;
          font-family: $font_jost;
          text-transform: capitalize;
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 3px;
          color: $black_1;
        }
        .forms {
          .form-group {
            border: 1px solid $light-gray;
            border-radius: 7px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 15px;

            input {
              width: 386px;
              border-radius: 7px;
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              color: $light-gray;
              padding: 9px 30px 8px 14px;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input[type="number"] {
              -moz-appearance: textfield;
            }
          }
        }
      }
    }
    .modalNote {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
      color: $black_1;
      font-weight: 700;
      text-transform: capitalize;
    }
    .subButtonGrp {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button,
      a {
        width: 150px;
        border-radius: 20px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        text-transform: capitalize;
        color: $white;
        text-align: center;
        padding: 7px 23px 8px 22px;
        margin-right: 16px;
        cursor: pointer;
        @include prefix(transition, all 0.3s ease-in-out);
      }
      button {
        background-color: $green-shadow;
        &:hover {
          background-color: $theme-color;
        }
      }
      .subCancel {
        background-color: $black_1;
        &:hover {
          background-color: $black;
        }
      }
    }
  }
  .modal-footer {
    display: none;
  }
}

// responsive
@include responsive(xl) {
  .transactionDetail {
    h3 {
      font-size: 28px;
    }
    .transactionDetailWrap {
      margin-top: 18px;
      .transactionDetailHeader {
        padding: 22px 25px 20px 18px;
      }
      .transactionFormGroup {
        width: 200px;
        padding-right: 12px;

        input {
          width: 172px;
          padding: 10px;
          font-size: 13px;
        }
        ::-webkit-input-placeholder {
          font-size: 13px;
        }

        :-ms-input-placeholder {
          font-size: 13px;
        }

        ::placeholder {
          font-size: 13px;
        }
      }
      .generateButton {
        width: 58px;
        padding: 8px 15px 8px 16px;
        margin-left: 18px;
        // justify-content: start;
        button {
          flex-shrink: 0;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        span {
          font-size: 13px;
          margin-left: 8px;
        }
      }
      .transcationRange {
        width: 136px;
        padding: 10px 18px 8px 18px;
        justify-content: flex-start;
        span {
          font-size: 13px;
          margin-left: 10px;
        }
      }
      .transactionDetailBody {
        span {
          font-size: 14px;
        }
        h3 {
          width: 81px;
          font-size: 14px;
        }
        .tableCount {
          min-width: 40px;
          font-size: 14px;
          padding: 3px 10px;
        }
        .download {
          .commonRound {
            width: 25px;
            height: 25px;
          }
          .whiteButton {
            padding: 7px;
          }
          .cancelSub {
            padding: 7px;
          }
          button {
            margin-left: 3px;
          }
        }
      }
    }
  }
  .WQNxq {
    max-height: 425px !important;
    font-size: 14px;
  }
  .rangeModal {
    width: 580px;
    padding: 36px 55px;

    .modal-header {
      margin-bottom: 44px;
      h3 {
        font-size: 28px;
      }
    }
    .modal-body {
      .dateRange {
        line-height: 20px;
      }
      .rangeModalDate {
        padding-right: 10px;
      }
    }
  }
  .subscriptionModal {
    width: 450px;
    padding: 28px;

    .modal-header {
      margin-bottom: 18px;
    }
    .modal-body {
      .subBodyDetails {
        margin-bottom: 22px;
        p {
          font-size: 26px;
        }
      }
      .subButtonGrp {
        button,
        a {
          width: 140px;
          font-size: 15px;
          padding: 7px 20px 8px;
          margin-right: 15px;
        }
      }
    }
  }
  .updateSub {
    width: 480px;
    padding: 28px 38px;

    .modal-header {
      margin-bottom: 28px;
      h3 {
        font-size: 28px;
      }
    }
    .modal-body {
      .updateForm {
        ul li {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 28px;
            .forms {
              .form-group {
                padding-left: 14px;
                span h5 {
                  margin-right: 4px;
                }
                input {
                  width: 375px;
                  padding: 9px 28px 8px 0;
                }
              }
            }
          }
          label {
            font-size: 16px;
          }
          .forms {
            .form-group {
              padding-right: 14px;

              input {
                width: 370px;
                padding: 8px 28px 8px 14px;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .transactionDetail {
    h3 {
      font-size: 25px;
    }
    .transactionDetailWrap {
      margin-top: 16px;
      .transactionDetailHeader {
        padding: 20px;
      }
      .transactionFormGroup {
        width: 170px;
        padding-right: 10px;

        input {
          width: 145px;
          padding: 8px;
          font-size: 13px;
        }
        ::-webkit-input-placeholder {
          font-size: 11px;
        }

        :-ms-input-placeholder {
          font-size: 11px;
        }

        ::placeholder {
          font-size: 11px;
        }
      }
      .generateButton {
        width: 40px;
        padding: 7px 12px 7px 14px;
        margin-left: 7px;
        button {
          flex-shrink: 0;
          svg {
            width: 14px;
            height: 14px;
          }
        }
        span {
          font-size: 12px;
          margin-left: 6px;
        }
      }
      .transcationRange {
        width: 118px;
        padding: 8px 15px;
        span {
          font-size: 12px;
          margin-left: 8px;
        }
        svg {
          width: 14px;
          height: 14px;
        }
      }
      .transactionDetailBody {
        span {
          font-size: 13px;
        }
        h3 {
          width: 75px;
          font-size: 13px;
        }
        .tableCount {
          min-width: 40px;
          font-size: 13px;
          padding: 3px 8px;
        }
        .download {
          .commonRound {
            width: 25px;
            height: 25px;
          }
          .whiteButton {
            padding: 7px;
          }
          .cancelSub {
            padding: 7px;
          }
          button {
            margin-left: 3px;
          }
        }
      }
    }
  }
  .WQNxq {
    max-height: 412px !important;
    font-size: 13px;
  }
  .rangeModal {
    width: 550px;
    padding: 34px 50px;

    .modal-header {
      margin-bottom: 40px;
      h3 {
        font-size: 26px;
      }
    }
    .modal-body {
      .dateRange {
        line-height: 18px;
      }
      .rangeModalDate {
        padding-right: 0px;
      }
    }
  }
  .subscriptionModal {
    width: 430px;
    padding: 26px;

    .modal-header {
      margin-bottom: 16px;
    }
    .modal-body {
      .subBodyDetails {
        margin-bottom: 20px;
        p {
          font-size: 24px;
        }
      }
      .subButtonGrp {
        button,
        a {
          width: 130px;
          font-size: 14px;
          padding: 7px 18px;
          margin-right: 14px;
        }
      }
    }
  }
  .updateSub {
    width: 470px;
    padding: 26px 35px;
    .modal-header {
      margin-bottom: 26px;
      h3 {
        font-size: 26px;
      }
    }
    .modal-body {
      .updateForm {
        ul li {
          &:last-child {
            margin-bottom: 26px;
            .forms {
              .form-group {
                padding-left: 13px;
                input {
                  padding: 8px 26px 8px 0;
                }
              }
            }
          }
          label {
            font-size: 15px;
          }
          .forms {
            .form-group {
              padding-right: 13px;

              input {
                width: 360px;
                padding: 8px 26px 8px 12px;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .transactionDetail {
    h3 {
      font-size: 20px;
    }
    .transactionDetailWrap {
      margin-top: 15px;
      .transactionDetailHeader {
        padding: 18px;
      }
      .transactionFormGroup {
        width: 200px;
        padding-right: 12px;

        input {
          width: 172px;
          padding: 10px;
          font-size: 13px;
        }
        ::-webkit-input-placeholder {
          font-size: 13px;
        }

        :-ms-input-placeholder {
          font-size: 13px;
        }

        ::placeholder {
          font-size: 13px;
        }
      }
      .generateButton {
        width: 55px;
        padding: 8px 14px;
        margin-left: 10px;
        button {
          svg {
            width: 16px;
            height: 16px;
          }
        }
        span {
          font-size: 13px;
          margin-left: 8px;
        }
      }
      .transcationRange {
        width: 130px;
        padding: 10px 16px;
        span {
          font-size: 13px;
          margin-left: 10px;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .transactionDetailBody {
        span {
          font-size: 13px;
        }
        h3 {
          width: 75px;
          font-size: 13px;
        }
        .tableCount {
          min-width: 45px;
          font-size: 13px;
          padding: 4px 10px;
        }
        .download {
          .commonRound {
            width: 25px;
            height: 25px;
          }
          .whiteButton {
            padding: 7px;
          }
          .cancelSub {
            padding: 7px;
          }
          button {
            margin-left: 3px;
          }
        }
      }
    }
  }
  .WQNxq {
    max-height: 610px !important;
    font-size: 13px;
  }
  .rangeModal {
    width: 450px;
    padding: 30px;

    .modal-header {
      margin-bottom: 30px;
      h3 {
        font-size: 24px;
      }
    }
    .modal-body {
      .dateRange {
        line-height: 16px;
      }
    }
  }
  .subscriptionModal {
    width: 400px;
    padding: 22px;

    .modal-header {
      margin-bottom: 15px;
    }
    .modal-body {
      .subBodyDetails {
        margin-bottom: 18px;
        p {
          font-size: 22px;
        }
      }
      .subButtonGrp {
        button,
        a {
          width: 120px;
          font-size: 13px;
          padding: 7px 16px;
          margin-right: 13px;
        }
      }
    }
  }
  .updateSub {
    width: 460px;
    padding: 24px 30px;
    .modal-header {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
      }
    }
    .modal-body {
      .updateForm {
        ul li {
          &:last-child {
            margin-bottom: 25px;
            .forms {
              .form-group {
                padding-left: 13px;
                input {
                  padding: 8px 24px 8px 0;
                }
              }
            }
          }
          label {
            font-size: 14px;
          }
          .forms {
            .form-group {
              padding-right: 13px;

              input {
                width: 360px;
                padding: 8px 24px 8px 12px;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .transactionDetail {
    h3 {
      font-size: 18px;
    }
    .transactionDetailWrap {
      margin-top: 14px;
      .transactionDetailHeader {
        padding: 15px;
      }
      .transactionFormGroup {
        width: 180px;
        padding-right: 10px;

        input {
          width: 156px;
          padding: 8px;
          font-size: 12px;
        }
        ::-webkit-input-placeholder {
          font-size: 12px;
        }

        :-ms-input-placeholder {
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }
      }
      .generateButton {
        width: 42px;
        padding: 7px 12px;
        margin-left: 8px;
        button {
          svg {
            width: 14px;
            height: 14px;
          }
        }
        span {
          font-size: 12px;
          margin-left: 8px;
        }
      }
      .transcationRange {
        width: 116px;
        padding: 8px 14px;
        span {
          font-size: 12px;
          margin-left: 8px;
        }
        svg {
          width: 14px;
          height: 14px;
        }
      }
      .transactionDetailBody {
        span {
          font-size: 12px;
        }
        h3 {
          width: 70px;
          font-size: 12px;
        }
        .tableCount {
          min-width: 45px;
          font-size: 12px;
          padding: 4px 10px;
        }
        .download {
          .commonRound {
            width: 25px;
            height: 25px;
          }
          .whiteButton {
            padding: 7px;
          }
          .cancelSub {
            padding: 7px;
          }
          button {
            margin-left: 3px;
          }
        }
      }
    }
  }
  .WQNxq {
    max-height: 620px !important;
    font-size: 12px;
  }
  .rangeModal {
    width: 400px;
    padding: 20px;

    .modal-header {
      margin-bottom: 20px;
      h3 {
        font-size: 22px;
      }
    }
    .modal-body {
      .dateRange {
        line-height: 15px;
      }
    }
  }
  .subscriptionModal {
    width: 380px;
    padding: 18px;

    .modal-header {
      margin-bottom: 15px;
    }
    .modal-body {
      .subBodyDetails {
        margin-bottom: 15px;
        p {
          font-size: 20px;
        }
      }
      .subButtonGrp {
        button,
        a {
          width: 100px;
          font-size: 12px;
          padding: 7px 15px;
          margin-right: 10px;
        }
      }
    }
  }
  .updateSub {
    width: 440px;
    padding: 22px 28px;
    .modal-header {
      margin-bottom: 25px;
      h3 {
        font-size: 22px;
      }
    }
    .modal-body {
      .updateForm {
        ul li {
          &:last-child {
            margin-bottom: 25px;
            .forms {
              .form-group {
                padding-left: 13px;
                input {
                  padding: 8px 22px 8px 0;
                }
              }
            }
          }
          label {
            font-size: 14px;
          }
          .forms {
            .form-group {
              padding-right: 13px;

              input {
                width: 345px;
                padding: 8px 22px 8px 12px;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .transactionDetail {
    h3 {
      font-size: 16px;
    }
    .transactionDetailWrap {
      margin-top: 12px;
      .transactionDetailHeader {
        padding: 10px 10px 0 10px;
        flex-wrap: wrap;
      }
      .transactionFormGroup {
        width: 174px;
        padding-right: 8px;
        margin-bottom: 10px;
        margin-right: 8px;

        input {
          width: 152px;
          padding: 6px;
          font-size: 12px;
        }
        ::-webkit-input-placeholder {
          font-size: 12px;
        }

        :-ms-input-placeholder {
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }
      }
      .generateButton {
        width: 30px;
        padding: 7px 10px;
        margin-left: 6px;
        margin-bottom: 10px;
        button {
          svg {
            width: 12px;
            height: 12px;
          }
        }
        span {
          font-size: 12px;
          margin-left: 6px;
        }
      }
      .transcationRange {
        width: 105px;
        padding: 8px 10px;
        margin-bottom: 10px;
        span {
          font-size: 12px;
          margin-left: 6px;
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .transactionDetailBody {
        span {
          font-size: 12px;
        }
        h3 {
          width: 70px;
          font-size: 12px;
        }
        .tableCount {
          min-width: 40px;
          font-size: 12px;
          padding: 4px 8px;
        }
        .download {
          .commonRound {
            width: 25px;
            height: 25px;
          }
          .whiteButton {
            padding: 7px;
          }
          .cancelSub {
            padding: 7px;
          }
          button {
            margin-left: 3px;
          }
        }
      }
    }
  }
  .rangeModal {
    // width: 290px;
    width: calc(100% - 30px);
    padding: 15px;

    .modal-header {
      margin-bottom: 15px;
      h3 {
        font-size: 20px;
      }
    }
    .modal-body {
      .customDatePickerWidth{
        width: 100%;
        overflow: auto;
      }
      .dateRange {
        line-height: 15px;
      }
    }
  }
  .subscriptionModal {
    width: 290px;
    padding: 15px;

    .modal-header {
      margin-bottom: 15px;
    }
    .modal-body {
      .subBodyDetails {
        margin-bottom: 15px;
        p {
          font-size: 18px;
        }
      }
      .subButtonGrp {
        button,
        a {
          width: 90px;
          font-size: 12px;
          padding: 7px 14px;
          margin-right: 8px;
        }
      }
    }
  }
  .updateSub {
    width: 290px;
    padding: 15px;
    .modal-header {
      margin-bottom: 15px;
      h3 {
        font-size: 20px;
      }
    }
    .modal-body {
      .updateForm {
        ul li {
          &:last-child {
            margin-bottom: 25px;
            .forms {
              .form-group {
                padding-left: 13px;
                input {
                  padding: 8px 22px 8px 0;
                }
                span h5 {
                  font-size: 12px;
                }
              }
            }
          }
          label {
            font-size: 14px;
          }
          .forms {
            .form-group {
              padding-right: 13px;

              input {
                width: 225px;
                padding: 8px 12px;
                font-size: 12px;
              }
            }
          }
        }
      }
      .modalNote{
        font-size: 12px;
      }
    }
  }
}

// .donationButtonGrp {
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   .againButton {
//     width: 175px;
//     background-color: $green-shadow;
//     border-radius: 20px;
//     font-family: $font_jost;
//     font-weight: 400;
//     font-size: 14px;
//     color: $white;
//     padding: 7px 23px 8px 22px;
//     margin-right: 16px;
//     cursor: pointer;
//     @include prefix(transition, all 0.3s ease-in-out);
//   }

//   .doneButton {
//     width: 100px;
//     background-color: $white_1;
//     border-radius: 20px;
//     font-family: $font_jost;
//     font-weight: 400;
//     font-size: 14px;
//     color: $black_1;
//     padding: 8px 26px 7px 26px;
//     cursor: pointer;
//     @include prefix(transition, all 0.3s ease-in-out);

//     &:hover {
//       background-color: $white-dark;
//     }
//   }
// }
