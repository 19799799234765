@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.favoriteTab {
  padding: 30px 0px 0px 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background-color: $white;
  .tabsForFavorite {
    .react-tabs__tab-list {
      display: flex;
      justify-content: start !important;
      background-color: white !important;
      padding: 0 !important;
      margin-bottom: 20px !important;
      margin-left: 10px;
    }
    .react-tabs__tab {
      font-size: 16px !important;
      background-color: white !important;
      margin-right: 40px;

      &:last-of-type {
        margin-right: 0;
      }
    }
    .react-tabs__tab--selected {
      background-color: white !important;
      font-weight: 700 !important;
    }
  }

  .favoriteScroll {
    max-height: 650px;
    padding-left: 10px;
  }
}

// responsive
@include responsive(xl) {
  .favoriteTab {
    padding: 28px 0px 0px 10px;
    .tabsForFavorite {
      .react-tabs__tab-list {
        margin-bottom: 18px !important;
        margin-left: 10px;
      }
      .react-tabs__tab {
        font-size: 15px !important;

        &:first-of-type {
          margin-right: 36px;
        }
      }
    }

    .favoriteScroll {
      max-height: 650px;
      padding-left: 10px;
    }
  }
}

@include responsive(lg) {
  .favoriteTab {
    padding: 26px 0px 0px 10px;
    .tabsForFavorite {
      .react-tabs__tab-list {
        margin-bottom: 16px !important;
        margin-left: 9px;
      }
      .react-tabs__tab {
        font-size: 14px !important;

        &:first-of-type {
          margin-right: 30px;
        }
      }
    }

    .favoriteScroll {
      max-height: 650px;
      padding-left: 9px;
    }
  }
}

@include responsive(md) {
  .favoriteTab {
    padding: 24px 0px 0px 9px;
    .tabsForFavorite {
      .react-tabs__tab-list {
        margin-bottom: 14px !important;
        margin-left: 9px;
      }
      .react-tabs__tab {
        font-size: 13px !important;

        &:first-of-type {
          margin-right: 26px;
        }
      }
    }

    .favoriteScroll {
      max-height: 100%;
      padding-left: 9px;
    }
  }
}

@include responsive(sm) {
  .favoriteTab {
    padding: 20px 0px 0px 9px;
    .tabsForFavorite {
      .react-tabs__tab-list {
        margin-bottom: 12px !important;
        margin-left: 10px;
      }
      .react-tabs__tab {
        font-size: 13px !important;

        &:first-of-type {
          margin-right: 22px;
        }
      }
    }

    .favoriteScroll {
      padding-left: 8px;
    }
  }
}

@include responsive(xs) {
  .favoriteTab {
    padding: 15px 9px 0px 9px;
    .tabsForFavorite {
      .react-tabs__tab-list {
        margin-bottom: 10px !important;
        margin-left: 10px;
      }
      .react-tabs__tab {
        font-size: 12px !important;

        &:first-of-type {
          margin-right: 18px;
        }
      }
    }

    .favoriteScroll {
      padding-left: 0px;
    }
  }
}
