@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.eventMedia{
    padding: 30px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    background-color: $white;

    .mediaTab{
        .react-tabs__tab-list{
            display: flex;
            justify-content: start !important;
            background-color: white !important;
            padding: 0 !important;
            margin-bottom: 20px !important;
        }
        .react-tabs__tab{
            font-size: 20px !important;
            background-color: white !important;

            &:first-of-type{
                margin-right: 30px;
            }
        }
        .react-tabs__tab--selected{
            background-color: white !important;
        }
    }

    .VideoColumn{
        margin-bottom: 30px;
    }
}