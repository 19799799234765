@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";
@import "../../../../../scss/common";
@import "../../../../../scss/reset";
@import "../../../../../scss/flex";

// page css
.imageGallery {
  & > div > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
      width: 330px;
      height: 280px;
      margin-bottom: 0;
      margin-top: 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

// modal css
.styles_lightroom__1X2qE {
  display: none !important;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999 !important;
  overflow: hidden !important;
  background-color: rgba(30, 30, 30, 0.8) !important;

  .styles_lightroomimg__1r0lF {
    width: 890px;
    height: 740px;
  }
}
.styles_lightroomdesc__rNPED {
  display: none;
}

.styles_icon__1uWQb {
  cursor: pointer !important;
  position: relative !important;
  top: 50px !important;
  right: 200px !important;
  height: 50px !important;
  width: 50px !important;
  &:first-of-type {
    display: none;
  }
}

.styles_carouselcontrolnext__DoQ9- {
  position: absolute;
  right: 240px;
  background-color: $green_shadow;
  border-radius: 50%;
  padding: 28px;
  cursor: pointer;
}
.styles_carouselcontrolprev__Bmyua {
  position: absolute;
  left: 240px;
  background-color: $green_shadow;
  border-radius: 50%;
  padding: 28px;
  cursor: pointer;
}

// responsive
@include responsive(xl) {
  .imageGallery {
    & > div > div {
      & > div {
        width: 255px;
        height: 260px;
        margin-top: 14px;
      }
    }
  }
}

@include responsive(lg) {
  .imageGallery {
    & > div > div {
      & > div {
        width: 214px;
        height: 190px;
        margin-top: 14px;
      }
    }
  }
}

@include responsive(md) {
  .imageGallery {
    & > div > div {
      & > div {
        width: 214px;
        height: 150px;
        margin-top: 13px;
      }
    }
  }
}

@include responsive(sm) {
  .imageGallery {
    & > div > div {
      & > div {
        width: 248px;
        height: 150px;
        margin-top: 13px;
      }
    }
  }
}

@include responsive(xs) {
  .imageGallery {
    & > div > div {
      & > div {
        width: 100%;
        height: 180px;
        margin-top: 12px;
      }
    }
  }
}
