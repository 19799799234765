@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.profileDetails {
  width: 100%;
  margin: 85px 0 67px;
  padding-top: 8px;
  position: relative;
}
.errorProfile {
  width: 100%;
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background-color: $red;
    border-radius: 5px;
    margin-right: 17px;
  }
  h3 {
    font-family: $font_jost;
    font-weight: 700;
    font-size: 20px;
    color: $red;
  }
}

.profileDetailsWrap {
  width: 100%;
  margin: 52px auto 0;
  background-color: $white-shadow;

  .profileContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;

    .profileSidebar {
      width: 556px;
      max-height: 806px;
      padding: 30px 30px 30px 25px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

      .profileMain {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 19px;
        .profilemainWrap {
          display: flex;
          align-items: center;
          .upload__image-wrapper {
            display: flex;
            flex-shrink: 0;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            border: 2px solid $green-shadow;
            position: relative;
            margin-right: 16px;
            button {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: transparent;
              cursor: pointer;
              position: absolute;
              z-index: 15;

              img {
                position: absolute;
                right: -2px;
                bottom: 4px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $theme-color;
                padding: 4px;
              }
            }
            .image-item {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              border: none;
              margin: 0px;
              flex-shrink: 1;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                padding: 0;
                color: transparent;
              }
            }
          }
          .profileName {
            h2 {
              font-family: $font_lora;
              font-weight: 700;
              font-size: 20px;
              color: $black_1;
              text-align: start;
              line-height: 25px;
              margin-bottom: 10px;
              text-transform: capitalize;
            }
            h3 {
              display: flex;
              justify-items: center;
              svg {
                path,
                circle {
                  stroke: $black_1;
                }
              }
              address {
                font-family: $font_jost;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: $black_1;
                margin-left: 8px;
              }
            }
          }
        }
        .dropdownAvtar {
          position: relative;
          figure {
            cursor: pointer;
          }
          .profileDropdown {
            width: 160px;
            position: absolute;
            right: 0;
            top: 28px;
            z-index: 55;
            box-shadow: 0 0px 8px rgba($color: $black, $alpha: 0.2);

            &::before {
              content: "";
              position: absolute;
              top: -10px;
              right: 10px;
              width: 20px;
              height: 20px;
              background: $white-shadow;
              transform: rotate(45deg);
              box-shadow: 0 0px 8px rgba($color: $black, $alpha: 0.2);
            }
            li {
              width: 100%;
              padding: 7px 24px 7px 21px;
              font-family: $font_jost;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              z-index: 555;
              position: relative;
              color: $light-gray;
              background-color: $white;
              @include prefix(transition, all 0.3s ease-in-out);
              cursor: pointer;
              &:hover {
                background-color: $light-green;
              }
            }
          }
        }
      }
      .donationCount {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $light-green;
        margin-bottom: 30px;

        .profileCount {
          width: 33%;
          padding: 31px 23px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            height: 53px;
            width: 1px;
            background-color: $white_1;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }
          &:last-of-type {
            &::after {
              display: none;
            }
          }
          h3 {
            font-family: $font_jost;
            font-weight: 700;
            font-size: 19px;
            color: $green-shadow;
            line-height: 47px;
          }
          h4 {
            font-family: $font_jost;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: $black_1;
          }
        }
      }
      .progressCount {
        margin-bottom: 31px;
        .barCount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .setMonthly {
            width: 182px;
            margin-bottom: 31px;
            h3 {
              font-family: $font_jost;
              font-weight: 700;
              font-size: 30px;
              line-height: 50px;
              color: $green-shadow;
            }
            h4 {
              font-family: $font_lora;
              font-weight: 700;
              font-size: 20px;
              color: $black_1;
              margin-bottom: 12px;
            }
            svg {
              height: 4px;
            }
          }
          .setAnnual {
            width: 235px;
          }
        }
        .goalButton {
          display: inline-block;
          width: 240px;
          text-align: center;
          border-radius: 50px;
          background-color: $green-shadow;
          padding: 10px 44px;
          color: $white;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          @include prefix(transition, all 0.3s ease-in-out);
          cursor: pointer;

          &:hover {
            background-color: $theme-color;
          }
        }
      }
      .strategicCount {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .strategyCount {
          width: 33%;
          position: relative;
          padding-right: 28px;
          &:nth-of-type(1),
          &:nth-of-type(2) {
            width: 50%;
            margin-bottom: 36px;
          }
          &:nth-of-type(2) {
            padding-right: 0;
            &::after {
              display: none;
            }
          }
          &::after {
            content: "";
            position: absolute;
            right: 30px;
            top: 10px;
            height: 62px;
            width: 1px;
            background-color: $white_1;
          }
          &:last-of-type {
            &::after {
              display: none;
            }
          }
          h3 {
            font-family: $font_jost;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            color: $green-shadow;
          }
          h4 {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $black_1;
          }
        }
      }
      .strategicButton {
        display: inline-block;
        width: 100%;
        text-align: center;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $white;
        border-radius: 20px;
        padding: 10px 44px;
        background-color: $green-shadow;
        @include prefix(transition, all 0.3s ease-in-out);
        cursor: pointer;

        &:hover {
          background-color: $theme-color;
        }
      }
      .strategicDisableButton {
        display: inline-block;
        width: 100%;
        text-align: center;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $white;
        border-radius: 20px;
        padding: 10px 44px;
        background-color: $white-dark;
        @include prefix(transition, all 0.3s ease-in-out);
        cursor: pointer;

        &:hover {
          background-color: $gray;
        }
      }
    }
    .profileContentWrap {
      width: 754px;

      .profileTab {
        .react-tabs__tab-list {
          border-bottom: none;
          width: 500px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $light-green;
          border-radius: 50px;
          margin-bottom: 30px;
          padding: 20px 58px 20px 40px;
        }
        .react-tabs__tab {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 15px;
          color: $black_1;
          border: none;
          padding: 0px;
          background-color: $light-green;
        }
        .react-tabs__tab--selected {
          font-weight: 700;
          font-size: 15px;
          color: $green-shadow;
          background-color: $light-green;
        }
      }
    }
  }
}

.goalModal {
  width: 595px;
  height: auto;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 42px 50px 50px 50px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 39px;
    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
    }
    button {
      background-color: $white;
      cursor: pointer;
    }
  }
  .modalMonthly,
  .modalAnnualy {
    margin-bottom: 30px;
    .forms .form-group {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: row-reverse;
      border: 1px solid $light-gray;
      padding: 0px 14px;
      border-radius: 7px;
      margin-bottom: 12px;

      input {
        width: 350px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $light-gray;
        padding: 10px 0;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      span h5 {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $light-gray;
        margin-right: 5px;
      }
    }
    .form-group:focus-within {
      border: 1px solid $theme-color;
    }
    ::-webkit-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      text-transform: capitalize;
    }

    :-ms-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      text-transform: capitalize;
    }

    ::placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      text-transform: capitalize;
    }
    .modalAchieve {
      margin: 0 16px;
      p {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: $black_1;
        text-transform: capitalize;
      }
    }
  }
  .modalAnnualy {
    margin-bottom: 40px;
  }
  .donationButton {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      display: inline-block;
      width: 200px;
      text-align: center;
      padding: 13px 75px;
      border-radius: 50px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      color: $white;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);
    }
    .add {
      background-color: $green-shadow;
      margin-left: 30px;
      &:hover {
        background-color: $theme-color;
      }
    }
    .cancel {
      background-color: $black_1;
      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
  .modal-footer {
    display: none;
  }
}

.strategicModal {
  width: 595px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 22px 26px 50px 50px;

  .modal-header {
    display: flex;
    justify-content: end;
    margin-bottom: 22px;
    h3 {
      display: none;
    }
    button {
      background-color: $white;
      cursor: pointer;
    }
  }
  .modal-body {
    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
      text-transform: capitalize;
      margin-bottom: 22px;
    }
    .strategicModalButton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ul {
      margin-bottom: 30px;
      width: 495px;
      li {
        .gender-formGrp {
          width: 100%;
          margin-bottom: 27px;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;

          .strategicModalSearch {
            border: 1px solid $light-gray;
            border-radius: 7px;

            & > div > div {
              border: none;
              border-radius: 7px;
            }
          }
        }
        .form-group {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid $light-gray;
          border-radius: 7px;
          padding-right: 14px;
        }
        .form-group:focus-within {
          border: 1px solid $green-shadow;
          svg path,
          circle,
          .react {
            stroke: $green-shadow;
            fill: none;
          }
        }
        input {
          width: 460px;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
          background-color: transparent;
          padding: 9px 0 8px 14px;
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
        }
        ::-webkit-input-placeholder {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
        }

        :-ms-input-placeholder {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
        }

        ::placeholder {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
        }
      }
    }
    button {
      width: 200px;
      border-radius: 50px;
      padding: 14px 75px 12px;
      cursor: pointer;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      color: $white;
      @include prefix(transition, all 0.3s ease-in-out);
      &:first-of-type {
        margin-right: 30px;
        background-color: $black_1;
        &:hover {
          background-color: $black;
        }
      }
      &:last-of-type {
        background-color: $green-shadow;
        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }
  .modal-footer {
    display: none;
  }
}

.requestModal {
  width: 630px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 44px 52px 56px 45px;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    h3 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 30px;
      color: $black_1;
    }
    button {
      background-color: $white;
      cursor: pointer;
    }
  }
  .modal-body {
    text-align: center;
    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $gray;
      text-transform: capitalize;
      line-height: 25px;
      letter-spacing: 0.04em;
      margin-bottom: 38px;
      text-align: left;
    }
    .requestProgressBar {
      margin-bottom: 80px;
      .RSPBstep:first-of-type {
        display: none;
      }
      .RSPBprogressBar {
        background-color: $white-dark;
      }
      .label {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        color: $black;
        margin-top: 70px;
      }
    }
    .requestButton {
      width: 200px;
      background-color: $green-shadow;
      cursor: pointer;
      border-radius: 50px;
      padding: 11px 89px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      color: $white;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: $theme-color;
      }
    }
  }
  .modal-footer {
    display: none;
  }
}

// responsive
@include responsive(xl) {
  .profileDetails {
    margin: 80px 0 55px;
  }

  .profileDetailsWrap {
    margin-top: 48px;
    .profileContent {
      width: 1138px;
      .profileSidebar {
        width: 500px;
        max-height: 705px;
        padding: 28px 22px;

        .profileMain {
          margin-bottom: 17px;
          .profilemainWrap {
            .upload__image-wrapper {
              width: 75px;
              height: 75px;
              margin-right: 15px;
            }
            .profileName {
              h2 {
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 8px;
              }
            }
          }
          .dropdownAvtar {
            .profileDropdown {
              width: 155px;
              li {
                padding: 7px 22px;
                font-size: 13px;
              }
            }
          }
        }
        .donationCount {
          margin-bottom: 26px;
          .profileCount {
            padding: 25px 19px;
            h3 {
              font-size: 18px;
              line-height: 40px;
            }
            h4 {
              font-size: 15px;
              line-height: 18px;
            }
          }
        }
        .progressCount {
          margin-bottom: 28px;
          .barCount {
            .setMonthly {
              width: 160px;
              margin-bottom: 28px;
              h3 {
                font-size: 28px;
                line-height: 48px;
              }
              h4 {
                font-size: 18px;
              }
            }
            .setAnnual {
              width: 200px;
            }
          }
          .goalButton {
            width: 230px;
            padding: 8px 40px;
            font-size: 13px;
          }
        }
        .strategicCount {
          margin-bottom: 28px;
          .strategyCount {
            width: 33%;
            padding-right: 20px;
            &::after {
              right: 21px;
              top: 0px;
            }
            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 34px;
            }
            h3 {
              font-size: 22px;
              line-height: 35px;
            }
            h4 {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        .strategicButton {
          font-size: 15px;
          padding: 9px 42px;
        }
        .strategicDisableButton {
          font-size: 15px;
          padding: 9px 42px;
        }
      }
      .profileContentWrap {
        width: 600px;
        .profileTab {
          .react-tabs__tab-list {
            width: 450px;
            margin-bottom: 26px;
            padding: 18px 28px;
          }
          .react-tabs__tab {
            font-size: 13px;
          }
          .react-tabs__tab--selected {
            font-size: 13px;
          }
        }
      }
    }
  }

  .goalModal {
    width: 550px;
    height: auto;
    padding: 38px 48px 48px 48px;

    .modal-header {
      margin-bottom: 35px;
      h3 {
        font-size: 28px;
      }
    }
    .modalMonthly,
    .modalAnnualy {
      margin-bottom: 28px;
      .forms .form-group {
        padding: 0 13px;
        margin-bottom: 11px;

        input {
          width: 410px;
          font-size: 14px;
          padding: 10px 0;
        }

        span h5 {
          font-size: 14px;
          margin-right: 5px;
        }
      }
      ::-webkit-input-placeholder {
        font-size: 14px;
      }

      :-ms-input-placeholder {
        font-size: 14px;
      }

      ::placeholder {
        font-size: 14px;
      }
      .modalAchieve {
        margin: 0 14px;
        p {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
    .modalAnnualy {
      margin-bottom: 36px;
    }
    .donationButton {
      button {
        width: 180px;
        padding: 13px 70px;
        font-size: 15px;
      }
      .add {
        margin-right: 28px;
      }
    }
  }

  .strategicModal {
    width: 550px;
    padding: 20px 24px 45px 45px;

    .modal-header {
      margin-bottom: 20px;
    }
    .modal-body {
      h3 {
        font-size: 28px;
        margin-bottom: 20px;
      }
      ul {
        margin-bottom: 28px;
        width: 450px;
        li {
          .gender-formGrp {
            margin-bottom: 25px;
            font-size: 13px;
          }
          .form-group {
            padding-right: 12px;
          }
          input {
            width: 100%;
            font-size: 13px;
            padding: 8px 0 8px 12px;
          }
          ::-webkit-input-placeholder {
            font-size: 13px;
          }

          :-ms-input-placeholder {
            font-size: 13px;
          }

          ::placeholder {
            font-size: 13px;
          }
        }
      }
      button {
        width: 180px;
        text-align: center;
        padding: 14px 0 12px;
        font-size: 15px;
        &:first-of-type {
          margin-right: 28px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .profileDetails {
    margin: 80px 0 45px;
  }

  .profileDetailsWrap {
    margin-top: 45px;
    .profileContent {
      width: 960px;
      .profileSidebar {
        width: 410px;
        max-height: 685px;
        padding: 25px 20px;

        .profileMain {
          margin-bottom: 16px;
          .profilemainWrap {
            .upload__image-wrapper {
              width: 72px;
              height: 72px;
              margin-right: 14px;
              button {
                img {
                  width: 18px;
                  height: 18px;
                }
              }
            }
            .profileName {
              h3 {
                address {
                  font-size: 13px;
                  line-height: 17px;
                  margin-left: 7px;
                }
              }
            }
          }
          .dropdownAvtar {
            .profileDropdown {
              width: 150px;
              li {
                padding: 7px 20px;
              }
            }
          }
        }
        .donationCount {
          margin-bottom: 25px;
          .profileCount {
            padding: 20px 18px;
            h3 {
              font-size: 14px;
              line-height: 25px;
            }
            h4 {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        .progressCount {
          .barCount {
            .setMonthly {
              width: 140px;
              h3 {
                line-height: 45px;
              }
              h4 {
                margin-bottom: 11px;
              }
            }
            .setAnnual {
              width: 170px;
            }
          }
          .goalButton {
            width: 220px;
          }
        }
        .strategicCount {
          margin-bottom: 26px;
          .strategyCount {
            padding-right: 13px;
            &::after {
              right: 12px;
            }
            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 30px;
            }
            h3 {
              font-size: 20px;
              line-height: 30px;
            }
            h4 {
              font-size: 13px;
              line-height: 17px;
            }
          }
        }
        .strategicButton {
          padding: 9px 40px;
        }
        .strategicDisableButton {
          padding: 9px 40px;
        }
      }
      .profileContentWrap {
        width: 510px;
        .profileTab {
          .react-tabs__tab-list {
            width: 385px;
          }
        }
      }
    }
  }

  .goalModal {
    width: 500px;
    padding: 36px 42px 42px 42px;

    .modal-header {
      margin-bottom: 32px;
      h3 {
        font-size: 26px;
      }
    }
    .modalMonthly,
    .modalAnnualy {
      margin-bottom: 26px;
      .forms .form-group {
        padding: 0 12px;
        margin-bottom: 11px;

        input {
          width: 375px;
          font-size: 13px;
          padding: 10px 0;
        }

        span h5 {
          font-size: 13px;
          margin-right: 5px;
        }
      }
      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }
      .modalAchieve {
        margin: 0 13px;
        p {
          line-height: 17px;
        }
      }
    }
    .modalAnnualy {
      margin-bottom: 30px;
    }
    .donationButton {
      button {
        width: 170px;
        padding: 12px 60px;
        font-size: 14px;
      }
      .add {
        margin-right: 26px;
      }
    }
  }

  .strategicModal {
    width: 500px;
    padding: 18px 22px 40px 40px;

    .modal-header {
      margin-bottom: 18px;
    }
    .modal-body {
      h3 {
        font-size: 25px;
        margin-bottom: 18px;
      }
      ul {
        margin-bottom: 26px;
        width: 410px;
        li {
          .gender-formGrp {
            margin-bottom: 20px;
            font-size: 13px;
          }
          .form-group {
            padding-right: 12px;
          }
          input {
            width: 100%;
            font-size: 13px;
            padding: 8px 0 8px 12px;
          }
          ::-webkit-input-placeholder {
            font-size: 13px;
          }

          :-ms-input-placeholder {
            font-size: 13px;
          }

          ::placeholder {
            font-size: 13px;
          }
        }
      }
      button {
        width: 160px;
        text-align: center;
        padding: 14px 0 12px;
        font-size: 15px;
        &:first-of-type {
          margin-right: 25px;
        }
      }
    }
  }
}

@include responsive(md) {
  .profileDetails {
    margin: 80px 0 50px;
  }

  .profileDetailsWrap {
    margin-top: 38px;
    .profileContent {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      .profileSidebar {
        width: 100%;
        padding: 20px;

        .profileMain {
          margin-bottom: 14px;
          .profilemainWrap {
            .upload__image-wrapper {
              width: 80px;
              height: 80px;
              margin-right: 20px;
              button {
                img {
                  width: 17px;
                  height: 17px;
                }
              }
            }
            .profileName {
              h2 {
                font-size: 17px;
                line-height: 20px;
                margin-bottom: 6px;
              }
              h3 {
                address {
                  font-size: 13px;
                  line-height: 15px;
                  margin-left: 6px;
                }
              }
            }
          }
          .dropdownAvtar {
            .profileDropdown {
              width: 150px;
              li {
                padding: 7px 18px;
                font-size: 13px;
              }
            }
          }
        }
        .donationCount {
          margin-bottom: 20px;
          .profileCount {
            padding: 18px 20px;
            h3 {
              font-size: 20px;
              line-height: 36px;
            }
            h4 {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
        .progressCount {
          margin-bottom: 24px;
          .barCount {
            .setMonthly {
              width: 50%;
              margin-bottom: 24px;
              h3 {
                font-size: 26px;
                line-height: 40px;
              }
              h4 {
                font-size: 16px;
                margin-bottom: 10px;
              }
            }
          }
          .goalButton {
            width: 200px;
            padding: 8px 30px;
          }
        }
        .strategicCount {
          margin-bottom: 22px;
          .strategyCount {
            padding-right: 12px;
            &::after {
              right: 17px;
              height: 58px;
            }
            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 25px;
            }
            h3 {
              font-size: 19px;
              line-height: 28px;
            }
            h4 {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
        .strategicButton {
          font-size: 14px;
          padding: 8px 0px;
        }
        .strategicDisableButton {
          font-size: 14px;
          padding: 8px 0px;
        }
      }
      .profileContentWrap {
        width: 100%;
        margin-top: 22px;
        .profileTab {
          .react-tabs__tab-list {
            margin-bottom: 22px;
            padding: 16px 25px;
          }
        }
      }
    }
  }

  .goalModal {
    width: 500px;
    padding: 35px;

    .modal-header {
      margin-bottom: 28px;
      h3 {
        font-size: 24px;
      }
    }
    .modalMonthly,
    .modalAnnualy {
      margin-bottom: 24px;
      .forms .form-group {
        padding: 0 12px;
        margin-bottom: 11px;

        input {
          width: 375px;
          font-size: 13px;
          padding: 10px 0;
        }

        span h5 {
          font-size: 13px;
          margin-right: 5px;
        }
      }
      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }
      .modalAchieve {
        margin: 0 13px;
        p {
          line-height: 17px;
        }
      }
    }
    .modalAnnualy {
      margin-bottom: 25px;
    }
    .donationButton {
      button {
        width: 170px;
        padding: 12px 60px;
        font-size: 14px;
      }
      .add {
        margin-right: 26px;
      }
    }
  }

  .strategicModal {
    width: 480px;
    padding: 18px 20px 30px 30px;

    .modal-header {
      margin-bottom: 16px;
    }
    .modal-body {
      h3 {
        font-size: 24px;
        margin-bottom: 16px;
      }
      ul {
        margin-bottom: 24px;
        width: 400px;
        li {
          .gender-formGrp {
            margin-bottom: 18px;
            font-size: 13px;
          }
          .form-group {
            padding-right: 12px;
          }
          input {
            width: 100%;
            font-size: 13px;
            padding: 8px 0 8px 11px;
          }
          ::-webkit-input-placeholder {
            font-size: 13px;
          }

          :-ms-input-placeholder {
            font-size: 13px;
          }

          ::placeholder {
            font-size: 13px;
          }
        }
      }
      button {
        width: 150px;
        text-align: center;
        padding: 14px 0 12px;
        font-size: 15px;
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .profileDetails {
    margin: 80px 0 40px;
  }

  .profileDetailsWrap {
    margin-top: 30px;
    .profileContent {
      .profileSidebar {
        padding: 18px;

        .profileMain {
          .profilemainWrap {
            .upload__image-wrapper {
              width: 70px;
              height: 70px;
              margin-right: 10px;
              button {
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
            .profileName {
              h2 {
                font-size: 15px;
                line-height: 18px;
                margin-bottom: 6px;
              }
            }
          }
          .dropdownAvtar {
            .profileDropdown {
              li {
                font-size: 11px;
              }
            }
          }
        }
        .donationCount {
          margin-bottom: 16px;
          .profileCount {
            padding: 16px 18px;
            h3 {
              font-size: 18px;
              line-height: 30px;
            }
            h4 {
              font-size: 13px;
            }
          }
        }
        .progressCount {
          margin-bottom: 22px;
          .barCount {
            .setMonthly {
              margin-bottom: 20px;
              h3 {
                font-size: 22px;
                line-height: 30px;
              }
            }
          }
          .goalButton {
            width: 190px;
            padding: 7px 25px;
            font-size: 12px;
          }
        }
        .strategicCount {
          margin-bottom: 18px;
          .strategyCount {
            padding-right: 10px;
            &::after {
              height: 50px;
            }
            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 20px;
            }
            h3 {
              font-size: 18px;
              line-height: 25px;
            }
            h4 {
              line-height: 16px;
            }
          }
        }
        .strategicButton {
          font-size: 13px;
          padding: 7px 0px;
        }
        .strategicDisableButton {
          font-size: 13px;
          padding: 7px 0px;
        }
      }
      .profileContentWrap {
        margin-top: 20px;
        .profileTab {
          .react-tabs__tab-list {
            width: 380px;
            margin-bottom: 20px;
            padding: 14px 22px;
          }
          .react-tabs__tab {
            font-size: 12px;
          }
          .react-tabs__tab--selected {
            font-size: 12px;
          }
        }
      }
    }
  }

  .goalModal {
    width: 480px;
    padding: 25px;

    .modal-header {
      margin-bottom: 20px;
      h3 {
        font-size: 22px;
      }
    }
    .modalMonthly,
    .modalAnnualy {
      margin-bottom: 20px;
      .forms .form-group {
        padding: 0 12px;
        margin-bottom: 11px;

        input {
          width: 375px;
          font-size: 12px;
          padding: 10px 0;
        }

        span h5 {
          font-size: 12px;
          margin-right: 5px;
        }
      }
      ::-webkit-input-placeholder {
        font-size: 12px;
      }

      :-ms-input-placeholder {
        font-size: 12px;
      }

      ::placeholder {
        font-size: 12px;
      }
      .modalAchieve {
        margin: 0 13px;
        p {
          line-height: 17px;
        }
      }
    }
    .modalAnnualy {
      margin-bottom: 20px;
    }
    .donationButton {
      button {
        width: 140px;
        padding: 12px 50px;
        font-size: 13px;
      }
      .add {
        margin-right: 20px;
      }
    }
  }

  .strategicModal {
    width: 450px;
    padding: 16px 18px 20px 20px;

    .modal-header {
      margin-bottom: 15px;
    }
    .modal-body {
      h3 {
        font-size: 22px;
        margin-bottom: 15px;
      }
      ul {
        margin-bottom: 20px;
        width: 390px;
        li {
          .gender-formGrp {
            margin-bottom: 16px;
            font-size: 12px;
          }
          .form-group {
            padding-right: 12px;
          }
          input {
            font-size: 12px;
            padding: 8px 0 8px 10px;
          }
          ::-webkit-input-placeholder {
            font-size: 12px;
          }

          :-ms-input-placeholder {
            font-size: 12px;
          }

          ::placeholder {
            font-size: 12px;
          }
        }
      }
      button {
        width: 150px;
        text-align: center;
        padding: 12px 0;
        font-size: 14px;
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .profileDetails {
    margin: 80px 0 20px;
  }

  .profileDetailsWrap {
    margin-top: 15px;
    .profileContent {
      .profileSidebar {
        padding: 10px;

        .profileMain {
          margin-bottom: 12px;
          .profilemainWrap {
            .upload__image-wrapper {
              width: 55px;
              height: 55px;
              margin-right: 8px;
              button {
                top: 3px;
                img {
                  width: 15px;
                  height: 15px;
                  padding: 3px;
                }
              }
            }
            .profileName {
              h2 {
                font-size: 13px;
                line-height: 15px;
                margin-bottom: 4px;
              }
              h3 {
                address {
                  font-size: 10px;
                  line-height: 12px;
                  margin-left: 4px;
                }
              }
            }
          }
          .dropdownAvtar {
            .profileDropdown {
              width: 110px;
              top: 22px;
              &::before {
                width: 15px;
                height: 15px;
                top: -5px;
                right: 7px;
              }
              li {
                padding: 5px 12px;
                font-size: 10px;
              }
            }
          }
        }
        .donationCount {
          margin-bottom: 12px;
          .profileCount {
            padding: 10px 5px;
            h3 {
              font-size: 12px;
              line-height: 20px;
            }
            h4 {
              font-size: 11px;
              line-height: 14px;
            }
          }
        }
        .progressCount {
          margin-bottom: 15px;
          .barCount {
            .setMonthly {
              width: 48%;
              margin-bottom: 12px;
              h3 {
                font-size: 18px;
                line-height: 25px;
              }
              h4 {
                font-size: 13px;
                margin-bottom: 5px;
              }
            }
          }
          .goalButton {
            width: 160px;
            padding: 6px 20px;
            font-size: 11px;
          }
        }
        .strategicCount {
          margin-bottom: 15px;
          .strategyCount {
            padding-right: 0px;
            &::after {
              right: 9px;
            }
            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 15px;
            }
            h3 {
              font-size: 14px;
              line-height: 20px;
            }
            h4 {
              width: 88px;
              font-size: 12px;
              letter-spacing: 0.01em;
            }
          }
        }
        .strategicButton {
          font-size: 12px;
          padding: 6px 0px;
        }
        .strategicDisableButton {
          font-size: 12px;
          padding: 6px 0px;
        }
      }
      .profileContentWrap {
        margin-top: 15px;
        .profileTab {
          .react-tabs__tab-list {
            width: 290px;
            margin-bottom: 15px;
            padding: 12px;
          }
          .react-tabs__tab {
            flex-shrink: 0;
            margin-right: 10px;
            font-size: 11px;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .goalModal {
    width: 290px;
    padding: 15px;

    .modal-header {
      margin-bottom: 15px;
      h3 {
        font-size: 20px;
      }
    }
    .modalMonthly,
    .modalAnnualy {
      margin-bottom: 15px;
      .forms .form-group {
        padding: 0 12px;
        margin-bottom: 11px;

        input {
          width: 220px;
          font-size: 12px;
          padding: 10px 0;
        }

        span h5 {
          font-size: 12px;
          margin-right: 5px;
        }
      }
      ::-webkit-input-placeholder {
        font-size: 11px;
      }

      :-ms-input-placeholder {
        font-size: 11px;
      }

      ::placeholder {
        font-size: 11px;
      }
      .modalAchieve {
        margin: 0 13px;
        p {
          line-height: 17px;
        }
      }
    }
    .modalAnnualy {
      margin-bottom: 15px;
    }
    .donationButton {
      button {
        width: 100px;
        padding: 8px 0px;
        font-size: 12px;
      }
      .add {
        margin-right: 15px;
      }
    }
  }

  .strategicModal {
    width: 290px;
    padding: 15px;

    .modal-header {
      margin-bottom: 15px;
    }
    .modal-body {
      h3 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 15px;
      }
      ul {
        margin-bottom: 15px;
        width: 260px;
        li {
          .gender-formGrp {
            margin-bottom: 15px;
            font-size: 12px;
          }
          .form-group {
            padding-right: 12px;
          }
          input {
            font-size: 12px;
            padding: 10px 0 10px 10px;
          }
        }
      }
      button {
        width: 140px;
        text-align: center;
        padding: 8px 0;
        font-size: 14px;
        &:first-of-type {
          margin-right: 15px;
        }
      }
    }
  }
}
