@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.verification-text {
  margin-top: 22px;
  p {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 15px;
    color: $light-gray;
    margin-bottom: 10px;
  }
  span {
    margin-left: 5px;
  }
}

.timer {
  display: flex;
  justify-content: end;
  margin-bottom: 45px;

  .timer-wrap {
    display: flex;
    align-items: center;

    span {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 12px;
      color: $green-shadow;

      &:first-of-type {
        margin-right: 3px;
      }
    }
    img {
      margin-right: 5px;
    }
  }
}

.input-list {
  margin-bottom: 17px;

  .otp-container {
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .otp-input {
    display: flex;
    justify-content: center;

    input {
      background: none;
      width: 40px !important;
      height: 42px;
      border: 1px solid $light-gray;
      border-radius: 7px;
      font-family: $font_jost;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[value^="0"],
    input[value^="1"],
    input[value^="2"],
    input[value^="3"],
    input[value^="4"],
    input[value^="8"],
    input[value^="7"],
    input[value^="6"],
    input[value^="5"],
    input[value^="9"] {
      border: 1px solid $theme-color;
    }
  }
}

.verification-footer {
  h5 {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 12px;
    color: $black;
    margin-bottom: 204px;

    button {
      margin-left: 3px;
      background: none;
      cursor: pointer;
      color: $green-shadow;
      font-family: $font_jost;
    }
  }
}

.verification_error {
  font-family: $font_jost;
  font-size: 14px;
  color: $red;
  text-transform: capitalize;
}

// responsive
@include responsive(lg) {
  .verification-text {
    margin-top: 18px;
    p {
      font-size: 13px;
      margin-bottom: 9px;
    }
    span {
      margin-left: 4px;
    }
  }

  .timer {
    margin-bottom: 38px;
    .timer-wrap {
      span {
        font-size: 11px;
        &:first-of-type {
          margin-right: 3px;
        }
      }
      img {
        margin-right: 4px;
      }
    }
  }

  .input-list {
    margin-bottom: 14px !important;

    .otp-container {
      width: 335px;
    }
    .otp-input {
      input {
        width: 36px !important;
        height: 38px;
      }
    }
  }

  .verification-footer {
    h5 {
      font-size: 11px;
      margin-bottom: 180px;
    }
  }

  .verification_error {
    font-size: 13px;
  }
}

@include responsive(md) {
  .verification-text {
    margin-top: 16px;
    p {
      font-size: 13px;
      margin-bottom: 9px;
    }
    span {
      margin-left: 4px;
    }
  }

  .timer {
    margin-bottom: 32px;
    .timer-wrap {
      span {
        font-size: 11px;
        &:first-of-type {
          margin-right: 3px;
        }
      }
      img {
        margin-right: 4px;
      }
    }
  }

  .input-list {
    margin-bottom: 12px !important;

    .otp-container {
      width: 310px;
    }
    .otp-input {
      input {
        width: 34px !important;
        height: 36px;
      }
    }
  }

  .verification-footer {
    h5 {
      font-size: 11px;
      margin-bottom: 140px;
    }
  }

  .verification_error {
    font-size: 12px;
  }
}

@include responsive(sm) {
  .verification-text {
    margin-top: 14px;
    p {
      font-size: 12px;
      margin-bottom: 9px;
    }
    span {
      margin-left: 4px;
    }
  }

  .timer {
    margin-bottom: 28px;
    .timer-wrap {
      span {
        font-size: 11px;
        &:first-of-type {
          margin-right: 3px;
        }
      }
      img {
        margin-right: 4px;
      }
    }
  }

  .input-list {
    margin-bottom: 11px !important;

    .otp-container {
      width: 300px;
    }
    .otp-input {
      input {
        width: 32px !important;
        height: 34px;
      }
    }
  }

  .verification-footer {
    h5 {
      font-size: 11px;
      margin-bottom: 110px;
    }
  }

  .verification_error {
    font-size: 11px;
  }
}

@include responsive(xs) {
  .verification-text {
    margin-top: 12px;
    p {
      font-size: 11px;
      margin-bottom: 9px;
    }
  }

  .timer {
    margin-bottom: 22px;
  }

  .input-list {
    display: flex;
    justify-content: center;
    margin-bottom: 10px !important;

    .otp-container {
      width: 216px;
    }
    .otp-input {
      input {
        width: 30px !important;
        height: 32px;
      }
    }
  }

  .verification-footer {
    h5 {
      font-size: 10px;
      margin-bottom: 80px;
    }
  }

  .verification_error {
    font-size: 10px;
  }
}
