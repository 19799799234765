@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.postDetailsAccountCount {
  width: 330px;
  height: 75px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  background-color: $light-green;

  .attendingPostCount {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 175px;

    &:nth-of-type(2) {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 1px;
        height: 56px;
        background-color: rgba($color: $green-shadow, $alpha: 0.5);
      }
    }
  }
  span {
    font-family: $font_jost;
    font-weight: 700;
    font-size: 20px;
    color: $green-shadow;
    margin-bottom: 4px;
  }
  h4 {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 12px;
    color: $gray;
  }
}
