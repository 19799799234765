@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.FavoriteCharity {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 3px;

  .charityCard {
    width: 220px;
    margin-bottom: 30px;
    margin-right: 24px;
    &:nth-of-type(3n + 0) {
      margin-right: 0;
    }
    figure {
      width: 100%;
      height: 130px;
      img {
        color: transparent;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    h4,
    p {
      text-align: center;
    }
    .charityButtons {
      button {
        cursor: pointer;
      }
      .basket {
        min-width: 125px;
        font-size: 14px;
        padding: 9px 18px 5px;
      }
      .charityHeartActive {
        width: 54px;
        padding: 6px 14px 5px 16px;
        cursor: pointer;
        svg {
          width: 25px;
          height: 23px;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .FavoriteCharity {
    padding: 9px 3px;

    .charityCard {
      width: 210px;
      margin-bottom: 28px;
      margin-right: 22px;
      &:nth-of-type(3n + 0) {
        margin-right: 22px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
      figure {
        height: 125px;
      }
      h4 {
        height: 46px;
      }
      p {
        height: 90px;
        margin-bottom: 16px;
        line-height: 22px;
      }
      .charityButtons {
        .basket {
          min-width: 111px;
          font-size: 13px;
          padding: 9px 16px 7px;
        }
        .charityHeartActive {
          width: 52px;
          padding: 6px 12px 5px 14px;
          svg {
            width: 24px;
            height: 22px;
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .FavoriteCharity {
    padding: 8px 3px;

    .charityCard {
      width: 195px;
      margin-bottom: 26px;
      margin-right: 20px;
      &:nth-of-type(3n + 0) {
        margin-right: 20px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
      figure {
        height: 100px;
      }
      h4 {
        height: 40px;
      }
      p {
        height: 80px;
        margin-bottom: 15px;
        line-height: 20px;
      }
      .charityButtons {
        .basket {
          min-width: 105px;
          font-size: 12px;
          padding: 8px 14px 5px;
          margin-right: 5px;
        }
        .charityHeartActive {
          min-width: 50px;
          padding: 6px 10px 5px 12px;
          svg {
            width: 23px;
            height: 21px;
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .FavoriteCharity {
    padding: 7px 3px;

    .charityCard {
      width: 194px;
      margin-bottom: 22px;
      margin-right: 18px;
      &:nth-of-type(3n + 0) {
        margin-right: 18px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 18px;
      }
      figure {
        height: 105px;
      }
      h4 {
        height: 38px;
        font-size: 15px;
      }
      p {
        height: 70px;
        margin-bottom: 12px;
        line-height: 18px;
      }
      .charityButtons {
        .basket {
          min-width: 112px;
          font-size: 12px;
          padding: 7px 20px 5px;
          margin-right: 4px;
        }
        .charityHeartActive {
          min-width: 48px;
          padding: 5px 10px;
          svg {
            width: 22px;
            height: 20px;
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .FavoriteCharity {
    padding: 7px 3px;

    .charityCard {
      width: 200px;
      margin-bottom: 20px;
      margin-right: 16px;
      &:nth-of-type(3n + 0) {
        margin-right: 16px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 16px;
      }
      figure {
        height: 100px;
      }
      h4 {
        height: 36px;
        font-size: 13px;
      }
      p {
        height: 50px;
        margin-bottom: 10px;
        line-height: 16px;
      }
      .charityButtons {
        .basket {
          min-width: 120px;
          font-size: 12px;
          padding: 7px 24px 5px;
          margin-right: 4px;
        }
        .charityHeartActive {
          min-width: 46px;
          padding: 5px 8px;
          svg {
            width: 22px;
            height: 20px;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .FavoriteCharity {
    padding: 7px 3px;

    .charityCard {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0px;
      &:nth-of-type(3n + 0) {
        margin-right: 0px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
      figure {
        height: 100px;
      }
      h4 {
        height: 34px;
        font-size: 12px;
      }
      p {
        height: 34px;
        font-size: 11px;
        line-height: 18px;
        margin-bottom: 10px;
      }
      .charityButtons {
        justify-content: center;
        .basket {
          min-width: 150px;
          font-size: 12px;
          padding: 7px 39px 5px;
          margin-right: 4px;
        }
        .charityHeartActive {
          min-width: 46px;
          padding: 5px 8px;
          svg {
            width: 22px;
            height: 20px;
          }
        }
      }
    }
  }
}
