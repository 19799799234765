@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.contactUs {
  .sign-btn {
    margin-top: 50px;
    margin-bottom: 98px !important;
  }
  .textarea {
    &:focus-within {
      textarea {
        border: 1px solid $theme-color;
      }
    }
    textarea {
      width: 100%;
      height: 132px;
      border: 1px solid $light-gray;
      border-radius: 7px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      resize: none;
      padding: 9px 14px;
      margin-top: 8px;
    }
  }
}

// responsive
@include responsive(lg) {
  .contactUs {
    .sign-btn {
      margin-bottom: 90px !important;
      margin-bottom: 45px;
    }
    .textarea {
      textarea {
        height: 120px;
        font-size: 13px;
        padding: 8px 13px;
      }
    }
  }
}

@include responsive(md) {
  .contactUs {
    .sign-btn {
      margin-bottom: 80px !important;
      margin-bottom: 40px;
    }
    .textarea {
      textarea {
        height: 110px;
        font-size: 13px;
        padding: 8px 12px;
      }
    }
  }
}

@include responsive(sm) {
  .contactUs {
    .sign-btn {
      margin-bottom: 70px !important;
      margin-bottom: 30px;
    }
    .textarea {
      textarea {
        height: 100px;
        font-size: 12px;
        padding: 7px 11px;
      }
    }
  }
}

@include responsive(xs) {
  .contactUs {
    .sign-btn {
      margin-bottom: 60px !important;
      margin-bottom: 25px;
    }
    .textarea {
      textarea {
        height: 90px;
        font-size: 11px;
        padding: 6px 10px;
      }
    }
  }
}
