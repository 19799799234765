@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

// banner-section
.banner {
  width: 100%;
  padding: 160px 0;
  margin-top: 85px;
  position: relative;
  min-height: 650px;
  display: flex;
  align-items: center;
  .home-banner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 55;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .banner-content {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 56;
    display: flex;
    gap: 40px;
    flex-direction: column;
    &.empty-banner {
      gap: 0;
    }
    h1 {
      width: 670px;
      font-family: $font_lora;
      font-weight: 700;
      font-size: 70px;
      color: $white;
      line-height: 80px;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }

    p {
      width: 670px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 48px;
      color: $white;
      line-height: 120%;
      // margin-bottom: 47px;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }

    // .search {
    // display: flex;
    // align-items: center;
    // max-width: 420px;
    // height: 48px;
    // margin: 0 auto;
    // border-radius: 50px;
    // padding: 8px 0px 9px 20px;
    // background-color: rgba($color: $green-shadow, $alpha: 0.68);

    // }
  }
}
.banner-search {
  width: 100%;
  background: none;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  max-width: 670px;
  margin: 0 auto 60px;
  .wrapper {
    background-color: #f3f3f3;
    border: none;
    padding: 4px 0;
    @include prefix(transition, all 0.3s ease-in-out);
    input {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
      background-color: transparent;
    }
    ::-webkit-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
    }

    :-ms-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
    }

    ::placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
    }
  }

  li {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    color: #645f5f;

    &.selected {
      background-color: $white-dark;
      margin-right: 0 !important;
    }
  }
}

.searchItm {
  display: flex;
  align-items: center;
  .charityRight {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

// about-us
.about-sec {
  width: 100%;
  padding: 60px 0;
  background-color: rgba(109, 175, 95, 0.1);
}

// registration-section
.register-section {
  width: 100%;
  margin: 100px 0 50px;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

// register today
// .register-section {
//   width: 100%;
//   padding: 100px 0;

//   .reg-wrap {
//     display: flex;
//     justify-content: space-between;

//     .reg-left {
//       width: 41%;

//       h4 {
//         font-family: $font_jost;
//         font-weight: 700;
//         font-size: 16px;
//         color: $green-shadow;
//         margin-bottom: 20px;
//         text-transform: uppercase;
//       }

//       h3 {
//         font-family: $font_lora;
//         font-weight: 700;
//         font-size: 45px;
//         color: $black_1;
//         margin-bottom: 20px;
//         text-transform: capitalize;
//       }

//       figure {
//         width: 430px;
//         height: 263px;
//         margin-bottom: 43px;

//         img {
//           width: 100%;
//           height: 100%;
//         }
//       }

//       .reg-button {
//         display: inline-block;
//         width: 430px;
//         background-color: $green-shadow;
//         border-radius: 50px;
//         font-family: $font_jost;
//         font-weight: 400;
//         text-align: center;
//         color: $white;
//         font-size: 16px;
//         padding: 18px 125px 17px 124px;
//         @include prefix(transition, all 0.3s ease-in-out);

//         &:hover {
//           background-color: $theme-color;
//         }
//       }
//     }

//     .reg-right {
//       width: 55%;
//       display: flex;
//       justify-content: space-between;
//     }
//   }
// }

// contact us
// .contact-us {
//   width: 100%;
//   padding: 40px 0;
//   background-color: $green-shadow;

//   .contact-wrapper {
//     width: 50%;
//     margin: 0 auto;
//     text-align: center;

//     h2 {
//       font-family: $font_lora;
//       font-weight: 700;
//       font-size: 35px;
//       color: $white;
//       line-height: 50px;
//       margin-bottom: 26px;
//       text-transform: capitalize;
//     }

//     .contact-button {
//       display: inline-block;
//       max-width: 280px;
//       font-family: $font_jost;
//       font-weight: 400;
//       font-size: 16px;
//       text-align: center;
//       padding: 17px 101px 18px 100px;
//       background-color: $white;
//       border-radius: 50px;
//       color: $black_1 !important;
//       @include prefix(transition, all 0.3s ease-in-out);

//       &:hover {
//         color: $green-shadow !important;
//         box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
//       }
//     }
//   }
// }

// steps
// .homeSteps {
//   width: 100%;
//   padding: 100px 0;

//   .homeSteps-wrap {
//     .homeSteps-title {
//       width: 71%;
//       margin: 0 auto;
//       text-align: center;

//       h3 {
//         font-family: $font_jost;
//         font-weight: 700;
//         font-size: 16px;
//         color: $green-shadow;
//         margin-bottom: 20px;
//       }
//       h1 {
//         font-family: $font_lora;
//         font-weight: 700;
//         font-size: 45px;
//         color: $black_1;
//         margin-bottom: 85px;
//       }
//     }
//     .homeSteps-steps {
//       display: flex;
//       justify-content: space-between;

//       .fourSteps {
//         width: 35%;
//       }
//       .stepsImage {
//         width: 49%;
//         figure {
//           // margin-top: 24px;
//           width: 100%;
//         }
//       }
//     }
//   }
// }

// charity
.charity {
  width: 100%;
  margin: 50px 0;
  text-align: center;

  .charityText {
    text-align: center;
    margin-bottom: 40px;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $green-shadow;
      margin-bottom: 10px;
    }
    h2 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 45px;
      color: $black_1;
    }

    .nonProfit-search {
      width: 50%;
      margin: 25px auto 0;
    }
  }
  .charitiesCard {
    display: flex;
    flex-wrap: wrap;
  }
  .charityCard {
    margin-right: 26px;
    cursor: pointer;
    &:nth-of-type(4n + 0) {
      margin-right: 0;
    }
  }

  .charityButtons {
    .basket {
      min-width: 160px !important;
    }
  }
  .view {
    display: inline-block;
    width: 280px;
    height: 55px;
    padding: 15px 110px;
    text-align: center;
    font-family: $font_jost;
    font-weight: 400;
    font-size: 16px;
    color: $white;
    border-radius: 50px;
    background-color: $green-shadow;
    @include prefix(transition, all 0.3s ease-in-out);

    &:hover {
      background-color: $theme-color;
    }
  }
}

// register-now
// .register-banner {
//   width: 100%;

//   .home_register {
//     padding: 76px 0;
//   }

//   .registerHome {
//     display: inline-block;
//     width: 280px;
//     padding: 6px 66px;
//     border-radius: 20px;
//     background-color: $green-shadow;
//     color: $white;
//     font-family: $font_jost;
//     font-weight: 400;
//     font-size: 16px;
//     @include prefix(transition, all 0.3s ease-in-out);

//     &:hover {
//       background-color: $theme-color;
//     }
//   }
// }

// causes
.causes {
  width: 100%;
  margin: 50px 0 100px;
  text-align: center;

  .causeText {
    text-align: center;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $green-shadow;
      margin-bottom: 10px;
    }
    h2 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 45px;
      color: $black_1;
      margin-bottom: 25px;
    }
    .cause-search {
      width: 50%;
      margin: 0px auto 40px;
    }
  }

  .causesCard {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .causeCard {
      margin-right: 26px;
      position: relative;

      &:nth-of-type(4n + 0) {
        margin-right: 0px;
      }

      .causeImage {
        width: 100%;
        height: 187px;

        figure {
          display: inline-block;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            color: transparent;
          }
        }
      }
      .causeContent {
        padding: 12px 18px 23px 15px;
        text-align: center;

        h4 {
          width: 230px;
          height: 50px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          vertical-align: middle;
        }
      }
      .causeButtons {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }
  .view {
    display: inline-block;
    width: 280px;
    height: 55px;
    padding: 15px 110px;
    text-align: center;
    font-family: $font_jost;
    font-weight: 400;
    font-size: 16px;
    color: $white;
    border-radius: 50px;
    background-color: $green-shadow;
    @include prefix(transition, all 0.3s ease-in-out);

    &:hover {
      background-color: $theme-color;
    }
  }
}

// donor section
// .donor {
//   width: 100%;
//   background: url("../../../public/images/donor.png");
//   background-color: rgba($color: $green-shadow, $alpha: 1);
//   padding: 81px 0 111px;

//   .donor-wrapper {
//     width: 87%;
//     margin: 0 auto;
//     text-align: center;

//     h2 {
//       font-family: $font_lora;
//       font-weight: 700;
//       font-size: 45px;
//       color: $white;
//       margin-bottom: 60px;
//     }
//   }
// }

// responsive
@include responsive(xl) {
  .banner {
    margin-top: 80px;
    min-height: 600px;
    .banner-content {
      h1 {
        width: 670px;
        font-size: 66px;
        line-height: 70px;
      }

      p {
        width: 670px;
        font-size: 36px;
      }
    }
  }
  .banner-search {
    font-size: 13px;

    .wrapper {
      padding: 4px 0;
      input {
        font-size: 13px;
      }
      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }
    }

    li {
      font-size: 13px;
    }
  }

  // .register-section {
  //   padding: 90px 0;

  //   .reg-wrap {
  //     .reg-left {
  //       h4 {
  //         font-size: 15px;
  //         margin-bottom: 18px;
  //       }

  //       h3 {
  //         font-size: 42px;
  //         margin-bottom: 18px;
  //       }

  //       figure {
  //         width: 410px;
  //         height: 250px;
  //         margin-bottom: 38px;
  //       }

  //       .reg-button {
  //         width: 410px;
  //         font-size: 15px;
  //         padding: 16px 0;
  //       }
  //     }
  //     .reg-right {
  //       width: 55%;
  //     }
  //   }
  // }

  // .contact-us {
  //   padding: 38px 0;

  //   .contact-wrapper {
  //     h2 {
  //       font-size: 32px;
  //       line-height: 46px;
  //       margin-bottom: 24px;
  //     }

  //     .contact-button {
  //       max-width: 270px;
  //       font-size: 15px;
  //       padding: 16px 90px;
  //     }
  //   }
  // }

  // .homeSteps {
  //   padding: 90px 0;

  //   .homeSteps-wrap {
  //     .homeSteps-title {
  //       width: 71%;
  //       h3 {
  //         font-size: 15px;
  //         margin-bottom: 18px;
  //       }
  //       h1 {
  //         font-size: 42px;
  //         margin-bottom: 80px;
  //       }
  //     }
  //     .homeSteps-steps {
  //       .fourSteps {
  //         width: 35%;
  //       }
  //       .stepsImage {
  //         width: 49%;
  //         figure {
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  // }

  .charity {
    margin: 80px 0 40px;

    .charityText {
      margin-bottom: 38px;

      h3 {
        font-size: 15px;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 42px;
      }
    }
    .charitiesCard {
      margin: 0 auto;
      width: 82%;
    }
    .charityCard {
      margin-right: 24px;
      &:nth-of-type(4n + 0) {
        margin-right: 24px;
      }
      &:nth-of-type(3n + 0) {
        margin-right: 0;
      }
    }

    .charityButtons {
      .basket {
        min-width: 190px !important;
        padding: 8px 30px;
        font-size: 15px;
        margin-right: 10px;
      }
      .charityHeart {
        min-width: 58px;
        padding: 4px 13px 4px;

        svg {
          width: 24px;
          height: 22px;
        }
      }
      .charityHeartActive {
        min-width: 58px;
        padding: 4px 13px 4px;

        svg {
          width: 24px;
          height: 22px;
        }
      }
    }

    .view {
      width: 270px;
      height: auto;
      padding: 14px 0;
      font-size: 15px;
    }
  }

  // .register-banner {
  //   .home_register {
  //     padding: 72px 0;
  //   }

  //   .registerHome {
  //     width: 270px;
  //     padding: 6px 0;
  //     font-size: 15px;
  //   }
  // }

  .causes {
    margin: 40px 0 80px;

    .causeText {
      h3 {
        font-size: 15px;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 40px;
        margin-bottom: 35px;
      }
    }
    .causesCard {
      margin-bottom: 40px;

      .causeCard {
        margin-right: 25px;

        .causeImage {
          height: 180px;
        }
        .causeContent {
          padding: 11px 16px 20px 14px;
          h4 {
            width: 235px;
            height: 45px;
          }
        }
      }
    }
    .view {
      width: 270px;
      height: auto;
      padding: 12px 0;
      font-size: 15px;
    }
  }
}

@include responsive(lg) {
  .banner {
    padding: 80px 0;
    min-height: 390px;

    .banner-content {
      width: 62%;
      gap: 20px;
      h1 {
        width: 100%;
        font-size: 60px;
        line-height: 60px;
      }

      p {
        width: 100%;
        font-size: 32px;
      }
    }
  }

  // .register-section {
  //   padding: 80px 0;

  //   .reg-wrap {
  //     .reg-left {
  //       width: 45%;
  //       h4 {
  //         font-size: 14px;
  //         margin-bottom: 16px;
  //       }

  //       h3 {
  //         font-size: 38px;
  //         margin-bottom: 16px;
  //       }

  //       figure {
  //         width: 390px;
  //         height: 240px;
  //         margin-bottom: 34px;
  //       }

  //       .reg-button {
  //         width: 390px;
  //         font-size: 14px;
  //         padding: 15px 0;
  //       }
  //     }
  //     .reg-right {
  //       width: 55%;
  //     }
  //   }
  // }

  // .contact-us {
  //   padding: 35px 0;

  //   .contact-wrapper {
  //     width: 62%;
  //     h2 {
  //       font-size: 30px;
  //       line-height: 42px;
  //       margin-bottom: 22px;
  //     }

  //     .contact-button {
  //       max-width: 260px;
  //       font-size: 14px;
  //       padding: 15px 80px;
  //     }
  //   }
  // }

  // .homeSteps {
  //   padding: 80px 0;

  //   .homeSteps-wrap {
  //     .homeSteps-title {
  //       width: 73%;
  //       h3 {
  //         font-size: 14px;
  //         margin-bottom: 16px;
  //       }
  //       h1 {
  //         font-size: 38px;
  //         margin-bottom: 70px;
  //       }
  //     }
  //     .homeSteps-steps {
  //       .fourSteps {
  //         width: 40%;
  //       }
  //       .stepsImage {
  //         width: 49%;
  //         figure {
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  // }

  .charity {
    margin: 70px 0 35px;

    .charityText {
      margin-bottom: 35px;

      h3 {
        font-size: 14px;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 38px;
      }
    }
    .charitiesCard {
      width: 89%;
    }
    .charityCard {
      margin-right: 22px;
      &:nth-of-type(3n + 0) {
        margin-right: 0px;
      }
      &:nth-of-type(4n + 0) {
        margin-right: 22px;
      }
    }

    .charityButtons {
      .basket {
        min-width: 180px !important;
        padding: 8px 28px;
        font-size: 15px;
        margin-right: 10px;
      }
      .charityHeart {
        min-width: 56px;
        padding: 4px 13px 4px;

        svg {
          width: 24px;
          height: 22px;
        }
      }
      .charityHeartActive {
        min-width: 56px;
        padding: 4px 13px 4px;

        svg {
          width: 24px;
          height: 22px;
        }
      }
    }

    .view {
      width: 250px;
      padding: 14px 0;
      font-size: 15px;
    }
  }

  // .register-banner {
  //   .home_register {
  //     padding: 68px 0;
  //   }

  //   .registerHome {
  //     width: 260px;
  //     padding: 6px 0;
  //     font-size: 14px;
  //   }
  // }

  .causes {
    margin: 35px 0 80px;

    .causeText {
      h3 {
        font-size: 14px;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 38px;
        margin-bottom: 30px;
      }
    }
    .causesCard {
      margin-bottom: 36px;

      .causeCard {
        margin-right: 23px;

        .causeImage {
          height: 170px;
        }
        .causeContent {
          padding: 10px 15px 18px 13px;
          h4 {
            width: 100%;
            height: 45px;
          }
        }
      }
    }
    .view {
      width: 250px;
      padding: 11px 0;
      font-size: 14px;
    }
  }
}

@include responsive(md) {
  .banner {
    .banner-content {
      width: 80%;
      h1 {
        font-size: 56px;
        line-height: 65px;
      }
    }
  }

  // .register-section {
  //   padding: 80px 0;

  //   .reg-wrap {
  //     flex-wrap: wrap;
  //     .reg-left {
  //       text-align: center;
  //       width: 100%;
  //       h4 {
  //         text-align: left;
  //         font-size: 13px;
  //         margin-bottom: 15px;
  //       }

  //       h3 {
  //         text-align: left;
  //         font-size: 35px;
  //         margin-bottom: 15px;
  //       }

  //       figure {
  //         width: 350px;
  //         height: 230px;
  //         margin: 0 auto 32px;
  //       }

  //       .reg-button {
  //         width: 350px;
  //         font-size: 14px;
  //         padding: 15px 0;
  //       }
  //     }
  //     .reg-right {
  //       width: 100%;
  //       margin-top: 30px;
  //     }
  //   }
  // }

  // .contact-us {
  //   padding: 30px 0;

  //   .contact-wrapper {
  //     width: 62%;
  //     h2 {
  //       font-size: 26px;
  //       line-height: 38px;
  //       margin-bottom: 20px;
  //     }

  //     .contact-button {
  //       max-width: 250px;
  //       font-size: 13px;
  //       padding: 14px 70px;
  //     }
  //   }
  // }

  // .homeSteps {
  //   padding: 70px 0;

  //   .homeSteps-wrap {
  //     .homeSteps-title {
  //       width: 80%;
  //       h3 {
  //         font-size: 13px;
  //         margin-bottom: 15px;
  //       }
  //       h1 {
  //         font-size: 32px;
  //         margin-bottom: 60px;
  //       }
  //     }
  //     .homeSteps-steps {
  //       .fourSteps {
  //         width: 40%;
  //       }
  //       .stepsImage {
  //         width: 49%;
  //         figure {
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  // }

  .charity {
    margin: 70px 0 35px;

    .charityText {
      margin-bottom: 30px;

      h3 {
        font-size: 13px;
        margin-bottom: 9px;
      }
      h2 {
        font-size: 35px;
      }
      .nonProfit-search {
        width: 80%;
      }
    }
    .charitiesCard {
      width: 74%;
    }
    .charityCard {
      margin-right: 20px;
      &:nth-of-type(3n + 0) {
        margin-right: 20px;
      }
      &:nth-of-type(4n + 0) {
        margin-right: 20px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
    }

    .charityButtons {
      .basket {
        min-width: 170px !important;
        padding: 8px 28px;
        font-size: 14px;
        margin-right: 8px;
      }
      .charityHeart {
        min-width: 52px;
        padding: 3px 12px 3px;

        svg {
          width: 22px;
          height: 20px;
        }
      }
      .charityHeartActive {
        min-width: 52px;
        padding: 3px 12px 3px;

        svg {
          width: 22px;
          height: 20px;
        }
      }
    }

    .view {
      width: 230px;
      padding: 13px 0;
      font-size: 13px;
    }
  }

  // .register-banner {
  //   .home_register {
  //     padding: 65px 0;
  //   }

  //   .registerHome {
  //     width: 250px;
  //     padding: 6px 0;
  //     font-size: 13px;
  //   }
  // }

  .causes {
    margin: 35px 0 80px;

    .causeText {
      h3 {
        font-size: 13px;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 32px;
        margin-bottom: 25px;
      }
      .cause-search {
        width: 80%;
      }
    }
    .causesCard {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 0px;

      .causeCard {
        margin-right: 20px;
        margin-bottom: 25px;
        &:nth-of-type(2n + 0) {
          margin-right: 0;
        }

        .causeImage {
          height: 200px;
        }
        .causeContent {
          padding: 10px 15px 18px 13px;
          h4 {
            height: 48px;
          }
        }
      }
    }
    .view {
      width: 230px;
      padding: 11px 0;
      font-size: 13px;
    }
  }
}

@include responsive(sm) {
  .banner {
    padding: 60px 0;

    .banner-content {
      width: 82%;
      h1 {
        font-size: 50px;
        line-height: 60px;
      }

      p {
        font-size: 24px;
      }
    }
  }
  .about-sec {
    padding: 30px 0;
  }
  .banner-search {
    font-size: 12px;
    margin: 0 auto 30px;
    .wrapper {
      input {
        font-size: 12px;
      }
      ::-webkit-input-placeholder {
        font-size: 12px;
      }

      :-ms-input-placeholder {
        font-size: 12px;
      }

      ::placeholder {
        font-size: 12px;
      }
    }

    li {
      font-size: 12px;
    }
  }

  // .register-section {
  //   padding: 40px 0;

  //   .reg-wrap {
  //     .reg-left {
  //       h4 {
  //         font-size: 12px;
  //         margin-bottom: 14px;
  //       }

  //       h3 {
  //         font-size: 30px;
  //         margin-bottom: 15px;
  //       }

  //       figure {
  //         width: 320px;
  //         height: 210px;
  //         margin: 0 auto 25px;
  //       }

  //       .reg-button {
  //         width: 320px;
  //         font-size: 13px;
  //         padding: 15px 0;
  //       }
  //     }
  //     .reg-right {
  //       width: 100%;
  //       margin-top: 25px;
  //     }
  //   }
  // }

  // .contact-us {
  //   padding: 20px 0;

  //   .contact-wrapper {
  //     width: 100%;
  //     h2 {
  //       font-size: 24px;
  //       line-height: 35px;
  //       margin-bottom: 18px;
  //     }

  //     .contact-button {
  //       max-width: 240px;
  //       font-size: 13px;
  //       padding: 13px 65px;
  //     }
  //   }
  // }

  // .homeSteps {
  //   padding: 40px 0;

  //   .homeSteps-wrap {
  //     .homeSteps-title {
  //       width: 100%;
  //       h3 {
  //         font-size: 13px;
  //         margin-bottom: 12px;
  //       }
  //       h1 {
  //         font-size: 28px;
  //         margin-bottom: 25px;
  //       }
  //     }
  //     .homeSteps-steps {
  //       flex-wrap: wrap;
  //       flex-direction: column-reverse;
  //       .fourSteps {
  //         width: 100%;
  //       }
  //       .stepsImage {
  //         width: 100%;
  //         figure {
  //           width: 500px;
  //           margin: 0 auto;
  //           margin-bottom: 30px;
  //         }
  //       }
  //     }
  //   }
  // }

  .charity {
    margin: 60px 0 30px;

    .charityText {
      margin-bottom: 20px;

      h3 {
        font-size: 12px;
        margin-bottom: 9px;
      }
      h2 {
        font-size: 30px;
      }
    }
    .charitiesCard {
      width: 80%;
    }
    .charityCard {
      margin-right: 18px;
      &:nth-of-type(3n + 0) {
        margin-right: 18px;
      }
      &:nth-of-type(4n + 0) {
        margin-right: 18px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
    }

    .charityButtons {
      .basket {
        min-width: 130px !important;
        padding: 6px 15px;
        font-size: 13px;
        margin-right: 4px;
      }
      .charityHeart {
        min-width: 48px;
        padding: 2px 10px 2px;

        svg {
          width: 20px;
          height: 18px;
        }
      }
      .charityHeartActive {
        min-width: 48px;
        padding: 2px 10px 2px;

        svg {
          width: 20px;
          height: 18px;
        }
      }
    }

    .view {
      width: 200px;
      padding: 12px 0;
      font-size: 13px;
    }
  }

  // .register-banner {
  //   .home_register {
  //     padding: 40px 0;
  //   }

  //   .registerHome {
  //     width: 200px;
  //     padding: 6px 0;
  //     font-size: 13px;
  //   }
  // }

  .causes {
    margin: 30px 0 60px;

    .causeText {
      h3 {
        font-size: 13px;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
    .causeCard {
      margin-bottom: 28px;

      .causeCard {
        margin-right: 20px;
      }
    }
  }
}

@include responsive(xs) {
  .banner {
    padding: 50px 0;
    min-height: 280px;

    .banner-content {
      width: 100%;
      h1 {
        font-size: 30px;
        line-height: 40px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .banner-search {
    li {
      font-size: 12px;
      div {
        margin-left: 5px;
        span {
          width: 200px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  // .register-section {
  //   padding: 15px 0;

  //   .reg-wrap {
  //     .reg-left {
  //       h4 {
  //         font-size: 12px;
  //         margin-bottom: 14px;
  //       }

  //       h3 {
  //         font-size: 26px;
  //         margin-bottom: 15px;
  //       }

  //       figure {
  //         width: 100%;
  //         height: 200px;
  //         margin: 0 auto 15px;
  //       }

  //       .reg-button {
  //         width: 290px;
  //         font-size: 13px;
  //         padding: 14px 0;
  //       }
  //     }
  //     .reg-right {
  //       width: 100%;
  //       margin-top: 15px;
  //       flex-direction: column;
  //     }
  //   }
  // }

  // .contact-us {
  //   padding: 15px 0;

  //   .contact-wrapper {
  //     h2 {
  //       font-size: 18px;
  //       line-height: 25px;
  //       margin-bottom: 15px;
  //     }

  //     .contact-button {
  //       max-width: 240px;
  //       font-size: 13px;
  //       padding: 10px 60px;
  //     }
  //   }
  // }

  // .homeSteps {
  //   padding: 15px 0;

  //   .homeSteps-wrap {
  //     .homeSteps-title {
  //       width: 100%;
  //       h3 {
  //         font-size: 12px;
  //         margin-bottom: 10px;
  //       }
  //       h1 {
  //         font-size: 24px;
  //         margin-bottom: 15px;
  //       }
  //     }
  //     .homeSteps-steps {
  //       .stepsImage {
  //         figure {
  //           width: 100%;
  //           margin: 0 auto;
  //           margin-bottom: 28px;
  //         }
  //       }
  //     }
  //   }
  // }

  .charity {
    margin: 15px 0;

    .charityText {
      margin-bottom: 15px;

      h3 {
        font-size: 12px;
        margin-bottom: 8px;
      }
      h2 {
        font-size: 28px;
      }
      .nonProfit-search {
        width: 100%;
      }
    }
    .charitiesCard {
      width: 100%;
    }
    .charityCard {
      margin-right: 0px;
    }

    .charityButtons {
      justify-content: center;
      .basket {
        min-width: 130px !important;
        padding: 6px 24px;
        font-size: 12px;
        margin-right: 4px;
      }
    }

    .view {
      width: 180px;
      padding: 8px 0;
      font-size: 12px;
    }
  }

  // .register-banner {
  //   .home_register {
  //     padding: 15px 0;
  //   }

  //   .registerHome {
  //     width: 180px;
  //     padding: 6px 0;
  //     font-size: 12px;
  //   }
  // }

  .causes {
    margin: 15px 0;

    .causeText {
      h3 {
        font-size: 12px;
        margin-bottom: 8px;
      }
      h2 {
        font-size: 26px;
        margin-bottom: 15px;
      }
      .cause-search {
        width: 100%;
      }
    }
    .causeCard {
      margin-bottom: 15px;

      .causeCard {
        margin-right: 0px;
      }
    }
  }
}
