@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.leadershipCard {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;

  .main-card {
    width: 274px;
    min-height: 237px;
    background-color: $white;
    padding: 25px 30px;
    margin-bottom: 70px;
    margin-right: 14px;
    text-align: center;

    &:nth-child(4n + 0) {
      margin-right: 0;
    }

    figure {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-bottom: 7px;

      img {
        color: transparent;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    h4 {
      width: 214px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $font_lora;
      font-weight: 700;
      font-size: 24px;
      color: $black_1;
      margin-bottom: 4px;
      text-align: center;
    }

    p {
      font-family: $font_jost;
      height: 40px;
      overflow: hidden;
      font-weight: 400;
      font-size: 14px;
      color: $black_1;
      margin-bottom: 11px;
    }

    button {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $green-shadow;
      cursor: pointer;
      background: none;
    }
  }
}

.leaderModal {
  width: 630px;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 15px 30px;
  background-color: $white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    // position: absolute;
    // top: -36px;
    // right: 0;

    button {
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      background-color: $white;
    }
  }

  .modal-body {
    width: 100%;
    height: auto;
    background-color: $white;

    h4 {
      font-family: $font_lora;
      font-weight: 700;
      font-size: 24px;
      color: $black_1;
      margin-bottom: 6px;
      text-align: center;
    }

    h5 {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      color: $black_1;
      margin-bottom: 16px;
      text-align: center;
    }

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: $black_1;
      word-wrap: break-word;
      text-align: center;
    }
  }

  .modal-footer {
    display: none !important;
  }
}

// responsive
@include responsive(xl) {
  .leadershipCard {
    margin: 0 auto;
    .main-card {
      width: 260px;
      min-height: 210px;
      padding: 22px 28px;
      margin-right: 12px;
      margin-bottom: 65px;

      &:nth-child(4) {
        margin-right: 0px;
      }

      &:nth-child(8) {
        margin-right: 0px;
      }

      &:nth-child(12) {
        margin-right: 0px;
      }

      &:nth-child(16) {
        margin-right: 0px;
      }

      &:nth-child(20) {
        margin-right: 0px;
      }

      &:nth-child(24) {
        margin-right: 0px;
      }

      figure {
        width: 58px;
        height: 58px;
        margin-bottom: 6px;
      }
      h4 {
        width: 100%;
        font-size: 22px;
        margin-bottom: 2px;
      }
      p {
        height: 35px;
        font-size: 13px;
        margin-bottom: 10px;
      }
      button {
        font-size: 15px;
      }
    }
  }

  .leaderModal {
    width: 580px;
    padding: 15px 28px 20px;

    .modal-header {
      button {
        padding: 9px;
      }
    }
    .modal-body {
      h4 {
        font-size: 22px;
        margin-bottom: 4px;
      }
      h5 {
        font-size: 15px;
        margin-bottom: 14px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

@include responsive(lg) {
  .leadershipCard {
    width: 775px;
    margin: 0 auto;
    .main-card {
      width: 250px;
      min-height: 200px;
      padding: 22px 28px;
      margin-right: 12px;
      margin-bottom: 60px;

      &:nth-child(3) {
        margin-right: 0;
      }

      &:nth-child(4) {
        margin-right: 12px;
      }

      &:nth-child(6) {
        margin-right: 0;
      }

      &:nth-child(8) {
        margin-right: 12px;
      }

      &:nth-child(12) {
        margin-right: 0px;
      }

      &:nth-child(16) {
        margin-right: 12px;
      }

      &:nth-child(20) {
        margin-right: 12px;
      }

      &:nth-child(24) {
        margin-right: 12px;
      }

      figure {
        width: 55px;
        height: 55px;
        margin-bottom: 6px;
      }
      h4 {
        width: 100%;
        font-size: 22px;
        margin-bottom: 2px;
      }
      p {
        height: 30px;
        font-size: 13px;
        margin-bottom: 9px;
      }
      button {
        font-size: 15px;
      }
    }
  }

  .leaderModal {
    width: 560px;
    padding: 15px 25px 20px;

    .modal-header {
      button {
        padding: 8px;
      }
    }
    .modal-body {
      h4 {
        font-size: 20px;
        margin-bottom: 4px;
      }
      h5 {
        font-size: 14px;
        margin-bottom: 14px;
      }
      p {
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
}

@include responsive(md) {
  .leadershipCard {
    width: 620px;
    margin: 0 auto;
    .main-card {
      width: 200px;
      min-height: 175px;
      padding: 20px;
      margin-right: 9px;
      margin-bottom: 30px;

      &:nth-child(4) {
        margin-right: 11px;
      }

      figure {
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
      }
      h4 {
        font-size: 20px;
      }
      p {
        height: 25px;
        font-size: 12px;
        margin-bottom: 6px;
      }
      button {
        font-size: 14px;
      }
    }
  }

  .leaderModal {
    width: 500px;
    padding: 15px 25px 18px;

    .modal-header {
      button {
        padding: 6px;
      }
    }
    .modal-body {
      h4 {
        font-size: 18px;
        margin-bottom: 2px;
      }
      h5 {
        font-size: 12px;
        margin-bottom: 12px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

@include responsive(sm) {
  .leadershipCard {
    width: 472px;
    .main-card {
      width: 225px;
      min-height: 155px;
      padding: 18px;
      margin-right: 12px;
      margin-bottom: 24px;

      &:nth-child(2) {
        margin-right: 0;
      }

      &:nth-child(3) {
        margin-right: 11px;
      }

      &:nth-child(4n + 0) {
        margin-right: 0px;
      }

      figure {
        width: 45px;
        height: 45px;
        margin-bottom: 2px;
      }
      h4 {
        font-size: 18px;
      }
      p {
        height: 20px;
        font-size: 11px;
        margin-bottom: 4px;
      }
      button {
        font-size: 13px;
      }
    }
  }

  .leaderModal {
    width: 500px;
    padding: 15px 25px 16px;

    .modal-header {
      button {
        padding: 6px;
      }
    }
    .modal-body {
      h4 {
        font-size: 18px;
        margin-bottom: 2px;
      }
      h5 {
        font-size: 12px;
        margin-bottom: 12px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

@include responsive(xs) {
  .leadershipCard {
    width: 100%;
    .main-card {
      width: 100%;
      min-height: 135px;
      padding: 16px;
      margin-right: 0;
      margin-bottom: 12px;

      &:nth-child(3) {
        margin-right: 0px;
      }

      &:nth-child(4) {
        margin-right: 0px;
      }

      figure {
        width: 40px;
        height: 40px;
      }
      h4 {
        font-size: 14px;
        margin-bottom: 0;
      }
      p {
        height: 15px;
        font-size: 10px;
        margin-bottom: 2px;
      }
      button {
        font-size: 12px;
      }
    }
  }

  .leaderModal {
    width: 250px;
    max-height: 300px;
    padding: 15px;

    .modal-header {
      button {
        padding: 2px;

        svg {
          width: 10px;
        }
      }
    }
    .modal-body {
      h4 {
        font-size: 14px;
        margin-bottom: 0;
      }
      h5 {
        font-size: 11px;
        margin-bottom: 10px;
      }
    }
  }
}
