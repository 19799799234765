@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.card-body{
    background-color: $white;
    padding: 18px 64px 20px 75px;
    text-align: center;

    .logo{
        display: inline-block;
        margin-bottom: 30px;

        a{
            display: inline-block;
        }
    }

    h3{
        font-family: $font_lora;
        font-weight: 700;
        font-size: 25px;
        color: $black_1;
    }

    .footer{
        p{
            font-family: $font_jost;
            font-weight: 400;
            font-size: 12px;
            color: $light-gray;
        }
    }
}