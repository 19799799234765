@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.donationDetail {
  width: 100%;
  margin-top: 85px;
  padding: 13px 0 71px;

  .rosiBasketWrap {
    width: 100%;
    .basketContent {
      width: 100%;
      .basketContentWrap {
        width: 100%;
        .basketWrapCard {
          .basketSummary {
            margin-right: 20px;

            figure {
              width: 150px;
              margin-right: 30px;
            }

            .naming {
              .basketCharityName {
                width: 400px;
              }
            }
          }

          .basketPercent {
            .basketPercentWrap {
              width: 400px;
            }
            .percentButton {
              width: 80px;
            }
            .percentCancel {
              display: none;
            }
          }
        }
      }
    }
  }
}

.breadcrumbsDonationDetails {
  margin-bottom: 45px;
}

.donationButtonGrp {
  display: flex;
  margin-top: 40px;

  .updatedGrp {
    display: inline-block;
    width: 200px;
    border-radius: 50px;
    font-size: 18px;
    font-family: $font_jost;
    font-weight: 400;
    padding: 10px 22px;
    color: $white;
    text-align: center;
    cursor: pointer;
    @include prefix(transition, all 0.3s ease-in-out);
  }
  .donationUpdated {
    background-color: $black_1;
    margin-right: 20px;
    &:hover {
      background-color: $black;
    }
  }
  .donationCancel {
    background-color: $green-shadow;
    &:hover {
      background-color: $theme-color;
    }
  }
}

// responsive
@include responsive(xl) {
  .donationDetail {
    margin-top: 80px;
    padding: 12px 0 60px;

    .rosiBasketWrap {
      .basketContent {
        .basketContentWrap {
          .basketWrapCard {
            .basketSummary {
              margin-right: 2px;

              figure {
                width: 145px;
                margin-right: 28px;
              }

              .naming {
                .basketCharityName {
                  width: 374px;
                }
              }
            }

            .basketPercent {
              .basketPercentWrap {
                width: 400px;
                margin-right: 40px;
              }
              .percentButton {
                width: 78px;
              }
            }
          }
        }
      }
    }
  }

  .breadcrumbsDonationDetails {
    margin-bottom: 40px;
  }

  .donationButtonGrp {
    margin-top: 38px;

    .updatedGrp {
      width: 190px;
      font-size: 17px;
      padding: 10px 20px;
    }
    .donationUpdated {
      margin-right: 18px;
    }
  }
}

@include responsive(lg) {
  .donationDetail {
    padding: 12px 0 50px;

    .rosiBasketWrap {
      .basketContent {
        .basketContentWrap {
          .basketWrapCard {
            .basketSummary {
              margin-right: 2px;

              figure {
                width: 135px;
                margin-right: 25px;
              }

              .naming {
                .basketCharityName {
                  width: 300px;
                }
              }
            }

            .basketPercent {
              .basketPercentWrap {
                width: 330px;
                margin-right: 35px;
              }
              .percentButton {
                width: 75px;
              }
            }
          }
        }
      }
    }
  }

  .breadcrumbsDonationDetails {
    margin-bottom: 30px;
  }

  .donationButtonGrp {
    margin-top: 35px;

    .updatedGrp {
      width: 180px;
      font-size: 15px;
      padding: 9px 0px;
    }
    .donationUpdated {
      margin-right: 16px;
    }
  }
}

@include responsive(md) {
  .donationDetail {
    padding: 10px 0 40px;

    .rosiBasketWrap {
      .basketContent {
        .basketContentWrap {
          .basketWrapCard {
            .basketSummary {
              margin-right: 2px;

              figure {
                width: 110px;
                margin-right: 20px;
              }

              .naming {
                .basketCharityName {
                  width: 221px;
                }
              }
            }

            .basketPercent {
              .basketPercentWrap {
                width: 250px;
                margin-right: 30px;
              }
              .percentButton {
                width: 70px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .breadcrumbsDonationDetails {
    margin-bottom: 25px;
  }

  .donationButtonGrp {
    margin-top: 30px;

    .updatedGrp {
      width: 170px;
      font-size: 15px;
      padding: 8px 0px;
    }
    .donationUpdated {
      margin-right: 15px;
    }
  }
}

@include responsive(sm) {
  .donationDetail {
    padding: 10px 0 30px;

    .rosiBasketWrap {
      .basketContent {
        .basketContentWrap {
          .basketWrapCard {
            .basketSummary {
              margin-right: 2px;

              figure {
                width: 100px;
                margin-right: 18px;
              }

              .naming {
                .basketCharityName {
                  width: 134px;
                  margin-right: 5px;
                }
              }
            }

            .basketPercent {
              .basketPercentWrap {
                width: 175px;
                margin-right: 20px;
              }
              .percentButton {
                width: 60px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .breadcrumbsDonationDetails {
    margin-bottom: 20px;
  }

  .donationButtonGrp {
    margin-top: 20px;

    .updatedGrp {
      width: 150px;
      font-size: 14px;
      padding: 8px 0px;
    }
    .donationUpdated {
      margin-right: 15px;
    }
  }
}

@include responsive(xs) {
  .donationDetail {
    padding: 10px 0 15px;

    .rosiBasketWrap {
      .basketContent {
        .basketContentWrap {
          .basketWrapCard {
            .basketSummary {
              margin-right: 2px;

              figure {
                width: 100px;
                margin-right: 18px;
              }

              .naming {
                .basketCharityName {
                  width: 134px;
                  margin-right: 5px;
                }
              }
            }

            .basketPercent {
              .basketPercentWrap {
                width: 175px;
                margin-right: 20px;
              }
              .percentButton {
                width: 60px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .breadcrumbsDonationDetails {
    margin-bottom: 15px;
  }

  .donationButtonGrp {
    margin-top: 20px;

    .updatedGrp {
      width: 120px;
      font-size: 13px;
      padding: 7px 0px;
    }
    .donationUpdated {
      margin-right: 10px;
    }
  }
}
