@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.signin {
  width: 100%;
  background-color: $light-green;
  padding: 72px 40px;
  margin-top: 85px;

  .container-main {
    width: 1320px;
  }

  .signin-wrapper {
    display: flex;
    justify-content: space-between;

    .signinSwiper {
      width: 50%;
      overflow: hidden;

      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .form {
    .main-input {
      margin-bottom: 14px;
      margin-top: 21px;
    }

    .form-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border: 1px solid $light-gray;
      padding: 10px 15px 7px 14px;
      border-radius: 7px;
      background-color: $white;

      input {
        width: 250px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
        background: none !important;
      }

      ::-webkit-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      :-ms-input-placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }

      ::placeholder {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
      }
    }

    .form-group:focus-within {
      border: 1px solid $theme-color;
      svg path,
      circle,
      .react {
        stroke: $green-shadow;
        fill: none;
      }
    }

    .main-pass {
      margin-bottom: 14px;
    }

    .forgot {
      display: flex;
      // flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 50px;

      .remember {
        display: flex;
        align-items: center;

        .check {
          width: 16px;
          height: 16px;
          background-color: white;
          border-radius: 50%;
          vertical-align: middle;
          border: 1px solid $theme-color;
          appearance: none;
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
        }

        .check:checked {
          background: url("../../../public/images/remember_icon.svg");
          border: none;
          background-position: 50%;
        }

        label {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          color: $light-gray;
          letter-spacing: 0.04em;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      .pass {
        a {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          color: $light-gray;
          letter-spacing: 0.04em;
        }
      }
    }

    .sign-btn {
      margin-bottom: 14px;
      margin-top: 35px;

      .buttonSubmit {
        display: inline-block;
        cursor: pointer;
        padding: 11px 84px 11px 85px;
        width: 350px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        background-color: $green-shadow;
        border-radius: 50px;
        text-align: center;
        letter-spacing: 0.04em;
      }
    }

    .guest {
      margin-bottom: 16px;

      a {
        display: inline-block;
        padding: 11px 84px 11px 85px;
        width: 350px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        background-color: $black_1;
        border-radius: 50px;
        text-align: center;
        letter-spacing: 0.04em;
      }
    }

    .empl {
      margin-bottom: 42px;

      a {
        font-family: $font_jost;
        font-weight: 500;
        font-size: 14px;
        color: $green-shadow;
        letter-spacing: 0.04em;
        margin-right: 9px;
      }
    }

    .signin-account {
      margin-bottom: 13px;

      h5 {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black;
        letter-spacing: 0.04em;

        a {
          color: $green-shadow;
          margin-left: 3px;
          font-weight: 500;
        }
      }
    }
  }
}

.address-terms {
  margin-bottom: 30px;
  // margin-left: 25px;

  .rememberReg {
    justify-content: start;
    // text-transform: capitalize;

    a {
      color: $green-shadow;
    }
    h5 {
      text-align: left;
    }
  }
}

// responsive
@include responsive(xl) {
  .signin {
    padding: 70px 38px;
    margin-top: 80px;

    .signin-wrapper {
      .signinSwiper {
        width: 46%;
      }

      .main-swiper {
        width: 100% !important;
      }
      figure {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .card-body {
      width: 500px;
      padding: 16px 60px 18px 60px !important;

      .logo {
        margin-bottom: 28px;
      }
      h3 {
        font-size: 24px;
      }

      .form {
        .main-input {
          margin-bottom: 12px;
          margin-top: 20px;
        }
        .form-group {
          padding: 8px 14px 6px 12px;

          input {
            width: 325px;
            font-size: 14px;
          }
          ::-webkit-input-placeholder {
            font-size: 14px;
          }

          :-ms-input-placeholder {
            font-size: 14px;
          }

          ::placeholder {
            font-size: 14px;
          }
        }

        .main-pass {
          margin-bottom: 13px;
        }

        .forgot {
          margin-bottom: 48px;

          .remember {
            .check {
              width: 15px;
              height: 15px;
            }
            label {
              font-size: 12px;
              margin-left: 4px;
            }
          }

          .pass {
            a {
              font-size: 12px;
            }
          }
        }

        .sign-btn {
          margin-bottom: 14px;

          .buttonSubmit {
            width: 340px;
            padding: 10px 80px;
            font-size: 14px;
          }
        }
        .guest {
          margin-bottom: 15px;
          a {
            width: 340px;
            padding: 10px 80px;
            font-size: 14px;
          }
        }
        .empl {
          margin-bottom: 38px;
          a {
            font-size: 14px;
            margin-right: 8px;
          }
        }
        .signin-account {
          margin-bottom: 100px !important;
          h5 {
            font-size: 14px;
            a {
              margin-left: 2px;
            }
          }
        }
      }

      .footer {
        p {
          font-size: 12px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .signin {
    padding: 68px 36px;

    .signin-wrapper {
      .signinSwiper {
        width: 43%;
      }
    }

    .card-body {
      width: 435px;
      padding: 15px 50px !important;

      .logo {
        margin-bottom: 26px;
      }
      h3 {
        font-size: 22px;
      }

      .form {
        .main-input {
          margin-bottom: 12px;
          margin-top: 18px;
        }
        .form-group {
          padding: 8px 13px 6px 12px;

          input {
            width: 290px;
            font-size: 13px;
          }
          ::-webkit-input-placeholder {
            font-size: 13px;
          }

          :-ms-input-placeholder {
            font-size: 13px;
          }

          ::placeholder {
            font-size: 13px;
          }
        }

        .main-pass {
          margin-bottom: 12px;
        }

        .forgot {
          margin-bottom: 45px;

          .remember {
            .check {
              width: 15px;
              height: 15px;
            }
            label {
              font-size: 11px;
              margin-left: 4px;
            }
          }

          .pass {
            a {
              font-size: 11px;
            }
          }
        }

        .sign-btn {
          margin-bottom: 13px;

          .buttonSubmit {
            width: 335px;
            padding: 10px 70px;
            font-size: 13px;
          }
        }
        .guest {
          margin-bottom: 14px;
          a {
            width: 335px;
            padding: 10px 70px;
            font-size: 13px;
          }
        }
        .empl {
          margin-bottom: 35px;
          a {
            font-size: 13px;
            margin-right: 8px;
          }
        }
        .signin-account {
          margin-bottom: 100px !important;
          h5 {
            font-size: 13px;
            a {
              margin-left: 2px;
            }
          }
        }
      }

      .footer {
        p {
          font-size: 11px;
        }
      }
    }
  }
}

@include responsive(md) {
  .signin {
    padding: 0px;
    .signin-wrapper {
      width: 100%;
      height: 100vh;
      justify-content: center;
      align-items: center;
      .signinSwiper {
        display: none;
      }
      .card-body {
        width: 450px;
        padding: 12px 45px !important;

        .logo {
          margin-bottom: 22px;
        }
        h3 {
          font-size: 18px;
        }

        .form {
          .main-input {
            margin-bottom: 10px;
            margin-top: 15px;
          }
          .form-group {
            padding: 7px 12px 5px 12px;
            input {
              width: 250px;
              font-size: 12px;
            }
            ::-webkit-input-placeholder {
              font-size: 12px;
            }

            :-ms-input-placeholder {
              font-size: 12px;
            }

            ::placeholder {
              font-size: 12px;
            }
          }
          .main-pass {
            margin-bottom: 11px;
          }

          .forgot {
            margin-bottom: 35px;

            .remember {
              .check {
                width: 14px;
                height: 14px;
              }
              label {
                font-size: 11px;
                margin-left: 4px;
              }
            }

            .pass {
              a {
                font-size: 10px;
              }
            }
          }

          .sign-btn {
            margin-bottom: 12px;

            .buttonSubmit {
              width: 310px;
              padding: 10px 65px;
              font-size: 12px;
            }
          }
          .guest {
            margin-bottom: 13px;
            a {
              width: 310px;
              padding: 10px 65px;
              font-size: 12px;
            }
          }
          .empl {
            margin-bottom: 30px;
            a {
              font-size: 12px;
              margin-right: 8px;
            }
          }
          .signin-account {
            margin-bottom: 80px !important;
            h5 {
              font-size: 12px;
            }
          }
        }

        .footer {
          p {
            font-size: 11px;
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .signin {
    .signin-wrapper {
      .card-body {
        width: 420px;
        padding: 12px 40px !important;

        .logo {
          margin-bottom: 18px;
        }
        h3 {
          font-size: 16px;
        }

        .form {
          .main-input {
            margin-bottom: 8px;
            margin-top: 12px;
          }
          .form-group {
            padding: 6px 10px 5px 10px;
            input {
              width: 270px;
              font-size: 12px;
            }
            ::-webkit-input-placeholder {
              font-size: 12px;
            }

            :-ms-input-placeholder {
              font-size: 12px;
            }

            ::placeholder {
              font-size: 12px;
            }
          }
          .main-pass {
            margin-bottom: 10px;
          }

          .forgot {
            margin-bottom: 28px;

            .remember {
              .check {
                width: 14px;
                height: 14px;
              }
              label {
                font-size: 11px;
                margin-left: 4px;
              }
            }

            .pass {
              a {
                font-size: 10px;
              }
            }
          }

          .sign-btn {
            margin-bottom: 12px;

            .buttonSubmit {
              width: 300px;
              padding: 10px 65px;
              font-size: 12px;
            }
          }
          .guest {
            margin-bottom: 13px;
            a {
              width: 300px;
              padding: 10px 65px;
              font-size: 12px;
            }
          }
          .empl {
            margin-bottom: 25px;
            a {
              font-size: 12px;
              margin-right: 5px;
            }
          }
          .signin-account {
            margin-bottom: 60px !important;
            h5 {
              font-size: 12px;
            }
          }
        }

        .footer {
          p {
            font-size: 11px;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .signin {
    .signin-wrapper {
      .card-body {
        width: 290px;
        padding: 10px 17px !important;

        .logo {
          width: 100px;
          margin-bottom: 16px;
        }
        h3 {
          font-size: 14px;
        }

        .form {
          .main-input {
            margin-bottom: 6px;
            margin-top: 10px;
          }
          .form-group {
            padding: 6px 10px 5px 10px;
            margin: 15px 0 0;
            input {
              font-size: 11px;
            }
            ::-webkit-input-placeholder {
              font-size: 11px;
            }

            :-ms-input-placeholder {
              font-size: 11px;
            }

            ::placeholder {
              font-size: 11px;
            }
          }
          .main-pass {
            margin-bottom: 10px;
          }

          .forgot {
            margin-bottom: 20px;

            .remember {
              .check {
                width: 13px;
                height: 13px;
              }
              label {
                font-size: 10px;
              }
            }
          }

          .sign-btn {
            margin-bottom: 10px;

            .buttonSubmit {
              width: 210px;
              padding: 8px 45px;
              font-size: 11px;
            }
          }
          .guest {
            margin-bottom: 12px;
            a {
              width: 210px;
              padding: 8px 45px;
              font-size: 11px;
            }
          }
          .empl {
            margin-bottom: 20px;
            a {
              font-size: 11px;
              margin-right: 4px;
            }
          }
          .signin-account {
            margin-bottom: 50px !important;
            h5 {
              font-size: 11px;
            }
          }
        }

        .footer {
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}
