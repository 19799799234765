@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.ratingSeal {
  .sealHeading {
    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      margin-bottom: 14px;
    }
  }

  .sealLogo {
    display: flex;
    align-items: center;
    width: 34%;

    figure {
      width: 95px;
      height: 95px;
    }

    h4 {
      font-family: $font_jost;
      font-weight: 500;
      font-size: 18px;
      color: $black_1;
      margin-left: 18px;
    }
  }
  .seal-question {
    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      margin-bottom: 24px;
    }
    .questionAnswer {
      margin-bottom: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }

      h4 {
        font-family: $font_jost;
        font-weight: 500;
        font-size: 14px;
        color: $black;
        line-height: 25px;
        text-transform: capitalize;
      }
      h5 {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $light-gray;
        line-height: 25px;
        text-transform: capitalize;
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .ratingSeal {
    .sealHeading {
      h3 {
        font-size: 15px;
      }
    }

    .sealLogo {
      width: 42%;

      figure {
        width: 92px;
        height: 92px;
      }
      h4 {
        font-size: 17px;
      }
    }
    .seal-question {
      h3 {
        font-size: 15px;
        margin-bottom: 22px;
      }
      .questionAnswer {
        margin-bottom: 23px;

        h4 {
          font-size: 13px;
          line-height: 23px;
        }
        h5 {
          font-size: 13px;
          line-height: 23px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .ratingSeal {
    .sealHeading {
      h3 {
        font-size: 14px;
      }
    }

    .sealLogo {
      width: 53%;

      figure {
        width: 90px;
        height: 90px;
      }
      h4 {
        font-size: 15px;
      }
    }
    .seal-question {
      h3 {
        font-size: 14px;
        margin-bottom: 20px;
      }
      .questionAnswer {
        margin-bottom: 20px;

        h4 {
          font-size: 13px;
          line-height: 20px;
        }
        h5 {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
}

@include responsive(md) {
  .ratingSeal {
    .sealHeading {
      h3 {
        font-size: 14px;
      }
    }

    .sealLogo {
      width: 33%;

      figure {
        width: 88px;
        height: 88px;
      }
      h4 {
        font-size: 14px;
      }
    }
    .seal-question {
      h3 {
        font-size: 14px;
        margin-bottom: 18px;
      }
      .questionAnswer {
        margin-bottom: 18px;

        h4 {
          font-size: 13px;
          line-height: 18px;
        }
        h5 {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .ratingSeal {
    .sealHeading {
      h3 {
        font-size: 13px;
      }
    }

    .sealLogo {
      width: 39%;

      figure {
        width: 85px;
        height: 85px;
      }
      h4 {
        font-size: 13px;
      }
    }
    .seal-question {
      h3 {
        font-size: 13px;
        margin-bottom: 16px;
      }
      .questionAnswer {
        margin-bottom: 16px;

        h4 {
          font-size: 12px;
          line-height: 16px;
        }
        h5 {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .ratingSeal {
    .sealHeading {
      h3 {
        font-size: 13px;
      }
    }

    .sealLogo {
      width: 100%;

      figure {
        width: 80px;
        height: 80px;
      }
      h4 {
        font-size: 12px;
      }
    }
    .seal-question {
      h3 {
        margin-bottom: 15px;
      }
      .questionAnswer {
        margin-bottom: 15px;

        h4 {
          line-height: 16px;
        }
        h5 {
          line-height: 16px;
        }
      }
    }
  }
}
