@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.memberTab {
  padding: 30px 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background-color: $white;
  .tabsForMember {
    .react-tabs__tab-list {
      display: flex;
      justify-content: start !important;
      background-color: white !important;
      padding: 0 0 10px 30px !important;
      margin-bottom: 0px !important;
    }
    .react-tabs__tab {
      font-size: 16px !important;
      background-color: white !important;

      &:first-of-type {
        margin-right: 40px;
      }
    }
    .react-tabs__tab--selected {
      background-color: white !important;
    }
  }
}

.content {
  padding: 0 30px;
}

.friends,
.members {
  display: flex;
  flex-wrap: wrap;
  max-height: 619px;

  .memberDetails {
    width: 327px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 38px;
    margin-top: 30px;
    &:nth-of-type(2n + 0) {
      margin-right: 0px;
    }

    .memberDetailsWrap {
      display: flex;
      align-items: center;
    }
    .memberImage {
      display: inline-block;
      width: 55px;
      height: 55px;
      margin-right: 12px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .memberName {
      margin-right: 10px;
      h3 {
        width: 120px;
        font-family: $font_jost;
        font-weight: 500;
        color: $black_1;
        font-size: 14px;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      h4 {
        width: 120px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        color: $gray;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .memberButton {
      button {
        width: 130px;
        background-color: $white-dark;
        cursor: pointer;
        border-radius: 20px;
        padding: 8px 17px 8px 16px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $black_1;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $gray;
        }
      }
    }
    .friendButton {
      button {
        width: 130px;
        background-color: $green-shadow;
        cursor: pointer;
        border-radius: 20px;
        padding: 8px 17px 8px 16px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .memberTab {
    padding: 28px 0;
    .tabsForMember {
      .react-tabs__tab-list {
        padding: 0 0 8px 28px !important;
      }
      .react-tabs__tab {
        font-size: 15px !important;

        &:first-of-type {
          margin-right: 38px;
        }
      }
    }
  }

  .content {
    padding: 0 28px;
  }

  .friends,
  .members {
    .memberDetails {
      width: 300px;
      margin-right: 36px;
      margin-top: 28px;

      .memberImage {
        width: 52px;
        height: 52px;
        margin-right: 10px;
      }
      .memberName {
        margin-right: 9px;
        h3 {
          width: 110px;
          font-size: 13px;
        }
        h4 {
          width: 110px;
          font-size: 12px;
        }
      }
      .memberButton {
        button {
          width: 120px;
          padding: 7px 15px;
          font-size: 13px;
        }
      }
      .friendButton {
        button {
          width: 120px;
          padding: 7px 15px;
          font-size: 13px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .memberTab {
    padding: 26px 0;
    .tabsForMember {
      .react-tabs__tab-list {
        padding: 0 0 8px 26px !important;
      }
      .react-tabs__tab {
        font-size: 14px !important;

        &:first-of-type {
          margin-right: 34px;
        }
      }
    }
  }

  .content {
    padding: 0 26px;
  }

  .friends,
  .members {
    .memberDetails {
      width: 300px;
      margin-right: 34px;
      margin-top: 26px;

      .memberImage {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      .memberName {
        margin-right: 9px;
        h3 {
          width: 110px;
          font-size: 13px;
        }
        h4 {
          width: 110px;
          font-size: 12px;
        }
      }
      .memberButton {
        button {
          width: 110px;
          padding: 7px 14px;
          font-size: 13px;
        }
      }
      .friendButton {
        button {
          width: 110px;
          padding: 7px 14px;
          font-size: 13px;
        }
      }
    }
  }
}

@include responsive(md) {
  .memberTab {
    padding: 24px 0;
    .tabsForMember {
      .react-tabs__tab-list {
        padding: 0 0 8px 24px !important;
      }
      .react-tabs__tab {
        font-size: 13px !important;

        &:first-of-type {
          margin-right: 30px;
        }
      }
    }
  }

  .content {
    padding: 0 24px;
  }

  .friends,
  .members {
    .memberDetails {
      width: 280px;
      margin-right: 24px;
      margin-top: 24px;

      &:nth-of-type(2n + 0){
        margin-right: 24px;
      }

      .memberImage {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }
      .memberName {
        margin-right: 8px;
        h3 {
          width: 100px;
          font-size: 13px;
        }
        h4 {
          width: 100px;
          font-size: 12px;
        }
      }
      .memberButton {
        button {
          width: 100px;
          padding: 7px 13px;
          font-size: 12px;
        }
      }
      .friendButton {
        button {
          width: 100px;
          padding: 7px 12px;
          font-size: 12px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .memberTab {
    padding: 22px 0;
    .tabsForMember {
      .react-tabs__tab-list {
        padding: 0 0 8px 22px !important;
      }
      .react-tabs__tab {
        font-size: 12px !important;

        &:first-of-type {
          margin-right: 25px;
        }
      }
    }
  }

  .content {
    padding: 0 20px;
  }

  .friends,
  .members {
    .memberDetails {
      width: 250px;
      margin-right: 25px;
      margin-top: 20px;

      .memberImage {
        width: 46px;
        height: 46px;
        margin-right: 8px;
      }
      .memberName {
        margin-right: 8px;
        h3 {
          width: 90px;
          font-size: 12px;
        }
        h4 {
          width: 90px;
          font-size: 11px;
        }
      }
      .memberButton {
        button {
          width: 95px;
          padding: 7px 12px;
          font-size: 11px;
        }
      }
      .friendButton {
        button {
          width: 95px;
          padding: 7px 12px;
          font-size: 11px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .memberTab {
    padding: 15px 0;
    .tabsForMember {
      .react-tabs__tab-list {
        padding: 0 0 8px 15px !important;
      }
      .react-tabs__tab {
        font-size: 12px !important;

        &:first-of-type {
          margin-right: 15px;
        }
      }
    }
  }

  .content {
    padding: 0 15px;
  }

  .friends,
  .members {
    .memberDetails {
      width: 230px;
      margin-right: 0px;
      margin-top: 15px;

      .memberImage {
        width: 40px;
        height: 40px;
        margin-right: 5px;
      }
      .memberName {
        margin-right: 6px;
        h3 {
          width: 80px;
          font-size: 12px;
        }
        h4 {
          width: 80px;
          font-size: 11px;
        }
      }
      .memberButton {
        button {
          width: 90px;
          padding: 6px 0px;
          font-size: 10px;
        }
      }
      .friendButton {
        button {
          width: 90px;
          padding: 6px 0px;
          font-size: 10px;
        }
      }
    }
  }
}
