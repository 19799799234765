@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.header {
  display: inline-block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white;
  box-shadow: 0 1px 0 rgba($color: $black, $alpha: 0.1);
  z-index: 9999;

  .full-container {
    width: 100%;
    max-width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }

  .header-wrap {
    position: relative;
    width: 100%;

    .logo {
      a {
        display: inline-block;
        width: 150px;
        height: 85px;

        img {
          height: 100%;
        }
      }
    }

    .nav {
      .navigation {
        margin-right: 30px;
        .header-menu {
          display: flex;
          align-items: center;
          li {
            display: inline-block;
            margin-right: 30px;
            text-align: center;
            &:last-of-type {
              margin-right: 0;
            }
            a,
            .headerDropdown {
              display: inline-block;
              font-size: 19px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 1px;
              position: relative;
              font-family: $font_jost;
              color: rgba($color: $black, $alpha: 0.6);
              cursor: pointer;
              @include prefix(transition, all 0.3s ease-in-out);
            }
            .headerDropdown .dropdownLink {
              color: rgba($color: $black, $alpha: 0.6);
              &:hover {
                opacity: 0.7;
              }
            }
            a {
              &:hover {
                opacity: 0.7;
              }
            }
            a.active {
              color: $brown;
            }
            .more {
              margin-left: 8px;
            }
            .moreAnimation {
              margin-left: 10px;
            }
          }

          .registerItem {
            display: none;
          }
        }

        .dropdown {
          opacity: 0;
          visibility: hidden;

          width: 240px;
          position: absolute;
          top: 50px;
          box-shadow: 0 2px 5px rgba($color: $black, $alpha: 0.1);
          border-top: 3px solid #931c1c;
          background-color: $white;
          padding: 20px 0;
          @include prefix(transition, all 0.2s ease-in-out);

          li {
            padding: 0 20px;
            margin: 0;
            line-height: 2em;
            font-size: 14px;

            a {
              width: 100%;
              padding: 6px 20px;
              letter-spacing: 1px;
              line-height: unset;

              &:hover {
                opacity: 0.7;
                background: rgba($color: $black, $alpha: 0.03);
              }
            }
          }
        }

        .headerDropdown:hover {
          .dropdown {
            visibility: visible;
            opacity: 1;
          }
        }

        ul.dropdown {
          display: flex;
          flex-direction: column;
          text-align: left;
        }

        /* toggle */
        .toggle-area {
          cursor: pointer;
          position: relative;
          display: none;
          width: 25px;
          height: 25px;
        }
        .toggle {
          position: absolute;
          top: 12px;
        }
        .toggle-area li {
          display: block;
          margin-bottom: 5px;
          background-color: $theme-color;
          width: 21px;
          height: 3px;
          border-radius: 25px;
          position: absolute;
          -webkit-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
        .toggle-area.close-btn1 li:nth-child(1),
        .toggle-area.close-btn1 li:nth-child(2) {
          transform: rotate(45deg);
          -o-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
        .toggle-area.close-btn1 li:nth-child(3) {
          transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
        .toggle-area .top {
          top: -7px;
        }
        .toggle-area .bottom {
          top: 7px;
        }
        .toggle-area.close-btn1 .top,
        .toggle-area.close-btn1 .bottom {
          top: 0;
        }
      }

      .navigation-right {
        .withoutLoginList {
          display: flex;
          align-items: center;
          position: relative;
          background-color: white;

          li {
            .register {
              margin-top: 29px;
              display: inline-block;
              width: 105px;
              height: auto;
              text-align: center;
              padding: 8px 15px 20px;
              background-color: $green-shadow;
              border-radius: 5px;
              color: $white;
              font-weight: 600;
              font-size: 18px;
              letter-spacing: 1px;
              font-family: $font_jost;
              @include prefix(transition, all 0.4s ease-in-out);
              &:hover {
                opacity: 0.7;
                background-color: #931c1c;
              }
            }
            &:first-child {
              margin-right: 20px;
            }
            .guestName {
              width: 180px;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: $font_jost;
              font-size: 14px;
              text-transform: capitalize;
              color: $green-shadow;
            }
          }
        }
        .logginList {
          display: flex;
          align-items: center;

          li {
            margin-right: 15px;
            &:nth-child(3) {
              margin-right: 15px;
            }
            &:nth-child(4) {
              margin-right: 30px;
            }
            &:not(:first-of-type) {
              z-index: 55;
            }
            &:last-of-type {
              margin-right: 0;
            }
            .userHeader {
              display: flex;
              align-items: center;
              h4 {
                width: 80px;
                white-space: nowrap;
                overflow: hidden;
                font-family: $font_jost;
                font-weight: 500;
                font-size: 14px;
                color: $black_1;
                text-transform: capitalize;
                max-height: 20px;
                text-overflow: ellipsis;
              }
            }
            .userProfile {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              border: 1px solid $green-shadow;
              margin-right: 6px;
              img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                color: transparent;
              }
            }
            .message {
              position: relative;
              img {
                width: 26px;
              }
              span {
                position: absolute;
                top: -1px;
                left: 18px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: $red;
                border: 2px solid $white;
              }
            }
            .exitBtn {
              width: 25px;
              height: 25px;
              background: transparent;
              cursor: pointer;
              position: relative;
            }
            .bell {
              position: relative;
              span {
                position: absolute;
                top: 2px;
                left: 16px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: $red;
                border: 2px solid $white;
              }
            }
          }
        }
      }
    }
  }
}

.searchItm {
  display: flex;
  align-items: center;
  .charityRight {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.basketCount {
  position: relative;

  span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -2px;
    left: 16px;
    padding: 1px 0 3px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $red;
    font-family: $font_jost;
    font-weight: 700;
    font-size: 9px;
    line-height: 13px;
    color: $white;
  }
}

.search {
  position: relative;

  .searchInput {
    display: none;
  }

  .autoSearch {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    border-radius: 50px;
    opacity: 1;
    z-index: 40;

    .wrapper {
      background-color: #f3f3f3;
      border: none;
      padding: 4px 0;
      @include prefix(transition, all 0.3s ease-in-out);
    }

    input {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
      background-color: transparent;
    }

    ::-webkit-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
      text-transform: capitalize;
    }

    :-ms-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
      text-transform: capitalize;
    }

    ::placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
      text-transform: capitalize;
    }

    li {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;

      &.selected {
        background-color: $white-dark;
        margin-right: 0 !important;
      }
    }

    span {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
    }
  }

  .autoSearch > div {
    width: 100%;
  }
}

.logOutSearch {
  .autoSearch {
    width: 848px;
    height: 58px;
    left: -820px;
    padding-left: 20px;

    input {
      width: 765px;
    }
  }
}

.loginSearch {
  .autoSearch {
    width: 846px;
    height: 58px;
    left: -815px;
    padding-left: 20px;

    input {
      width: 765px;
    }
  }
}

// responsive
@include responsive(xl) {
  .header {
    .header-wrap {
      .logo a {
        height: 80px;
      }

      .nav {
        .navigation {
          .header-menu {
            li {
              margin-right: 24px;
              &:last-of-type {
                margin-right: 0;
              }
              a,
              .headerDropdown {
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
          .dropdown {
            width: 160px;
            padding: 10px 0;
            li {
              margin-right: 0;
              a {
                padding: 6px;
              }
            }
          }
        }

        .navigation-right {
          .withoutLoginList {
            li {
              .guestName {
                font-size: 12px;
              }
            }
          }
          .logginList {
            li {
              margin-right: 12px;
              &:nth-child(3) {
                margin-right: 12px;
              }
              &:nth-child(4) {
                margin-right: 18px;
              }
              .userHeader {
                h4 {
                  display: none;
                }
              }
              .userProfile {
                width: 26px;
                height: 26px;
              }
            }
          }
        }
      }
    }
  }

  .basketCount {
    span {
      width: 15px;
      height: 15px;
      padding: 0;
      line-height: 12px;
    }
  }

  .search {
    .autoSearch {
      .wrapper {
        padding: 4px 0;
      }

      input {
        font-size: 13px;
      }

      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }

      li {
        font-size: 13px;
      }

      span {
        font-size: 13px;
      }
    }
  }

  .logOutSearch {
    .autoSearch {
      width: 800px;
      left: -774px;
      padding-left: 0;

      input {
        width: 575px;
      }
    }
  }

  .loginSearch {
    .autoSearch {
      width: 620px;
      left: -595px;
      padding-left: 0;

      input {
        width: 575px;
      }
    }
  }
}

@include responsive(lg) {
  .header {
    .full-container {
      width: 80%;
      max-width: 1080px;
      padding: 0 15px;
    }

    .header-wrap {
      .logo a {
        max-width: 75px;
      }
      .nav {
        flex-direction: row-reverse;
        justify-content: flex-end;
        .navigation {
          margin-right: 0;
          .header-menu {
            display: none;
            max-height: 350px;
            overflow: hidden auto;

            li {
              text-align: left;
              &:last-of-type {
                margin-right: 0;
                border-bottom: 0;
              }
              a,
              .headerDropdown {
                line-height: 2em;
                font-size: 14px;
                border-bottom: 1px solid rgba($color: $black, $alpha: 0.03);
              }
              .headerDropdown {
                width: 100%;
                padding: 0;
                .dropdownLink {
                  width: 100%;
                  display: inline-block;
                  padding: 10px 5%;
                  line-height: 2em;
                  background-color: rgba($color: $black, $alpha: 0.03);
                }
                .moreAnimation,
                .more {
                  position: absolute;
                  right: 20px;
                  bottom: 0;
                  top: 8px;
                }
              }
              .dropdownLink br {
                display: none;
              }
            }
          }

          .dropdown {
            visibility: visible;
            opacity: 1;
            position: static;
            border: none;
            padding: 0 0 0 10px;
            box-shadow: none;
            li {
              padding: 0 0 0 5%;
            }
            a {
              border-bottom: 1px solid rgba($color: $black, $alpha: 0.03);
            }
          }

          ul.dropdown {
            width: 100%;
          }
          .toggle-area {
            display: block;
            margin-left: 20px;
          }
          .open-menu.header-menu {
            width: 100%;
            background-color: $white;
            border-top: 3px solid $theme-color;
            position: absolute;
            top: 80px;
            left: 0;
            right: 0;
            z-index: 111;
            display: block;
            padding: 3% 5%;
            box-shadow: 0 4px 8px rgba($color: $black, $alpha: 0.2);
            li {
              display: block;
              margin-right: 0;
              margin-bottom: 0px;
              a {
                min-width: 100%;
                width: 100%;
                font-size: 14px;
                padding: 10px 5%;
                color: #666666;
                &:hover {
                  background-color: $light-green;
                }
              }
              a.active {
                min-width: 100px;
                padding: 10px 0;
              }
            }
          }
        }

        .navigation-right {
          .withoutLoginList {
            li {
              .register {
                width: 160px;
                height: 32px;
                padding: 7px 0;
                font-size: 13px;
              }
              &:first-child {
                margin: 0;
              }
              &:nth-child(2) {
                display: none;
              }
              .guestName {
                width: 170px;
              }
            }
          }
          .logginList {
            li {
              margin-right: 12px;
              .userHeader {
                h4 {
                  width: 70px;
                  font-size: 13px;
                  max-height: 18px;
                }
              }
              .userProfile {
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }
  }

  .basketCount {
    span {
      width: 15px;
      height: 15px;
      padding: 0;
      line-height: 12px;
    }
  }

  .search {
    .autoSearch {
      .wrapper {
        padding: 4px 0;
      }

      input {
        font-size: 13px;
      }

      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }

      li {
        font-size: 13px;
      }

      span {
        font-size: 13px;
      }
    }
  }

  .logOutSearch {
    .autoSearch {
      width: 580px;
      left: -550px;
      padding-left: 0;

      input {
        width: 650px;
      }
    }
  }

  .loginSearch {
    .autoSearch {
      width: 530px;
      left: -507px;
      padding-left: 0;

      input {
        width: 490px;
      }
    }
  }
}

@include responsive(md) {
  .header {
    .header-wrap {
      .nav {
        .navigation-right {
          .withoutLoginList {
            li {
              .register {
                width: 160px;
                height: 32px;
                padding: 7px 0;
                font-size: 13px;
              }
              .guestName {
                width: 160px;
                font-size: 13px;
              }
            }
          }
          .logginList {
            li {
              margin-right: 10px;
              img,
              svg {
                width: 22px;
                height: 22px;
              }
              &:nth-child(3) {
                margin-right: 12px;
              }
              &:nth-child(4) {
                margin-right: 16px;
              }
              .userHeader {
                h4 {
                  width: 65px;
                  font-size: 13px;
                  max-height: 18px;
                }
              }
              .userProfile {
                width: 26px;
                height: 26px;
              }
            }
          }
        }
      }
    }
  }
  .basketCount {
    span {
      width: 14px;
      height: 14px;
      padding: 0;
      line-height: 12px;
      left: 14px;
    }
  }
  .search {
    .autoSearch {
      .wrapper {
        padding: 4px 0;
      }

      input {
        font-size: 13px;
      }

      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }

      li {
        font-size: 13px;
      }

      span {
        font-size: 13px;
      }
    }
  }
  .logOutSearch {
    .autoSearch {
      width: 450px;
      left: -420px;
      padding-left: 0;

      input {
        width: 510px;
      }
    }
  }
  .loginSearch {
    .autoSearch {
      width: 360px;
      left: -334px;
      padding-left: 0;

      input {
        width: 320px;
      }
    }
  }
}

@include responsive(sm) {
  .header {
    .header-wrap {
      .nav {
        .navigation {
          .header-menu {
            display: none;
            li {
              &:last-of-type {
                margin-right: 0;
              }
              a,
              .headerDropdown {
                font-size: 12px;
              }
            }
          }
          .dropdown {
            li {
              margin-right: 0;
            }
          }
          .toggle-area {
            margin-left: 15px;
          }
          .open-menu.header-menu {
            li {
              a {
                font-size: 12px;
              }
            }
          }
        }

        .navigation-right {
          .withoutLoginList {
            li {
              .register {
                width: 150px;
                height: 30px;
                padding: 5px 0;
                font-size: 13px;
              }
              &:nth-child(2) {
                margin-right: 15px;
              }
              .guestName {
                width: 160px;
                font-size: 13px;
              }
            }
          }
          .logginList {
            li {
              margin-right: 10px;
              img,
              svg {
                width: 20px;
                height: 20px;
              }
              &:nth-child(3) {
                margin-right: 11px;
              }
              &:nth-child(4) {
                margin-right: 15px;
              }
              .userHeader {
                h4 {
                  width: 65px;
                  font-size: 13px;
                  max-height: 18px;
                }
              }
              .userProfile {
                width: 26px;
                height: 26px;
              }
            }
          }
        }
      }
    }
  }
  .basketCount {
    span {
      width: 13px;
      height: 13px;
      padding: 0;
      line-height: 12px;
      left: 13px;
    }
  }
  .search {
    .autoSearch {
      .wrapper {
        padding: 4px 0;
      }

      input {
        font-size: 13px;
      }

      ::-webkit-input-placeholder {
        font-size: 13px;
      }

      :-ms-input-placeholder {
        font-size: 13px;
      }

      ::placeholder {
        font-size: 13px;
      }

      li {
        font-size: 13px;
      }

      span {
        font-size: 13px;
      }
    }
  }
  .logOutSearch {
    .autoSearch {
      width: 300px;
      left: -270px;

      input {
        width: 320px;
      }
    }
  }
  .loginSearch {
    .autoSearch {
      width: 410px;
      left: -200px;
      padding-left: 0;
      z-index: 5555;

      input {
        width: 320px;
      }
    }
  }
}

@include responsive(xs) {
  .header {
    .full-container {
      padding: 0;
    }

    .header-wrap {
      .logo a {
        max-width: 50px;
      }

      .nav {
        .navigation {
          .header-menu {
            li {
              &:last-of-type {
                margin-right: 0;
              }
              a,
              .headerDropDown {
                font-size: 12px;
              }
            }
          }
          .toggle-area {
            margin-left: 10px;
            width: 15px;
            li {
              width: 15px;
              height: 2px;
              margin-bottom: 0px !important;
            }
          }
          .toggle-area .top {
            top: -5px;
          }
          .toggle-area .bottom {
            top: 5px;
          }
        }

        .navigation-right {
          .withoutLoginList {
            li {
              svg {
                width: 16px;
                height: 16px;
              }
              .register {
                width: 160px;
                height: 25px;
                padding: 4px 0;
                font-size: 12px;
              }
              &:nth-child(2) {
                margin-right: 14px;
              }
              .guestName {
                width: 150px;
                font-size: 12px;
              }
            }
          }
          .logginList {
            li {
              margin-right: 8px;
              img,
              svg {
                width: 18px;
                height: 18px;
              }
              &:nth-child(3) {
                margin-right: 8px;
              }
              &:nth-child(4) {
                margin-right: 8px;
              }
              .userHeader {
                h4 {
                  width: 45px;
                  font-size: 12px;
                  max-height: 18px;
                }
              }
              .userProfile {
                width: 20px;
                height: 20px;
                margin-right: 3px;
              }
            }
          }
        }
      }
    }
  }
  .basketCount {
    span {
      width: 13px;
      height: 13px;
      padding: 0;
      font-size: 8px;
      line-height: 12px;
      left: 10px;
    }
  }
  .search {
    .autoSearch {
      .wrapper {
        padding: 0;
      }

      input {
        font-size: 12px;
      }

      ::-webkit-input-placeholder {
        font-size: 12px;
      }

      :-ms-input-placeholder {
        font-size: 12px;
      }

      ::placeholder {
        font-size: 12px;
      }

      li {
        font-size: 12px;
      }

      span {
        font-size: 12px;
      }
    }
  }
  .logOutSearch {
    .autoSearch {
      width: 240px;
      left: -220px;

      input {
        width: calc(100% - 50px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
      }
    }
  }
  .loginSearch {
    .autoSearch {
      width: 250px;
      left: -75px;
      padding-left: 0;

      input {
        width: 320px;
      }
    }
  }
}
