@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.customModal{
    animation: modal 0.3;
    transform: translate(50px) !important;
}

@keyframes modal {
    0%{
        transform: translate(500px);
    }
    100%{
        transform: translate(50px);
    }
}