@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.main-about {
  display: flex;
  justify-content: space-between;

  .section-left {
    width: 56%;
    h4 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $green-shadow;
      margin-bottom: 28px;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
    h1 {
      max-width: 501px;
      font-family: $font_lora;
      font-weight: 700;
      font-size: 45px;
      color: $black_1;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }
  }

  .section-right {
    width: 44%;
    h5 {
      max-width: 501px;
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      margin-bottom: 30px;
      text-transform: capitalize;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }
    p {
      max-width: 501px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: $light-gray;
      letter-spacing: 0.04em;
      // display: -webkit-box;
      // -webkit-line-clamp: 5;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }
  }
}

// responsive
@include responsive(xl) {
  .main-about {
    .section-left {
      h4 {
        font-size: 15px;
        margin-bottom: 26px;
      }
      h1 {
        max-width: 490px;
        font-size: 42px;
      }
    }
    .section-right {
      h5 {
        max-width: 490px;
        font-size: 15px;
        margin-bottom: 28px;
      }
      p {
        max-width: 490px;
        font-size: 15px;
        line-height: 28px;
      }
    }
  }
}

@include responsive(lg) {
  .main-about {
    .section-left {
      h4 {
        font-size: 14px;
        margin-bottom: 24px;
      }
      h1 {
        max-width: 470px;
        font-size: 40px;
      }
    }
    .section-right {
      h5 {
        max-width: 470px;
        font-size: 14px;
        margin-bottom: 26px;
      }
      p {
        max-width: 470px;
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
}

@include responsive(md) {
  .main-about {
    .section-left {
      width: 50%;
      h4 {
        font-size: 13px;
        margin-bottom: 22px;
      }
      h1 {
        max-width: 460px;
        font-size: 36px;
      }
    }
    .section-right {
      h5 {
        max-width: 460px;
        font-size: 13px;
        margin-bottom: 24px;
      }
      p {
        max-width: 460px;
        font-size: 13px;
        line-height: 24px;
      }
    }
  }
}

@include responsive(sm) {
  .main-about {
    flex-wrap: wrap;
    .section-left {
      width: 100%;
      margin-bottom: 20px;
      h4 {
        font-size: 12px;
        margin-bottom: 18px;
      }
      h1 {
        max-width: 100%;
        font-size: 32px;
      }
    }
    .section-right {
      width: 100%;
      h5 {
        max-width: 100%;
        font-size: 12px;
        margin-bottom: 22px;
      }
      p {
        max-width: 100%;
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
}

@include responsive(xs) {
  .main-about {
    .section-left {
      margin-bottom: 15px;
      h4 {
        font-size: 12px;
        margin-bottom: 12px;
      }
      h1 {
        font-size: 24px;
      }
    }
    .section-right {
      h5 {
        font-size: 12px;
        margin-bottom: 15px;
      }
      p {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
