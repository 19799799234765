@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.postCard {
  width: 100%;
  padding: 30px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .postHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .postWrap {
      display: flex;

      figure {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 11px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          color: transparent;
        }
      }

      .communityLogo {
        width: 20px;
        height: 20px;
      }

      .communityName {
        color: #6daf5f;
        font-weight: bold;
      }
    }

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black;
      margin-bottom: 4px;
      text-transform: capitalize;
    }

    span {
      position: relative;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.46);

      &:first-of-type {
        margin-right: 20px;

        &::before {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          position: absolute;
          right: -14px;
          top: 40%;
          background: $white-dark;
        }
      }
    }
  }

  .postDesc {
    width: 610px;
    margin-bottom: 14px;

    p {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $light-gray;
      line-height: 25px;
      letter-spacing: 0.04em;
      // word-wrap: break-word;
      // word-break: break-all;

      a {
        color: #4f4f4f;
        border-bottom: 1px solid #4f4f4f;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          color: #8a8a8a;
        }
      }
    }
    .readLess {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    button {
      font-family: $font_jost;
      font-weight: 700;
      color: $green-shadow;
      background: none;
      cursor: pointer;
      text-align: right;
      display: inline-block;
      width: 100%;
    }

    .hideBtn {
      display: none;
    }
  }

  .mainPostContentWrap {
    overflow: hidden;
  }

  .postImage {
    margin-bottom: 20px;

    .post-swiper {
      overflow: hidden;
      border-radius: 18px;
    }

    .swiper-slide {
      width: 100%;
      height: 350px;
    }

    .swiper-pagination {
      bottom: -30px !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
      background-color: #000000;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -12px;

      &::after {
        font-size: 10px;
        color: $white;
      }
    }

    figure {
      display: inline-block;
      width: 100%;
      height: 350px;
      border-radius: 18px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 18px;
        color: transparent;
      }
    }

    .post-video {
      width: 100% !important;
      height: 350px !important;
      border-radius: 18px;
      padding-top: 0 !important;

      .video-react-control-bar.video-react-control-bar-auto-hide {
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;
      }

      .video-react-video {
        width: 100% !important;
        height: 100% !important;
        border-radius: 18px;
      }
    }
  }

  .postAction {
    margin-bottom: 10px;

    ul {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 5555;
      padding: 3px;

      li {
        display: flex;
        align-items: center;
        margin-right: 12px;
        cursor: pointer;

        &:last-of-type {
          margin-right: 0px;
        }

        svg {
          cursor: pointer;
        }

        .postActionCount {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 11px;
          color: $light-gray;
          margin-left: 6px;
        }
      }
    }

    .postLikeActive {
      svg {
        animation: bubble 0.3s;

        path {
          fill: red;
          stroke: red;
        }
      }
    }

    @keyframes bubble {
      0% {
        transform: scale(0.7);
      }

      100% {
        transform: scale(1.3);
      }
    }
  }

  .comment-sectionList {
    padding-top: 18px;
    border-top: 0.6px solid rgba($color: $white-dark, $alpha: 0.5);

    .commentToggle {
      display: block;
      font-family: $font_jost;
      font-size: 14px;
      font-weight: 700;
      text-align: right;
      color: $green-shadow;
      cursor: pointer;
    }
  }

  .comment-parent {
    // height: 300px;
    // overflow-y: hidden;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .comment-show {
    height: auto;
  }

  .comment-bar {
    display: flex;
    background-color: $light-green;
    border-radius: 7px;
    padding: 9px 14px 9px;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    figure {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 50%;
      }
    }
  }

  .comment-wrap {
    margin-left: 12px;

    .commentPersonName {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 14px;
      color: $light-gray;
      text-transform: capitalize;
      letter-spacing: 0.004em;
      line-height: 18px;
      margin-bottom: 6px;
    }

    .comment-text {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 12px;
      color: $light-gray;
      word-break: break-all;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      span {
        font-size: 14px;
        font-weight: 700;
        color: $green-shadow;
        cursor: pointer;
      }
      a {
        color: #4f4f4f;
        border-bottom: 1px solid #4f4f4f;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          color: #8a8a8a;
        }
      }
    }

    .commentShow {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 12px;
      color: $light-gray;
      line-height: 20px;
      word-break: break-all;

      span {
        font-size: 14px;
        font-weight: 700;
        color: $green-shadow;
        cursor: pointer;
      }

      a {
        color: #4f4f4f;
        border-bottom: 1px solid #4f4f4f;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          color: #8a8a8a;
        }
      }
    }
  }

  .comment-child {
    margin-left: 55px;
    margin-top: 20px;

    .comment-child-list {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .postCommentAction {
    display: flex;
    align-items: center;
    margin-left: 55px;
    margin-top: 4px;

    li {
      display: flex;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;

      span {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        color: $gray;
      }
    }
    .postLikeActive,
    .postLike {
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
      }
    }

    .postLikeActive {
      svg {
        animation: bubble 0.3s;

        path {
          fill: red;
          stroke: red;
        }
      }
    }

    @keyframes bubble {
      0% {
        transform: scale(0.7);
      }

      100% {
        transform: scale(1.3);
      }
    }
  }

  .replyBox {
    display: flex;
    background-color: $white;
    border-radius: 7px;
    padding: 9px 14px 9px;
    // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    margin-top: 15px;
    margin-left: 55px;

    figure {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 12px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 50%;
      }
    }

    .form-group {
      display: flex;

      textarea {
        width: 466px;
        height: 48px;
        background: transparent;
        resize: none;
        border: 1px solid rgba($color: $black, $alpha: 0.12);
        border-radius: 16px;
        padding: 10px;
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        margin-right: 10px;
        color: #666666;
      }

      .commentReplyButton {
        width: 90px;
        max-height: 30px;
        cursor: pointer;
        background-color: $green-shadow;
        font-family: $font_jost;
        font-weight: 400;
        color: $white;
        font-size: 14px;
        border-radius: 25px;
        @include prefix(transition, all 0.3s ease-in-out);

        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }

  .childReplyBox {
    margin-left: 0;
  }

  .replyBoxHeader {
    margin-left: 0;
    margin-bottom: 10px;

    .form-group {
      width: calc(100% - 42px);
      textarea {
        width: calc(100% - 100px);
        height: 48px;
      }
    }
  }

  .postCommentSection {
    width: 100%;
    // height: 712px;
    height: 488px;

    .content {
      padding: 6px;
      background: transparent;
      width: 100%;
      height: auto;
    }
  }
}

// responsive
@include responsive(xl) {
  .postCard {
    padding: 28px;
    margin-bottom: 28px;

    .postHeader {
      margin-bottom: 9px;

      .postWrap {
        figure {
          width: 42px;
          height: 42px;
          margin-right: 10px;
        }
      }

      h3 {
        font-size: 15px;
        margin-bottom: 4px;
      }

      span {
        font-size: 14px;

        &:first-of-type {
          margin-right: 18px;

          &::before {
            width: 4px;
            height: 4px;
            right: -12px;
            top: 40%;
          }
        }
      }
    }

    .postDesc {
      width: 100%;
      margin-bottom: 13px;

      p {
        line-height: 22px;
      }
    }

    .postImage {
      margin-bottom: 18px;

      .swiper-slide {
        height: 300px;
      }

      .swiper-pagination {
        bottom: -33px !important;
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: 25px;
        height: 25px;
        margin-top: -12px;

        &::after {
          font-size: 10px;
        }
      }

      figure {
        height: 300px;
      }

      .post-video {
        height: 300px !important;
      }
    }

    .postAction {
      margin-bottom: 9px;

      ul {
        padding: 3px;

        li {
          margin-right: 11px;

          .postActionCount {
            font-size: 11px;
            margin-left: 6px;
          }
        }
      }
    }

    .comment-sectionList {
      padding-top: 18px;

      .commentToggle {
        font-size: 13px;
      }
    }

    .comment-parent {
      margin-bottom: 18px;
    }

    .comment-bar {
      padding: 9px 13px;
      margin-bottom: 9px;

      figure {
        width: 30px;
        height: 30px;
      }
    }

    .comment-wrap {
      margin-left: 11px;

      .commentPersonName {
        font-size: 13px;
        line-height: 17px;
      }

      .comment-text {
        font-size: 12px;

        span {
          font-size: 13px;
        }
      }

      .commentShow {
        font-size: 12px;

        span {
          font-size: 14px;
        }
      }
    }

    .comment-child {
      margin-left: 50px;
      margin-top: 18px;

      .comment-child-list {
        margin-bottom: 18px;
      }
    }

    .postCommentAction {
      margin-left: 50px;
      margin-top: 4px;

      li {
        margin-right: 18px;

        span {
          font-size: 12px;
        }
      }
      .postLikeActive,
      .postLike {
        svg {
          margin-right: 6px;
        }
      }
    }

    .replyBox {
      padding: 9px 13px;
      margin-top: 14px;
      margin-left: 50px;

      figure {
        width: 30px;
        height: 30px;
        margin-right: 11px;
      }

      .form-group {
        textarea {
          width: 320px;
          height: 48px;
          padding: 9px;
          font-size: 13px;
          margin-right: 9px;
        }

        .commentReplyButton {
          width: 76px;
          max-height: 28px;
          font-size: 13px;
        }
      }
    }

    .replyBoxHeader {
      margin-left: 0;
      margin-bottom: 10px;

      .form-group {
        width: calc(100% - 41px);
        textarea {
          width: calc(100% - 85px);
          height: 48px;
        }
      }
    }

    .childReplyBox {
      margin-left: 0;
    }
  }
}

@include responsive(lg) {
  .postCard {
    padding: 26px;
    margin-bottom: 26px;

    .postHeader {
      margin-bottom: 8px;

      .postWrap {
        figure {
          width: 40px;
          height: 40px;
          margin-right: 9px;
        }
      }

      h3 {
        font-size: 14px;
        margin-bottom: 4px;
      }

      span {
        font-size: 13px;

        &:first-of-type {
          margin-right: 17px;

          &::before {
            width: 4px;
            height: 4px;
            right: -10px;
            top: 40%;
          }
        }
      }
    }

    .postDesc {
      margin-bottom: 12px;
    }

    .postImage {
      margin-bottom: 16px;

      .swiper-slide {
        height: 260px;
      }

      .swiper-pagination {
        bottom: -33px !important;
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: 25px;
        height: 25px;
        margin-top: -12px;

        &::after {
          font-size: 10px;
        }
      }

      figure {
        height: 260px;
      }

      .post-video {
        height: 260px !important;
      }
    }

    .postAction {
      margin-bottom: 8px;

      ul {
        padding: 3px;

        li {
          margin-right: 10px;

          .postActionCount {
            font-size: 11px;
            margin-left: 5px;
          }
        }
      }
    }

    .comment-sectionList {
      padding-top: 16px;

      .commentToggle {
        font-size: 12px;
      }
    }

    .comment-parent {
      margin-bottom: 16px;
    }

    .comment-bar {
      padding: 8px 12px;
      margin-bottom: 8px;

      figure {
        width: 28px;
        height: 28px;
      }
    }

    .comment-wrap {
      margin-left: 10px;

      .commentPersonName {
        font-size: 12px;
        line-height: 16px;
      }

      .comment-text {
        font-size: 12px;
        // line-height: 14px;

        span {
          font-size: 12px;
        }
      }

      .commentShow {
        font-size: 12px;
        // line-height: 14px;

        span {
          font-size: 13px;
        }
      }
    }

    .comment-child {
      margin-left: 45px;
      margin-top: 17px;

      .comment-child-list {
        margin-bottom: 17px;
      }
    }

    .postCommentAction {
      margin-left: 45px;
      margin-top: 4px;

      li {
        margin-right: 16px;

        span {
          font-size: 12px;
        }
      }
      .postLikeActive,
      .postLike {
        svg {
          margin-right: 5px;
        }
      }
    }

    .replyBox {
      padding: 8px 12px;
      margin-top: 13px;
      margin-left: 45px;

      figure {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }

      .form-group {
        textarea {
          width: 220px;
          height: 46px;
          padding: 8px;
          font-size: 12px;
          margin-right: 8px;
        }

        .commentReplyButton {
          width: 60px;
          max-height: 26px;
          font-size: 12px;
        }
      }
    }

    .replyBoxHeader {
      margin-left: 0;
      margin-bottom: 9px;

      .form-group {
        width: calc(100% - 38px);
        textarea {
          width: calc(100% - 68px);
          height: 44px;
        }
      }
    }

    .childReplyBox {
      margin-left: 0;
    }
  }
}

@include responsive(md) {
  .postCard {
    width: 100%;
    padding: 24px;
    margin: 0 auto 24px auto;

    .postHeader {
      margin-bottom: 8px;

      .postWrap {
        flex-direction: row;
        justify-content: start;
        figure {
          width: 42px;
          height: 42px;
          margin-right: 9px;
        }
      }

      h3 {
        font-size: 14px;
        margin-bottom: 3px;
      }

      span {
        font-size: 13px;

        &:first-of-type {
          margin-right: 16px;

          &::before {
            width: 4px;
            height: 4px;
            right: -10px;
            top: 40%;
          }
        }
      }
    }

    .postDesc {
      margin-bottom: 11px;

      p {
        font-size: 13px;
        line-height: 21px;
      }
    }

    .postImage {
      margin-bottom: 14px;

      .swiper-slide {
        height: 280px;
      }

      .swiper-pagination {
        bottom: -28px !important;
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: 25px;
        height: 25px;
        margin-top: -12px;

        &::after {
          font-size: 10px;
        }
      }

      figure {
        height: 280px;
      }

      .post-video {
        height: 280px !important;
      }
    }

    .postAction {
      margin-bottom: 8px;

      ul {
        padding: 3px;

        li {
          margin-right: 10px;

          .postActionCount {
            font-size: 11px;
            margin-left: 5px;
          }
        }
      }
    }

    .comment-sectionList {
      padding-top: 14px;

      .commentToggle {
        font-size: 12px;
      }
    }

    .comment-parent {
      margin-bottom: 14px;
    }

    .comment-bar {
      padding: 8px 10px;
      margin-bottom: 7px;

      figure {
        width: 28px;
        height: 28px;
      }
    }

    .comment-wrap {
      margin-left: 10px;

      .commentPersonName {
        font-size: 12px;
        line-height: 15px;
      }

      .comment-text {
        font-size: 12px;
        // line-height: 14px;

        span {
          font-size: 12px;
        }
      }

      .commentShow {
        font-size: 12px;
        // line-height: 14px;

        span {
          font-size: 13px;
        }
      }
    }

    .comment-child {
      margin-left: 40px;
      margin-top: 15px;

      .comment-child-list {
        margin-bottom: 15px;
      }
    }

    .postCommentAction {
      margin-left: 40px;
      margin-top: 4px;

      li {
        margin-right: 14px;

        span {
          font-size: 12px;
        }
      }
      .postLikeActive,
      .postLike {
        svg {
          margin-right: 5px;
        }
      }
    }

    .replyBox {
      padding: 8px 11px;
      margin-top: 11px;
      margin-left: 40px;

      figure {
        width: 28px;
        height: 28px;
        margin-right: 9px;
      }

      .form-group {
        textarea {
          width: 278px;
          height: 46px;
          padding: 8px;
          font-size: 12px;
          margin-right: 8px;
        }

        .commentReplyButton {
          width: 65px;
          max-height: 28px;
          font-size: 12px;
        }
      }
    }

    .replyBoxHeader {
      margin-left: 0;
      margin-bottom: 8px;

      .form-group {
        width: calc(100% - 37px);
        textarea {
          width: calc(100% - 73px);
          height: 42px;
        }
      }
    }

    .childReplyBox {
      margin-left: 0;
    }
  }
}

@include responsive(sm) {
  .postCard {
    padding: 20px;
    margin: 0 auto 20px auto;

    .postHeader {
      margin-bottom: 8px;

      .postWrap {
        figure {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
      }

      h3 {
        font-size: 13px;
        margin-bottom: 3px;
      }

      span {
        font-size: 13px;

        &:first-of-type {
          margin-right: 15px;

          &::before {
            width: 4px;
            height: 4px;
            right: -10px;
            top: 42%;
          }
        }
      }
    }

    .postDesc {
      margin-bottom: 10px;
    }

    .postImage {
      margin-bottom: 12px;

      .swiper-slide {
        height: 260px;
      }

      .swiper-pagination {
        bottom: -26px !important;
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: 22px;
        height: 22px;
        margin-top: -12px;

        &::after {
          font-size: 10px;
        }
      }

      figure {
        height: 260px;
      }

      .post-video {
        height: 260px !important;
      }
    }

    .postAction {
      margin-bottom: 8px;

      ul {
        padding: 3px;

        li {
          margin-right: 8px;

          .postActionCount {
            font-size: 11px;
            margin-left: 5px;
          }
        }
      }
    }

    .comment-sectionList {
      padding-top: 12px;

      .commentToggle {
        font-size: 12px;
      }
    }

    .comment-parent {
      margin-bottom: 12px;
    }

    .comment-bar {
      padding: 8px 10px;
      margin-bottom: 7px;

      figure {
        width: 26px;
        height: 26px;
      }
    }

    .comment-wrap {
      margin-left: 9px;

      .commentPersonName {
        font-size: 12px;
        line-height: 15px;
      }

      .comment-text {
        font-size: 12px;
        // line-height: 14px;

        span {
          font-size: 12px;
        }
      }

      .commentShow {
        font-size: 12px;
        // line-height: 14px;

        span {
          font-size: 12px;
        }
      }
    }

    .comment-child {
      margin-left: 35px;
      margin-top: 10px;

      .comment-child-list {
        margin-bottom: 10px;
      }
    }

    .postCommentAction {
      margin-left: 35px;
      margin-top: 4px;

      li {
        margin-right: 12px;

        span {
          font-size: 12px;
        }
      }
      .postLikeActive,
      .postLike {
        svg {
          margin-right: 5px;
        }
      }
    }

    .replyBox {
      padding: 8px 10px;
      margin-top: 10px;
      margin-left: 35px;

      figure {
        width: 26px;
        height: 26px;
        margin-right: 9px;
      }

      .form-group {
        textarea {
          width: 250px;
          height: 44px;
          padding: 7px;
          font-size: 12px;
          margin-right: 7px;
        }

        .commentReplyButton {
          width: 60px;
          max-height: 26px;
          font-size: 12px;
        }
      }
    }

    .replyBoxHeader {
      margin-left: 0;
      margin-bottom: 8px;

      .form-group {
        width: calc(100% - 35px);
        textarea {
          width: calc(100% - 67px);
          height: 40px;
        }
      }
    }

    .childReplyBox {
      margin-left: 0;
    }
  }
}

@include responsive(xs) {
  .postCard {
    padding: 15px;
    margin: 0 auto 15px auto;

    .postHeader {
      margin-bottom: 6px;

      .postWrap {
        figure {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }
      }

      h3 {
        font-size: 12px;
        margin-bottom: 0px;
      }

      span {
        font-size: 12px;

        &:first-of-type {
          margin-right: 15px;
        }
      }
    }

    .postDesc {
      margin-bottom: 8px;

      p {
        font-size: 12px;
        line-height: 20px;
      }
    }

    .postImage {
      margin-bottom: 15px;

      .swiper-slide {
        height: 200px;
      }

      .swiper-pagination {
        bottom: -15px !important;
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: 20px;
        height: 20px;
        margin-top: -12px;

        &::after {
          font-size: 10px;
        }
      }

      figure {
        height: 200px;
      }

      .post-video {
        height: 200px !important;
      }
    }

    .postAction {
      margin-bottom: 6px;

      ul {
        padding: 3px;

        li {
          margin-right: 6px;

          .postActionCount {
            font-size: 10px;
            margin-left: 5px;
          }
        }
      }
    }

    .comment-sectionList {
      padding-top: 10px;

      .commentToggle {
        font-size: 11px;
      }
    }

    .comment-parent {
      margin-bottom: 10px;
    }

    .comment-bar {
      padding: 8px 10px;
      margin-bottom: 7px;

      figure {
        width: 20px;
        height: 20px;
      }
    }

    .comment-wrap {
      margin-left: 6px;

      .commentPersonName {
        font-size: 11px;
        // line-height: 14px;
      }

      .comment-text {
        font-size: 11px;
        // line-height: 14px;

        span {
          font-size: 11px;
        }
      }

      .commentShow {
        font-size: 11px;
        // line-height: 14px;

        span {
          font-size: 11px;
        }
      }
    }

    .comment-child {
      margin-left: 15px;
      margin-top: 8px;

      .comment-child-list {
        margin-bottom: 8px;
      }
    }

    .postCommentAction {
      margin-left: 15px;
      margin-top: 4px;

      li {
        margin-right: 10px;

        span {
          font-size: 11px;
        }
      }
      .postLikeActive,
      .postLike {
        svg {
          margin-right: 5px;
        }
      }
    }

    .replyBox {
      padding: 8px 10px;
      margin-top: 8px;
      margin-left: 15px;

      figure {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      .form-group {
        flex-wrap: wrap;
        textarea {
          width: 154px;
          height: 42px;
          padding: 6px;
          font-size: 11px;
          margin-right: 7px;
        }

        .commentReplyButton {
          width: 60px;
          font-size: 11px;
          padding: 3px 0;
        }
      }
    }

    .replyBoxHeader {
      margin-left: 0;
      margin-bottom: 6px;

      .form-group {
        width: calc(100% - 28px);
        textarea {
          width: calc(100% - 67px);
          height: 36px;
        }
      }
    }

    .childReplyBox {
      margin-left: 0;
    }
  }
}
