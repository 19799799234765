@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.tabrelated {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  padding-bottom: 28px;

  .tabRelatedHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px 20px 40px;

    h3 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 24px;
      color: $black_1;
      text-transform: capitalize;
    }
    button {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $green_shadow;
      text-transform: capitalize;
      background: none;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        color: $theme-color;
      }
    }
  }
  .tabCharityBodyWrap {
    .content {
      max-height: 684px;
      padding: 0 30px 0 40px;
    }
  }
  .relatedBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
    cursor: pointer;
    @include prefix(transition, all 0.3s ease-in-out);

    &:hover {
      background-color: $light-green;
      padding: 10px;
      border-radius: 7px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .relatedLeft {
      display: flex;
      align-items: center;
      figure {
        width: 65px;
        height: 65px;
        margin-right: 21px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain ;
          // object-fit: cover;
          color: transparent;
        }
      }
      h3 {
        font-family: $font_jost;
        font-weight: 500;
        font-size: 14px;
        color: $black_1;
      }
    }
    .relatedRight {
      .relatedButtons {
        display: flex;
        justify-content: space-between;
        .basket {
          width: 150px;
          height: 30px;
          background-color: $green-shadow;
          font-family: $font_jost;
          font-weight: 400;
          padding: 5px 17px;
          font-size: 14px;
          color: $white;
          border-radius: 20px;
          @include prefix(transition, all 0.3s ease-in-out);
          margin-right: 7px;
          text-align: center;

          &:hover {
            background-color: $theme-color;
          }
        }
        .causeHeart {
          width: 50px;
          padding: 4px 14px;
          background-color: rgba($color: $theme-color, $alpha: 0.5);
          border-radius: 20px;
          svg {
            width: 25px;
            height: 23px;
            path {
              fill: $white;
              stroke: $white;
            }
          }
        }
        .causeHeartActive {
          width: 50px;
          padding: 4px 13px;
          border-radius: 20px;
          background-color: transparent;
          border: 1px solid $green-shadow;
          cursor: pointer;
          svg {
            width: 25px;
            height: 23px;
            transform: scale(1);
            animation: bubble 0.3s;
            path {
              fill: $red;
              stroke: $red;
            }
          }
        }
        @keyframes bubble {
          0% {
            transform: scale(0.7);
          }
          100% {
            transform: scale(1.3);
          }
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .tabrelated {
    padding-bottom: 24px;

    .tabRelatedHeader {
      padding: 22px 28px 18px 36px;

      h3 {
        font-size: 22px;
      }
      button {
        font-size: 13px;
      }
    }
    .tabCharityBodyWrap {
      .content {
        max-height: 539px;
        padding: 0 28px 0 36px;
      }
    }
    .relatedBody {
      margin-bottom: 18px;
      padding: 9px;

      &:hover {
        padding: 9px;
      }
      .relatedLeft {
        figure {
          width: 62px;
          height: 62px;
          margin-right: 20px;
        }
        h3 {
          font-size: 13px;
        }
      }
      .relatedRight {
        .relatedButtons {
          .basket {
            width: 145px;
            height: 28px;
            padding: 5px 16px;
            font-size: 13px;
            margin-right: 6px;
          }
          .causeHeart {
            width: 48px;
            padding: 4px 13px;
            svg {
              width: 25px;
              height: 22px;
            }
          }
          .causeHeartActive {
            width: 48px;
            padding: 4px 13px;
            svg {
              width: 25px;
              height: 22px;
            }
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .tabrelated {
    padding-bottom: 20px;
    .tabRelatedHeader {
      padding: 20px 24px 16px 30px;

      h3 {
        font-size: 20px;
      }
      button {
        font-size: 13px;
      }
    }
    .tabCharityBodyWrap {
      .content {
        max-height: 482px;
        padding: 0 24px 0 30px;
      }
    }
    .relatedBody {
      margin-bottom: 16px;
      padding: 8px;

      &:hover {
        padding: 8px;
      }
      .relatedLeft {
        figure {
          width: 58px;
          height: 58px;
          margin-right: 15px;
        }
        h3 {
          font-size: 12px;
        }
      }
      .relatedRight {
        .relatedButtons {
          .basket {
            width: 115px;
            height: 28px;
            padding: 5px 12px;
            font-size: 12px;
            margin-right: 5px;
          }
          .causeHeart {
            width: 45px;
            padding: 4px 12px;
            svg {
              width: 22px;
              height: 20px;
            }
          }
          .causeHeartActive {
            width: 45px;
            padding: 4px 12px;
            svg {
              width: 22px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .tabrelated {
    padding-bottom: 25px;
    .tabRelatedHeader {
      padding: 18px 22px 15px 25px;

      h3 {
        font-size: 18px;
      }
      button {
        font-size: 13px;
      }
    }
    .tabCharityBodyWrap {
      .content {
        max-height: 100%;
        padding: 0 22px 0 28px;
      }
    }
    .relatedBody {
      margin-bottom: 15px;
      padding: 8px;

      &:hover {
        padding: 8px;
      }
      .relatedLeft {
        figure {
          width: 60px;
          height: 60px;
          margin-right: 15px;
        }
        h3 {
          font-size: 13px;
        }
      }
      .relatedRight {
        .relatedButtons {
          .basket {
            width: 125px;
            height: 30px;
            padding: 5px 12px;
            font-size: 13px;
            margin-right: 7px;
          }
          .causeHeart {
            width: 48px;
            padding: 4px 13px;
            svg {
              width: 23px;
              height: 21px;
            }
          }
          .causeHeartActive {
            width: 48px;
            padding: 4px 12px;
            svg {
              width: 23px;
              height: 21px;
            }
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .tabrelated {
    padding-bottom: 20px;
    .tabRelatedHeader {
      padding: 16px 20px 15px 20px;

      h3 {
        font-size: 18px;
      }
      button {
        font-size: 13px;
      }
    }
    .tabCharityBodyWrap {
      .content {
        padding: 0 20px 0 20px;
      }
    }
    .relatedBody {
      margin-bottom: 15px;
      padding: 8px;

      &:hover {
        padding: 8px;
      }
      .relatedLeft {
        figure {
          width: 58px;
          height: 58px;
          margin-right: 13px;
        }
        h3 {
          font-size: 12px;
        }
      }
      .relatedRight {
        .relatedButtons {
          .basket {
            width: 120px;
            height: 28px;
            padding: 5px 12px;
            font-size: 12px;
            margin-right: 6px;
          }
          .causeHeart {
            width: 45px;
            padding: 4px 13px;
            svg {
              width: 23px;
              height: 21px;
            }
          }
          .causeHeartActive {
            width: 45px;
            padding: 4px 13px;
            svg {
              width: 23px;
              height: 21px;
            }
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .tabrelated {
    padding-bottom: 15px;
    .tabRelatedHeader {
      padding: 15px;

      h3 {
        font-size: 15px;
      }
      button {
        font-size: 12px;
      }
    }
    .tabCharityBodyWrap {
      .content {
        padding: 0 15px;
      }
    }
    .relatedBody {
      margin-bottom: 10px;
      padding: 5px 0;

      &:hover {
        padding: 5px 0;
      }
      .relatedLeft {
        figure {
          width: 45px;
          height: 45px;
          margin-right: 0px;
          flex-shrink: 0;
        }
        h3 {
          min-width: 70px;
          font-size: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-left: 10px;
        }
      }
      .relatedRight {
        .relatedButtons {
          .basket {
            width: 85px;
            height: auto;
            padding: 6px 10px;
            font-size: 10px;
            margin-right: 5px;
          }
          .causeHeart {
            width: 40px;
            padding: 4px 11px;
            svg {
              width: 20px;
              height: 18px;
            }
          }
          .causeHeartActive {
            width: 40px;
            padding: 4px 11px;
            svg {
              width: 20px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}
