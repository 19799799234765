@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.eventCard {
  width: 218px;
  padding: 20px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  margin-right: 18px;
  cursor: pointer;
  &:nth-of-type(3n + 0) {
    margin-right: 0px;
  }

  .eventFigure {
    width: 100%;
    height: 120px;
    margin-bottom: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .eventDescription {
    h3 {
      width: 100%;
      font-family: $font-jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .eventTime {
      display: flex;
      // align-items: center;
      flex-direction: column;
      margin-bottom: 15px;
      li:last-of-type {
        margin-top: 5px;
        // margin-left: 6px;
        svg path {
          stroke: $black_1;
        }
      }
      li:nth-of-type(2) svg path {
        stroke : $black_1;
      }
      span {
        font-family: $font-jost;
        font-weight: 400;
        font-size: 10px;
        color: $light-gray;
        margin-left: 6px;
      }
    }
    .address {
      display: flex;
      align-items: center;
      // margin-bottom: 12px;
      // height: 38px;

      address {
        font-family: $font-jost;
        font-weight: 400;
        font-size: 10px;
        color: $light-gray;
        font-style: normal;
        margin-left: 6px;
      }
    }
    img {
      height: 18px;
      width: 17px;
    }
    .communityTexts, 
    .charityTexts {
      color: $green-shadow !important;
      font-weight: bold !important;
    }
  }
  .upcomingButton,
  .pastButton {
    button {
      width: 100%;
      padding: 8px 50px 7px 50px;
      border-radius: 20px;
      cursor: pointer;
      background-color: $green-shadow;
      font-family: $font-jost;
      font-weight: 400;
      color: $white;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: $theme-color;
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .eventCard {
    width: 210px;
    padding: 18px;
    margin-right: 16px;
    margin-bottom: 28px;

    &:nth-of-type(3n + 0) {
      margin-right: 16px;
    }

    &:nth-of-type(2n + 0) {
      margin-right: 0px;
    }

    .eventFigure {
      height: 115px;
      margin-bottom: 7px;
    }

    .eventDescription {
      h3 {
        font-size: 15px;
        margin-bottom: 8px;
      }
      .eventTime {
        margin-bottom: 7px;
        li:last-of-type {
          // margin-left: 6px;
        }
        span {
          font-size: 10px;
          margin-left: 5px;
        }
      }
      .address {
        // height: 36px;
        // margin-bottom: 11px;

        address {
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .upcomingButton,
    .pastButton {
      button {
        padding: 8px 48px 7px 48px;
      }
    }
  }
}

@include responsive(lg) {
  .eventCard {
    width: 195px;
    padding: 17px;
    margin-right: 15px;
    margin-bottom: 26px;
    &:nth-of-type(2n + 0) {
      margin-right: 0px;
    }

    .eventFigure {
      height: 110px;
      margin-bottom: 7px;
    }

    .eventDescription {
      h3 {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .eventTime {
        margin-bottom: 7px;
        li:last-of-type {
          // margin-left: 6px;
        }
        span {
          font-size: 10px;
          margin-left: 5px;
        }
      }
      .address {
        // height: 34px;
        // margin-bottom: 11px;

        address {
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .upcomingButton,
    .pastButton {
      button {
        padding: 7px 44px 7px 44px;
      }
    }
  }
}

@include responsive(md) {
  .eventCard {
    width: 194px;
    padding: 16px;
    margin-right: 13px;
    margin-bottom: 22px;
    &:nth-of-type(3n + 0) {
      margin-right: 13px;
    }
    &:nth-of-type(2n + 0) {
      margin-right: 13px;
    }

    .eventFigure {
      height: 100px;
      margin-bottom: 7px;
    }

    .eventDescription {
      h3 {
        font-size: 13px;
        margin-bottom: 7px;
      }
      .eventTime {
        margin-bottom: 7px;
        li:last-of-type {
          // margin-left: 6px;
        }
        span {
          font-size: 10px;
          margin-left: 5px;
        }
      }
      .address {
        // height: 32px;
        // margin-bottom: 10px;

        address {
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .upcomingButton,
    .pastButton {
      button {
        padding: 6px 0px;
      }
    }
  }
}

@include responsive(sm) {
  .eventCard {
    width: 190px;
    padding: 15px;
    margin-right: 15px;
    margin-bottom: 18px;
    &:nth-of-type(3n + 0) {
      margin-right: 15px;
    }
    &:nth-of-type(2n + 0) {
      margin-right: 15px;
    }

    .eventFigure {
      height: 90px;
      margin-bottom: 7px;
    }

    .eventDescription {
      h3 {
        font-size: 13px;
        margin-bottom: 7px;
      }
      .eventTime {
        margin-bottom: 7px;
        li:last-of-type {
          // margin-left: 6px;
        }
        span {
          font-size: 10px;
          margin-left: 5px;
        }
      }
      .address {
        // height: 30px;
        // margin-bottom: 10px;

        address {
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .upcomingButton,
    .pastButton {
      button {
        padding: 6px 0px;
      }
    }
  }
}

@include responsive(xs) {
  .eventCard {
    width: 100%;
    padding: 15px;
    margin-right: 0px;
    margin-bottom: 15px;
    &:nth-of-type(3n + 0) {
      margin-right: 0px;
    }
    &:nth-of-type(2n + 0) {
      margin-right: 0px;
    }

    .eventFigure {
      height: 100px;
      margin-bottom: 7px;
    }

    .eventDescription {
      h3 {
        font-size: 12px;
        margin-bottom: 7px;
      }
      .eventTime {
        margin-bottom: 7px;
        li:last-of-type {
          // margin-left: 6px;
        }
        span {
          font-size: 10px;
          margin-left: 5px;
        }
      }
      .address {
        // height: 30px;
        // margin-bottom: 10px;

        address {
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .upcomingButton,
    .pastButton {
      text-align: center;
      button {
        width: 210px;
        padding: 6px 0px;
      }
    }
  }
}
