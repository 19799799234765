@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.badge {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background-color: $white;
  padding-bottom: 30px;
}

.badgeScroll {
  max-height: 712px;
}

.badgeDetails {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 26px;
  .badgeCard {
    width: 214px;
    background-color: $light-green;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    padding: 32px 45px;
    margin-right: 30px;
    margin-top: 30px;
    &:nth-of-type(3n + 0) {
      margin-right: 0;
    }
    &:last-of-type{
      margin-right: 0;
    }
    figure {
      width: 100px;
      height: 100px;
      display: inline-block;
      margin-bottom: 14px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    p {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $theme-color;
      line-height: 21px;
      text-transform: capitalize;
    }
  }
}

// responsive
@include responsive(xl) {
  .badge {
    padding-bottom: 28px;
  }

  .badgeScroll {
    max-height: 625px;
  }

  .badgeDetails {
    padding: 0px 24px;
    .badgeCard {
      width: 155px;
      padding: 28px 22px;
      margin-right: 28px;
      margin-top: 28px;
      &:nth-of-type(3n + 0) {
        margin-right: 0;
      }
      figure {
        width: 80px;
        height: 80px;
        margin-bottom: 13px;
      }
      p {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}

@include responsive(lg) {
  .badge {
    padding-bottom: 26px;
  }

  .badgeScroll {
    max-height: 607px;
  }

  .badgeDetails {
    padding: 0px 22px;
    .badgeCard {
      width: 155px;
      padding: 26px 20px;
      margin-right: 26px;
      margin-top: 26px;
      &:nth-of-type(3n + 0) {
        margin-right: 26px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0;
      }
      figure {
        width: 75px;
        height: 75px;
        margin-bottom: 12px;
      }
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

@include responsive(md) {
  .badge {
    padding-bottom: 25px;
  }

  .badgeScroll {
    max-height: 600px;
  }

  .badgeDetails {
    padding: 0px 22px;
    .badgeCard {
      width: 189px;
      padding: 24px 28px;
      margin-right: 24px;
      margin-top: 24px;
      &:nth-of-type(2n + 0) {
        margin-right: 24px;
      }
      figure {
        width: 80px;
        height: 80px;
        margin-bottom: 12px;
      }
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

@include responsive(sm) {
  .badge {
    padding-bottom: 25px;
  }

  .badgeDetails {
    padding: 0px 20px;
    .badgeCard {
      width: 145px;
      padding: 18px 20px;
      margin-right: 20px;
      margin-top: 20px;
      &:nth-of-type(2n + 0) {
        margin-right: 24px;
      }
      figure {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
      }
      p {
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
}

@include responsive(xs) {
  .badge {
    padding-bottom: 15px;
  }

  .badgeScroll {
    max-height: 500px;
  }

  .badgeDetails {
    padding: 0px 15px;
    .badgeCard {
      width: 100%;
      padding: 15px;
      margin-right: 0px;
      margin-top: 15px;
      &:nth-of-type(3n + 0) {
        margin-right: 0px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
      &:nth-of-type(6) {
        margin-right: 0;
      }
      &:last-of-type{
        margin-right: 0;
      }
      figure {
        width: 60px;
        height: 60px;
        margin-bottom: 8px;
      }
      p {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
