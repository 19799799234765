@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.swiper {
  margin: 0 !important;
  overflow: visible !important;
}

.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background-color: $black_1;
}

.swiper-pagination-bullet-active {
  width: 15px;
  height: 5px;
  background-color: $black_1;
  border-radius: 50px;
}
