@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.banner-sec {
  width: 100%;
  background-image: url("../../../../public/images/charity.png");
  background-repeat: no-repeat;
  padding: 70px 0 45px;
  background-size: cover;
  text-align: center;

  h3 {
    font-family: $font_jost;
    font-weight: 700;
    font-size: 21px;
    color: $white;
    margin-bottom: 20px;
  }
  h2 {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 45px;
    color: $white;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  button {
    width: 288px;
    height: 35px;
    border-radius: 20px;
    background-color: $green-shadow;
    color: $white;
    font-family: $font_jost;
    font-weight: 400;
    font-size: 16px;
    @include prefix(transition, all 0.3s ease-in-out);

    &:hover {
      background-color: $theme-color;
    }
  }
}

// responsive
@include responsive(lg) {
  .banner-sec {
    h3 {
      font-size: 18px;
      margin-bottom: 15px;
    }
    h2 {
      font-size: 38px;
      margin-bottom: 18px;
    }
    // button{
    //     width: 278px;
    //     height: 32px;
    //     font-size: 15px;
    // }
  }
}

@include responsive(md) {
  .banner-sec {
    h3 {
      font-size: 16px;
      margin-bottom: 14px;
    }
    h2 {
      font-size: 30px;
      margin-bottom: 16px;
    }
  }
}

@include responsive(sm) {
  .banner-sec {
    h3 {
      font-size: 14px;
      margin-bottom: 6px;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 14px;
    }
  }
}

@include responsive(xs) {
  .banner-sec {
    h3 {
      font-size: 13px;
      margin-bottom: 2px;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}