@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.card-body {
  width: 489px;
  padding: 18px 74px 14px 65px !important;
}

h3 {
  margin-bottom: 30px;
}

.form-password svg path,
circle {
  fill: none;
}

.saveButton {
  button {
    display: inline-block;
    width: 350px;
    background-color: $green-shadow;
    padding: 11px 85px;
    border-radius: 50px;
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: $white;
    @include prefix(transition, all 0.3s ease-in-out);
    margin-top: 48px;
    margin-bottom: 190px;
    cursor: pointer;
    &:hover {
      background-color: $theme-color;
    }
  }
}

.passwordShow {
  cursor: pointer;
}

.form-password {
  height: 40px;
}

// responsive
@include responsive(lg) {
  .saveButton {
    button {
      width: 335px;
      padding: 10px 75px;
      font-size: 13px;
      margin-top: 42px;
      margin-bottom: 150px;
    }
  }

  .form-password {
    height: 36px;
  }
}

@include responsive(md) {
  .saveButton {
    button {
      width: 310px;
      padding: 8px 65px;
      font-size: 13px;
      margin-top: 38px;
      margin-bottom: 130px;
    }
  }

  .form-password {
    height: 35px;
  }
}

@include responsive(sm) {
  .saveButton {
    button {
      width: 300px;
      padding: 7px 55px;
      font-size: 12px;
      margin-top: 32px;
      margin-bottom: 100px;
    }
  }

  .form-password {
    height: 32px;
  }
}

@include responsive(xs) {
  .saveButton {
    button {
      width: 216px;
      padding: 6px 50px;
      font-size: 12px;
      margin-top: 28px;
      margin-bottom: 80px;
    }
  }

  .form-password {
    height: 30px;
  }
}
