@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.profileDetail {
  width: 100%;

  .content {
    max-height: 715px;
  }
  .ps__thumb-y {
    visibility: hidden;
  }

  .heightContent {
    height: auto !important;
  }

  // back
  .form-group {
    background-color: transparent;
  }
  .formGroup {
    padding-right: 0px !important;

    select {
      width: 100%;
    }
  }

  .personalContent,
  .locationContent,
  .WorkDetails {
    h3 {
      font-family: $font_lora;
      font-weight: 700;
      color: $black_1;
      font-size: 30px;
      text-transform: capitalize;
      margin-bottom: 21px;
    }
    .workForm{
      .employeCodeInp{
        width: 365px;
        border:1px solid #4f4f4f;
        border-radius: 7px;
        padding-right: 14px;
      }
    }
  }

  .personalContent {
    .personalForm {
      margin-bottom: 40px;

      ul {
        .name,
        .email,
        .mobile,
        .gender {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .name {
          margin-bottom: 20px;

          .forms {
            width: 32%;

            .form-group {
              border: 1px solid $light-gray;
              border-radius: 7px;
              // padding-right: 12px;

              input {
                width: calc(100% - 17px);
                border: $white;
                padding: 9px 0px 8px 14px;
                border-radius: 7px;
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              ::-webkit-input-placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              :-ms-input-placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              ::placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }
            }
          }
        }

        .email {
          margin-bottom: 20px;

          .forms {
            width: 49%;

            .requiredField {
              display: flex;
              align-items: center;
              input {
                width: 274px;
              }
              span {
                display: flex;
                align-items: center;
                h5 {
                  font-family: $font_jost;
                  font-weight: 700;
                  font-size: 12px;
                  margin-right: 20px;
                }
              }
            }
            .form-group {
              border: 1px solid $light-gray;
              border-radius: 7px;
              // padding-right: 14px;

              input {
                width: calc(100% - 18px);
                border: $white;
                border-radius: 7px;
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
                padding: 9px 0px 8px 14px;
              }

              ::-webkit-input-placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              :-ms-input-placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              ::placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }
            }

            .form-email {
              display: flex;
              align-items: center;
              border: 1px solid $light-gray;
              border-radius: 7px;
              padding-right: 14px;

              span {
                display: flex;
                align-items: center;
              }

              .verify {
                button {
                  font-family: $font_jost;
                  font-weight: 400;
                  font-size: 12px;
                  color: $white;
                  margin-right: 20px;
                  cursor: pointer;
                  background-color: $red;
                  border-radius: 50px;
                  padding: 7px 12px;
                  @include prefix(transition, all 0.3s ease-in-out);
                  &:hover {
                    // background-color: $theme-color;
                  }
                }
              }
              .verified {
                button {
                  background-color: transparent;
                  font-family: $font_jost;
                  font-weight: 700;
                  font-size: 13px;
                  margin-right: 20px;
                  color: $green-shadow;
                }
              }

              input {
                width: calc(100% - 96px);
                border: $white;
                border-radius: 7px;
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
                padding: 9px 0px 8px 14px;
              }

              ::-webkit-input-placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              :-ms-input-placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              ::placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }
            }

            .form-email:focus-within {
              border: 1px solid $theme-color;
            }
          }
        }

        .mobile {
          margin-bottom: 20px;

          .forms {
            width: 49%;

            .form-group {
              border: 1px solid $light-gray;
              border-radius: 7px;
              // padding-right: 14px;

              input {
                width: calc(100% - 12px);
                border: $white;
                border-radius: 7px;
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
                padding: 9px 0px 8px 14px;
              }

              ::-webkit-input-placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              :-ms-input-placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              ::placeholder {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 14px;
                color: $light-gray;
              }

              input[type="number"] {
                -moz-appearance: textfield;
              }

              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
        }

        .gender {
          .gender-formGrp {
            width: 49%;
          }
          .forms {
            width: 49%;

            .form-group {
              display: flex;
              align-items: center;
              border: 1px solid $light-gray;
              border-radius: 7px;
              padding-right: 14px;
            }
          }
          .requiredField {
            width: 100%;
            border-radius: 7px;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
          }
          .requiredField > div {
            width: 100%;
          }
          .basic-multi-select {
            width: 100%;
            border: 1px solid $light-gray;
            border-radius: 7px;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
          }
          .css-1pahdxg-control {
            border-color: transparent;
            box-shadow: none;
            border-width: 0;
            background-color: transparent;
          }
          .css-1s2u09g-control {
            border: none;
            border-radius: 0px;
            background-color: transparent;
          }
        }
      }

      ul li {
        .form-group {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid $light-gray;
          border-radius: 7px;
          padding-right: 14px;

          input {
            width: calc(100% - 0px);
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            background: none !important;
            padding: 9px 30px 8px 14px;
          }

          ::-webkit-input-placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
          }

          :-ms-input-placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
          }

          ::placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
          }

          select {
            width: 100%;
            border-radius: 7px;
            padding: 9px 25px 8px 14px;
            background-color: $white;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            -webkit-appearance: none;
            -moz-appearance: none;
          }
        }
      }
    }
  }

  .locationContent {
    .locationForm {
      ul li {
        .form-group {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid $light-gray;
          border-radius: 7px;
          padding-right: 14px;

          input {
            width: calc(100% - 18px);
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            background: none !important;
            padding: 9px 30px 8px 14px;
          }

          ::-webkit-input-placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
          }

          :-ms-input-placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
          }

          ::placeholder {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
          }

          // back
          select {
            width: 100%;
            border-radius: 7px;
            padding: 9px 25px 8px 14px;
            background-color: transparent;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            -webkit-appearance: none;
            -moz-appearance: none;
          }
        }
      }

      .form-middle,
      .form-last {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 20px;

        .forms {
          width: 49%;
        }

        .form-grp {
          width: 49%;
          border: 1px solid $black_1;
          border-radius: 7px;
        }
        .css-1s2u09g-control {
          background-color: transparent;
          border-width: 0px;
        }
        .disabledLast {
          border: 1px solid $white-dark !important;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
        }
        .css-1pahdxg-control {
          border-color: transparent;
          box-shadow: none;
          border-width: 0;
        }

        .select__menu {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
        }
      }

      .form-middle {
        .select__single-value.css-qc6sy-singleValue {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
        }
      }

      .dropdownGrp {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $light-gray;
      }

      .street:first-of-type {
        margin-bottom: 20px;
      }

      .street input {
        width: 100% !important;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      // .select__menu {
      //   max-height: 200px !important;
      //   overflow: hidden;
      // }
    }
  }

  .WorkDetails {
    margin-top: 45px;
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      &:last-of-type{
        margin-bottom: 0;
      }
      .forms {
        width: 365px;
      }
      .form-group {
        border: 1px solid $light-gray;
        border-radius: 7px;
        padding-right: 14px;
      }
      input {
        width: calc(100% - 20px);
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $light-gray;
        background-color: transparent;
        padding: 9px 14px 8px;
      }
    }
    .disabledLast {
      border: 1px solid $white-dark !important;
    }
  }
  .save {
    width: 239px;
    border-radius: 50px;
    background-color: $green-shadow;
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: $white;
    padding: 10px 75px;
    margin-top: 34px;
    cursor: pointer;
    @include prefix(transition, all 0.3s ease-in-out);

    &:hover {
      background-color: $theme-color;
    }
  }
}

// responsive
@include responsive(xl) {
  .profileDetail {
    .content {
      max-height: 700px;
    }

    .personalContent,
    .locationContent,
    .WorkDetails {
      h3 {
        font-size: 28px;
        margin-bottom: 18px;
      }
    }

    .personalContent {
      .personalForm {
        margin-bottom: 38px;

        ul {
          .name {
            margin-bottom: 18px;

            .forms {
              width: 32%;

              .form-group {
                input {
                  padding: 8px 0 7px 12px;
                  font-size: 13px;
                }
                ::-webkit-input-placeholder {
                  font-size: 13px;
                }

                :-ms-input-placeholder {
                  font-size: 13px;
                }

                ::placeholder {
                  font-size: 13px;
                }
              }
            }
          }
          .email {
            margin-bottom: 18px;

            .forms {
              width: 49%;

              .form-group {
                input {
                  padding: 8px 0 7px 12px;
                  font-size: 13px;
                }
                ::-webkit-input-placeholder {
                  font-size: 13px;
                }

                :-ms-input-placeholder {
                  font-size: 13px;
                }

                ::placeholder {
                  font-size: 13px;
                }
                span {
                  svg {
                    width: 16px;
                  }
                }
              }
              .form-email {

                .verified{
                  button{
                    font-size: 11px;
                    margin-right: 15px;
                  }
                }

                .verify {
                  button {
                    font-size: 11px;
                    margin-right: 15px;
                    padding: 7px 12px;
                  }
                }
              }
            }
          }
          .mobile {
            margin-bottom: 18px;

            .forms {
              width: 49%;

              .form-group {
                input {
                  // width: 260px;
                  font-size: 13px;
                  padding: 8px 0 7px 12px;
                }
                ::-webkit-input-placeholder {
                  font-size: 13px;
                }

                :-ms-input-placeholder {
                  font-size: 13px;
                }

                ::placeholder {
                  font-size: 13px;
                }
              }
            }
          }
          .gender {
            .forms {
              width: 49%;

              .form-group {
                padding-right: 12px;

                select {
                  padding: 8px 22px 7px 12px;
                  font-size: 13px;
                }
                input {
                  width: calc(100% - 0px);
                  padding: 8px 22px 7px 12px;
                  font-size: 13px;
                }
              }
            }
            .gender-formGrp {
              width: 49%;

              .basic-multi-select {
                width: 100%;
                font-size: 13px;
              }
            }
          }
        }

        ul li {
          .form-group {
            padding-right: 12px;

            input {
              padding: 8px 26px 7px 12px;
              width: 13px;
            }
            ::-webkit-input-placeholder {
              font-size: 13px;
            }

            :-ms-input-placeholder {
              font-size: 13px;
            }

            ::placeholder {
              font-size: 13px;
            }

            select {
              padding: 8px 22px 7px 12px;
              font-size: 13px;
            }
          }
        }
      }
    }

    .locationContent {
      .locationForm {
        ul li {
          .form-group {
            padding-right: 12px;

            input {
              // width: 254px;
              font-size: 13px;
              padding: 8px 26px 7px 12px;
            }
            ::-webkit-input-placeholder {
              font-size: 13px;
            }

            :-ms-input-placeholder {
              font-size: 13px;
            }

            ::placeholder {
              font-size: 13px;
            }

            select {
              padding: 8px 22px 7px 12px;
              font-size: 13px;
            }
          }
        }

        .form-middle,
        .form-last {
          margin-top: 18px;

          .form-group {
            width: 100%;
          }
          .form-grp {
            width: 49%;
            // height: 36px;
          }
          .disabledLast {
            border-radius: 7px;
            // max-height: 36px;
            overflow: hidden;
          }
        }

        .form-middle {
          .select__single-value.css-qc6sy-singleValue {
            font-size: 13px;
          }
        }

        .street:first-of-type {
          margin-bottom: 16px;
        }

        // .select__menu {
        //   max-height: 180px !important;
        // }
      }
    }

    .WorkDetails {
      margin-top: 40px;
      li {
        .forms {
          width: 49%;
        }
        .form-group {
          padding-right: 13px;
        }
        input {
          // width: 259px;
          font-size: 13px;
          padding: 9px 13px 8px;
        }
      }
      li:first-of-type {
        margin-bottom: 18px;
      }
    }

    .save {
      width: 210px;
      font-size: 13px;
      padding: 9px 65px;
      margin-top: 30px;
    }
  }
}

@include responsive(lg) {
  .profileDetail {
    .personalContent,
    .locationContent,
    .WorkDetails {
      h3 {
        font-size: 26px;
      }
    }

    .personalContent {
      .personalForm {
        margin-bottom: 35px;

        ul {
          .email {
            .forms {
              .form-email {
                .verify {
                  button {
                    padding: 6px 12px;
                  }
                }

                .verified{
                  button{
                    font-size: 11px;
                    margin-right: 15px;
                  }
                }
              }

              .form-group {
                span {
                  svg {
                    width: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .locationContent {
      .locationForm {
        ul li {
          .form-group {
            padding-right: 12px;
          }
        }
      }
    }

    .WorkDetails {
      margin-top: 30px;
      li {
        .form-group {
          padding-right: 13px;
        }
        input {
          // width: 214px;
          font-size: 13px;
          padding: 9px 13px 8px;
        }
      }
      li:first-of-type {
        margin-bottom: 16px;
      }
    }

    .save {
      width: 200px;
      padding: 9px 60px;
      margin-top: 20px;
    }
  }
}

@include responsive(md) {
  .profileDetail {
    .personalContent,
    .locationContent,
    .WorkDetails {
      h3 {
        font-size: 24px;
        margin-bottom: 15px;
      }
    }

    .personalContent {
      .personalForm {
        margin-bottom: 30px;

        ul {
          .name {
            margin-bottom: 15px;

            .forms {
              .form-group {
                input {
                  // width: 180px;
                  padding: 7px 0 7px 10px;
                }
              }
            }
          }
          .email {
            margin-bottom: 15px;

            .forms {
              .form-group {
                input {
                  // width: 246px;
                  padding: 7px 0 7px 10px;
                }
              }
              .form-email {
                .verify {
                  button {
                    font-size: 11px;
                    margin-right: 12px;
                  }
                }

                .verified{
                  button{
                    font-size: 11px;
                    margin-right: 12px;
                  }
                }
              }
            }
          }
          .mobile {
            margin-bottom: 15px;

            .forms {
              .form-group {
                input {
                  // width: 298px;
                  padding: 7px 0 7px 10px;
                }
              }
            }
          }
          .gender {
            .forms {
              .form-group {
                select {
                  padding: 7px 18px 7px 10px;
                }
                input {
                  // width: 294px;
                  padding: 7px 18px 7px 10px;
                }
              }
            }
            .gender-formGrp {
              .basic-multi-select {
                width: 100%;
              }
            }
          }
        }

        ul li {
          .form-group {
            input {
              padding: 7px 22px 7px 10px;
            }

            select {
              padding: 7px 22px 7px 10px;
            }
          }
        }
      }
    }

    .locationContent {
      .locationForm {
        ul li {
          .form-group {
            input {
              // width: 292px;
              padding: 7px 22px 7px 10px;
            }

            select {
              padding: 7px 22px 7px 10px;
            }
          }
        }

        .form-middle,
        .form-last {
          margin-top: 16px;

          .forms {
            width: 49%;
          }

          .form-group {
            width: 100%;
          }

          .form-grp {
            // height: 33px;
          }
          .disabledLast {
            // max-height: 33px;
          }
        }

        // .select__menu {
        //   max-height: 110px !important;
        // }
      }
    }

    .WorkDetails {
      margin-top: 20px;
      li {
        .form-group {
          padding-right: 12px;
        }
        input {
          width: calc(100% - 20px);
          font-size: 13px;
          padding: 9px 13px 8px;
        }
      }
      li:first-of-type {
        margin-bottom: 16px;
      }
    }

    .save {
      margin-top: 15px;
    }
  }
}

@include responsive(sm) {
  .profileDetail {
    .personalContent,
    .locationContent,
    .WorkDetails {
      h3 {
        font-size: 22px;
        margin-bottom: 12px;
      }
    }

    .personalContent {
      .personalForm {
        margin-bottom: 20px;

        ul {
          .name {
            margin-bottom: 12px;

            .forms {
              .form-group {
                input {
                  // width: 144px;
                  padding: 6px 0 6px 9px;
                  font-size: 12px;
                }
                ::-webkit-input-placeholder {
                  font-size: 12px;
                }

                :-ms-input-placeholder {
                  font-size: 12px;
                }

                ::placeholder {
                  font-size: 12px;
                }
              }
            }
          }
          .email {
            margin-bottom: 12px;

            .forms {
              .form-group {
                input {
                  // width: 188px;
                  padding: 6px 0 6px 8px;
                  font-size: 12px;
                }
                ::-webkit-input-placeholder {
                  font-size: 12px;
                }

                :-ms-input-placeholder {
                  font-size: 12px;
                }

                ::placeholder {
                  font-size: 12px;
                }
              }
              .form-email {
                .verify {
                  button {
                    margin-right: 10px;
                  }
                }

                .verified{
                  button{
                    margin-right: 10px;
                  }
                }
              }
            }
          }
          .mobile {
            margin-bottom: 12px;

            .forms {
              .form-group {
                input {
                  font-size: 12px;
                  padding: 6px 0 6px 8px;
                }
                ::-webkit-input-placeholder {
                  font-size: 12px;
                }

                :-ms-input-placeholder {
                  font-size: 12px;
                }

                ::placeholder {
                  font-size: 12px;
                }
              }
            }
          }
          .gender {
            .forms {
              .form-group {
                select {
                  padding: 6px 15px 6px 8px;
                  font-size: 12px;
                }
                input {
                  // width: 234px;
                  padding: 6px 15px 6px 8px;
                  font-size: 12px;
                }
              }
            }
            .gender-formGrp {
              .basic-multi-select {
                font-size: 12px;
              }
            }
          }
        }

        ul li {
          .form-group {
            input {
              padding: 6px 18px 6px 8px;
              font-size: 12px;
            }
            ::-webkit-input-placeholder {
              font-size: 12px;
            }

            :-ms-input-placeholder {
              font-size: 12px;
            }

            ::placeholder {
              font-size: 12px;
            }

            select {
              padding: 6px 18px 6px 8px;
              font-size: 12px;
            }
          }
        }
      }
    }

    .locationContent {
      .locationForm {
        ul li {
          .form-group {
            input {
              // width: 232px;
              font-size: 12px;
              padding: 6px 18px 6px 8px;
            }
            ::-webkit-input-placeholder {
              font-size: 12px;
            }

            :-ms-input-placeholder {
              font-size: 12px;
            }

            ::placeholder {
              font-size: 12px;
            }

            select {
              padding: 6px 18px 6px 8px;
              font-size: 12px;
            }
          }
        }

        .form-middle,
        .form-last {
          margin-top: 12px;

          .form-grp {
            // height: auto;
          }
          .disabledLast {
            // max-height: auto;
          }
        }

        .form-middle {
          .select__single-value.css-qc6sy-singleValue {
            font-size: 12px;
          }
        }

        .street:first-of-type {
          margin-bottom: 12px;
        }
      }
    }

    .WorkDetails {
      margin-top: 18px;
      li {
        .form-group {
          padding-right: 12px;
        }
        input {
          width: calc(100% - 20px);
          font-size: 12px;
          padding: 8px 12px;
        }
      }
      li:first-of-type {
        margin-bottom: 15px;
      }
    }

    .save {
      width: 185px;
      font-size: 12px;
      padding: 8px 55px;
    }
  }
}

@include responsive(xs) {
  .profileDetail {
    .personalContent,
    .locationContent,
    .WorkDetails {
      h3 {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    .personalContent {
      .personalForm {
        margin-bottom: 5px;

        ul {
          .name {
            margin-bottom: 0px;
            flex-wrap: wrap;

            .forms {
              width: 100%;
              margin-bottom: 10px;

              .form-group {
                input {
                  // width: 240px;
                }
              }
            }
          }
          .email {
            margin-bottom: 0px;
            flex-wrap: wrap;

            .forms {
              margin-bottom: 10px;
              width: 100%;

              .form-group {
                .verify {
                  button {
                    padding: 5px 10px;
                  }
                }
              }
            }
          }
          .mobile {
            margin-bottom: 0px;
            flex-wrap: wrap;

            .forms {
              margin-bottom: 10px;
              width: 100%;

              .form-group {
                input {
                  // width: 240px;
                }
              }
            }
          }
          .gender {
            flex-wrap: wrap;
            .forms {
              width: 100%;
              margin-bottom: 10px;

              .form-group {
                input {
                  // width: 220px;
                }
              }
            }
            .gender-formGrp {
              width: 100%;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .locationContent {
      .locationForm {
        ul li {
          .form-group {
            margin-bottom: 10px;
            input {
              // width: 218px;
            }
          }
        }

        .form-middle,
        .form-last {
          flex-wrap: wrap;
          width: 100%;
          margin-top: 0px;

          .forms {
            width: 100%;
          }

          .form-grp {
            width: 100%;
            margin-bottom: 10px;
          }
          .disabledLast {
            border-radius: 7px;
            margin-bottom: 0;
          }
        }

        .street:first-of-type {
          margin-bottom: 0px;
        }
      }
    }

    .WorkDetails {
      margin-top: 18px;
      li {
        margin-bottom: 15px;
        .forms:first-of-type {
          margin-bottom: 15px;
        }
        flex-wrap: wrap;
        .forms {
          width: 100%;
        }
        .form-group {
          padding-right: 12px;
        }
        input {
          width: calc(100% - 20px);
          font-size: 12px;
          padding: 8px 12px;
        }
      }
    }

    .save {
      width: 160px;
      padding: 8px 40px;
    }
  }
}
