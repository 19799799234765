@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.strategicDetail {
  width: 100%;
  margin: 114px 0 60px;

  .strategicDetailsWrap {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
  }

  .strategicDetailSidebar {
    width: 556px;

    .strategicDetailsCard {
      background: $white;
      margin-bottom: 30px;
      padding: 30px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

      .strategicDetailsFigure {
        width: 494px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          color: transparent;
        }
      }
      .strategicUser {
        .tagline {
          display: flex;
          justify-content:center;
          position: relative;
        }
        .telegramButton {
          margin-left: 30px;
          width: 60px;
          padding: 11px 23px 8px 21px;
          border-radius: 50px;
          cursor: pointer;
          background-color: $white_1;
          position: absolute;
          right: 0;
        }
        h3 {
          font-family: $font_jost;
          font-weight: 600;
          font-size: 16px;
          color: $black_1;
          text-transform: capitalize;
          margin-bottom: 9px;
        }
        .maintag {
          display: flex;
          align-items: center;
          margin: 6px 0;
          text-align: center;
          justify-content: center;
          svg {
            width: 17px;
            height: 17px;
          }

          h4 {
            font-family: $font_jost;
            font-weight: 600;
            font-size: 12px;
            color: $black_1;
            font-style: italic;
            line-height: 25px;
            margin-left: 7px;
          }
        }
        .strategicUserName {
          display: flex;
          align-items: center;
          padding-bottom: 15px;
          margin-bottom: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          h5 {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 12px;
            color: $black_1;
            margin-right: 15px;
          }
        }
        p {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
          text-transform: capitalize;
          line-height: 22px;
          margin-bottom: 15px;
        }

        .readLess {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .member {
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          // justify-content: space-between;
          align-items: center;

          button {
            font-family: $font_jost;
            font-weight: 700;
            color: $green-shadow;
            background: none;
            cursor: pointer;
          }

          .hideBtn {
            display: none;
          }
        }
      }
      .strategicCardDetails {
        .strategicCardAdrees {
          display: flex;
          margin-bottom: 6px;
        }
        .location {
          display: flex;
          img {
            width: 17px;
            height: 18px;
          }
        }
        .location address {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .cardContact {
          display: flex;
          margin-bottom: 6px;

          .mail,
          .mobile,
          .money {
            display: flex;
            align-items: center;
          }

          .website {
            display: flex;
            align-items: center;
            flex-shrink: 0;

            svg {
              width: 18px;
              height: 15px;
            }

            h4 {
              width: 390px;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              a {
                border-bottom: 1px solid $light-gray;
              }
            }
          }

          .phnNumber {
            display: flex;
            align-items: center;
          }
        }

        h4,
        address {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 12px;
          color: $black_1;
          font-style: normal;
          line-height: normal;
          margin-left: 7px;
          margin-right: 10px;
        }
        h4 a {
          color: $black_1;
          @include prefix(transition, all 0.3s ease-in-out);
          &:hover {
            color: $gray;
          }
        }
        .strategicFullName {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          h5 {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 12px;
            color: $black_1;
            margin-left: 10px;
          }
        }
      }
    }
    .research {
      background-color: $white;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      padding: 30px 28px 30px 30px;
      margin-bottom: 30px;

      h3 {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 16px;
        color: $black_1;
        margin-bottom: 14px;
        text-transform: capitalize;
      }

      p {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 14px;
        color: $light-gray;
        margin-bottom: 15px;
        text-transform: capitalize;
        line-height: 25px;
      }

      .readLess {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .member {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .member-list {
          display: flex;
          align-items: center;

          .member-images {
            display: flex;
            margin-right: 15px;

            figure {
              display: flex;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              border: 2px solid $white;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                color: transparent;
              }

              span {
                display: inline-block;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-size: cover;
              }

              &:nth-child(2) {
                margin-left: -5px;
              }

              &:nth-child(3) {
                margin-left: -5px;
              }

              &:nth-child(4) {
                margin-left: -5px;
              }
            }
          }

          .member-count {
            font-family: $font_jost;
            font-weight: 400;
            color: $green-shadow;
            font-size: 12px;
          }
        }

        button {
          font-family: $font_jost;
          font-weight: 700;
          color: $green-shadow;
          background: none;
          cursor: pointer;
        }

        .hideBtn {
          display: none;
        }
      }
    }
  }
  .strategicContent {
    width: 752px;

    .strategicTab {
      .react-tabs__tab-list {
        border-bottom: none;
        width: 448px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $light-green;
        border-radius: 50px;
        margin-bottom: 30px;
        padding: 17px 59px 18px 40px;
      }

      .react-tabs__tab {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        color: $black_1;
        border: none;
        padding: 0px;
        background-color: $light-green;
      }

      .react-tabs__tab--selected {
        font-weight: 400;
        font-size: 16px;
        color: $green-shadow;
        background-color: $light-green;
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .strategicDetail {
    margin: 108px 0 58px;

    .strategicDetailsWrap {
      margin-top: 45px;
      width: 1138px;
    }

    .strategicDetailSidebar {
      width: 500px;

      .strategicDetailsCard {
        padding: 28px;
        margin-bottom: 28px;

        .strategicDetailsFigure {
          max-width: 100%;
          height: 230px;
          margin-bottom: 23px;
        }
        .strategicUser {
          h3 {
            font-size: 15px;
            margin-bottom: 8px;
          }
          .strategicUserName {
            padding-bottom: 13px;
            margin-bottom: 9px;

            h5 {
              font-size: 12px;
              margin-right: 14px;
            }

            .strategicFullName {
              h5 {
                font-size: 12px;
                margin-left: 8px;
              }
            }
          }
          p {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 14px;
          }
        }
        .strategicCardDetails {
          .strategicCardAdrees {
            margin-bottom: 5px;
          }
          h4,
          address {
            font-size: 12px;
            line-height: 23px;
            margin-left: 7px;
            margin-right: 10px;
          }
        }
      }
      .research {
        padding: 28px 26px 28px 28px;
        margin-bottom: 28px;

        h3 {
          font-size: 15px;
          margin-bottom: 12px;
        }

        p {
          font-size: 13px;
          line-height: 22px;
          margin-bottom: 14px;
        }

        .member {
          button {
            font-family: $font_jost;
            font-weight: 700;
            color: $green-shadow;
            background: none;
            cursor: pointer;
          }

          .hideBtn {
            display: none;
          }
        }
      }
    }
    .strategicContent {
      width: 600px;

      .strategicTab {
        .react-tabs__tab-list {
          width: 400px;
          margin-bottom: 24px;
          padding: 16px 50px 16px 38px;
        }

        .react-tabs__tab {
          font-size: 14px;
        }
        .react-tabs__tab--selected {
          font-size: 14px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .strategicDetail {
    margin: 104px 0 45px;

    .strategicDetailsWrap {
      width: 960px;
      margin-top: 40px;
    }

    .strategicDetailSidebar {
      width: 410px;

      .strategicDetailsCard {
        margin-bottom: 26px;
        padding: 26px;

        .strategicDetailsFigure {
          height: 200px;
          margin-bottom: 21px;
        }
        .strategicUser {
          h3 {
            font-size: 14px;
            margin-bottom: 7px;
          }
          .strategicUserName {
            padding-bottom: 12px;
            margin-bottom: 8px;

            h5 {
              font-size: 12px;
              margin-right: 12px;
            }

            .strategicFullName {
              h5 {
                font-size: 12px;
                margin-left: 7px;
              }
            }
          }
          p {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 13px;
          }
        }
        .strategicCardDetails {
          .strategicCardAdrees {
            margin-bottom: 5px;
          }
          h4,
          address {
            font-size: 12px;
            line-height: 22px;
            margin-left: 7px;
            margin-right: 8px;
          }
        }
      }
      .research {
        padding: 26px 24px 26px 26px;
        margin-bottom: 26px;

        h3 {
          font-size: 14px;
          margin-bottom: 10px;
        }

        p {
          font-size: 13px;
          margin-bottom: 11px;
          line-height: 20px;
        }

        .member {
          button {
            font-family: $font_jost;
            font-weight: 700;
            color: $green-shadow;
            background: none;
            cursor: pointer;
          }

          .hideBtn {
            display: none;
          }
        }
      }
    }
    .strategicContent {
      width: 510px;

      .strategicTab {
        .react-tabs__tab-list {
          width: 380px;
          margin-bottom: 22px;
          padding: 14px 45px 15px 30px;
        }

        .react-tabs__tab {
          font-size: 14px;
        }
        .react-tabs__tab--selected {
          font-size: 14px;
        }
      }
    }
  }
}

@include responsive(md) {
  .strategicDetail {
    margin: 95px 0 35px;

    .strategicDetailsWrap {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 35px;
    }

    .strategicDetailSidebar {
      width: 100%;
      margin-bottom: 25px;

      .strategicDetailsCard {
        margin-bottom: 22px;
        padding: 22px;

        .strategicDetailsFigure {
          width: 100%;
          height: 200px;
          margin-bottom: 15px;
        }
        .strategicUser {
          h3 {
            font-size: 13px;
            margin-bottom: 5px;
          }
          .strategicUserName {
            padding-bottom: 11px;
            margin-bottom: 8px;

            h5 {
              font-size: 12px;
              margin-right: 10px;
            }

            .strategicFullName {
              h5 {
                font-size: 12px;
                margin-left: 6px;
              }
            }
          }
          p {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 10px;
          }
        }
        .strategicCardDetails {
          .strategicCardAdrees {
            margin-bottom: 5px;
          }
          h4,
          address {
            font-size: 12px;
            line-height: 20px;
            margin-right: 6px;
            margin-left: 6px;
          }
        }
      }
      .research {
        padding: 24px;
        margin-bottom: 22px;

        h3 {
          font-size: 14px;
          margin-bottom: 8px;
        }

        p {
          font-size: 13px;
          margin-bottom: 8px;
          line-height: 18px;
        }

        .member {
          button {
            font-family: $font_jost;
            font-weight: 700;
            color: $green-shadow;
            background: none;
            cursor: pointer;
          }

          .hideBtn {
            display: none;
          }
        }
      }
    }
    .strategicContent {
      width: 100%;

      .strategicTab {
        .react-tabs__tab-list {
          width: 450px;
          margin-bottom: 22px;
          padding: 14px 45px 15px 30px;
        }

        .react-tabs__tab {
          font-size: 15px;
        }
        .react-tabs__tab--selected {
          font-size: 15px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .strategicDetail {
    margin: 90px 0 25px;

    .strategicDetailsWrap {
      margin-top: 25px;
    }

    .strategicDetailSidebar {
      margin-bottom: 20px;

      .strategicDetailsCard {
        margin-bottom: 20px;
        padding: 20px;

        .strategicDetailsFigure {
          height: 180px;
          margin-bottom: 12px;
        }
        .strategicUser {
          h3 {
            font-size: 13px;
            margin-bottom: 4px;
          }
          .strategicUserName {
            padding-bottom: 10px;
            margin-bottom: 8px;

            h5 {
              font-size: 12px;
              margin-right: 9px;
            }

            .strategicFullName {
              h5 {
                font-size: 12px;
                margin-left: 6px;
              }
              img {
                width: 14px;
              }
            }
          }
          p {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 8px;
          }
        }
        .strategicCardDetails {
          .strategicCardAdrees {
            margin-bottom: 5px;
          }
          h4,
          address {
            font-size: 12px;
            line-height: 18px;
            margin-right: 6px;
            margin-left: 6px;
          }
          img {
            width: 15px;
          }
        }
      }
      .research {
        padding: 22px;
        margin-bottom: 20px;

        h3 {
          font-size: 13px;
          margin-bottom: 6px;
        }

        p {
          font-size: 12px;
          margin-bottom: 6px;
          line-height: 18px;
        }

        .member {
          button {
            font-family: $font_jost;
            font-weight: 700;
            color: $green-shadow;
            background: none;
            cursor: pointer;
          }

          .hideBtn {
            display: none;
          }
        }
      }
    }
    .strategicContent {
      .strategicTab {
        .react-tabs__tab-list {
          width: 410px;
          margin-bottom: 20px;
          padding: 14px 40px 14px 28px;
        }

        .react-tabs__tab {
          font-size: 14px;
        }
        .react-tabs__tab--selected {
          font-size: 14px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .strategicDetail {
    margin: 68px 0 15px;

    .strategicDetailsWrap {
      margin-top: 15px;
    }

    .strategicDetailSidebar {
      margin-bottom: 15px;

      .strategicDetailsCard {
        margin-bottom: 15px;
        padding: 15px;

        .strategicDetailsFigure {
          height: 100px;
          margin-bottom: 10px;
        }
        .strategicUser {
          h3 {
            font-size: 12px;
          }
          .strategicUserName {
            padding-bottom: 8px;
            margin-bottom: 6px;

            h5 {
              font-size: 11px;
              margin-right: 6px;
            }

            .strategicFullName {
              h5 {
                font-size: 11px;
                margin-left: 6px;
              }
              img {
                width: 13px;
              }
            }
          }
          p {
            font-size: 11px;
            margin-bottom: 7px;
          }
        }
        .strategicCardDetails {
          margin-bottom: 10px;
          .strategicCardAdrees {
            margin-bottom: 5px;
          }
          .localDetails,
          .cardContact {
            flex-wrap: wrap;
            margin-bottom: 5px;
          }

          h4 {
            font-size: 12px;
            line-height: 23px;
          }
          img {
            width: 15px;
          }
        }
      }
      .research {
        padding: 15px;
        margin-bottom: 15px;

        h3 {
          font-size: 12px;
          margin-bottom: 4px;
        }

        p {
          font-size: 11px;
          margin-bottom: 5px;
          line-height: 15px;
        }

        .member {
          button {
            font-family: $font_jost;
            font-weight: 700;
            color: $green-shadow;
            background: none;
            cursor: pointer;
          }

          .hideBtn {
            display: none;
          }
        }
      }
    }
    .strategicContent {
      .strategicTab {
        .react-tabs__tab-list {
          width: 250px;
          margin-bottom: 15px;
          padding: 15px;
        }

        .react-tabs__tab {
          font-size: 12px;
        }
        .react-tabs__tab--selected {
          font-size: 12px;
        }
      }
    }
  }
}
