@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.bread-list {
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    margin-right: 6px;

    a h4 {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: rgba($color: #000000, $alpha: 0.4);
    }

    .crumb {
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
    }
  }
}

// responsive
@include responsive(lg) {
  .bread-list {
    li {
      margin-right: 5px;

      a h4 {
        font-size: 15px;
      }
      .crumb {
        font-size: 15px;
      }
    }
  }
}

@include responsive(md) {
  .bread-list {
    li {
      margin-right: 4px;

      a h4 {
        font-size: 14px;
      }
      .crumb {
        font-size: 14px;
      }
    }
  }
}

@include responsive(sm) {
  .bread-list {
    li {
      margin-right: 3px;

      a h4 {
        font-size: 13px;
      }
      .crumb {
        font-size: 13px;
      }
    }
  }
}

@include responsive(xs) {
  .bread-list {
    li {
      margin-right: 2px;

      a h4 {
        font-size: 12px;
      }
      .crumb {
        font-size: 12px;
      }
    }
  }
}
