@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.favoriteScroll {
  max-height: 650px;

  .ps__rail-y {
    visibility: hidden;
  }
}

.FavoriteCause {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 3px;

  .causeCard {
    width: 220px;
    margin-bottom: 30px;
    margin-right: 24px;
    position: relative;
    &:nth-of-type(3n + 0) {
      margin-right: 0;
    }
    .causeImage {
      width: 100%;
      height: 164px;
      // margin-bottom: 19px;
      figure {
        display: inline-block;
        width: 100%;
        height: 100%;
        img {
          color: transparent;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .causeContent {
      padding: 20px 18px 30px 18px;
      h4 {
        width: 188px;
        height: 46px;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .causeButtons {
      position: absolute;
      top: 15px;
      right: 15px;
      .causeHeartActive {
        width: 54px;
        padding: 6px 14px 5px 16px;
        cursor: pointer;
        svg {
          width: 25px;
          height: 23px;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .favoriteScroll {
    max-height: 650px;
  }

  .FavoriteCause {
    padding: 9px 3px;

    .causeCard {
      width: 210px;
      margin-bottom: 28px;
      margin-right: 22px;
      &:nth-of-type(3n + 0) {
        margin-right: 22px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }
      .causeImage {
        height: 155px;
      }
      .causeContent {
        padding: 18px 16px 28px;
        h4 {
          height: 44px;
          font-size: 16px;
        }
      }
      .causeButtons {
        .causeHeartActive {
          min-width: 52px;
          padding: 6px 12px 5px 14px;
          svg {
            width: 24px;
            height: 22px;
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .favoriteScroll {
    max-height: 650px;
  }

  .FavoriteCause {
    padding: 8px 3px;

    .causeCard {
      width: 194px;
      margin-bottom: 26px;
      margin-right: 20px;
      &:nth-of-type(3n + 0) {
        margin-right: 20px;
      }
      &:nth-of-type(6) {
        margin-right: 0;
      }
      .causeImage {
        height: 145px;
      }
      .causeContent {
        padding: 16px 14px 24px;
        h4 {
          height: 42px;
          font-size: 15px;
        }
      }
      .causeButtons {
        .causeHeartActive {
          min-width: 50px;
          padding: 6px 10px 5px 12px;
          svg {
            width: 23px;
            height: 21px;
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .favoriteScroll {
    max-height: auto;
  }

  .FavoriteCause {
    padding: 7px 3px;

    .causeCard {
      width: 194px;
      margin-bottom: 22px;
      margin-right: 18px;
      &:nth-of-type(3n + 0) {
        margin-right: 18px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 18px;
      }
      .causeImage {
        height: 130px;
      }
      .causeContent {
        padding: 15px 12px 20px;
        h4 {
          width: 100%;
          height: 38px;
          font-size: 14px;
        }
      }
      .causeButtons {
        .basket {
          min-width: 110px;
          padding: 7px 20px 5px;
          font-size: 12px;
          margin-right: 4px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .FavoriteCause {
    padding: 7px 3px;

    .causeCard {
      width: 200px;
      margin-bottom: 20px;
      margin-right: 16px;
      &:nth-of-type(3n + 0) {
        margin-right: 16px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 16px;
      }
      .causeImage {
        height: 120px;
      }
      .causeContent {
        padding: 12px 12px 18px;
        h4 {
          height: 36px;
          font-size: 13px;
        }
      }
      .causeButtons {
        .causeHeartActive {
          min-width: 46px;
          padding: 5px 8px;
          svg {
            width: 22px;
            height: 20px;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .FavoriteCause {
    padding: 7px 3px;

    .causeCard {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px auto;
      margin-bottom: 12px;
      padding: 10px;
      @include prefix(transition, all 0.3s ease-in-out);

      &:hover {
        background-color: $light-green;
      }

      &:nth-of-type(3n + 0) {
        margin-right: 0px;
      }
      &:nth-of-type(2n + 0) {
        margin-right: 0px;
      }

      .causemainContent {
        display: flex;
        align-items: center;
      }

      .causeImage {
        width: 50px;
        height: 50px;
        margin-right: 8px;

        figure img {
          object-fit: cover;
        }
      }
      .causeContent {
        padding: 0px;
        margin-right: 8px;
        h4 {
          width: 134px;
          font-size: 12px;
          height: auto;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .causeButtons {
        position: static;
        .causeHeartActive {
          width: 45px;
          svg {
            width: 20px;
            height: 18px;
          }
        }
      }
    }
  }
}
