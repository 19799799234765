@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.common-search {
  width: 100%;
  background: none;
  color: $white;
  font-weight: 400;
  font-size: 14px;

  .wrapper {
    background-color: #f3f3f3;
    z-index: 5555 !important;
    border: none;
    @include prefix(transition, all 0.3s ease-in-out);
    input {
      width: 765px;
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
      background-color: transparent;
    }
    ::-webkit-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
    }

    :-ms-input-placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
    }

    ::placeholder {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;
    }
    li {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: #645f5f;

      &.selected {
        background-color: $white-dark;
        margin-right: 0 !important;
      }
    }
  }
}
