@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.charitydetails {
  width: 100%;
  margin: 114px 0 60px;

  .charityDetailsWrap {
    padding-top: 48px;
    width: 1343px;
    display: flex;
    justify-content: space-between;
    // background-color: $white-shadow;

    .charitySidebar {
      width: 556px;

      .detailCard {
        background: $white;
        margin-bottom: 30px;
        padding: 30px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

        .detailsFigure {
          max-width: 494px;
          height: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 25px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            color: transparent;
          }
        }

        .cardUser {
          position: relative;

          .nameWIcon {
            display: flex;
            align-items: center;
            margin-bottom: 9px;

            h3 {
              font-family: $font_jost;
              font-weight: 600;
              font-size: 16px;
              color: $black_1;
            }

            figure {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              margin-left: 10px;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }

          .maintag {
            display: flex;
            align-items: start;
            margin: 6px 0;
            text-align: start;
            justify-content: center;

            svg {
              width: 16px;
              height: 17px;
              // margin-bottom: 10px;
            }

            p {
              font-family: $font_jost;
              font-weight: 600;
              font-size: 13px;
              color: $black_1;
              font-style: italic;
              line-height: 25px;
              // margin-left: 7px;
            }
          }

          .cardusername {
            display: flex;
            align-items: center;
            padding-bottom: 15px;
            margin-bottom: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            h5 {
              font-family: $font_jost;
              font-weight: 400;
              font-size: 12px;
              color: $black_1;
              margin-right: 15px;
            }

            .fullUserName {
              display: flex;
              align-items: center;

              h5 {
                font-family: $font_jost;
                font-weight: 400;
                font-size: 12px;
                color: $black_1;
                margin-left: 10px;
              }
            }
          }

          p {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            line-height: 22px;
            margin-bottom: 15px;
          }

          .readLess {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: pre-line;
          }

          .member {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            // justify-content: space-between;
            align-items: center;

            button {
              font-family: $font_jost;
              font-weight: 700;
              color: $green-shadow;
              background: none;
              cursor: pointer;
            }

            .hideBtn {
              display: none;
            }
          }
        }

        .cardDetails {
          margin-bottom: 25px;

          .cardAddress {
            display: flex;
            margin-bottom: 6px;

            .location {
              display: flex;
              align-items: center;
              margin-top: 5px;
            }

            .location address {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          .cardContact {
            display: flex;
            margin-bottom: 6px;

            .mail,
            .mobile {
              display: flex;
              align-items: center;
            }

            .phnNumber {
              display: flex;
              align-items: center;
            }
          }

          .localDetails {
            display: flex;
            align-items: flex-start;
            margin-bottom: 6px;

            .website {
              display: flex;
              align-items: center;
              flex-shrink: 0;

              svg {
                width: 18px;
                height: 15px;
              }

              h4 {
                width: 390px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;

                a {
                  border-bottom: 1px solid $light-gray;
                }
              }
            }
          }

          .type {
            display: flex;

            .nation,
            .money {
              display: flex;

              img {
                width: 20px;
                height: 20px;
              }
            }

            .nation {
              flex-shrink: 0;

              h4 {
                max-width: 75px;
              }
            }
          }

          .mail h4 a {
            border-bottom: 1px solid $light-gray;
          }

          h4,
          address {
            font-family: $font_jost;
            font-weight: 400;
            font-size: 12px;
            color: $black_1;
            font-style: normal;
            line-height: 25px;
            margin-left: 7px;
            margin-right: 10px;
          }

          h4 a {
            color: $black_1;
            @include prefix(transition, all 0.3s ease-in-out);

            &:hover {
              color: $gray;
            }
          }
        }

        .cardButton {
          .basket {
            width: 149px;
            background-color: $green-shadow;
            font-family: $font_jost;
            font-weight: 400;
            padding: 8px 20px;
            font-size: 14px;
            color: $white;
            border-radius: 20px;
            cursor: pointer;
            @include prefix(transition, all 0.3s ease-in-out);
            margin-right: 10px;

            &:hover {
              background-color: $theme-color;
            }
          }

          .charityHeart {
            width: 56px;
            padding: 8px 17px 5px;
            background-color: rgba($color: $theme-color, $alpha: 0.5);
            border-radius: 20px;
            cursor: pointer;

            svg {
              width: 25px;
              height: 23px;

              path {
                fill: white;
                stroke: white;
              }
            }
          }

          .charityHeartActive {
            width: 66px;
            padding: 6px 17px 5px;
            border-radius: 20px;
            background-color: transparent;
            border: 1px solid $green-shadow;
            cursor: pointer;

            svg {
              width: 25px;
              height: 23px;

              path {
                fill: $red;
                stroke: $red;
              }
            }
          }

          .telegramButtons {
            flex-shrink: 0;
            width: 60px;
            padding: 11px 23px 8px 21px;
            border-radius: 20px;
            margin-left: 10px;
            cursor: pointer;
            background-color: $green-shadow;

            svg path {
              fill: $color2;
            }
          }
        }
      }

      .research {
        background-color: $white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        padding: 30px 28px 30px 30px;
        margin-bottom: 30px;

        h3 {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 16px;
          color: $black_1;
          margin-bottom: 14px;
        }

        p {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
          margin-bottom: 15px;
          line-height: 25px;
        }

        .readLess {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .newline {
          white-space: pre-line;
        }

        .member {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .member-list {
            display: flex;
            align-items: center;

            .member-images {
              display: flex;
              margin-right: 15px;

              figure {
                display: flex;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 2px solid $white;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                  color: transparent;
                }

                span {
                  display: inline-block;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  background-repeat: no-repeat;
                  background-size: cover;
                }

                &:nth-child(2) {
                  margin-left: -5px;
                }

                &:nth-child(3) {
                  margin-left: -5px;
                }

                &:nth-child(4) {
                  margin-left: -5px;
                }
              }
            }

            .member-count {
              font-family: $font_jost;
              font-weight: 400;
              color: $green-shadow;
              font-size: 12px;
            }
          }

          button {
            font-family: $font_jost;
            font-weight: 700;
            color: $green-shadow;
            background: none;
            cursor: pointer;
          }

          .hideBtn {
            display: none;
          }
        }
      }

      .share {
        width: 100%;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        background-color: $white;
        padding: 25px 30px 30px;

        h3 {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 16px;
          color: $black_1;
          margin-bottom: 8px;
          text-transform: capitalize;
        }

        p {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
          margin-bottom: 15px;
          text-transform: capitalize;
          line-height: 25px;
        }

        .copy-text {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $light-green;
          border-radius: 50px;
          padding: 5px 4px 5px 22px;

          input {
            width: 365px;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            background: none;
            text-overflow: ellipsis;
          }

          button {
            border-radius: 50px;
            background-color: $green-shadow;
            width: 84px;
            height: 28px;
            text-align: center;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $white;
            cursor: pointer;
          }
        }
      }
    }

    .charityContent {
      width: 754px;

      .charityTab {
        .react-tabs__tab-list {
          border-bottom: none;
          width: 606px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $light-green;
          border-radius: 50px;
          margin-bottom: 30px;
          padding: 17px 59px 18px 40px;
        }

        .react-tabs__tab {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 16px;
          color: $black_1;
          border: none;
          padding: 0px;
          background-color: $light-green;
        }

        .react-tabs__tab--selected {
          font-weight: 700;
          font-size: 16px;
          color: $green-shadow;
          background-color: $light-green;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .charitydetails {
    margin: 108px 0 58px;

    .charityDetailsWrap {
      padding-top: 45px;
      width: 1138px;

      .charitySidebar {
        width: 500px;

        .detailCard {
          margin-bottom: 28px;
          padding: 28px;

          .detailsFigure {
            max-width: 100%;
            height: 220px;
            margin-bottom: 23px;
          }

          .cardUser {
            .nameWIcon {
              margin-bottom: 8px;

              h3 {
                font-size: 15px;
              }

              figure {
                width: 19px;
                height: 19px;
                margin-left: 9px;
              }
            }

            .cardusername {
              padding-bottom: 13px;
              margin-bottom: 9px;

              h5 {
                font-size: 12px;
                margin-right: 14px;
              }

              .fullUserName {
                h5 {
                  font-size: 12px;
                  margin-left: 8px;
                }
              }
            }

            p {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 14px;
            }
          }

          .cardDetails {
            .cardAddress,
            .cardContact,
            .localDetails,
            .type {
              margin-bottom: 5px;
            }

            h4,
            address {
              font-size: 12px;
              line-height: 23px;
              margin-right: 9px;
            }
          }

          .cardButton {
            .basket {
              width: 140px;
              padding: 7px 18px;
              font-size: 13px;
              margin-right: 9px;
            }

            .charityHeart {
              width: 54px;
              padding: 7px 16px 5px;

              svg {
                width: 23px;
                height: 22px;
              }
            }

            .charityHeartActive {
              width: 54px;
              padding: 7px 16px 5px;

              svg {
                width: 23px;
                height: 22px;
              }
            }
          }
        }

        .research {
          padding: 28px 26px 28px 28px;
          margin-bottom: 28px;

          h3 {
            font-size: 15px;
            margin-bottom: 12px;
          }

          p {
            font-size: 13px;
            line-height: 22px;
            margin-bottom: 14px;
          }
        }

        .share {
          padding: 22px 28px 28px;

          h3 {
            font-size: 15px;
            margin-bottom: 7px;
          }

          p {
            font-size: 13px;
            margin-bottom: 13px;
            line-height: 23px;
          }

          .copy-text {
            padding: 5px 4px 5px 15px;

            input {
              width: 335px;
              font-size: 13px;
              letter-spacing: 0.001em;
              white-space: nowrap;
              overflow: hidden;
            }

            button {
              width: 75px;
              height: 26px;
              font-size: 13px;
            }
          }
        }
      }

      .charityContent {
        width: 600px;

        .charityTab {
          .react-tabs__tab-list {
            width: 590px;
            margin-bottom: 28px;
            padding: 16px 55px 17px 38px;
          }

          .react-tabs__tab {
            font-size: 15px;
          }

          .react-tabs__tab--selected {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .charitydetails {
    margin: 104px 0 45px;

    .charityDetailsWrap {
      padding-top: 40px;
      width: 960px;

      .charitySidebar {
        width: 410px;

        .detailCard {
          margin-bottom: 26px;
          padding: 26px;

          .detailsFigure {
            height: 200px;
            margin-bottom: 21px;
          }

          .cardUser {
            .nameWIcon {
              margin-bottom: 7px;

              h3 {
                font-size: 14px;
              }

              figure {
                width: 18px;
                height: 18px;
                margin-left: 8px;
              }
            }

            .cardusername {
              padding-bottom: 12px;
              margin-bottom: 8px;

              h5 {
                font-size: 12px;
                margin-right: 12px;
              }

              .fullUserName {
                h5 {
                  font-size: 12px;
                  margin-left: 7px;
                }
              }
            }

            p {
              font-size: 13px;
              line-height: 18px;
              margin-bottom: 13px;
            }
          }

          .cardDetails {
            .cardAddress,
            .cardContact,
            .localDetails,
            .type {
              margin-bottom: 5px;
            }

            h4,
            address {
              font-size: 12px;
              line-height: 22px;
              margin-right: 8px;
            }
          }

          .cardButton {
            .basket {
              width: 140px;
              padding: 7px 18px;
              font-size: 13px;
              margin-right: 8px;
            }

            .charityHeart {
              width: 54px;
              padding: 6px 14px 5px;

              svg {
                width: 23px;
                height: 21px;
              }
            }

            .charityHeartActive {
              width: 54px;
              padding: 6px 14px 5px;

              svg {
                width: 23px;
                height: 21px;
              }
            }
          }
        }

        .research {
          padding: 26px 24px 26px 26px;
          margin-bottom: 26px;

          h3 {
            font-size: 14px;
            margin-bottom: 10px;
          }

          p {
            font-size: 13px;
            margin-bottom: 11px;
            line-height: 20px;
          }
        }

        .share {
          padding: 20px 26px;

          h3 {
            font-size: 14px;
            margin-bottom: 5px;
          }

          p {
            font-size: 13px;
            margin-bottom: 12px;
            line-height: 20px;
          }

          .copy-text {
            padding: 5px 4px 5px 15px;

            input {
              width: 265px;
              font-size: 12px;
            }

            button {
              width: 72px;
              height: 25px;
              font-size: 12px;
            }
          }
        }
      }

      .charityContent {
        width: 510px;

        .charityTab {
          .react-tabs__tab-list {
            width: 410px;
            margin-bottom: 25px;
            padding: 14px 45px 15px 30px;
          }

          .react-tabs__tab {
            font-size: 14px;
          }

          .react-tabs__tab--selected {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .charitydetails {
    margin: 95px 0 35px;

    .charityDetailsWrap {
      padding-top: 30px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;

      .charitySidebar {
        width: 100%;
        margin-bottom: 25px;

        .detailCard {
          margin-bottom: 22px;
          padding: 22px;

          .detailsFigure {
            height: 200px;
            margin-bottom: 15px;
          }

          .cardUser {
            .nameWIcon {
              margin-bottom: 5px;

              h3 {
                font-size: 13px;
              }

              figure {
                width: 18px;
                height: 18px;
                margin-left: 7px;
              }
            }

            .cardusername {
              padding-bottom: 11px;
              margin-bottom: 8px;

              h5 {
                font-size: 12px;
                margin-right: 10px;
              }

              .fullUserName {
                h5 {
                  font-size: 12px;
                  margin-left: 6px;
                }

                img {
                  width: 15px;
                }
              }
            }

            p {
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 10px;
            }
          }

          .cardDetails {
            .cardAddress {
              margin-bottom: 5px;
            }

            h4,
            address {
              font-size: 12px;
              line-height: 20px;
              margin-right: 6px;
            }

            img {
              width: 16px;
            }
          }

          .cardButton {
            .basket {
              width: 140px;
              padding: 7px 18px;
              font-size: 13px;
              margin-right: 8px;
            }

            .charityHeart {
              width: 55px;
              padding: 6px 14px 5px;

              svg {
                width: 23px;
                height: 21px;
              }
            }

            .charityHeartActive {
              width: 55px;
              padding: 6px 14px 5px;

              svg {
                width: 23px;
                height: 21px;
              }
            }
          }
        }

        .research {
          padding: 24px;
          margin-bottom: 22px;

          h3 {
            font-size: 14px;
            margin-bottom: 8px;
          }

          p {
            font-size: 13px;
            margin-bottom: 8px;
            line-height: 18px;
          }
        }

        .share {
          padding: 20px 24px;

          h3 {
            font-size: 14px;
            margin-bottom: 5px;
          }

          p {
            font-size: 13px;
            margin-bottom: 12px;
            line-height: 20px;
          }

          .copy-text {
            padding: 5px 4px 5px 15px;

            input {
              width: 580px;
              font-size: 12px;
            }

            button {
              width: 72px;
              height: 25px;
              font-size: 12px;
            }
          }
        }
      }

      .charityContent {
        width: 100%;

        .charityTab {
          .react-tabs__tab-list {
            width: 450px;
            margin-bottom: 22px;
            padding: 14px 45px 15px 30px;
          }

          .react-tabs__tab {
            font-size: 15px;
          }

          .react-tabs__tab--selected {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .charitydetails {
    margin: 90px 0 25px;

    .charityDetailsWrap {
      padding-top: 20px;

      .charitySidebar {
        margin-bottom: 20px;

        .detailCard {
          margin-bottom: 20px;
          padding: 20px;

          .detailsFigure {
            height: 180px;
            margin-bottom: 12px;
          }

          .cardUser {
            .nameWIcon {
              margin-bottom: 4px;

              h3 {
                font-size: 13px;
              }

              figure {
                width: 18px;
                height: 18px;
                margin-left: 6px;
              }
            }

            .cardusername {
              padding-bottom: 10px;
              margin-bottom: 8px;

              h5 {
                font-size: 12px;
                margin-right: 9px;
              }

              .fullUserName {
                h5 {
                  font-size: 12px;
                  margin-left: 6px;
                }

                img {
                  width: 14px;
                }
              }
            }

            p {
              font-size: 12px;
              line-height: 16px;
              margin-bottom: 8px;
            }
          }

          .cardDetails {
            .cardAddress {
              margin-bottom: 5px;
            }

            h4,
            address {
              font-size: 12px;
              line-height: 18px;
              margin-right: 6px;
            }

            img {
              width: 15px;
            }
          }

          .cardButton {
            .basket {
              width: 130px;
              padding: 6px 16px;
              font-size: 12px;
              margin-right: 6px;
            }

            .charityHeart {
              width: 52px;
              padding: 6px 13px 5px;

              svg {
                width: 22px;
                height: 20px;
              }
            }

            .charityHeartActive {
              width: 52px;
              padding: 6px 13px 5px;

              svg {
                width: 22px;
                height: 20px;
              }
            }
          }
        }

        .research {
          padding: 22px;
          margin-bottom: 20px;

          h3 {
            font-size: 13px;
            margin-bottom: 6px;
          }

          p {
            font-size: 12px;
            margin-bottom: 6px;
            line-height: 18px;
          }
        }

        .share {
          padding: 18px 22px;

          h3 {
            font-size: 13px;
            margin-bottom: 4px;
          }

          p {
            font-size: 12px;
            margin-bottom: 8px;
            line-height: 18px;
          }

          .copy-text {
            padding: 5px 4px 5px 15px;

            input {
              width: 395px;
              font-size: 12px;
            }

            button {
              width: 72px;
              height: 25px;
              font-size: 12px;
            }
          }
        }
      }

      .charityContent {
        .charityTab {
          .react-tabs__tab-list {
            width: 410px;
            margin-bottom: 20px;
            padding: 14px 40px 14px 28px;
          }

          .react-tabs__tab {
            font-size: 14px;
          }

          .react-tabs__tab--selected {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .charitydetails {
    margin: 68px 0 15px;

    .charityDetailsWrap {
      padding-top: 15px;

      .charitySidebar {
        width: 100%;
        margin-bottom: 15px;

        .detailCard {
          margin-bottom: 15px;
          padding: 15px;

          .detailsFigure {
            height: 100px;
            margin-bottom: 10px;
          }

          .cardUser {
            .nameWIcon {
              h3 {
                font-size: 12px;
              }

              figure {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }
            }

            .cardusername {
              padding-bottom: 8px;
              margin-bottom: 6px;

              h5 {
                font-size: 11px;
                margin-right: 6px;
              }

              .fullUserName {
                h5 {
                  font-size: 11px;
                  margin-left: 6px;
                }

                img {
                  width: 13px;
                }
              }
            }

            p {
              font-size: 11px;
              margin-bottom: 7px;
            }
          }

          .cardDetails {
            margin-bottom: 10px;

            .cardAddress {
              margin-bottom: 5px;
            }

            .localDetails {
              .website h4 {
                width: 245px;
              }
            }

            .cardContact {
              flex-wrap: wrap;

              .mail {
                margin-bottom: 5px;
              }
            }

            h4 {
              font-size: 12px;
              line-height: 23px;
            }

            img {
              width: 15px;
            }
          }

          .cardButton {
            .basket {
              width: 115px;
              padding: 5px 14px;
              font-size: 12px;
              margin-right: 5px;
            }

            .charityHeart {
              width: 45px;
              padding: 6px 13px 5px;

              svg {
                width: 20px;
                height: 18px;
              }
            }

            .charityHeartActive {
              width: 45px;
              padding: 6px 13px 5px;

              svg {
                width: 20px;
                height: 18px;
              }
            }
          }
        }

        .research {
          padding: 15px;
          margin-bottom: 15px;

          h3 {
            font-size: 12px;
            margin-bottom: 4px;
          }

          p {
            font-size: 11px;
            margin-bottom: 5px;
            line-height: 15px;
          }
        }

        .share {
          padding: 15px;

          h3 {
            font-size: 12px;
            margin-bottom: 4px;
          }

          p {
            font-size: 11px;
            margin-bottom: 7px;
            line-height: 16px;
          }

          .copy-text {
            padding: 4px 4px 4px 12px;

            input {
              width: 170px;
              font-size: 11px;
            }

            button {
              width: 60px;
              height: 22px;
              font-size: 12px;
            }
          }
        }
      }

      .charityContent {
        width: 100%;

        .charityTab {
          .react-tabs__tab-list {
            width: 100%;
            overflow-x: auto;
            margin-bottom: 15px;
            padding: 15px;
          }

          .react-tabs__tab {
            flex-shrink: 0;
            margin-right: 10px;
            font-size: 12px;

            &:last-of-type {
              margin-right: 0;
            }
          }

          .react-tabs__tab--selected {
            font-size: 12px;
          }
        }

        .eventTab {
          .react-tabs__tab-list {
            width: 100%;
          }
        }
      }
    }
  }
}
