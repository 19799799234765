@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.rating {
  padding: 40px 30px 30px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

  .nonprofit-navigator {
    margin: 0 auto;
    width: 300px;
    margin-bottom: 25px;
  }

  .starRating {
    .star-heading {
      margin-bottom: 20px;
      h3 {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 20px;
        color: $black_1;
        text-transform: capitalize;
      }
    }
    .totalStar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      .totalStar-main,
      .total-score {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .totalStar-main {
        width: 60%;
      }
      .total-score {
        width: 40%;
      }
      label {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 18px;
        color: $black_1;
        text-transform: capitalize;
        margin-right: 30px;
      }
    }
    .starCount {
      display: flex;
      justify-content: space-around;
      margin-bottom: 30px;

      .leftStar,
      .rightStar {
        display: flex;
        align-items: center;
        flex-direction: column;
        .rating-label {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 16px;
          color: $green_shadow;
          margin-bottom: 7px;
        }
        span {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 16px;
          color: $black_1;
          margin-top: 7px;
        }
      }
    }
  }

  .emcompassRating {
    margin-bottom: 40px;
    .emcompassHeading {
      margin-bottom: 20px;
      h3 {
        font-family: $font_jost;
        font-weight: 700;
        font-size: 20px;
        color: $black_1;
        text-transform: capitalize;
      }
    }
    .totalEmcompass {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      .ratingBar {
        width: 130px;
        height: 130px;

        span,
        h1 {
          text-align: center;
          width: 98px;
          font-family: $font_jost;
          font-weight: 700;
          font-size: 16px;
          color: $black;
        }
      }
      h4 {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 18px;
        color: $black_1;
        margin-top: 8px;
        text-transform: capitalize;
      }
    }
    .compassCount {
      display: flex;
      justify-content: space-between;

      .countStar {
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;

        .ratingBars {
          width: 100px;

          span,
          h1 {
            text-align: center;
            width: 68px;
            font-family: $font_jost;
            font-weight: 500;
            font-size: 14px;
            color: $black;
          }
        }
        h4 {
          width: 54%;
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $black_1;
          margin-top: 8px;
          text-transform: capitalize;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .rating {
    padding: 37px 28px 28px;

    .nonprofit-navigator {
      width: 280px;
      margin-bottom: 20px;
    }

    .starRating {
      .star-heading {
        margin-bottom: 18px;
        h3 {
          font-size: 18px;
        }
      }
      .totalStar {
        margin-bottom: 18px;
        flex-wrap: wrap;
        h4 {
          font-size: 17px;
        }
        .totalStar-main {
          width: 100%;
          flex-wrap: wrap;
        }
        .total-score {
          width: 100%;
          flex-wrap: wrap;
        }
        .star-ratings {
          margin-bottom: 18px;
        }
      }
      .starCount {
        margin-bottom: 22px;

        .leftStar,
        .rightStar {
          h4 {
            font-size: 15px;
            margin-top: 9px;
          }
        }
      }
    }

    .emcompassRating {
      margin-bottom: 38px;

      .emcompassHeading {
        margin-bottom: 18px;
        h3 {
          font-size: 18px;
        }
      }
      .totalEmcompass {
        margin-bottom: 28px;
        .ratingBar {
          width: 120px;
          height: 120px;

          span,
          h1 {
            width: 74px;
            font-size: 15px;
          }
        }
        h4 {
          font-size: 17px;
          margin-top: 7px;
        }
      }
      .compassCount {
        .countStar {
          .ratingBars {
            width: 90px;

            span,
            h1 {
              width: 58px;
              font-size: 13px;
            }
          }
          h4 {
            width: 59%;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .rating {
    padding: 34px 26px 26px;

    .nonprofit-navigator {
      width: 260px;
      margin-bottom: 20px;
    }

    .starRating {
      .star-heading {
        margin-bottom: 16px;
        h3 {
          font-size: 17px;
        }
      }
      .totalStar {
        margin-bottom: 16px;
        h4 {
          font-size: 16px;
        }

        .star-ratings {
          margin-bottom: 16px;
          svg {
            width: 45px !important;
            height: 45px !important;
          }
        }
      }
      .starCount {
        margin-bottom: 20px;

        .leftStar,
        .rightStar {
          h4 {
            font-size: 14px;
            margin-top: 8px;
          }
          svg {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }

    .emcompassRating {
      margin-bottom: 34px;

      .emcompassHeading {
        margin-bottom: 16px;
        h3 {
          font-size: 17px;
        }
      }
      .totalEmcompass {
        margin-bottom: 26px;
        .ratingBar {
          width: 110px;
          height: 110px;

          span,
          h1 {
            width: 68px;
            font-size: 14px;
          }
        }
        h4 {
          font-size: 16px;
          margin-top: 6px;
        }
      }
      .compassCount {
        .countStar {
          .ratingBars {
            width: 85px;

            span,
            h1 {
              width: 53px;
              font-size: 12px;
            }
          }
          h4 {
            width: 71%;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .rating {
    padding: 30px 24px 24px;

    .nonprofit-navigator {
      width: 240px;
      margin-bottom: 18px;
    }

    .starRating {
      .star-heading {
        margin-bottom: 14px;
        h3 {
          font-size: 16px;
        }
      }
      .totalStar {
        margin-bottom: 15px;
        h4 {
          font-size: 15px;
        }

        .totalStar-main {
          width: 70%;
        }

        .total-score {
          width: 30%;
        }

        .star-ratings {
          margin-bottom: 15px;
          svg {
            width: 48px !important;
            height: 48px !important;
          }
        }
      }
      .starCount {
        margin-bottom: 20px;

        .leftStar,
        .rightStar {
          h4 {
            font-size: 14px;
            margin-top: 8px;
          }
          svg {
            width: 26px !important;
            height: 26px !important;
          }
        }
      }
    }

    .emcompassRating {
      margin-bottom: 30px;

      .emcompassHeading {
        margin-bottom: 14px;
        h3 {
          font-size: 16px;
        }
      }
      .totalEmcompass {
        margin-bottom: 24px;
        .ratingBar {
          width: 120px;
          height: 120px;

          span,
          h1 {
            width: 68px;
            font-size: 14px;
          }
        }
        h4 {
          font-size: 16px;
          margin-top: 6px;
        }
      }
      .compassCount {
        .countStar {
          .ratingBars {
            width: 90px;

            span,
            h1 {
              width: 53px;
              font-size: 12px;
            }
          }
          h4 {
            width: 54%;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .rating {
    padding: 20px;

    .nonprofit-navigator {
      width: 220px;
      margin-bottom: 16px;
    }

    .starRating {
      .star-heading {
        margin-bottom: 13px;
        h3 {
          font-size: 15px;
        }
      }
      .totalStar {
        margin-bottom: 14px;
        flex-wrap: wrap;
        h4 {
          font-size: 14px;
        }

        .totalStar-main {
          width: 100%;
        }

        .total-score {
          width: 100%;
        }

        .star-ratings {
          margin-bottom: 14px;
          svg {
            width: 46px !important;
            height: 46px !important;
          }
        }
      }
      .starCount {
        margin-bottom: 18px;

        .leftStar,
        .rightStar {
          h4 {
            font-size: 13px;
            margin-top: 7px;
          }
          svg {
            width: 25px !important;
            height: 25px !important;
          }
        }
      }
    }

    .emcompassRating {
      margin-bottom: 25px;

      .emcompassHeading {
        margin-bottom: 13px;
        h3 {
          font-size: 15px;
        }
      }
      .totalEmcompass {
        margin-bottom: 22px;
        .ratingBar {
          width: 110px;
          height: 110px;

          span,
          h1 {
            width: 63px;
            font-size: 13px;
          }
        }
        h4 {
          font-size: 14px;
          margin-top: 5px;
        }
      }
      .compassCount {
        .countStar {
          .ratingBars {
            width: 85px;

            span,
            h1 {
              width: 53px;
              font-size: 12px;
            }
          }
          h4 {
            width: 58%;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .rating {
    padding: 15px;

    .nonprofit-navigator {
      width: 200px;
      margin-bottom: 15px;
    }

    .starRating {
      .star-heading {
        margin-bottom: 12px;
        h3 {
          font-size: 14px;
        }
      }
      .totalStar {
        margin-bottom: 12px;
        flex-wrap: wrap;
        h4 {
          font-size: 13px;
        }

        .totalStar-main {
          width: 100%;
          flex-wrap: wrap;
        }
        .total-score {
          width: 100%;
          flex-wrap: wrap;
        }
        .star-ratings {
          margin-bottom: 12px;
          svg {
            width: 28px !important;
            height: 28px !important;
          }
        }
      }
      .starCount {
        margin-bottom: 15px;
        flex-wrap: wrap;

        .leftStar,
        .rightStar {
          width: 100%;
          h4 {
            font-size: 12px;
            margin-top: 5px;
          }
          svg {
            width: 22px !important;
            height: 22px !important;
          }
        }
        .leftStar {
          margin-bottom: 5px;
        }
      }
    }

    .emcompassRating {
      margin-bottom: 15px;

      .emcompassHeading {
        margin-bottom: 11px;
        h3 {
          font-size: 14px;
        }
      }
      .totalEmcompass {
        margin-bottom: 15px;
        .ratingBar {
          width: 100px;
          height: 100px;

          span,
          h1 {
            width: 60px;
            font-size: 12px;
          }
        }
        h4 {
          font-size: 13px;
          margin-top: 4px;
        }
      }
      .compassCount {
        flex-wrap: wrap;
        .countStar {
          width: 50%;
          .ratingBars {
            width: 80px;

            span,
            h1 {
              width: 50px;
              font-size: 11px;
            }
          }
          h4 {
            width: 100%;
            font-size: 11px;
            text-align: center;
          }
          &:first-of-type {
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}
