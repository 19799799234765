@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.causeDeails {
  width: 100%;
  margin: 114px 0 60px;

  .causeDetailsWrap {
    padding-top: 48px;
    width: 1343px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;

    .causeSidebar {
      width: 556px;

      .detailCard {
        background: $white;
        margin-bottom: 30px;
        padding: 30px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

        .detailsFigure {
          max-width: 494px;
          height: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 25px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            color: transparent;
          }
        }

        .causeDetailsContent {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .cardUser {
          h3 {
            font-family: $font_jost;
            font-weight: 700;
            font-size: 16px;
            color: $black_1;
            margin-bottom: 10px;
          }
        }

        .member {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .member-list {
            display: flex;
            align-items: center;

            .member-images {
              display: flex;
              margin-right: 15px;

              figure {
                display: flex;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 2px solid $white;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                  color: transparent;
                }

                span {
                  display: inline-block;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  background-repeat: no-repeat;
                  background-size: cover;
                }

                &:nth-child(2) {
                  margin-left: -5px;
                }

                &:nth-child(3) {
                  margin-left: -5px;
                }

                &:nth-child(4) {
                  margin-left: -5px;
                }
              }
            }

            .member-count {
              font-family: $font_jost;
              font-weight: 400;
              color: $green-shadow;
              font-size: 12px;
            }
          }
        }

        .cardButton {
          .causeHeart {
            width: 56px;
            padding: 8px 16px 5px;
            background-color: rgba($color: $theme-color, $alpha: 0.5);
            border-radius: 20px;
            cursor: pointer;
            svg {
              width: 25px;
              height: 23px;
              path {
                fill: white;
                stroke: white;
              }
            }
          }
          .causeHeartActive {
            width: 56px;
            padding: 8px 16px 5px;
            border-radius: 20px;
            background-color: transparent;
            border: 1px solid $green-shadow;
            cursor: pointer;
            svg {
              width: 25px;
              height: 23px;
              path {
                fill: $red;
                stroke: $red;
              }
            }
          }
        }
      }

      .research {
        background-color: $white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        padding: 30px 28px 30px 30px;
        margin-bottom: 30px;

        h3 {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 16px;
          color: $black_1;
          margin-bottom: 14px;
        }

        p {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
          margin-bottom: 15px;
          line-height: 25px;
        }

        .readLess {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .read-btn {
          display: flex;
          justify-content: end;

          button {
            font-family: $font_jost;
            font-weight: 700;
            color: $green-shadow;
            background: none;
            cursor: pointer;
            @include prefix(transition, all 0.3s ease-in-out);

            &:hover {
              color: $theme-color;
            }
          }

          .hideBtn {
            display: none;
          }
        }
      }

      .share {
        width: 100%;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        background-color: $white;
        padding: 25px 30px 30px;

        h3 {
          font-family: $font_jost;
          font-weight: 700;
          font-size: 16px;
          color: $black_1;
          margin-bottom: 8px;
          text-transform: capitalize;
        }

        p {
          font-family: $font_jost;
          font-weight: 400;
          font-size: 14px;
          color: $light-gray;
          margin-bottom: 15px;
          text-transform: capitalize;
          line-height: 25px;
        }

        .copy-text {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $light-green;
          border-radius: 50px;
          padding: 5px 4px 5px 22px;

          input {
            width: 370px;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $light-gray;
            background: none;
            text-overflow: ellipsis;
          }

          button {
            border-radius: 50px;
            background-color: $green-shadow;
            width: 84px;
            height: 28px;
            text-align: center;
            font-family: $font_jost;
            font-weight: 400;
            font-size: 14px;
            color: $white;
            cursor: pointer;
          }
        }
      }
    }

    .causeContent {
      width: 754px;

      .tabCommunity {
        // padding: 25px 30px 28px 40px !important;
      }
    }

    .causeTab {
      .react-tabs__tab-list {
        border-bottom: none;
        width: 404px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $light-green;
        border-radius: 50px;
        margin-bottom: 30px;
        padding: 17px 59px 18px 40px;
      }

      .react-tabs__tab {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 16px;
        color: $black_1;
        border: none;
        padding: 0px;
        background-color: $light-green;
      }

      .react-tabs__tab--selected {
        font-weight: 700;
        font-size: 16px;
        color: $green-shadow;
        background-color: $light-green;
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .causeDeails {
    margin: 108px 0 55px;

    .causeDetailsWrap {
      padding-top: 45px;
      width: 1138px;

      .causeSidebar {
        width: 500px;

        .detailCard {
          margin-bottom: 28px;
          padding: 28px;

          .detailsFigure {
            max-width: 100%;
            height: 230px;
            margin-bottom: 23px;
          }

          .cardUser {
            h3 {
              font-size: 15px;
              margin-bottom: 8px;
            }
          }

          .cardButton {
            .causeHeart {
              width: 54px;
              padding: 8px 15px 5px;

              svg {
                width: 24px;
                height: 22px;
              }
            }

            .causeHeartActive {
              width: 54px;
              padding: 8px 15px 5px;

              svg {
                width: 24px;
                height: 22px;
              }
            }
          }
        }

        .research {
          padding: 28px 26px 28px 28px;
          margin-bottom: 28px;

          h3 {
            font-size: 15px;
            margin-bottom: 12px;
          }
          p {
            font-size: 13px;
            margin-bottom: 13px;
            line-height: 22px;
          }
        }

        .share {
          padding: 22px 28px 28px;
          h3 {
            font-size: 15px;
            margin-bottom: 7px;
          }
          p {
            font-size: 13px;
            margin-bottom: 13px;
            line-height: 23px;
          }
          .copy-text {
            padding: 5px 4px 5px 15px;

            input {
              width: 340px;
              font-size: 13px;
              letter-spacing: 0.001em;
              white-space: nowrap;
              overflow: hidden;
            }
            button {
              width: 75px;
              height: 26px;
              font-size: 13px;
            }
          }
        }
      }

      .causeContent {
        width: 600px;
      }

      .causeTab {
        .react-tabs__tab-list {
          width: 395px;
          margin-bottom: 28px;
          padding: 16px 55px 17px 38px;
        }
        .react-tabs__tab {
          font-size: 15px;
        }
        .react-tabs__tab--selected {
          font-size: 15px;
        }
      }
    }
  }
}

@include responsive(lg) {
  .causeDeails {
    margin: 104px 0 45px;

    .causeDetailsWrap {
      padding-top: 40px;
      width: 960px;

      .causeSidebar {
        width: 410px;

        .detailCard {
          margin-bottom: 26px;
          padding: 26px;

          .detailsFigure {
            height: 200px;
            margin-bottom: 21px;
          }

          .cardUser {
            h3 {
              font-size: 14px;
              margin-bottom: 7px;
            }
          }

          .cardButton {
            .causeHeart {
              width: 55px;
              padding: 6px 14px 5px;

              svg {
                width: 23px;
                height: 21px;
              }
            }

            .causeHeartActive {
              width: 55px;
              padding: 6px 14px 5px;

              svg {
                width: 23px;
                height: 21px;
              }
            }
          }
        }

        .research {
          padding: 26px 24px 26px 26px;
          margin-bottom: 26px;

          h3 {
            font-size: 14px;
            margin-bottom: 10px;
          }
          p {
            font-size: 13px;
            margin-bottom: 11px;
            line-height: 20px;
          }
        }

        .share {
          padding: 20px 26px;
          h3 {
            font-size: 14px;
            margin-bottom: 5px;
          }
          p {
            font-size: 13px;
            margin-bottom: 12px;
            line-height: 20px;
          }
          .copy-text {
            padding: 5px 4px 5px 15px;

            input {
              width: 268px;
              font-size: 12px;
            }
            button {
              width: 72px;
              height: 25px;
              font-size: 12px;
            }
          }
        }
      }

      .causeContent {
        width: 510px;
      }

      .causeTab {
        .react-tabs__tab-list {
          width: 320px;
          margin-bottom: 25px;
          padding: 14px 45px 15px 30px;
        }
        .react-tabs__tab {
          font-size: 14px;
        }
        .react-tabs__tab--selected {
          font-size: 14px;
        }
      }
    }
  }
}

@include responsive(md) {
  .causeDeails {
    margin: 95px 0 35px;

    .causeDetailsWrap {
      padding-top: 30px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;

      .causeSidebar {
        width: 100%;
        margin-bottom: 25px;

        .detailCard {
          margin-bottom: 22px;
          padding: 22px;

          .detailsFigure {
            height: 200px;
            margin-bottom: 15px;
          }

          .cardUser {
            h3 {
              font-size: 13px;
              margin-bottom: 5px;
            }
          }

          .cardButton {
            .causeHeart {
              width: 55px;
              padding: 6px 14px 5px;

              svg {
                width: 23px;
                height: 21px;
              }
            }

            .causeHeartActive {
              width: 55px;
              padding: 6px 14px 5px;

              svg {
                width: 23px;
                height: 21px;
              }
            }
          }
        }

        .research {
          padding: 24px;
          margin-bottom: 22px;

          h3 {
            font-size: 14px;
            margin-bottom: 8px;
          }
          p {
            font-size: 13px;
            margin-bottom: 8px;
            line-height: 18px;
          }
        }

        .share {
          padding: 20px 24px;
          h3 {
            font-size: 14px;
            margin-bottom: 5px;
          }
          p {
            font-size: 13px;
            margin-bottom: 12px;
            line-height: 20px;
          }
          .copy-text {
            padding: 5px 4px 5px 15px;

            input {
              width: 580px;
              font-size: 12px;
            }
            button {
              width: 72px;
              height: 25px;
              font-size: 12px;
            }
          }
        }
      }

      .causeContent {
        width: 100%;
      }

      .causeTab {
        .react-tabs__tab-list {
          width: 350px;
          margin-bottom: 22px;
          padding: 14px 45px 15px 30px;
        }
        .react-tabs__tab {
          font-size: 15px;
        }
        .react-tabs__tab--selected {
          font-size: 15px;
        }
      }
    }
  }
}

@include responsive(sm) {
  .causeDeails {
    margin: 90px 0 25px;

    .causeDetailsWrap {
      padding-top: 20px;

      .causeSidebar {
        margin-bottom: 20px;

        .detailCard {
          margin-bottom: 20px;
          padding: 20px;

          .detailsFigure {
            height: 180px;
            margin-bottom: 12px;
          }

          .cardUser {
            h3 {
              font-size: 13px;
              margin-bottom: 4px;
            }
          }

          .cardButton {
            .causeHeart {
              width: 52px;
              padding: 6px 13px 5px;

              svg {
                width: 22px;
                height: 20px;
              }
            }

            .causeHeartActive {
              width: 52px;
              padding: 6px 13px 5px;

              svg {
                width: 22px;
                height: 20px;
              }
            }
          }
        }

        .research {
          padding: 22px;
          margin-bottom: 20px;

          h3 {
            font-size: 13px;
            margin-bottom: 6px;
          }
          p {
            font-size: 12px;
            margin-bottom: 6px;
            line-height: 18px;
          }
        }

        .share {
          padding: 18px 22px;
          h3 {
            font-size: 13px;
            margin-bottom: 4px;
          }
          p {
            font-size: 12px;
            margin-bottom: 8px;
            line-height: 18px;
          }
          .copy-text {
            padding: 5px 4px 5px 15px;

            input {
              width: 395px;
              font-size: 12px;
            }
            button {
              width: 72px;
              height: 25px;
              font-size: 12px;
            }
          }
        }
      }

      .causeTab {
        .react-tabs__tab-list {
          width: 320px;
          margin-bottom: 20px;
          padding: 14px 40px 14px 28px;
        }
        .react-tabs__tab {
          font-size: 14px;
        }
        .react-tabs__tab--selected {
          font-size: 14px;
        }
      }
    }
  }
}

@include responsive(xs) {
  .causeDeails {
    margin: 68px 0 15px;

    .causeDetailsWrap {
      padding-top: 15px;

      .causeSidebar {
        margin-bottom: 15px;

        .detailCard {
          margin-bottom: 15px;
          padding: 15px;

          .detailsFigure {
            height: 100px;
            margin-bottom: 10px;
          }

          .cardUser {
            h3 {
              font-size: 12px;
            }
          }

          .cardButton {
            .causeHeart {
              width: 45px;
              padding: 6px 13px 5px;

              svg {
                width: 20px;
                height: 18px;
              }
            }

            .causeHeartActive {
              width: 45px;
              padding: 6px 13px 5px;

              svg {
                width: 20px;
                height: 18px;
              }
            }
          }
        }

        .research {
          padding: 15px;
          margin-bottom: 15px;

          h3 {
            font-size: 12px;
            margin-bottom: 4px;
          }
          p {
            font-size: 11px;
            margin-bottom: 5px;
            line-height: 15px;
          }
        }

        .share {
          padding: 15px;
          h3 {
            font-size: 12px;
            margin-bottom: 4px;
          }
          p {
            font-size: 11px;
            margin-bottom: 7px;
            line-height: 16px;
          }
          .copy-text {
            padding: 4px 4px 4px 12px;

            input {
              width: 170px;
              font-size: 11px;
            }
            button {
              width: 60px;
              height: 22px;
              font-size: 12px;
            }
          }
        }
      }

      .causeContent {
        width: 100%;
      }

      .causeTab {
        .react-tabs__tab-list {
          width: 280px;
          margin-bottom: 15px;
          padding: 12px 28px;
        }
        .react-tabs__tab {
          font-size: 12px;
        }
        .react-tabs__tab--selected {
          font-size: 12px;
        }
      }
    }
  }
}
