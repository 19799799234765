@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.eventTab {
  padding: 30px 10px 0px 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background-color: $white;
  .tabsForEvent {
    .react-tabs__tab-list {
      display: flex;
      justify-content: start !important;
      background-color: white !important;
      padding: 0 !important;
      margin-bottom: 20px !important;
      margin-left: 10px !important;
    }
    .react-tabs__tab {
      font-size: 16px !important;
      background-color: white !important;

      &:first-of-type {
        margin-right: 40px;
      }
    }
    .react-tabs__tab--selected {
      background-color: white !important;
    }
  }
  .content {
    max-height: 640px;
    padding: 0 30px 0 10px !important;
  }
}

.upcomingEvent,
.pastEvent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

// responsive
@include responsive(xl) {
  .eventTab {
    padding: 28px 10px 0 10px;
    .tabsForEvent {
      .react-tabs__tab-list {
        margin-bottom: 18px !important;
        margin-left: 10px !important;
      }
      .react-tabs__tab {
        font-size: 15px !important;

        &:first-of-type {
          margin-right: 38px;
        }
      }
    }
    .content {
      padding: 0 28px 0 10px !important;
    }
  }
}

@include responsive(lg) {
  .eventTab {
    padding: 26px 10px 0 10px;
    .tabsForEvent {
      .react-tabs__tab-list {
        margin-bottom: 16px !important;
        margin-left: 10px !important;
      }
      .react-tabs__tab {
        font-size: 14px !important;

        &:first-of-type {
          margin-right: 36px;
        }
      }
    }
    .content {
      padding: 0 15px 0 10px !important;
    }
  }
}

@include responsive(md) {
  .eventTab {
    padding: 24px 10px 0 10px;
    .tabsForEvent {
      .react-tabs__tab-list {
        margin-bottom: 15px !important;
        margin-left: 8px !important;
      }
      .react-tabs__tab {
        font-size: 13px !important;

        &:first-of-type {
          margin-right: 32px;
        }
      }
    }
    .content {
      padding: 0 15px !important;
    }
  }
}

@include responsive(sm) {
  .eventTab {
    padding: 20px 10px 0 10px;
    .tabsForEvent {
      .react-tabs__tab-list {
        margin-bottom: 13px !important;
        margin-left: 8px !important;
      }
      .react-tabs__tab {
        font-size: 13px !important;

        &:first-of-type {
          margin-right: 28px;
        }
      }
    }
    .content {
      padding: 0 15px !important;
    }
  }
}

@include responsive(xs) {
  .eventTab {
    padding: 15px 10px 0 10px;
    .tabsForEvent {
      .react-tabs__tab-list {
        margin-bottom: 12px !important;
        margin-left: 7px !important;
      }
      .react-tabs__tab {
        font-size: 12px !important;

        &:first-of-type {
          margin-right: 22px;
        }
      }
    }
    .content {
      padding: 0 15px !important;
    }
  }
}
