@import "../../../../scss/variable";
@import "../../../../scss/mixin";
@import "../../../../scss/common";
@import "../../../../scss/reset";
@import "../../../../scss/flex";

.media {
  padding: 30px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background-color: $white;

  .mediaTab {
    .react-tabs__tab-list {
      display: flex;
      justify-content: start !important;
      background-color: white !important;
      padding: 0 !important;
      margin-bottom: 0px !important;
    }
    .react-tabs__tab {
      font-size: 20px !important;
      background-color: white !important;

      &:first-of-type {
        margin-right: 30px;
      }
    }
    .react-tabs__tab--selected {
      background-color: white !important;
    }
    .videoMedia {
      .rowVideo {
        .VideoColumn {
          margin-top: 15px !important;
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .media {
    padding: 28px;

    .mediaTab {
      .react-tabs__tab-list {
        width: 400px !important;
      }
      .react-tabs__tab {
        font-size: 18px !important;

        &:first-of-type {
          margin-right: 28px;
        }
      }
      .videoMedia {
        .rowVideo {
          .VideoColumn {
            margin-top: 14px !important;
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .media {
    padding: 25px;

    .mediaTab {
      .react-tabs__tab {
        font-size: 16px !important;

        &:first-of-type {
          margin-right: 25px;
        }
      }
      .videoMedia {
        .rowVideo {
          .VideoColumn {
            margin-top: 14px !important;
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .media {
    padding: 22px;

    .mediaTab {
      .react-tabs__tab {
        font-size: 15px !important;

        &:first-of-type {
          margin-right: 22px;
        }
      }
      .videoMedia {
        .rowVideo {
          .VideoColumn {
            margin-top: 13px !important;
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .media {
    padding: 20px;

    .mediaTab {
      .react-tabs__tab {
        font-size: 15px !important;

        &:first-of-type {
          margin-right: 20px;
        }
      }
      .videoMedia {
        .rowVideo {
          .VideoColumn {
            margin-top: 12px !important;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .media {
    padding: 15px;

    .mediaTab {
      .react-tabs__tab-list {
        width: 100% !important;
        height: 100% !important;
        overflow: hidden !important;
      }
      .react-tabs__tab {
        font-size: 14px !important;

        &:first-of-type {
          margin-right: 16px;
        }
      }
      .videoMedia {
        .rowVideo {
          .VideoColumn {
            margin-top: 12px !important;
          }
        }
      }
    }
  }
}
