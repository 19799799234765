@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.postTabing {
  width: 100%;
  padding: 30px 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

  .scrollBarTab {
    // min-height: 320px;

    .scrollbar-container {
      max-height: 320px !important;
      padding: 0 30px;
    }
  }
}

.postTabingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  padding: 0 30px;

  h4 {
    font-family: $font_jost;
    font-weight: 700;
    font-size: 16px;
    color: $black_1;
    text-transform: capitalize;
  }
  button {
    background-color: transparent;
    color: $green-shadow;
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }
}

.postSearch {
  padding: 0 30px;
  .searchGroup {
    display: flex;
    justify-content: space-between;
    border: 1px solid $gray;
    padding: 13px 15px;
    border-radius: 7px;
    margin-bottom: 30px;

    input {
      width: 90%;
      font-family: $font_jost;
      font-weight: 400;
      color: $gray;
    }
    svg path {
      stroke: $gray;
    }
  }
}

.postTabingContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .postTabbingLeft {
    display: flex;
    align-items: center;
    cursor: pointer;

    figure {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      margin-right: 12px;

      img {
        width: 100%;
        height: 100%;
        color: transparent;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .postDonor {
      h4 {
        font-family: $font_jost;
        font-weight: 500;
        font-size: 14px;
        color: $black_1;
        text-transform: capitalize;
      }
      h5 {
        font-family: $font_jost;
        font-weight: 400;
        font-size: 12px;
        color: $gray;
      }
    }
  }
  .postTabbingButton {
    button {
      font-family: $font_jost;
      font-weight: 400;
      font-size: 14px;
      color: $white;
      cursor: pointer;
      @include prefix(transition, all 0.3s ease-in-out);
      border-radius: 20px;
    }
    .requestButton {
      width: 130px;
      padding: 8px 14px;
      text-align: center;
      background-color: $green-shadow;
      @include prefix(transition, all 0.3s ease-in-out);
      &:hover {
        background-color: $theme-color;
      }
    }
    .pendingButton {
      width: 130px;
      padding: 8px 14px;
      text-align: center;
      background-color: $gray_3;
      @include prefix(transition, all 0.3s ease-in-out);
      &:hover {
        background-color: rgba($color: $gray_3, $alpha: 0.8);
      }
    }
    .postButtonGrp {
      button {
        width: 50px;
        padding: 9px 18px 10px 19px;

        &:first-of-type {
          background-color: $green-shadow;
          margin-right: 10px;
          &:hover {
            background-color: $theme-color;
          }
        }
      }
    }
  }
}

// responsive
@include responsive(xl) {
  .postTabing {
    padding: 26px 0;

    .scrollBarTab {
      .scrollbar-container {
        max-height: 320px !important;
        padding: 0 26px;
      }
    }
  }

  .postTabingHeader {
    margin-bottom: 16px;
    padding: 0 26px;

    h4 {
      font-size: 15px;
    }
    button {
      font-size: 13px;
    }
  }

  .postSearch {
    padding: 0 26px;
    .searchGroup {
      padding: 12px 14px;
      margin-bottom: 26px;

      input {
        width: 92%;
      }
    }
  }

  .postTabingContent {
    margin-bottom: 26px;

    .postTabbingLeft {
      figure {
        width: 52px;
        height: 52px;
        margin-right: 11px;
      }
      .postDonor {
        h4 {
          font-size: 13px;
        }
        h5 {
          font-size: 12px;
        }
      }
    }
    .postTabbingButton {
      button {
        font-size: 13px;
      }
      .requestButton,
      .pendingButton {
        width: 125px;
        padding: 8px 13px;
      }
      .postButtonGrp {
        button {
          width: 50px;
          padding: 7px 16px 11px 18px;

          &:first-of-type {
            margin-right: 9px;
          }
        }
      }
    }
  }
}

@include responsive(lg) {
  .postTabing {
    padding: 24px 0;

    .scrollBarTab {
      .scrollbar-container {
        max-height: 300px !important;
        padding: 0 24px;
      }
    }
  }

  .postTabingHeader {
    margin-bottom: 15px;
    padding: 0 24px;

    h4 {
      font-size: 15px;
    }
    button {
      font-size: 13px;
    }
  }

  .postSearch {
    padding: 0 24px;
    .searchGroup {
      padding: 11px 13px;
      margin-bottom: 24px;

      input {
        width: 92%;
      }
    }
  }

  .postTabingContent {
    margin-bottom: 24px;

    .postTabbingLeft {
      figure {
        width: 50px;
        height: 50px;
        margin-right: 11px;
      }
      .postDonor {
        h4 {
          font-size: 13px;
        }
        h5 {
          font-size: 12px;
        }
      }
    }
    .postTabbingButton {
      button {
        font-size: 13px;
      }
      .requestButton,
      .pendingButton {
        width: 120px;
        padding: 8px 12px;
      }
      .postButtonGrp {
        button {
          width: 45px;
          padding: 6px 14px 10px 16px;

          &:first-of-type {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@include responsive(md) {
  .postTabing {
    padding: 22px 0;

    .scrollBarTab {
      .scrollbar-container {
        max-height: 290px !important;
        padding: 0 22px;
      }
    }
  }

  .postTabingHeader {
    margin-bottom: 15px;
    padding: 0 22px;

    h4 {
      font-size: 14px;
    }
    button {
      font-size: 12px;
    }
  }

  .postSearch {
    padding: 0 22px;
    .searchGroup {
      padding: 11px;
      margin-bottom: 22px;

      input {
        width: 92%;
      }
    }
  }

  .postTabingContent {
    margin-bottom: 22px;

    .postTabbingLeft {
      figure {
        width: 48px;
        height: 48px;
        margin-right: 10px;
      }
      .postDonor {
        h4 {
          font-size: 13px;
        }
        h5 {
          font-size: 12px;
        }
      }
    }
    .postTabbingButton {
      button {
        font-size: 12px;
      }
      .requestButton,
      .pendingButton {
        width: 110px;
        padding: 7px 10px;
      }
      .postButtonGrp {
        button {
          width: 45px;
          padding: 6px 14px 10px 16px;

          &:first-of-type {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@include responsive(sm) {
  .postTabing {
    padding: 20px 0;

    .scrollBarTab {
      .scrollbar-container {
        max-height: 280px !important;
        padding: 0 20px;
      }
    }
  }

  .postTabingHeader {
    margin-bottom: 15px;
    padding: 0 20px;

    h4 {
      font-size: 14px;
    }
    button {
      font-size: 12px;
    }
  }

  .postSearch {
    padding: 0 20px;
    .searchGroup {
      padding: 11px;
      margin-bottom: 20px;

      input {
        width: 92%;
      }
    }
  }

  .postTabingContent {
    margin-bottom: 20px;

    .postTabbingLeft {
      figure {
        width: 45px;
        height: 45px;
        margin-right: 9px;
      }
      .postDonor {
        h4 {
          font-size: 13px;
        }
        h5 {
          font-size: 12px;
        }
      }
    }
    .postTabbingButton {
      button {
        font-size: 12px;
      }
      .requestButton,
      .pendingButton {
        width: 100px;
        padding: 6px 8px;
      }
      .postButtonGrp {
        button {
          width: 42px;
          padding: 6px 12px 8px 13px;

          &:first-of-type {
            margin-right: 6px;
          }
        }
      }
    }
  }
}

@include responsive(xs) {
  .postTabing {
    padding: 15px 0;

    .scrollBarTab {
      .scrollbar-container {
        max-height: 280px !important;
        padding: 0 15px;
      }
    }
  }

  .postTabingHeader {
    margin-bottom: 15px;
    padding: 0 15px;

    h4 {
      font-size: 13px;
    }
    button {
      font-size: 12px;
    }
  }

  .postSearch {
    padding: 0 15px;
    .searchGroup {
      padding: 10px;
      margin-bottom: 15px;

      input {
        width: 86%;
      }
    }
  }

  .postTabingContent {
    margin-bottom: 15px;

    .postTabbingLeft {
      figure {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        flex-shrink: 0;
      }
      .postDonor {
        h4 {
          font-size: 13px;
        }
        h5 {
          font-size: 12px;
        }
      }
    }
    .postTabbingButton {
      button {
        font-size: 11px;
      }
      .requestButton,
      .pendingButton {
        width: 80px;
        padding: 5px 0px;
      }
      .postButtonGrp {
        button {
          width: 40px;
          padding: 5px 10px 7px 10px;

          &:first-of-type {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
