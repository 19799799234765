@import "../../scss/variable";
@import "../../scss/mixin";
@import "../../scss/common";
@import "../../scss/reset";
@import "../../scss/flex";

.buttonSubmit {
  @include prefix(transition, all 0.3s ease-in-out);
  &:hover {
    background-color: $theme-color !important;
  }
}

.signin-account {
  margin-bottom: 116px !important;
}

// responsive
@include responsive(lg) {
  .signin-account {
    margin-bottom: 100px !important;
  }
}

@include responsive(md) {
  .signin-account {
    margin-bottom: 90px !important;
  }
}

@include responsive(sm) {
  .signin-account {
    margin-bottom: 80px !important;
  }
}

@include responsive(xs) {
  .signin-account {
    margin-bottom: 70px !important;
  }
}
