@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.swiperText {
  margin-top: 40px;
  text-align: center;
  padding: 0 70px;
  h3 {
    font-family: $font_lora;
    font-weight: 700;
    font-size: 42px;
    color: $green-shadow;
    line-height: 55px;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }
  p {
    font-family: $font_jost;
    font-weight: 400;
    font-size: 14px;
    color: $black_1;
    line-height: 30px;
  }
}

// responsive
@include responsive(xl) {
  .swiperText {
    padding: 0 60px;
    h3 {
      font-size: 40px;
    }
  }
}

@include responsive(lg) {
  .swiperText {
    margin-top: 18px;
    padding: 0 66px;
    h3 {
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 16px;
    }
    p {
      font-size: 13px;
      line-height: 26px;
    }
  }
}
