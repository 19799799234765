@import "../../../scss/variable";
@import "../../../scss/mixin";
@import "../../../scss/common";
@import "../../../scss/reset";
@import "../../../scss/flex";

.communityCard {
  width: 312px;
  padding: 22px 18px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  margin-right: 25px;
  margin-top: 30px;
  cursor: pointer;
  &:nth-of-type(4n + 0) {
    margin-right: 0px;
  }

  .communityImage {
    margin-bottom: 15px;
    .mainImage {
      display: inline-block;
      width: 100%;
      height: 130px;
      vertical-align: middle;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        color: transparent;
      }
    }
  }
  .communityListContent {
    h4 {
      min-height: 25px;
      font-family: $font_jost;
      font-weight: 700;
      font-size: 16px;
      color: $black_1;
      text-align: center;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}